import { Component, Input, OnInit } from "@angular/core";
import { License } from "src/app/models/user/user-license";
import { UnitService } from "src/app/services/unit/unit.service";
import { UsedResourcesForUser } from "src/app/models/user/used-resources-for-user";
import { LicensedResourceComponent } from "../licensed-resource/licensed-resource.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { LicensedUsedResourceComponent } from "../licensed-used-resource/licensed-used-resource.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-license",
  templateUrl: "./license.component.html",
  styleUrls: ["./license.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    LicensedResourceComponent,
    LicensedUsedResourceComponent,
  ],
})
export class LicenseComponent implements OnInit {
  usedMusicPieces: number = 0;
  usedPracticeTime: number = 0;
  usedStorage: number = 0;
  usedCollections: number = 0;
  usedPracticeRoutines: number = 0;

  @Input()
  license?: License;

  _usedResources?: UsedResourcesForUser;

  @Input()
  set usedResources(resources: UsedResourcesForUser | undefined) {
    this._usedResources = resources;

    if (resources) {
      this.usedStorage = this.unitService.bytesToMegaBytes(
        resources.totalStorageBytes,
      );

      this.usedMusicPieces = resources.numberOfPiecesThisYear;

      this.usedPracticeTime = this.unitService.millisecondsAsHours(
        resources.practiceDurationThisMonthMs,
      );

      this.usedCollections = resources.totalCollections;
      this.usedPracticeRoutines = resources.totalPracticeRoutines;
    } else {
      this.usedMusicPieces = 0;
      this.usedPracticeTime = 0;
      this.usedStorage = 0;
      this.usedCollections = 0;
      this.usedPracticeTime = 0;
    }
  }

  get usedResources() {
    return this._usedResources;
  }

  constructor(private readonly unitService: UnitService) {}

  ngOnInit(): void {}

  millisecondsAsHours(ms: number | "unlimited"): number | "unlimited" {
    if (ms == "unlimited") {
      return "unlimited";
    }
    return this.unitService.millisecondsAsHours(ms);
  }

  bytesAsMegaBytes(bytes: number | "unlimited"): number | "unlimited" {
    if (bytes == "unlimited") {
      return "unlimited";
    }
    return this.unitService.bytesToMegaBytes(bytes);
  }
}
