import { SubscriptionHelper } from "@aht/frappe-client";
import { CurrencyPipe, JsonPipe } from "@angular/common";
import { Component, effect, input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SubscriptionLike } from "rxjs";
import { PaymentDialogData } from "src/app/models/artist/payment-dialog-data";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { MaterialModule } from "src/app/modules/material.module";
import { AppState } from "src/app/ngrx/reducers";
import { PricePipe } from "src/app/pipes/price.pipe";
import { PaymentDialogComponent } from "../payment-dialog/payment-dialog.component";
import { ConfirmationDialogHelper } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { UserData } from "src/app/models/user/user-data";
import { PurchaseStatusPageLinkComponent } from "../../purchase/purchase-status-page-link/purchase-status-page-link.component";
import { VideoFeedbackPurchaseDetails } from "src/app/ngrx/selectors/purchase.selector";
import { PurchaseSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-purchase-video-feedback",
  templateUrl: "./purchase-video-feedback.component.html",
  standalone: true,
  styleUrls: [],
  imports: [
    TranslateModule,
    CurrencyPipe,
    JsonPipe,
    MaterialModule,
    PurchaseStatusPageLinkComponent,
  ],
})
export class PurchaseVideoFeedbackComponent implements OnDestroy {
  purchaseButtonTranslationData: { price: string } = { price: "€ 0.-" };
  finishPurchaseButtonTranslationData: { price: string } = { price: "€ 0.-" };

  artistProfile = input.required<StoredArtistProfile>();
  loggedInUser = input.required<UserData | undefined>();

  videoFeedbackPurchaseDetails?: VideoFeedbackPurchaseDetails;

  videoFeedbackPurchaseDetailsSubscription?: SubscriptionLike;
  private readonly subscriptionHelper = new SubscriptionHelper();

  hasPendingPayment: boolean = false;
  requiresPayment: boolean = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly translate: TranslateService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly matDialog: MatDialog,
  ) {
    const pipe = new PricePipe(translate, currencyPipe);

    effect(() => {
      this.purchaseButtonTranslationData = {
        price: pipe.transform(this.artistProfile().videoFeedbackPrice) || "",
      };
    });

    effect(
      () => {
        const ap = this.artistProfile();
        if (ap && !this.videoFeedbackPurchaseDetailsSubscription) {
          this.videoFeedbackPurchaseDetailsSubscription = this.store
            .select(PurchaseSelectors.selectPurchaseDetailsForVideoFeedback(ap))
            .subscribe((data) => {
              this.videoFeedbackPurchaseDetails = data;
              if (data.videoFeedbackPurchase) {
                this.finishPurchaseButtonTranslationData = {
                  price: pipe.transform(data.videoFeedbackPurchase.price) || "",
                };
                this.setFlagsFromPurchaseStatus(data.status);
              }
            });

          this.subscriptionHelper.add(
            this.videoFeedbackPurchaseDetailsSubscription,
          );
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngOnDestroy(): void {
    this.subscriptionHelper.unsubscribeAll();
  }

  private setFlagsFromPurchaseStatus(
    purchaseStatus: PurchaseSelectors.PurchaseStatus,
  ) {
    switch (purchaseStatus) {
      case PurchaseSelectors.PurchaseStatus.PENDING_PAYMENT:
        this.hasPendingPayment = true;
        this.requiresPayment = false;
        break;
      case PurchaseSelectors.PurchaseStatus.REQUIRES_PAYMENT:
        this.requiresPayment = true;
        this.hasPendingPayment = false;
        break;
      default:
        break;
    }
  }

  purchaseFeedback() {
    const loggedInUser = this.loggedInUser();
    if (loggedInUser && loggedInUser.email != this.artistProfile().user) {
      new ConfirmationDialogHelper(
        this.matDialog,
        "artist.purchase-video-feedback.buy-dialog-title",
        "artist.purchase-video-feedback.buy-dialog-content",
      ).show(() => {
        if (this.videoFeedbackPurchaseDetails) {
          this.matDialog.open<PaymentDialogComponent, PaymentDialogData>(
            PaymentDialogComponent,
            {
              data: {
                purchaseDetails: this.videoFeedbackPurchaseDetails,
                purchaseType: "video-feedback",
              },
              minWidth: "40vw",
              disableClose: true,
              hasBackdrop: false,
            },
          );
        }
      });
    }
  }
}
