<h1 mat-dialog-title>{{ data.musicPiece.nameOfPiece }}</h1>
<mat-dialog-content>
  <yr-music-piece-type
    [isCopiedMusicPiece]="data.musicPiece.isCopy"
    [isLinkedMusicPiece]="data.isLinkedMusicPiece"
    [collectionInfo]="data.collectionInfo"
    [showShortCollectionInfo]="false"
  ></yr-music-piece-type>

  <br />
  {{ "music-piece.music-piece-card.createdAt" | translate }}
  {{ data.musicPiece.creation | date : ("common.dateTimeFormat" | translate) }}
  <br />
  {{ "music-piece.music-piece-card.lastViewed" | translate }}
  <span *ngIf="data.musicPiece.lastTimeViewed">
    {{
      data.musicPiece.lastTimeViewed
        | date : ("common.dateTimeFormat" | translate)
    }}
  </span>
  <span *ngIf="!data.musicPiece.lastTimeViewed">-</span>
  <br />
  <yr-music-piece-details
    [isListing]="data.isListing"
    [musicPiece]="data.musicPiece"
  ></yr-music-piece-details>
</mat-dialog-content>

<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>
    {{ "common.close" | translate }}
  </button>
</div>
