import { StoredPageBreakDto } from "src/app/dtos/page-break/stored-page-break-dto";
import { PageBreak } from "src/app/models/page-break/page-break";
import { Conversion } from "../conversion";

export class StoredPageBreakDtoToPageBreakConversion
  implements Conversion<StoredPageBreakDto, PageBreak>
{
  convert(item: StoredPageBreakDto): PageBreak {
    return {
      name: item.name,
      timestampMs: item.timestamp,
      recording: item.recording,
      musicScore: item.music_score,
    };
  }
}
