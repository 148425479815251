import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { tap, filter, take } from "rxjs/operators";
import { UploadEvent } from "src/app/models/music-piece/attachment-upload-event";
import { UploadDialogData } from "src/app/models/music-piece/upload-dialog-data";
import { UploadDialogResult } from "src/app/models/music-piece/upload-dialog-result";
import { MaterialModule } from "src/app/modules/material.module";
import { AttachmentService } from "src/app/services/music-piece/attachment.service";

@Component({
  selector: "yr-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  isUploading = false;
  isUploadFinishedSuccessfully = false;

  progressPercentage: number = 0;

  private wakeLock?: /* WakeLockSentinel */ any;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: UploadDialogData,
    private readonly dialogReference: MatDialogRef<
      UploadDialogComponent,
      UploadDialogResult
    >,
    private readonly attachmentService: AttachmentService,
  ) {}

  ngOnInit(): void {
    this.performUpload();
    this.acquireWakeLock();
  }
  ngOnDestroy(): void {
    this.releaseWakeLock();
  }

  private handleUploadFinished() {
    this.data.uploadChange({ result: "Success" });
    this.isUploading = false;
    this.isUploadFinishedSuccessfully = true;
  }

  private handleError(error: any) {
    console.error(error);
    this.isUploading = false;

    this.data.uploadChange({
      error: error,
      result: "Failure",
    });
  }

  private handleUploadProgress(event: UploadEvent) {
    console.log(`handleUploadProgress: ${event.type}, ${event.uploadProgress}`);
    this.progressPercentage = Math.round(event.uploadProgress * 100);
  }

  private async acquireWakeLock() {
    if ("wakeLock" in navigator) {
      try {
        this.wakeLock = await (navigator as any) /* stupid tsc */.wakeLock
          .request("screen");
      } catch (error) {
        console.warn(`couldn't acquire wake lock for upload`, error);
      }
    }
  }

  private async releaseWakeLock() {
    if (this.wakeLock) {
      await this.wakeLock.release();
      this.wakeLock = undefined;
    }
  }

  private async performUpload() {
    try {
      this.isUploading = true;
      const doc = await this.data.createOrGetDocument();
      const uploadPrepared = await this.data.prepareUpload(doc.name);
      const uploadObservable = this.attachmentService
        .uploadFile(this.data.file, uploadPrepared.uploadUrl)
        .pipe(
          tap((ue) => {
            this.handleUploadProgress(ue);
          }),
          filter((ue) => ue.type == "Finished"),
          take(1),
        );

      await firstValueFrom(uploadObservable);
      await this.data.finishUpload(doc.name);

      this.handleUploadFinished();
      this.dialogReference.close({
        success: true,
      });
    } catch (error) {
      this.dialogReference.close({
        success: false,
        error: error,
      });
      this.handleError(error);
    }
  }
}
