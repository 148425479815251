import { UsedResourcesForUserDto } from "src/app/dtos/user/used-resources-for-user";
import { UsedResourcesForUser } from "src/app/models/user/used-resources-for-user";
import { Conversion } from "../conversion";

export class UsedResourcesForUserDtoConversion
  implements Conversion<UsedResourcesForUserDto, UsedResourcesForUser>
{
  convert(dto: UsedResourcesForUserDto): UsedResourcesForUser {
    return {
      numberOfPiecesThisYear: dto.number_of_pieces_this_year || 0,
      practiceDurationThisMonthMs: dto.practice_duration_this_month || 0,
      totalStorageBytes: dto.total_storage || 0,
      totalCollections: dto.total_collections || 0,
      totalPracticeRoutines: dto.total_practice_routines || 0,
    };
  }
}
