import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { PracticeRoutinesService } from "src/app/services/practice-routine/practice-routines.service";
import { PracticeRoutinesActions } from "../actions";
import { map, mergeMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PracticeRoutinesEffects {
  loadPracticeRoutines$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutinesActions.load),
      mergeMap((_) =>
        this.practiceRoutinesService.loadPracticeRoutines().pipe(
          map((practiceRoutines) =>
            PracticeRoutinesActions.loaded({
              practiceRoutines,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly practiceRoutinesService: PracticeRoutinesService,
    private readonly store: Store<AppState>,
  ) {}
}
