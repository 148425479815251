import { DIALOG_DATA } from "@angular/cdk/dialog";
import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { SearchResultDetailsDialogData } from "src/app/models/search/search-result-details-dialog-data";
import { MaterialModule } from "src/app/modules/material.module";
import { PricePipe } from "src/app/pipes/price.pipe";

@Component({
  selector: "yr-search-result-details-dialog",
  templateUrl: "./search-result-details-dialog.component.html",
  styleUrls: ["./search-result-details-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, PricePipe, MaterialModule],
})
export class SearchResultDetailsDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) readonly data: SearchResultDetailsDialogData,
    private readonly ref: MatDialogRef<SearchResultDetailsDialogComponent>,
  ) {}

  close() {
    this.ref.close();
  }
}
