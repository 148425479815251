import { UnitService } from "src/app/services/unit/unit.service";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredCollectionItemDto } from "src/app/dtos/collection/stored-collection-item.dto";
import { ExtendableConversion } from "../extendable-conversion";

export class StoredCollectionItemConversion extends ExtendableConversion<
  StoredCollectionItemDto,
  StoredCollectionItem
> {
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredCollectionItemDto): StoredCollectionItem {
    return {
      name: dto.name,
      creation: this.us.parseApiDate(dto.creation),
      collection: dto.collection,
      musicPice: dto.music_piece,
      linkedMusicPiece: dto.linked_music_piece,
      order: dto.order,
      isPreview: dto.is_preview,
      owner: dto.owner,
      practiceInterval:
        dto.practice_interval != undefined ? dto.practice_interval : 0,
    };
  }
}
