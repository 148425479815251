import { Component } from "@angular/core";
import { NotificationListComponent } from "../notification-list/notification-list.component";
import { NotificationSettingsComponent } from "../notification-settings/notification-settings.component";
import { ToolBarSecondRowComponent } from "../../common/tool-bar-second-row/tool-bar-second-row.component";
import { MarkAsReadComponent } from "../mark-as-read/mark-as-read.component";

@Component({
  selector: "yr-notification-page",
  standalone: true,
  templateUrl: "./notification-page.component.html",
  styleUrls: [],
  imports: [
    NotificationListComponent,
    NotificationSettingsComponent,
    ToolBarSecondRowComponent,
    MarkAsReadComponent,
  ],
})
export class NotificationPageComponent {}
