<yr-tool-bar-second-row>
  <div class="mx-auto w-full md:w-4/5">
    <yr-faq-search></yr-faq-search>
  </div>
</yr-tool-bar-second-row>

<div class="mx-auto mt-4 w-full md:w-4/5">
  <div class="mx-4 md:mx-0">
    <yr-faq></yr-faq>
  </div>
</div>
