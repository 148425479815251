import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreditPayout } from "src/app/models/sales/credit-payout";
import { CreditStatement } from "src/app/models/sales/credit-statement";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-credit-statement",
  templateUrl: "./credit-statement.component.html",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class CreditStatementComponent {
  @Input()
  creditStatement?: CreditStatement;

  @Input()
  creditPayout?: CreditPayout;

  @Output()
  download = new EventEmitter<{ creditStatement: CreditStatement }>();

  handleDownload() {
    if (this.creditStatement) {
      this.download.emit({ creditStatement: this.creditStatement });
    }
  }
}
