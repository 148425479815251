import { Pipe, PipeTransform } from "@angular/core";
import { MusicPiece } from "../models/music-piece/music-piece";
import { Preview } from "../models/music-piece/preview";

@Pipe({
  name: "preview",
  standalone: true,
})
export class PreviewPipe implements PipeTransform {
  transform(musicPiece: MusicPiece, previews: Preview[]): Preview | undefined {
    return previews.find((p) => p.musicPiece == musicPiece.name);
  }
}
