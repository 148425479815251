import { Injectable } from "@angular/core";
import { Conversion } from "../conversion";
import { GoogleSignInResponseDto } from "src/app/dtos/user/google-sign-in-response.dto";
import { GoogleSignInResponse } from "src/app/models/user/google-sign-in-response";
import { UserDtoToUserConversion } from "./user-dto-to-user-conversion";

@Injectable({
  providedIn: "root",
})
export class GoogleSignInConversion
  implements Conversion<GoogleSignInResponseDto, GoogleSignInResponse>
{
  private readonly userConversion = new UserDtoToUserConversion();

  convert(dto: GoogleSignInResponseDto): GoogleSignInResponse {
    return {
      requiresSignUp: dto.requires_sign_up > 0,
      userData: dto.auth_data
        ? this.userConversion.convert(dto.auth_data)
        : undefined,
      credential: dto.credential,
    };
  }
}
