import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "yr-purchase-subscription-failure",
  templateUrl: "./purchase-subscription-failure.component.html",
  styleUrls: ["./purchase-subscription-failure.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class PurchaseSubscriptionFailureComponent {}
