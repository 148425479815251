import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { News } from "src/app/models/news/news";
import { NewsService } from "src/app/services/news/news.service";
import { NewsComponent } from "../news/news.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-news-list",
  templateUrl: "./news-list.component.html",
  styleUrls: ["./news-list.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, NewsComponent],
})
export class NewsListComponent implements OnInit {
  news: News[] = [];

  constructor(private readonly newsService: NewsService) {}

  ngOnInit(): void {
    this.newsService.loadAllNews().subscribe((news) => {
      this.news = news;
    });
  }
}
