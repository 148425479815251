import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  EmptyMusicPiece,
  MusicPiece,
} from "src/app/models/music-piece/music-piece";
import { MusicPieceDetailsDialogData } from "src/app/models/music-piece/music-piece-details-dialog-data";
import { Preview } from "src/app/models/music-piece/preview";
import { MusicPieceDetailsDialogComponent } from "../music-piece-details-dialog/music-piece-details-dialog.component";
import { CollectionTypeData } from "src/app/models/collection/collection-type-data";
import { PreviewComponent } from "../preview/preview.component";
import { CommonModule } from "@angular/common";
import { MusicPieceThumbnailPipe } from "src/app/pipes/music-piece-thumbnail.pipe";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-card",
  templateUrl: "./music-piece-card.component.html",
  styleUrls: ["./music-piece-card.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    PreviewComponent,
    MusicPieceThumbnailPipe,
  ],
})
export class MusicPieceCardComponent implements OnInit {
  @Input()
  musicPiece: MusicPiece = EmptyMusicPiece;

  @Input()
  canPractice: boolean = true;

  @Input()
  canCreateNew: boolean = true;

  @Input()
  hasFreeStorageLeft = true;

  @Input()
  canEdit: boolean = true;

  @Input()
  isLinkedMusicPiece: boolean = false;

  @Output()
  reloadData = new EventEmitter<void>();

  @Output()
  editMusicPiece = new EventEmitter<void>();

  @Output()
  navigateToPlayerChange = new EventEmitter();

  @Output()
  createCopyFromLinkedMusicPieceChange = new EventEmitter<void>();

  @Input()
  preview?: Preview;

  @Input()
  disableCreateCopy = false;

  @Input()
  collectionInfo: CollectionTypeData[] = [];

  constructor(private readonly matDialog: MatDialog) {}

  ngOnInit(): void {}

  navigateToPlayer(musicPiece: MusicPiece) {
    this.navigateToPlayerChange.emit();
  }

  handleEditMusicPiece() {
    this.editMusicPiece.emit();
  }

  showDetails() {
    if (this.musicPiece) {
      this.matDialog.open<
        MusicPieceDetailsDialogComponent,
        MusicPieceDetailsDialogData
      >(MusicPieceDetailsDialogComponent, {
        data: {
          isListing: false,
          musicPiece: this.musicPiece,
          isLinkedMusicPiece: this.isLinkedMusicPiece,
          collectionInfo: this.collectionInfo,
        },
        maxWidth: "80vw",
      });
    }
  }
}
