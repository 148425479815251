import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";
import { ArtistCertificationState } from "src/app/models/user/artist-certification";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import {
  ArtistCertificationSelectors,
  PurchaseSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { SellingPriceCalculatorComponent } from "../../edit-music-piece/selling-price-calculator/selling-price-calculator.component";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-edit-collection-listing",
  templateUrl: "./edit-collection-listing.component.html",
  styleUrls: ["./edit-collection-listing.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    SellingPriceCalculatorComponent,
    CreateProfileHintComponent,
  ],
})
export class EditCollectionListingComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new SubscriptionService();
  collection: StoredCollection | undefined;
  isLoadingCollectionListing: boolean = false;
  isSavingCollectionListing: boolean = false;

  readonly price = new FormControl<number>(0, [
    Validators.required,
    Validators.min(0),
  ]);

  readonly isListingCollection = new FormControl<boolean>(false);

  readonly isFree = new FormControl<boolean>(false);

  readonly form = new FormGroup({
    price: this.price,
    isListingCollection: this.isListingCollection,
    isFree: this.isFree,
  });

  minSellingPrice: number = 1.0;
  totalPrice: number = 0;

  listing?: StoredCollectionListing;
  hasListingLicense: boolean = false;
  isCertified: boolean = false;

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptions.addMany(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionData)
        .subscribe((data) => {
          this.collection = data.collection;
        }),
      this.store
        .select(PurchaseSelectors.selectPurchaseSettings)
        .subscribe((data) => {
          this.minSellingPrice = data.purchaseSettings?.minSellingPrice || 1.0;
        }),
      this.store
        .select(EditCollectionSelectors.selectCollectionListing)
        .subscribe((data) => {
          this.listing = data.listing;
          this.isLoadingCollectionListing = data.isLoadingCollectionListing;
          this.isLoadingCollectionListing = data.isLoadingCollectionListing;

          this.isListingCollection.patchValue(data.listing != undefined);
          if (data.listing) {
            this.price.patchValue(data.listing.price);
            this.isFree.patchValue(data.listing.price == 0);
            this.setTotalPrice();
          }

          if (this.isLoadingCollectionListing || !this.listing) {
            this.disableForm();
          } else {
            this.enableForm();
          }
        }),
      this.store
        .select(
          ArtistCertificationSelectors.selectArtistCertificationWithLicense,
        )
        .subscribe((data) => {
          this.hasListingLicense = data.license.canListCollections;
          this.isCertified =
            data.artistCertification?.state ==
            ArtistCertificationState.Approved;
        }),
    );
  }

  private disableForm() {
    this.price.disable();
    this.isFree.disable();
  }

  private enableForm() {
    this.price.enable();
    this.isFree.enable();
  }

  handleListingChange() {
    if (this.isListingCollection.value) {
      this.price.setValue(this.minSellingPrice);
      this.setTotalPrice();
      this.enableForm();
    } else {
      this.disableForm();
    }
  }

  setTotalPrice() {
    if (this.isFree.value) {
      this.totalPrice = 0;
    } else {
      if ((this.price.value || 0) < this.minSellingPrice) {
        this.price.setValue(this.minSellingPrice);
      }
      this.totalPrice = this.price.value || this.minSellingPrice;
    }
  }

  save() {
    if (this.form.valid && this.collection) {
      this.store.dispatch(
        EditCollectionActions.saveCollectionListing({
          collection: this.collection.name,
          price: this.totalPrice,
          name: this.listing?.name,
          shouldList: this.isListingCollection.value == true,
        }),
      );
    }
  }
}
