import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { UserActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";

@Component({
  selector: "yr-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class UpdatePasswordComponent {
  password = new FormControl("", [
    Validators.required,
    Validators.minLength(7),
  ]);
  passwordConfirmation = new FormControl("", [
    Validators.required,
    Validators.minLength(7),
  ]);

  form = new FormGroup(
    {
      password: this.password,
      passwordConfirmation: this.passwordConfirmation,
    },
    [this.passwordsMustNotBeEqualValidator()],
  );

  constructor(private readonly store: Store<AppState>) {}

  passwordsMustNotBeEqualValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.password.value;
      const passwordConfirmation = this.passwordConfirmation.value;
      return password != passwordConfirmation
        ? { passwordDoNotMatch: true }
        : null;
    };
  }

  updatePassword() {
    if (this.password.value) {
      this.store.dispatch(
        UserActions.updatePassword({ newPassword: this.password.value }),
      );
      this.form.reset();
    }
  }
}
