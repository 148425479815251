<div mat-dialog-title>
  <h1>{{ data.collection.title }}</h1>
</div>
<div mat-dialog-content>
  <p>{{ data.collection.instrument }}</p>
  <p>{{ data.collection.title }}</p>
  <p>{{ data.collection.artist }}</p>
  <p>{{ data.collection.description }}</p>
  <p>{{ data.collection.temperament }}</p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-button (click)="close()">{{ "common.close" | translate }}</button>
</div>
