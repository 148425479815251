import { CommonModule } from "@angular/common";
import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import {
  ResizeDirective,
  YrResizeEvent,
} from "src/app/directives/resize.directive";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { CreditStatement } from "src/app/models/sales/credit-statement";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { SalesSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
  standalone: true,
  imports: [CommonModule, ResizeDirective, TranslateModule, MaterialModule],
})
export class SalesComponent implements OnInit {
  dataSource: MatTableDataSource<PurchasedItem> =
    new MatTableDataSource<PurchasedItem>();

  isLoadingSales: boolean = true;

  private _sales: PurchasedItem[] = [];

  set sales(sales: PurchasedItem[]) {
    this._sales = sales;
    this.dataSource.data = [...sales].sort(
      (a, b) => b.item.creation.valueOf() - a.item.creation.valueOf(),
    );
  }

  get sales() {
    return this._sales;
  }

  creditStatements: CreditStatement[] = [];

  isLoadingCreditStatements: boolean = true;
  creditsForNextPayout = 0;
  lastCreditStatementCutoffDate: Date = new Date(1990, 1, 1);
  now = new Date();

  totalPrice: number = 0;
  totalSalesTax: number = 0;
  totalPlatformFees: number = 0;
  totalCredits: number = 0;

  readonly displayedColumnsFull: string[] = [
    "title",
    "price",
    "totalSalesTax",
    "platformFee",
    "credit",
    "purchasedBy",
    "paymentDate",
    "actions",
  ];

  readonly displayedColumnsMedium: string[] = [
    "title",
    "price",
    "paymentDate",
    "actions",
  ];

  readonly displayedColumnsSmall: string[] = [
    "title",
    "paymentDate",
    "actions",
  ];

  displayedColumns: string[] = this.displayedColumnsFull;

  private readonly subscriptions = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly ngZone: NgZone,
  ) {}

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.dataSource.paginator = paginator;
    }
  }

  ngOnInit(): void {
    this.subscriptions.addMany(
      this.store.select(SalesSelectors.selectSales).subscribe((data) => {
        this.sales = data.sales;
        this.isLoadingSales = data.isLoadingSales;

        this.calculateTotals();
      }),

      this.store
        .select(SalesSelectors.selectCreditStatements)
        .subscribe((data) => {
          this.creditStatements = data.creditStatements;
          this.isLoadingCreditStatements = data.isLoadingCreditStatements;

          this.calculateTotals();
        }),
    );
  }

  calculateTotals() {
    this.totalPrice = this.sales.reduce((acc, pi) => pi.item.price + acc, 0);
    this.totalSalesTax = this.sales.reduce(
      (acc, pi) => pi.item.totalSalesTax + acc,
      0,
    );
    this.totalPlatformFees = this.sales.reduce(
      (acc, pi) => pi.item.platformFee + acc,
      0,
    );

    const lastCreditStatement = [...this.creditStatements]
      .sort(
        (a, b) =>
          b.creditCutoffEndDate.valueOf() - a.creditCutoffEndDate.valueOf(),
      )
      .find((c) => true);
    const lastCreditStatementCutoffEndDate =
      lastCreditStatement != undefined
        ? lastCreditStatement.creditCutoffEndDate.valueOf()
        : 0;

    this.totalCredits = this.sales.reduce(
      (acc, pi) =>
        pi.item.price - pi.item.platformFee - pi.item.totalSalesTax + acc,
      0,
    );

    this.lastCreditStatementCutoffDate =
      lastCreditStatement?.creditCutoffEndDate ??
      this.lastCreditStatementCutoffDate;

    this.creditsForNextPayout = this.sales
      .filter((pi) => pi.item.paymentDate != undefined)
      .filter(
        (pi) =>
          pi.item.paymentDate!.valueOf() > lastCreditStatementCutoffEndDate,
      )
      .reduce(
        (acc, pi) =>
          pi.item.price - pi.item.platformFee - pi.item.totalSalesTax + acc,
        0,
      );
  }

  downloadReceipt(purchase: PurchasedItem) {
    this.store.dispatch(
      PurchaseActions.downloadReceipt({
        purchase: purchase,
      }),
    );
  }

  handleResize(event: YrResizeEvent) {
    // see: https://m2.material.io/design/layout/responsive-layout-grid.html#breakpoints
    // however since we are in an container, we have to switch it up a bit.
    console.log(event.width);
    this.ngZone.run(() => {
      if (event.width < 800) {
        this.displayedColumns = this.displayedColumnsSmall;
      } else if (event.width < 1240) {
        this.displayedColumns = this.displayedColumnsMedium;
      } else {
        this.displayedColumns = this.displayedColumnsFull;
      }
    });
  }
}
