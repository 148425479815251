<button
  mat-raised-button
  color="warn"
  *ngIf="!isNew"
  [disabled]="
    isDeleteInProgress ||
    hasListing ||
    isPartOfSharedCollection ||
    isLoadingIsPartOfSharedCollection ||
    hasSharedCollectionReceivers
  "
  (click)="deleteMusicPiece()"
>
  {{ "edit-music-piece.delete-music-piece.delete" | translate }}
</button>
<p *ngIf="hasListing" class="can-not-delete-hint">
  {{
    "edit-music-piece.delete-music-piece.can-not-delete-because-listing"
      | translate
  }}
</p>
<p *ngIf="isPartOfSharedCollection" class="can-not-delete-hint">
  {{
    "edit-music-piece.delete-music-piece.can-not-delete-because-shared-collection"
      | translate
  }}
</p>
<p *ngIf="hasSharedCollectionReceivers" class="can-not-delete-hint">
  {{
    "edit-music-piece.delete-music-piece.can-not-delete-because-has-shared-collection-receivers"
      | translate
  }}
</p>
