import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, tap } from "rxjs/operators";
import { SalesService } from "src/app/services/sales/sales.service";
import { SalesActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class SalesEffects {
  loadSales$ = createEffect(() =>
    this.actions.pipe(
      ofType(SalesActions.loadSales),
      mergeMap((_) =>
        this.salesService
          .loadSales()
          .pipe(map((sales) => SalesActions.loadedSales({ sales: sales }))),
      ),
    ),
  );

  loadCreditStatements$ = createEffect(() =>
    this.actions.pipe(
      ofType(SalesActions.loadCreditStatements),
      mergeMap((_) =>
        this.salesService
          .loadCreditStatements()
          .pipe(
            map((cs) =>
              SalesActions.loadedCreditStatements({ creditStatements: cs }),
            ),
          ),
      ),
    ),
  );

  loadCreditPayouts$ = createEffect(() =>
    this.actions.pipe(
      ofType(SalesActions.loadCreditStatements),
      mergeMap((_) =>
        this.salesService
          .loadCreditPayouts()
          .pipe(
            map((r) => SalesActions.loadedCreditPayouts({ creditPayouts: r })),
          ),
      ),
    ),
  );

  downloadCreditStatement$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SalesActions.downloadCreditStatement),
        mergeMap((action) =>
          this.salesService
            .downloadCreditStatement(action.creditStatement)
            .pipe(
              map((blob) => {
                return {
                  name: action.creditStatement.name,
                  blob: blob,
                };
              }),
            ),
        ),
        tap((data) => {
          var link = document.createElement("a");
          link.href = URL.createObjectURL(data.blob);
          link.download = `${data.name}.pdf`;
          link.click();
          link.remove();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly salesService: SalesService,
  ) {}
}
