<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingSearchResults || isLoadingItems"
></mat-progress-bar>

<yr-create-profile-hint></yr-create-profile-hint>

<div *ngIf="searchQuery == ''" class="explore-items">
  <yr-explore-artist-profile
    *ngFor="let item of items"
    [item]="item"
  ></yr-explore-artist-profile>
</div>

<!-- SEARCH RESULTS -->
<div *ngIf="artistProfileSearchResults.length > 0" class="explore-items mb-8">
  <yr-explore-artist-profile
    *ngFor="let artistProfile of artistProfileSearchResults"
    [item]="artistProfile"
  ></yr-explore-artist-profile>
</div>

<div class="w-full">
  <div
    class="collections mt-4"
    *ngIf="
      listedCollectionsSearchResults.length > 0 ||
      musicPiecesSearchResults.length > 0
    "
  >
    <yr-explore-collection
      class="w-full"
      *ngFor="let collection of listedCollectionsSearchResults"
      [collection]="collection"
    ></yr-explore-collection>

    <yr-explore-music-piece
      class="w-full"
      *ngFor="let musicPiece of musicPiecesSearchResults"
      [preview]="musicPiece | previewForListing: previews"
      [musicPiece]="musicPiece"
    ></yr-explore-music-piece>
  </div>
</div>

<yr-no-items-indicator
  *ngIf="
    searchQuery != '' &&
    artistProfileSearchResults.length == 0 &&
    listedCollectionsSearchResults.length == 0 &&
    musicPiecesSearchResults.length == 0 &&
    !isLoadingSearchResults
  "
  text="{{ 'explore.explore.no-search-results' | translate }}"
></yr-no-items-indicator>
