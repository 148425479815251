<div class="mr-2 flex flex-row items-center">
  <button
    class="mr-2 !h-10"
    mat-raised-button
    color="primary"
    (click)="add()"
    [disabled]="!canCreateNew"
  >
    <mat-icon>add</mat-icon>
    {{ "collection.collection-control.new" | translate }}
  </button>

  <input
    *ngIf="canFilter"
    class="h-10 flex-grow rounded px-2 text-base text-zinc-700 caret-as-700 outline-none placeholder:text-base placeholder:text-zinc-500 sm:flex-none"
    type="text"
    id="filter"
    [placeholder]="'collection.collection-control.search' | translate"
    [(ngModel)]="filterText"
    (keyup)="applyFilter()"
  />
  <!-- <mat-form-field color="primary" class="ml-2 mr-2" *ngIf="canFilter">
    <mat-label class="text-white">
      {{ "collection.collection-control.search" | translate }}
    </mat-label>
    <input
      matInput
      [(ngModel)]="filterText"
      (keyup)="applyFilter()"
      class="!text-white placeholder:text-white"
    />
    <mat-icon matSuffix class="text-white">search</mat-icon>
  </mat-form-field> -->
</div>
