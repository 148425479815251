<div>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingListedMusicPieces || isLoadingCollections"
  ></mat-progress-bar>

  <div class="listed-items mx-2">
    <!-- collections -->
    <div *ngFor="let collection of listedCollections" class="w-full">
      <div class="aspect-video h-auto w-full rounded-lg bg-white shadow">
        <div
          class="relative overflow-clip rounded-lg hover:cursor-pointer"
          (click)="showCollectionDetails(collection)"
        >
          <img
            class="block aspect-video h-auto w-full"
            *ngIf="collection.downloadLink"
            alt="preview"
            [src]="collection.downloadLink"
          />
          <div
            *ngIf="!collection.downloadLink"
            class="flex aspect-video h-auto w-full flex-col items-center justify-center bg-black text-white"
          >
            <mat-icon>hide_image</mat-icon>
          </div>

          <div
            class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
          >
            <button mat-icon-button>
              <mat-icon>info</mat-icon>
            </button>
          </div>
          @if (collection.isPracticeRoutine) {
            <div
              class="border-1 absolute bottom-12 right-2 flex flex-row items-center rounded-lg border-gray-700 bg-black/50 px-2 py-1 text-sm text-white"
            >
              <ng-icon name="lucideListTodo" size="24px"></ng-icon>
              <span class="ml-2">{{
                "artist.listed-music-pieces-and-collections.practice-routine"
                  | translate
              }}</span>
            </div>
          }
          <div
            class="border-1 absolute bottom-2 right-2 flex flex-row items-center rounded-lg border-gray-700 bg-black/50 px-2 py-1 text-sm text-white"
          >
            <mat-icon>video_library</mat-icon>
            <span class="ml-2">{{
              "artist.listed-music-pieces-and-collections.collection"
                | translate
            }}</span>
          </div>
        </div>
        <div class="flex flex-col truncate p-2">
          <span
            class="truncate text-lg hover:cursor-pointer"
            (click)="showCollectionDetails(collection)"
          >
            {{ collection.title }}
          </span>
          <div class="flex flex-row items-center justify-between">
            <yr-purchase-button
              [collectionListing]="collection"
            ></yr-purchase-button>

            <span class="mx-2 mb-1 rounded bg-as-500 px-1 text-base text-white">
              {{ collection.price | price }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- music pieces -->
    <div *ngFor="let listing of listedMusicPieces" class="w-full">
      <div
        class="aspect-video h-auto w-full rounded-lg bg-white shadow"
        [class.bg-as-200]="listing.musicPiece == focusMusicPiece"
      >
        <div class="relative overflow-clip rounded-lg">
          <yr-preview
            [preview]="listing | previewForListing: previews"
            [musicPieceThumbnail]="listing | thumbnail"
          ></yr-preview>

          <div
            class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
          >
            <button mat-icon-button (click)="showDetails(listing)">
              <mat-icon>info</mat-icon>
            </button>
          </div>
        </div>
        <div class="flex flex-col truncate p-2">
          <span class="truncate text-lg">
            {{ listing.musicPieceName }}
          </span>
          <div class="flex flex-row items-center justify-between">
            <yr-purchase-button
              [musicPieceListing]="listing"
            ></yr-purchase-button>

            <span class="mx-2 mb-1 rounded bg-as-500 px-1 text-base text-white">
              {{ listing.price | price }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        !isLoadingListedMusicPieces &&
        listedMusicPieces.length == 0 &&
        listedCollections.length == 0
      "
    >
      {{ "artist.listed-music-pieces.no-listing-hint" | translate }}
    </div>
  </div>
</div>
