<div *ngIf="creditStatement" class="my-4 rounded bg-white p-4 shadow">
  <div class="flex flex-row justify-between">
    <div>
      <div class="text-3xl">
        {{ creditStatement.credit | number: "1.2-2" }}€
      </div>
      <div class="text-lg">
        {{ creditStatement.name }}

        <span class="text-gray-600">
          ({{
            creditStatement.creditCutoffStartDate
              | date: ("common.dateFormat" | translate)
          }}
          -
          {{
            creditStatement.creditCutoffEndDate
              | date: ("common.dateFormat" | translate)
          }})
        </span>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <button mat-icon-button (click)="handleDownload()">
        <mat-icon>download</mat-icon>
      </button>

      <div
        class="rounded border-2 border-blue-500 bg-blue-200 p-2 text-lg"
        *ngIf="creditStatement.status == 'draft'"
      >
        {{ "sales.credit-statement.draft" | translate }}
      </div>

      <div
        class="rounded border-2 border-green-500 bg-green-200 p-2 text-lg"
        *ngIf="creditStatement.status == 'submitted' && !creditPayout"
      >
        {{ "sales.credit-statement.submitted" | translate }}
      </div>
      <div
        class="rounded border-2 border-green-500 bg-green-200 p-2 text-lg"
        *ngIf="creditStatement.status == 'submitted' && creditPayout"
      >
        {{ "sales.credit-statement.paid" | translate }}
      </div>
    </div>
  </div>

  <mat-divider class="my-4"></mat-divider>

  <div class="mt-8 flex flex-col gap-4 text-lg">
    <div class="flex flex-row items-center gap-x-4">
      <mat-icon class="shrink-0 text-gray-600">event</mat-icon>
      <span class="shrink-0 text-gray-600">
        {{ creditStatement.creation | date: ("common.dateFormat" | translate) }}
      </span>
      {{ "sales.credit-statement.created" | translate }}
    </div>

    <div
      *ngFor="let message of creditStatement.messages"
      class="flex flex-row items-center gap-x-4"
    >
      <mat-icon class="shrink-0 text-gray-600">chat_bubble_outline</mat-icon>
      <span class="shrink-0 text-gray-600">
        {{ message.modified | date: ("common.dateFormat" | translate) }}
      </span>
      {{ message.message }}
    </div>

    <div
      *ngIf="creditStatement.status == 'submitted'"
      class="flex flex-row items-center gap-x-4"
    >
      <mat-icon class="shrink-0 text-gray-600">done</mat-icon>
      <span class="shrink-0 text-gray-600">
        {{ creditStatement.modified | date: ("common.dateFormat" | translate) }}
      </span>
      {{ "sales.credit-statement.submitted-and-ready-for-payout" | translate }}
    </div>

    <div *ngIf="creditPayout" class="flex flex-row items-center gap-x-4">
      <mat-icon class="shrink-0 text-gray-600">euro_symbol</mat-icon>
      <span class="shrink-0 text-gray-600">{{
        creditPayout.payoutDate | date: ("common.dateFormat" | translate)
      }}</span>

      {{
        "sales.credit-statement.payout"
          | translate
            : {
                amount: creditPayout.amount | number: "1.2-2",
                mode: creditPayout.mode,
                paymentReference: creditPayout.paymentReference,
                receiver: creditPayout.receiver
              }
      }}
    </div>
  </div>
</div>
