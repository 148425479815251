import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AppState, UserSelectors } from "../ngrx/reducers";

@Injectable({
  providedIn: "root",
})
export class SkipLoginGuard  {
  constructor(
    private readonly store: Store<AppState>,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(UserSelectors.isLoggedIn).pipe(
      tap((isLoggedIn) => {
        console.log(`skip-login-guard: ${isLoggedIn}`);
        if (isLoggedIn) {
          this.router.navigate(["/"]);
        }
      }),
      map((_) => true) // we can always activate, but sometimes we redirect (see above)
    );
  }
}
