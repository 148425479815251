import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import { ArtistProfileService } from "src/app/services/artist/artist-profile-service";
import { ArtistProfileActions } from "../actions";
import { AppState, UserSelectors } from "../reducers";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ArtistProfileEffects {
  loadArtistProfileForCurrentUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadArtistProfileForCurrentUser),
      throttleTime(100),
      withLatestFrom(this.store.select(UserSelectors.currentUser)),
      mergeMap(([action, user]) =>
        this.artistProfileService
          .loadArtistProfileForCurrentUser(user!.username)
          .pipe(
            map((artistProfile) =>
              ArtistProfileActions.loadedArtistProfileForCurrentUser({
                requiresRedirect: action.redirectToArtistProfileAfterLoading,
                artistProfile: artistProfile,
              }),
            ),
          ),
      ),
    ),
  );

  redirectToArtistProfile$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ArtistProfileActions.loadedArtistProfileForCurrentUser),
        filter(
          (a) => a.requiresRedirect == true && a.artistProfile != undefined,
        ),
        tap((a) => this.router.navigate(["/artists", a.artistProfile!.name])),
      ),
    { dispatch: false },
  );

  loadArtistProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadArtistProfile),
      mergeMap((action) =>
        this.artistProfileService.loadArtistProfile(action.name),
      ),
      map((artistProfile) =>
        ArtistProfileActions.loadedArtistProfile({
          artistProfile: artistProfile,
        }),
      ),
    ),
  );

  loadArtistProfileByShortUrl$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadArtistProfileByCustomUrl),
      mergeMap((action) =>
        this.artistProfileService
          .loadArtistProfileByCustomUrl(action.customUrl)
          .pipe(
            map((artistProfile) =>
              ArtistProfileActions.loadedArtistProfile({ artistProfile }),
            ),
          ),
      ),
    ),
  );

  loadListingForArtist$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadedArtistProfile),
      filter((action) => action.artistProfile != undefined),
      mergeMap((action) =>
        this.artistProfileService
          .loadListedMusicPiecesForArtist(action.artistProfile!.name)
          .pipe(
            map((listings) =>
              ArtistProfileActions.loadedListedMusicPieces({
                musicPieces: listings,
              }),
            ),
          ),
      ),
    ),
  );

  loadCollectionListingForArtist$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadedArtistProfile),
      filter((action) => action.artistProfile != undefined),
      mergeMap((action) =>
        this.artistProfileService
          .loadListedCollectionsForArtist(action.artistProfile!.name)
          .pipe(
            map((listedCollections) =>
              ArtistProfileActions.loadedListedCollections({
                collections: listedCollections,
              }),
            ),
          ),
      ),
    ),
  );

  loadListingPreviews$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadedListedMusicPieces),
      mergeMap((action) =>
        this.artistProfileService.findPreviews(action.musicPieces),
      ),
      map((previews) =>
        ArtistProfileActions.loadedListingPreviews({
          previews: previews,
        }),
      ),
    ),
  );

  updateArtistProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.updateProfile),
      mergeMap((action) =>
        this.artistProfileService.update(action.artistProfile).pipe(
          map((profile) =>
            ArtistProfileActions.updatedProfile({ artistProfile: profile }),
          ),
          catchError((error) =>
            of(ArtistProfileActions.updateProfileFailed({ error: error })),
          ),
        ),
      ),
    ),
  );

  redirectToCustomUrl$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ArtistProfileActions.updatedProfile,
          ArtistProfileActions.loadedArtistProfile,
        ),
        map((action) => {
          if (
            action.artistProfile &&
            action.artistProfile.customUrl &&
            window.location.pathname != `/@${action.artistProfile.customUrl}`
          ) {
            this.router.navigate([`@${action.artistProfile.customUrl}`]);
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  redirectToNormalArtistWhenCustomUrlIsRemoved$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ArtistProfileActions.updatedProfile),
        map((action) => {
          if (
            action.artistProfile &&
            action.artistProfile.customUrl == undefined &&
            window.location.pathname != `/artists/${action.artistProfile.name}`
          ) {
            this.router.navigate(["/artists", action.artistProfile.name]);
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  showUpdatedToast$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ArtistProfileActions.updatedProfile),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  showUpdateFailedToast$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ArtistProfileActions.updateProfileFailed),
        tap((_) => this.toastr.error(this.translate.instant("common.fail"))),
      ),
    { dispatch: false },
  );

  deleteProfilePicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.deleteProfilePicture),
      mergeMap(async (action) => {
        await this.artistProfileService.deleteProfilePicture(
          action.profile.name,
        );
        return ArtistProfileActions.updatedProfile({
          artistProfile: {
            ...action.profile,
            downloadLink: "",
            uploadFinished: false,
            contentType: "",
          },
        });
      }),
    ),
  );

  deleteLogo$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.deleteLogo),
      mergeMap(async (action) => {
        await this.artistProfileService.deleteLogo(action.profile.name);
        return ArtistProfileActions.updatedProfile({
          artistProfile: {
            ...action.profile,
            logoDownloadLink: "",
            logoUploadFinished: false,
            logoContentType: "",
          },
        });
      }),
    ),
  );

  loadPossibleTeasers$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadPossibleTeasers),
      mergeMap((action) =>
        this.artistProfileService.loadPossibleTeasers(action.profile).pipe(
          map((data) =>
            ArtistProfileActions.loadedPossibleTeasers({
              teasers: data,
            }),
          ),
        ),
      ),
    ),
  );

  loadTeaser$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistProfileActions.loadTeaser),
      mergeMap((action) =>
        this.artistProfileService.loadTeaser(action.artistProfile).pipe(
          map((teaser) =>
            ArtistProfileActions.loadedTeaser({
              teaser: teaser,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly artistProfileService: ArtistProfileService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly router: Router,
  ) {}
}
