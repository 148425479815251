export enum ArtistCertificationState {
  Started = "Started",
  Pending = "Pending",
  Rejected = "Rejected",
  Approved = "Approved",
}

export interface ArtistCertification {
  name: string;
  user: string;
  created: Date;
  state: ArtistCertificationState;
}
