<div class="container m-auto mt-4 flex flex-col items-center justify-center">
  <div
    *ngIf="!isWaitingForWebhook"
    class="m-12 flex aspect-square w-64 flex-col items-center justify-center rounded-full bg-green-300 text-9xl text-green-800 shadow-lg"
  >
    ✓
  </div>

  <div
    *ngIf="isWaitingForWebhook"
    class="bg-as-300 text-as-800 m-12 flex aspect-square w-64 flex-col items-center justify-center rounded-full text-9xl shadow-lg"
  >
    ⧖
  </div>
  <div
    *ngIf="!isWaitingForWebhook"
    class="whitespace-pre-line text-center text-2xl text-gray-900"
  >
    {{ "user.purchase-subscription-success.success" | translate }}
  </div>
  <div
    *ngIf="isWaitingForWebhook"
    class="text-as-300 whitespace-pre-line text-center text-2xl"
  >
    {{ "user.purchase-subscription-success.waiting-for-webhook" | translate }}
  </div>
  <!-- <div>
    <a
      routerLink="/"
      class="text-lg text-as-700 hover:decoration-as-900"
      >{{ "user.purchase-subscription-success.music-pieces" | translate }}</a
    >
  </div> -->
</div>
