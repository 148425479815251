import { ExtendableConversion } from "../extendable-conversion";
import { UnitService } from "src/app/services/unit/unit.service";
import { CreditPayoutDto } from "src/app/dtos/sales/credit-payout.dto";
import { CreditPayout } from "src/app/models/sales/credit-payout";

export class CreditPayoutConversion extends ExtendableConversion<
  CreditPayoutDto,
  CreditPayout
> {
  private readonly us = new UnitService();

  convert(dto: CreditPayoutDto): CreditPayout {
    return {
      name: dto.name,
      amount: dto.amount,
      creditStatement: dto.credit_statement,
      mode: dto.mode,
      paymentReference: dto.payment_reference,
      receiver: dto.receiver,
      payoutDate: this.us.parseApiDate(dto.payout_date),
      creation: this.us.parseApiDate(dto.creation),
    };
  }
}
