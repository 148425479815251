<div class="custom-dialog-content !text-black">
  <div class="m-6">
    <!-- header -->
    <div class="mb-4 flex flex-row items-start">
      <img
        *ngIf="
          data.showcaseCollectionListing.downloadLink &&
          data.showcaseCollectionListing.downloadLink.length > 0
        "
        [src]="data.showcaseCollectionListing.downloadLink"
        [alt]="data.showcaseCollectionListing.title"
        class="mr-2 h-14 w-14 rounded-full"
      />
      <img
        src="assets/logo/logo.png"
        [alt]="data.showcaseCollectionListing.title"
        *ngIf="!data.showcaseCollectionListing.downloadLink"
        class="mr-2 h-14 w-14 rounded-full"
      />
      <div class="flex flex-grow flex-col items-start truncate">
        <span class="truncate text-lg">{{
          data.showcaseCollectionListing.title
        }}</span>
        <span class="flex-1 truncate text-start text-base font-light">
          {{ data.showcaseCollectionListing.artist }}
        </span>
      </div>
      <div class="flex-grow"></div>
      <span class="mb-1 ml-3 rounded bg-as-500 px-1 text-base text-white">
        {{ data.showcaseCollectionListing.price | price }}
      </span>
    </div>

    <p
      *ngIf="data.showcaseCollectionListing.description"
      class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
    >
      <mat-icon class="text-as-500">description</mat-icon>
      <span class="ml-2 whitespace-pre-line">
        {{ data.showcaseCollectionListing.description }}
      </span>
    </p>
    <p
      *ngIf="data.showcaseCollectionListing.instrument"
      class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
    >
      <mat-icon class="text-as-500">piano</mat-icon>
      <span class="ml-2">
        {{ data.showcaseCollectionListing.instrument }}
      </span>
    </p>
    <p
      *ngIf="data.showcaseCollectionListing.temperament"
      class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
    >
      <mat-icon class="text-as-500">music_note</mat-icon>
      <span class="ml-2">
        {{ data.showcaseCollectionListing.temperament }}
      </span>
    </p>
  </div>
  <div
    class="listed-items rounded-b-lg rounded-t-lg bg-gray-200 p-6"
    *ngIf="data.showcaseCollectionListing.items.length > 0"
  >
    <!-- render items -->
    <div
      class="w-full overflow-clip rounded-lg bg-white shadow"
      *ngFor="let item of data.showcaseCollectionListing.items"
    >
      <!-- music piece -->
      <!-- preview -->
      <yr-preview
        [preview]="item.preview"
        [musicPieceThumbnail]="item.musicPiece | thumbnail"
      ></yr-preview>
      <div class="truncate p-2">
        <span class="truncate text-lg">{{ item.musicPiece.nameOfPiece }}</span>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-button (click)="close()">{{ "common.close" | translate }}</button>
</div>
