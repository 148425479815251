@if (isOwner) {
  <div class="rounded bg-white p-4 shadow">
    <mat-slide-toggle (change)="handleEditModeChange($event.checked)">{{
      "artist.artist-profile.edit" | translate
    }}</mat-slide-toggle>

    @if (isInEditMode) {
      @if (canSetCustomUrl) {
        <div class="my-2 rounded border border-as-400 bg-gray-100 p-2">
          <yr-custom-artist-url
            (customUrlChanged)="customUrlChanged.emit($event)"
            [customUrl]="customUrl"
          ></yr-custom-artist-url>
        </div>
      }

      <div class="my-2 rounded border border-as-400 bg-gray-100 p-2">
        <yr-set-video-feedback-offering
          [videoFeedbackPrice]="videoFeedbackPrice()"
          [isOfferingVideoFeedback]="isOfferingVideoFeedback()"
          [minSellingPrice]="minSellingPrice()"
          (videoFeedbackOfferingChanged)="
            videoFeedbackOfferingChanged.emit($event)
          "
        ></yr-set-video-feedback-offering>
      </div>
    }
  </div>
}
