import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-offline-indicator",
  templateUrl: "./offline-indicator.component.html",
  styleUrls: ["./offline-indicator.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class OfflineIndicatorComponent implements OnInit {
  isOffline = false;

  constructor() {}

  ngOnInit(): void {
    window.addEventListener("offline", (e) => {
      this.isOffline = true;
    });

    window.addEventListener("online", (e) => {
      this.isOffline = false;
    });
  }
}
