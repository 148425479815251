import { StoredRecordingPartDto } from "src/app/dtos/recording-part/stored-recording-part-dto";
import { RecordingPart } from "src/app/models/recording-part/recording-part";
import { Conversion } from "../conversion";

export class StoredRecordingPartToRecordingConversion
  implements Conversion<StoredRecordingPartDto, RecordingPart>
{
  convert(item: StoredRecordingPartDto): RecordingPart {
    return {
      color: item.color,
      endMarker: item.end_marker,
      name: item.name,
      note: item.note,
      recordingPartGroup: item.recording_part_group,
      startMarker: item.start_marker,
      title: item.title,
    };
  }
}
