<br />
<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ collection?.title }}</mat-card-title>
    <mat-card-subtitle>
      {{ collection?.created | date: ("common.dateTimeFormat" | translate) }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingCollection"
    ></mat-progress-bar>
    <div *ngIf="collection" fxLayout="row" fxLayout.lt-md="column">
      <div class="image-container">
        <div>
          <img
            class="preview"
            *ngIf="collection.uploadFinished && collection.downloadLink"
            alt="preview"
            [src]="collection.downloadLink"
          />
        </div>

        <div
          class="no-image-placeholder"
          *ngIf="!collection.downloadLink || !collection.uploadFinished"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <mat-icon>hide_image</mat-icon>
        </div>
      </div>

      <div>
        <p class="label" *ngIf="collection.instrument">
          {{
            "readonly-collection.readonly-collection-details.instrument"
              | translate
          }}
        </p>
        <p>{{ collection.instrument }}</p>
        <p class="label" *ngIf="collection.artist">
          {{
            "readonly-collection.readonly-collection-details.artist" | translate
          }}
        </p>
        <p>{{ collection.artist }}</p>
        <p class="label" *ngIf="collection.description">
          {{
            "readonly-collection.readonly-collection-details.description"
              | translate
          }}
        </p>
        <p>{{ collection.description }}</p>
        <p class="label" *ngIf="collection.temperament">
          {{
            "readonly-collection.readonly-collection-details.temperament"
              | translate
          }}
        </p>
        <p>{{ collection.temperament }}</p>
        <button
          mat-raised-button
          (click)="delete()"
          color="warn"
          [disabled]="isLoadingCollection || isDeletingCollection"
        >
          {{
            "readonly-collection.readonly-collection-details.delete" | translate
          }}
        </button>

        <br />
        <br />
        <form [formGroup]="form">
          <mat-checkbox matInput [formControl]="isPracticeRoutine">
            {{
              "edit-collection.edit-collection-form.is-practice-routine"
                | translate
            }}
          </mat-checkbox>

          @if (linkedCollection && linkedCollection.isPracticeRoutine) {
            <button
              mat-raised-button
              (click)="navigateToPracticeRoutine()"
              color="primary"
              class="ml-2"
            >
              {{
                "edit-collection.edit-collection-form.go-to-practice-routine"
                  | translate
              }}
            </button>
          }

          <br />
          <button
            id="save-button"
            (click)="save()"
            mat-raised-button
            color="primary"
            [disabled]="form.invalid || !form.dirty"
          >
            {{ "common.save" | translate }}
          </button>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
