import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";

@Component({
  selector: "yr-licensed-resource",
  templateUrl: "./licensed-resource.component.html",
  styleUrls: ["./licensed-resource.component.scss"],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule],
})
export class LicensedResourceComponent implements OnInit {
  @Input()
  title: string = "";

  @Input()
  unit: string = "";

  @Input()
  available: number | "unlimited" | boolean = "unlimited";

  constructor() {}

  ngOnInit(): void {}
}
