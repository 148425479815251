import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { MusicPiecePurchaseDtoConversion } from "src/app/conversions/music-piece/music-piece-purchase-dto-conversion";
import { PurchaseSettingsDtoConversion } from "src/app/conversions/purchase/purchase-settings-dto.conversion";
import { MusicPiecePurchase } from "src/app/models/music-piece/music-piece-purchase";
import { PurchaseSettings } from "src/app/models/purchase/purchase-settings";
import { noConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import {
  PreparedPurchaseItem,
  PurchasedItem,
} from "src/app/models/purchase/purchased-item";
import { ItemToPurchase } from "src/app/models/purchase/item-to-purchase";
import { CollectionPurchase } from "src/app/models/collection/collection-purchase";
import { CollectionPurchaseDtoConversion } from "src/app/conversions/collection/collection-purchase-conversion";
import { PurchasedItemDtoConversion } from "src/app/conversions/purchase/purchased-item-dto.conversion";
import { FrappeApiHelper, FrappeRequestListOptions } from "@aht/frappe-client";
import {
  VideoFeedbackPurchase,
  VideoFeedbackPurchaseConversionHelper,
} from "src/app/models/video-feedback/video-feedback-purchase";

@Injectable({
  providedIn: "root",
})
export class PurchaseService {
  private readonly videoFeedbackPurchaseMapper =
    VideoFeedbackPurchaseConversionHelper.wrapper();

  constructor(
    private readonly http: HttpClient,
    private readonly helper: FrappeApiHelper,
  ) {}

  loadPurchasedMusicPieces(
    includeUnfinished: boolean,
  ): Observable<MusicPiecePurchase[]> {
    const conversion = new MusicPiecePurchaseDtoConversion();

    let helper = new FrappeRequestHelper().withFields([
      "name",
      "creation",
      "price",
      "client_secret",
      "purchased_by",
      "music_piece",
      "docstatus",
      "name_of_music_piece",
      "platform_fee",
      "total_sales_tax",
      "payment_intent_status",
    ]);

    if (!includeUnfinished) {
      helper = helper.withFilter(FrappeRequestFilter.submitted());
    }

    const crud = new FrappeCrudHelper(
      "Music Piece Purchase",
      this.http,
      conversion,
    );
    return crud.queryAll(helper);
  }

  loadPurchasedCollections(
    includeUnfinished: boolean,
  ): Observable<CollectionPurchase[]> {
    const conversion = new CollectionPurchaseDtoConversion();

    let helper = new FrappeRequestHelper().withFields([
      "name",
      "creation",
      "price",
      "client_secret",
      "purchased_by",
      "collection",
      "docstatus",
      "name_of_collection",
      "platform_fee",
      "total_sales_tax",
      "payment_intent_status",
    ]);

    if (!includeUnfinished) {
      helper = helper.withFilter(FrappeRequestFilter.submitted());
    }

    const crud = new FrappeCrudHelper(
      "Collection Purchase",
      this.http,
      conversion,
    );
    return crud.queryAll(helper);
  }

  loadPurchasedVideoFeedbacks(
    includeUnfinished: boolean,
  ): Observable<VideoFeedbackPurchase[]> {
    var options = new FrappeRequestListOptions();
    if (!includeUnfinished) {
      options = options.withFilter(FrappeRequestFilter.submitted());
    }
    return this.helper.getList(this.videoFeedbackPurchaseMapper, options);
  }

  loadPurchaseSettings(): Observable<PurchaseSettings> {
    const conversion = new PurchaseSettingsDtoConversion();
    const crud = new FrappeCrudHelper(
      "Purchase Settings",
      this.http,
      conversion,
    );
    return crud.getSingle("Purchase Settings");
  }

  purchase(item: ItemToPurchase): Observable<PreparedPurchaseItem> {
    const conversion = new PurchasedItemDtoConversion();
    const methodHelper = new FrappeMethodHelper(this.http, conversion);
    const method = "yobi_rocks.controllers.purchase_controller.purchase";
    const data = new MethodDataWrapper().withDto({
      item: item.item.name,
      purchase_type: item.type,
    });
    return methodHelper.callMethod(method, data);
  }

  private loadPurchasedMusicPieceByClientSecret(
    clientSecret: string,
  ): Observable<MusicPiecePurchase | undefined> {
    const conversion = new MusicPiecePurchaseDtoConversion();

    const helper = new FrappeRequestHelper()
      .withFields([
        "name",
        "creation",
        "price",
        "client_secret",
        "purchased_by",
        "music_piece",
        "docstatus",
        "name_of_music_piece",
        "platform_fee",
        "total_sales_tax",
        "payment_intent_status",
      ])
      .withFilter(new FrappeRequestFilter("client_secret", "=", clientSecret));

    const crud = new FrappeCrudHelper(
      "Music Piece Purchase",
      this.http,
      conversion,
    );

    return crud.queryFirst(helper);
  }
  private loadCollectionPurchaseByClientSecret(
    clientSecret: string,
  ): Observable<CollectionPurchase | undefined> {
    const conversion = new CollectionPurchaseDtoConversion();

    const helper = new FrappeRequestHelper()
      .withFields([
        "name",
        "creation",
        "price",
        "client_secret",
        "purchased_by",
        "collection",
        "docstatus",
        "name_of_collection",
        "platform_fee",
        "total_sales_tax",
        "payment_intent_status",
      ])
      .withFilter(new FrappeRequestFilter("client_secret", "=", clientSecret));

    const crud = new FrappeCrudHelper(
      "Collection Purchase",
      this.http,
      conversion,
    );

    return crud.queryFirst(helper);
  }

  private loadPurchasedVideoFeedbackByClientSecret(
    clientSecret: string,
  ): Observable<VideoFeedbackPurchase | undefined> {
    return this.helper
      .getList(
        this.videoFeedbackPurchaseMapper,
        new FrappeRequestListOptions()
          .withFilter(
            new FrappeRequestFilter("client_secret", "=", clientSecret),
          )
          .withPageLength(1),
      )
      .pipe(map((results) => (results.length > 0 ? results[0] : undefined)));
  }

  loadPurchaseByClientSecret(
    purchaseType: "music-piece" | "collection" | "video-feedback",
    clientSecret: string,
  ): Observable<PurchasedItem | undefined> {
    if (purchaseType == "music-piece") {
      return this.loadPurchasedMusicPieceByClientSecret(clientSecret).pipe(
        filter((r) => r != undefined),
        map((r) => ({
          item: r!,
          type: "music-piece",
        })),
      );
    }
    if (purchaseType == "video-feedback") {
      return this.loadPurchasedVideoFeedbackByClientSecret(clientSecret).pipe(
        filter((r) => r != undefined),
        map((r) => ({
          item: r!,
          type: "video-feedback",
        })),
      );
    } else {
      return this.loadCollectionPurchaseByClientSecret(clientSecret).pipe(
        filter((r) => r != undefined),
        map((r) => ({
          item: r!,
          type: "collection",
        })),
      );
    }
  }

  downloadReceipt(purchase: PurchasedItem): Observable<Blob> {
    const methodHelper = new FrappeMethodHelper(this.http, noConversion);
    const method =
      "yobi_rocks.controllers.purchase_controller.download_receipt";
    const data = new MethodDataWrapper().withDto({
      item: purchase.item.name,
      purchase_type: purchase.type,
    });
    return methodHelper.callMethod(method, data).pipe(
      map((data) => {
        const array = new Uint8Array(data);
        const blob = new Blob([array], { type: "application/pdf" });
        return blob;
      }),
    );
  }
}
