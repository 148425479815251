import { StoredNewsReadDto } from "src/app/dtos/news/stored-news-read-dto";
import { NewsRead } from "src/app/models/news/news-read";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class StoredNewsReadDtoToNewsReadConversion
  implements Conversion<StoredNewsReadDto, NewsRead>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredNewsReadDto): NewsRead {
    return {
      name: dto.name,
      readUntil: this.us.parseApiDate(dto.read_until),
    };
  }
}
