<span>
  <div *ngIf="!isInEditMode" class="flex flex-row flex-wrap items-center">
    <span *ngIf="canEdit && !value">{{ placeholder }}</span>
    <ng-content></ng-content>
    <button mat-icon-button *ngIf="canEdit" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-form-field *ngIf="isInEditMode && type == 'Input'">
    <mat-label>
      {{ placeholder }}
    </mat-label>
    <input
      matInput
      [ngModel]="value"
      (ngModelChange)="updateValue($event)"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
    />
  </mat-form-field>

  <mat-form-field *ngIf="isInEditMode && type == 'TextArea'">
    <mat-label>
      {{ placeholder }}
    </mat-label>
    <textarea
      fxFill
      matInput
      [ngModel]="value"
      (ngModelChange)="updateValue($event)"
      [placeholder]="placeholder"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      [maxLength]="maxLength"
    ></textarea>
  </mat-form-field>
  <span *ngIf="isInEditMode">{{ value?.length || 0 }} / {{ maxLength }}</span>

  <button mat-icon-button *ngIf="isInEditMode" (click)="done()">
    <mat-icon>done</mat-icon>
  </button>
</span>
