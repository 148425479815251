import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { MusicPieceTypeComponent } from "../music-piece-type/music-piece-type.component";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideEye, lucideEyeOff } from "@ng-icons/lucide";

@Component({
  selector: "yr-music-pieces-controls",
  templateUrl: "./music-pieces-controls.component.html",
  styleUrls: ["./music-pieces-controls.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    MusicPieceTypeComponent,
    NgIconComponent,
  ],
  providers: [provideIcons({ lucideEye, lucideEyeOff })],
})
export class MusicPiecesControlsComponent implements OnInit {
  @Input()
  canCreateNew: boolean = false;

  @Input()
  showNew: boolean = true;

  @Input()
  showFilterLinkedMusicPieces = false;

  displayType: "list" | "cards" = "cards";

  @Output()
  displayTypeChange = new EventEmitter<"list" | "cards">();

  filterText: string = "";

  includeLinkedMusicPieces: boolean = true;

  @Output()
  filterChange = new EventEmitter<{
    filter: string;
    includeLinkedMusicPieces: boolean;
  }>();

  @Output()
  addMusicPieceChange = new EventEmitter<void>();

  @Input()
  showFilter = true;

  @Input()
  showDisplayChange = true;

  constructor() {}

  ngOnInit(): void {
    this.displayTypeChange.emit(this.displayType);
  }

  addMusicPiece() {
    this.addMusicPieceChange.emit();
  }

  applyFilter() {
    this.filterChange.emit({
      filter: this.filterText,
      includeLinkedMusicPieces: this.includeLinkedMusicPieces,
    });
  }

  changeDisplayType(displayType: "list" | "cards") {
    this.displayType = displayType;
    this.displayTypeChange.emit(displayType);
  }
}
