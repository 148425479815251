import { Injectable } from "@angular/core";
import { CreateRecordingResponseDto } from "src/app/dtos/recording/create-recording-response-dto";
import { Recording } from "src/app/models/recording/recording";

import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class CreateRecordingResponseDtoToreRecordingConversion
  implements Conversion<CreateRecordingResponseDto, Recording>
{
  convert(dto: CreateRecordingResponseDto): Recording {
    return {
      musicPieceName: dto.music_piece,
      name: dto.name,
      mirrorHorizontal: dto.mirror_horizontal == 1,
      mirrorVertical: dto.mirror_vertical == 1,
      rotation: dto.rotation,
      renderFinished: dto.render_finished == 1,
      uploadFinished: dto.upload_finished == 1,
      contentTypeOriginalFile: dto.content_type_original_file,
      useMultipleAudioTracks: dto.use_multiple_audio_tracks > 0,
    };
  }
}
