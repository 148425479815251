import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  CollectionActions,
  MusicPieceActions,
  PlayerActions,
} from "src/app/ngrx/actions";
import { loadUsedResourcesAction } from "src/app/ngrx/actions/used-resources-for-user.actions";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { AppState } from "src/app/ngrx/reducers";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";

import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { Preview } from "src/app/models/music-piece/preview";
import {
  CollectionSelectors,
  MusicPieceSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import {
  CollectionTypeData,
  MusicPieceToCollectionItemMapping,
} from "src/app/models/collection/collection-type-data";
import {
  ResizeDirective,
  YrResizeEvent,
} from "src/app/directives/resize.directive";
import { ToolBarSecondRowComponent } from "../../common/tool-bar-second-row/tool-bar-second-row.component";
import { MusicPiecesControlsComponent } from "../music-pieces-controls/music-pieces-controls.component";
import { CommonModule } from "@angular/common";
import { NoItemsIndicatorComponent } from "../../common/no-items-indicator/no-items-indicator.component";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPieceCardsComponent } from "../music-piece-cards/music-piece-cards.component";
import { MusicPiecesTableComponent } from "../music-pieces-table/music-pieces-table.component";

@Component({
  selector: "yr-music-piece-page",
  templateUrl: "./music-piece-page.component.html",
  styleUrls: ["./music-piece-page.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ToolBarSecondRowComponent,
    MusicPiecesControlsComponent,
    ResizeDirective,
    NoItemsIndicatorComponent,
    CreateProfileHintComponent,
    MusicPieceCardsComponent,
    MusicPiecesTableComponent,
  ],
})
export class MusicPiecePageComponent implements OnInit {
  canCreateNew: boolean = false;

  canShare: boolean = false;

  isLoading: boolean = false;

  hasPracticeDurationLeft = true;

  hasFreeStorageLeft = true;

  linkedMusicPieces: LinkedMusicPieceWithMusicPiece[] = [];
  musicPieces: MusicPiece[] = [];

  filteredMusicPieces: MusicPieceToDisplay[] = [];

  previews: Preview[] = [];
  linkedMusicPiecePreviews: Preview[] = [];

  collectionData: CollectionTypeData[] = [];
  musicPieceToCollectionItemMapping: MusicPieceToCollectionItemMapping[] = [];

  displayType: "cards" | "list" = "cards";
  shouldAllowTablesDisplayType = true;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.selectUsedResources();
    this.selectMusicPieces();
    this.selectIsLoadingMusicPieces();
    this.selectPreviews();
    this.selectCollectionsAndCollectionItems();
    this.reloadData();
  }

  private selectMusicPieces() {
    this.store
      .select(MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces)
      .subscribe((data) => {
        this.musicPieces = data.musicPieces;
        this.linkedMusicPieces = data.linkedMusicPieces;
      });

    this.store
      .select(MusicPieceSelectors.selectFilteredMusicPieces)
      .subscribe((musicPieces) => {
        this.filteredMusicPieces = musicPieces;
      });
  }

  private selectIsLoadingMusicPieces() {
    this.store
      .select(MusicPieceSelectors.selectIsLoadingMusicPieces)
      .subscribe((isLoadingMusicPieces) => {
        this.isLoading = isLoadingMusicPieces;
      });
  }

  private selectPreviews() {
    this.store
      .select(MusicPieceSelectors.selectPreviews)
      .subscribe((previews) => (this.previews = previews));

    this.store
      .select(MusicPieceSelectors.selectLinkedMusicPiecePreviews)
      .subscribe((previews) => (this.linkedMusicPiecePreviews = previews));
  }

  private reloadData() {
    this.store.dispatch(MusicPieceActions.loadMusicPieces());
    this.store.dispatch(loadUsedResourcesAction());
    this.store.dispatch(CollectionActions.loadCollections());
    this.store.dispatch(CollectionActions.loadCollectionItems());
    this.store.dispatch(CollectionActions.loadLinkedCollectionItems());
  }

  private selectUsedResources() {
    this.store
      .select(UsedResourcesSelectors.selectUsedResourcesForUserWithLicense)
      .subscribe((pair) => {
        const licensedPieces = pair.license.userLicense.license.musicPieces;
        const numMusicPieces = pair.resources.numberOfPiecesThisYear;

        this.canCreateNew =
          licensedPieces === "unlimited" || licensedPieces > numMusicPieces;

        this.canShare = pair.license.userLicense.license.canShare;

        this.hasPracticeDurationLeft =
          pair.license.userLicense.license.practiceDurationThisMonthMs ==
            "unlimited" ||
          pair.license.userLicense.license.practiceDurationThisMonthMs >
            pair.resources.practiceDurationThisMonthMs;

        this.hasFreeStorageLeft =
          pair.license.userLicense.license.storageBytes == "unlimited" ||
          pair.license.userLicense.license.storageBytes >
            pair.resources.totalStorageBytes;
      });
  }

  private selectCollectionsAndCollectionItems() {
    this.store
      .select(CollectionSelectors.selectCollectionsWithCollectionItems)
      .subscribe((data) => {
        const collectionTypeData = data.collections.map((c) => {
          return {
            isLinkedCollection: false,
            name: c.name,
            title: c.title,
          } as CollectionTypeData;
        });

        const linkedCollectionTypeData = data.linkedCollections.map((lc) => {
          return {
            isLinkedCollection: true,
            name: lc.name,
            title:
              data.collectionsForLinkedCollections.find(
                (c) => c.name == lc.collection,
              )?.title || "", // should be fine!
          } as CollectionTypeData;
        });

        this.collectionData = [
          ...collectionTypeData,
          ...linkedCollectionTypeData,
        ];

        const collectionItemMapping = data.collectionItems.map((ci) => {
          return {
            collectionOrLinkedCollectionName: ci.collection,
            musicPieceOrLinkedMusicPiece: ci.musicPice || ci.linkedMusicPiece,
          } as MusicPieceToCollectionItemMapping;
        });

        const linkedCollectionItemMapping = data.linkedCollectionItems.map(
          (lci) => {
            return {
              collectionOrLinkedCollectionName: lci.linkedCollection,
              musicPieceOrLinkedMusicPiece: lci.linkedMusicPiece,
            } as MusicPieceToCollectionItemMapping;
          },
        );

        this.musicPieceToCollectionItemMapping = [
          ...collectionItemMapping,
          ...linkedCollectionItemMapping,
        ];
      });
  }

  applyFilter(filter: { filter: string; includeLinkedMusicPieces: boolean }) {
    this.store.dispatch(
      MusicPieceActions.filterMusicPieces({
        filter: filter.filter,
        includeLinkedMusicPieces: filter.includeLinkedMusicPieces,
      }),
    );
  }

  changeDisplayType(displayType: "cards" | "list") {
    this.displayType = displayType;
  }

  editMusicPiece(musicPiece: MusicPiece) {
    this.router.navigate(["musicpiece", "edit", musicPiece.name]);
  }

  editLinkedMusicPiece(linkedMusicPiece: LinkedMusicPiece) {
    this.router.navigate(["musicpiece", "edit-share", linkedMusicPiece.name]);
  }

  addMusicPiece() {
    this.router.navigate(["musicpiece", "new"]);
  }

  navigateToPlayer(pair: LinkedMusicPieceWithMusicPiece) {
    this.store.dispatch(
      PlayerActions.musicPieceLoaded({
        musicPiece: pair.musicPiece,
        linkedMusicPiece: pair.linkedMusicPiece,
      }),
    );

    if (pair.linkedMusicPiece) {
      this.router.navigate(["/linked", pair.linkedMusicPiece.name]);
    } else {
      this.router.navigate(["/player", pair.musicPiece.name]);
    }
  }

  createCopyFromLinkedMusicPiece(data: LinkedMusicPiece) {
    this.store.dispatch(
      MusicPieceActions.createCopyFromLinkedMusicPiece({
        linkedMusicPiece: data,
      }),
    );
  }

  handleResize(event: YrResizeEvent) {
    // XXX: ngZone is required, otherwise the resize event is triggered but angular handles the change only very late!!!
    this.ngZone.run(() => {
      this.shouldAllowTablesDisplayType = event.width >= 640; // see: tailwind sm:   https://tailwindcss.com/docs/responsive-design
    });
  }
}
