import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { CollectionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";

import {
  CollectionSelectors,
  LicenseSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ToolBarSecondRowComponent } from "../../common/tool-bar-second-row/tool-bar-second-row.component";
import { CollectionControlComponent } from "../collection-control/collection-control.component";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { CollectionsComponent } from "../collections/collections.component";

@Component({
  selector: "yr-collection-page",
  templateUrl: "./collection-page.component.html",
  styleUrls: ["./collection-page.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ToolBarSecondRowComponent,
    CollectionControlComponent,
    CreateProfileHintComponent,
    CollectionsComponent,
  ],
})
export class CollectionPageComponent implements OnInit, OnDestroy {
  canCreateNew: boolean = false;
  canFilter: boolean = true;

  private readonly subscriptions = new SubscriptionService();

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(UsedResourcesSelectors.selectUsedResourcesForUserWithLicense)
        .subscribe((pair) => {
          const licensedCollections =
            pair.license.userLicense.license.collections;
          const numCollections = pair.resources.totalCollections;

          this.canCreateNew =
            licensedCollections === "unlimited" ||
            licensedCollections > numCollections;
        }),
    );

    this.subscriptions.add(
      this.store
        .select(CollectionSelectors.selectCollections)
        .subscribe((data) => {
          this.canFilter =
            data.collections.length > 0 || data.linkedCollections.length > 0;
        }),
    );
  }

  addCollection() {
    this.router.navigate(["collection", "new"]);
  }

  editCollection(collection: StoredCollection) {
    this.router.navigate(["collection", "edit", collection.name]);
  }

  openLinkedCollection(
    collection: StoredCollection,
    linkedCollection: StoredLinkedCollection,
  ) {
    this.router.navigate(["linked-collection", linkedCollection.name]);
  }

  applyFilter(filter: string) {
    this.store.dispatch(CollectionActions.filter({ filter }));
  }
}
