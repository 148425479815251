import { AbstractControl, UntypedFormControl, ValidatorFn } from "@angular/forms";

export class IdenticalValidator {
  constructor(private toCompare: UntypedFormControl) {}

  identicalValidator(): ValidatorFn {
    return (control) => {
      if (control.value !== this.toCompare.value) {
        return {
          nonidentical: true,
        };
      }
      return null;
    };
  }
}
