import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { CollectionInfoDialogComponent } from "../collection-info-dialog/collection-info-dialog.component";
import { CollectionInfoDialogData } from "src/app/models/collection/collection-info-dialog-data";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-collection-item",
  templateUrl: "./collection-item.component.html",
  styleUrls: ["./collection-item.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class CollectionItemComponent {
  @Output()
  editCollection = new EventEmitter<StoredCollection>();

  @Output()
  openLinkedCollection = new EventEmitter<{
    collection: StoredCollection;
    linkedCollection: StoredLinkedCollection;
  }>();

  @Input()
  collection?: StoredCollection;

  @Input()
  linkedCollection?: StoredLinkedCollection;

  constructor(private readonly matDialog: MatDialog) {}

  edit() {
    this.editCollection.emit(this.collection);
  }

  open() {
    if (this.collection && this.linkedCollection) {
      this.openLinkedCollection.emit({
        collection: this.collection,
        linkedCollection: this.linkedCollection,
      });
    }
  }

  handlePreviewClick() {
    if (this.linkedCollection) {
      this.open();
    } else {
      this.edit();
    }
  }

  info() {
    if (this.collection) {
      this.matDialog.open<
        CollectionInfoDialogComponent,
        CollectionInfoDialogData
      >(CollectionInfoDialogComponent, {
        data: {
          collection: this.collection,
        },
        minWidth: "20vw",
        maxWidth: "80vw",
      });
    }
  }
}
