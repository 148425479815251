<mat-card appearance="outlined" *ngIf="!isLoading && storedUser">
  <mat-card-header>
    <mat-icon class="card-icon" mat-card-avatar color="primary"
      >account_circle</mat-icon
    >
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="title">{{ storedUser.fullName }}</span>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      {{ storedUser.email }} ({{
        "settings.user-settings.created" | translate
      }}
      {{ storedUser.created | date: ("common.dateTimeFormat" | translate) }})
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>translate</mat-icon>
        <mat-form-field>
          <mat-select formControlName="langSelect">
            <mat-option *ngFor="let l of languages" [value]="l">
              {{ l.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="isLoading">
  <mat-progress-spinner></mat-progress-spinner>
</mat-card>
