import { Component, Input } from "@angular/core";
import { SearchResultDetailsDialogData } from "src/app/models/search/search-result-details-dialog-data";
import { SearchResultDetailsDialogComponent } from "../../search/search-result-details-dialog/search-result-details-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Preview } from "src/app/models/music-piece/preview";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { ExploreMusicPieceToMusicPieceListingConversion } from "src/app/conversions/explore/explore-music-piece.to-music-piece-listing.conversion";
import { ArtistProfileLinkService } from "src/app/services/artist/artist-profile-link.service";
import { CommonModule } from "@angular/common";
import { PricePipe } from "src/app/pipes/price.pipe";
import { ExploreMusicPieceThumbnailPipe } from "src/app/pipes/explore-music-piece-thumbnail.pipe";
import { PreviewComponent } from "../../music-piece/preview/preview.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-explore-music-piece",
  templateUrl: "./explore-music-piece.component.html",
  standalone: true,
  imports: [
    CommonModule,
    PricePipe,
    MaterialModule,
    ExploreMusicPieceThumbnailPipe,
    PreviewComponent,
  ],
})
export class ExploreMusicPieceComponent {
  @Input()
  musicPiece?: ExploreMusicPiece;

  @Input()
  preview?: Preview;

  private readonly conversion =
    new ExploreMusicPieceToMusicPieceListingConversion();

  constructor(
    private readonly matDialog: MatDialog,
    private readonly artistLinkService: ArtistProfileLinkService,
  ) {}

  showDetails() {
    if (this.musicPiece) {
      this.matDialog.open<
        SearchResultDetailsDialogComponent,
        SearchResultDetailsDialogData
      >(SearchResultDetailsDialogComponent, {
        data: {
          details: this.conversion.convert(this.musicPiece),
        },
        minWidth: "30vw",
        maxWidth: "80vw",
      });
    }
  }

  goToArtist() {
    if (this.musicPiece) {
      this.artistLinkService.goToArtist(this.musicPiece.artist);
    }
  }
}
