import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { PaymentDialogData } from "src/app/models/artist/payment-dialog-data";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";

import { PurchaseComponent } from "../purchase/purchase.component";
import { MaterialModule } from "src/app/modules/material.module";
import {
  PurchaseDetails,
  CollectionPurchaseDetails,
  VideoFeedbackPurchaseDetails,
  PurchaseStatus,
} from "src/app/ngrx/selectors/purchase.selector";

@Component({
  selector: "yr-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, PurchaseComponent, MaterialModule],
})
export class PaymentDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: PaymentDialogData,
    private readonly dialogRef: MatDialogRef<PaymentDialogComponent>,
    private readonly store: Store<AppState>,
  ) {
    switch (data.purchaseType) {
      case "music-piece":
        const purchaseDetails = data.purchaseDetails as PurchaseDetails;
        this.handleMusicPiecePurchase(purchaseDetails);
        break;
      case "collection":
        const collectionPurchaseDetails =
          data.purchaseDetails as CollectionPurchaseDetails;
        this.handleCollectionPurchase(collectionPurchaseDetails);
        break;
      case "video-feedback":
        const videoFeedbackPurchaseDetails =
          data.purchaseDetails as VideoFeedbackPurchaseDetails;
        this.handleVideoFeedbackPurchase(videoFeedbackPurchaseDetails);
        break;
      default:
        console.error(
          `Payment Dialog Component: invalid purchaseType=${data.purchaseType}`,
        );
    }
  }

  private handleMusicPiecePurchase(purchaseDetails: PurchaseDetails) {
    if (
      purchaseDetails.musicPiecePurchase &&
      purchaseDetails.status == PurchaseStatus.REQUIRES_PAYMENT
    ) {
      // we can continue with the purchase
      this.store.dispatch(
        PurchaseActions.purchasePrepared({
          item: {
            item: purchaseDetails.musicPiecePurchase,
            type: "music-piece",
          },
        }),
      );
    } else if (
      purchaseDetails.musicPiecePurchase == undefined &&
      purchaseDetails.status == PurchaseStatus.NOT_PURCHASED
    ) {
      // we start a new purchase
      this.store.dispatch(
        PurchaseActions.purchase({
          item: {
            item: purchaseDetails.listing,
            type: "music-piece",
          },
        }),
      );
    }
  }

  private handleCollectionPurchase(purchaseDetails: CollectionPurchaseDetails) {
    if (
      purchaseDetails.collectionPurchase &&
      purchaseDetails.status == PurchaseStatus.REQUIRES_PAYMENT
    ) {
      // we can continue with the purchase
      this.store.dispatch(
        PurchaseActions.purchasePrepared({
          item: {
            item: purchaseDetails.collectionPurchase,
            type: "collection",
          },
        }),
      );
    } else if (
      purchaseDetails.collectionPurchase == undefined &&
      purchaseDetails.status == PurchaseStatus.NOT_PURCHASED
    ) {
      // we start a new purchase
      this.store.dispatch(
        PurchaseActions.purchase({
          item: {
            item: purchaseDetails.listing,
            type: "collection",
          },
        }),
      );
    }
  }

  private handleVideoFeedbackPurchase(
    purchaseDetails: VideoFeedbackPurchaseDetails,
  ) {
    if (
      purchaseDetails.videoFeedbackPurchase &&
      purchaseDetails.status == PurchaseStatus.REQUIRES_PAYMENT
    ) {
      // we can continue with the purchase
      this.store.dispatch(
        PurchaseActions.purchasePrepared({
          item: {
            item: purchaseDetails.videoFeedbackPurchase,
            type: "video-feedback",
          },
        }),
      );
    } else if (
      purchaseDetails.videoFeedbackPurchase == undefined &&
      purchaseDetails.status == PurchaseStatus.NOT_PURCHASED
    ) {
      // we start a new purchase
      this.store.dispatch(
        PurchaseActions.purchase({
          item: {
            item: purchaseDetails.artistProfile,
            type: "video-feedback",
          },
        }),
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}
