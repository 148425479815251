<div fxLayout="row wrap">
  <span
    *ngIf="!isLinkedMusicPiece && !isCopiedMusicPiece"
    class="item"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>copyright</mat-icon>
    {{ "music-piece.music-piece-type.owner" | translate }}
  </span>
  <span
    *ngIf="isLinkedMusicPiece"
    class="item"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>done_all</mat-icon>
    {{ "music-piece.music-piece-type.linked-music-piece" | translate }}
  </span>
  <span
    *ngIf="isCopiedMusicPiece"
    class="item"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>file_copy</mat-icon>
    {{ "music-piece.music-piece-type.private-copy" | translate }}
  </span>

  <span
    *ngIf="showShortCollectionInfo && collectionInfo.length > 0"
    class="item"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>video_library</mat-icon>
  </span>
  <ng-container *ngIf="!showShortCollectionInfo">
    <span
      *ngFor="let ci of collectionInfo"
      class="item"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>video_library</mat-icon>
      {{ ci.title }}
    </span>
  </ng-container>
</div>
