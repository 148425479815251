<h1 matDialogTitle>
  {{
    "readonly-collection.practice-interval-readonly-collection-item-dialog.title"
      | translate
  }}
</h1>

<div matDialogContent>
  @for (item of linkedCollectionItems; track $index) {
    <div>
      @if ($index > 0) {
        <mat-divider></mat-divider>
      }
      <div
        class="flex flex-col items-start justify-between md:flex-row md:items-center"
      >
        <div class="flex flex-col">
          <span class="mx-4 mt-4 max-w-96 truncate whitespace-nowrap font-bold">
            {{ item.musicPiece.musicPiece.nameOfPiece }}
          </span>

          <div class="mx-4 mb-4 text-sm">
            <span class="mr-2">{{
              "readonly-collection.practice-interval-readonly-collection-item-dialog.recommended-interval"
                | translate
            }}</span>
            <yr-practice-interval-indicator
              [interval]="item.collectionItem.practiceInterval"
            ></yr-practice-interval-indicator>
          </div>
        </div>

        <div class="join mb-4 md:mb-0">
          <button
            class="btn join-item btn-sm"
            [class.btn-primary]="
              item.collectionItem.myPracticeInterval != undefined
                ? item.collectionItem.myPracticeInterval == 0
                : item.collectionItem.practiceInterval == undefined ||
                  item.collectionItem.practiceInterval == 0
            "
            (click)="updatePracticeInterval(item, 0)"
          >
            {{
              "readonly-collection.practice-interval-readonly-collection-item-dialog.never"
                | translate
            }}
          </button>
          <button
            class="btn join-item btn-sm"
            [class.btn-primary]="
              item.collectionItem.myPracticeInterval != undefined
                ? item.collectionItem.myPracticeInterval == 1
                : item.collectionItem.practiceInterval == 1
            "
            (click)="updatePracticeInterval(item, 1)"
          >
            {{
              "readonly-collection.practice-interval-readonly-collection-item-dialog.daily"
                | translate
            }}
          </button>
          <button
            class="btn join-item btn-sm"
            [class.btn-primary]="
              item.collectionItem.myPracticeInterval != undefined
                ? item.collectionItem.myPracticeInterval == 7
                : item.collectionItem.practiceInterval == 7
            "
            (click)="updatePracticeInterval(item, 7)"
          >
            {{
              "readonly-collection.practice-interval-readonly-collection-item-dialog.weekly"
                | translate
            }}
          </button>
          <button
            class="btn join-item btn-sm"
            [class.btn-primary]="
              item.collectionItem.myPracticeInterval != undefined
                ? item.collectionItem.myPracticeInterval == 30
                : item.collectionItem.practiceInterval == 30
            "
            (click)="updatePracticeInterval(item, 30)"
          >
            {{
              "readonly-collection.practice-interval-readonly-collection-item-dialog.monthly"
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
  }
</div>

<div matDialogActions fxLayout="row" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]>
    {{ "common.cancel" | translate }}
  </button>
  <button mat-button (click)="update()" [disabled]="!isDirty" color="primary">
    {{ "common.save" | translate }}
  </button>
</div>
