import {
  FrappeApiHelper,
  FrappeRequestFilter,
  FrappeRequestListOptions,
  mappingBuilder,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { PracticeConfirmation } from "src/app/models/practice-routine/practice-confirmation";
import {
  PracticeRoutineDisplayItem,
  PracticeRoutineStats,
} from "src/app/models/practice-routine/practice-routine-display-item";
import { CollectionType } from "src/app/ngrx/actions/practice-routine.actions";
@Injectable({
  providedIn: "root",
})
export class PracticeRoutinesService {
  private readonly collectionMapping = mappingBuilder<StoredCollection>(
    "Collection",
    [
      mappingHelper.stringMapper("name"),
      mappingHelper.dateMapper("creation", "created"),
      mappingHelper.stringMapper("artist"),
      mappingHelper.stringMapper("description"),
      mappingHelper.stringMapper("instrument"),
      mappingHelper.stringMapper("temperament"),
      mappingHelper.stringMapper("title"),
      mappingHelper.stringMapper("owner"),
      mappingHelper.stringMapper("content_type", "contentType"),
      mappingHelper.stringMapper("download_link", "downloadLink"),
      mappingHelper.booleanMapper("upload_finished", "uploadFinished"),
      mappingHelper.booleanMapper("accept_share_terms", "acceptShareTerms"),
      mappingHelper.booleanMapper("is_practice_routine", "isPracticeRoutine"),
    ],
  );

  private readonly linkedCollectionMapping =
    mappingBuilder<StoredLinkedCollection>("Linked Collection", [
      mappingHelper.stringMapper("name"),
      mappingHelper.stringMapper("collection"),
      mappingHelper.dateMapper("creation", "created"),
      mappingHelper.dateMapper("modified"),
      mappingHelper.stringMapper("user"),
      mappingHelper.stringMapper("owner"),
      mappingHelper.booleanMapper("is_practice_routine", "isPracticeRoutine"),
    ]);

  private readonly statsMapping = mappingBuilder<PracticeRoutineStats>("", [
    mappingHelper.intMapper("done"),
    mappingHelper.intMapper("total"),
  ]);

  private readonly practiceRoutineDisplayItemMapper =
    mappingBuilder<PracticeRoutineDisplayItem>("", [
      mappingHelper.objectMapper(
        this.collectionMapping.conversion(),
        "collection",
      ),
      mappingHelper.optionalObjectMapper(
        this.linkedCollectionMapping.conversion(),
        "linked_collection",
        "linkedCollection",
      ),
      mappingHelper.objectMapper(this.statsMapping.conversion(), "stats"),
    ]);

  private practiceConfirmationMapper =
    mappingBuilderWithDefaultMappings<PracticeConfirmation>(
      "Practice Confirmation",
      [
        mappingHelper.stringMapper("collection_item", "collectionItem"),
        mappingHelper.stringMapper(
          "linked_collection_item",
          "linkedCollectionItem",
        ),
        mappingHelper.stringMapper("summary"),
        mappingHelper.intMapper("rating"),
      ],
    );

  constructor(private readonly helper: FrappeApiHelper) {}

  loadPracticeRoutines(): Observable<PracticeRoutineDisplayItem[]> {
    return this.helper.callWithResults(
      this.practiceRoutineDisplayItemMapper.conversion(),
      {
        method:
          "yobi_rocks.controllers.practice_routines_controller.practice_routines",
        type: "GET",
      },
    );
  }

  loadPracticeRoutine(
    name: string,
    collectionType: "collection" | "linkedCollection",
  ): Observable<PracticeRoutineDisplayItem | undefined> {
    return this.helper.callWithOptionalResult(
      this.practiceRoutineDisplayItemMapper.conversion(),
      {
        method:
          "yobi_rocks.controllers.practice_routines_controller.practice_routine",
        type: "GET",
        extraParams: new Map([
          ["collection", name],
          ["collection_type", collectionType],
        ]),
      },
    );
  }

  loadCollectionItemForPracticeRoutineItem(
    musicPieceOrLinkedMusicPiece: string,
    collectionOrLinkedCollection: string,
  ): Observable<
    | {
        item: string;
        collectionType: string;
      }
    | undefined
  > {
    const mapper = mappingBuilder<{
      item: string;
      collectionType: CollectionType;
    }>("", [
      mappingHelper.stringMapper("item", "item"),
      mappingHelper.stringMapper("collection_type", "collectionType"),
    ]);

    return this.helper.callWithOptionalResult(mapper.conversion(), {
      method:
        "yobi_rocks.controllers.practice_routines_controller.find_collection_item_for_player",

      type: "GET",
      extraParams: new Map([
        ["collection_or_linked_collection", collectionOrLinkedCollection],
        ["music_piece_or_linked_music_piece", musicPieceOrLinkedMusicPiece],
      ]),
    });
  }

  loadAllPracticeConfirmations(): Observable<PracticeConfirmation[]> {
    return this.helper.getList(
      this.practiceConfirmationMapper,
      new FrappeRequestListOptions().withPageLength(0),
    );
  }

  loadPracticeConfirmations(
    collection: string,
    collectionType: "collection" | "linkedCollection",
  ) {
    return this.helper.callWithResults(
      this.practiceConfirmationMapper.conversion(),
      {
        method:
          "yobi_rocks.controllers.practice_routines_controller.practice_confirmations",

        type: "GET",
        extraParams: new Map([
          ["collection", collection],
          ["collection_type", collectionType],
        ]),
      },
    );
  }

  markAsPracticed(
    collectionItem: string,
    linkedCollectionItem: string,
    rating?: 1 | 2 | 3 | 4 | 5,
    summary?: string,
  ): Observable<PracticeConfirmation> {
    return this.helper.create(
      {
        collectionItem: collectionItem,
        linkedCollectionItem: linkedCollectionItem,
        rating: rating,
        summary: summary,
      },
      this.practiceConfirmationMapper,
    );
  }
}
