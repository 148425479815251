import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { LicenseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { LicenseSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-purchase-subscription-success",
  templateUrl: "./purchase-subscription-success.component.html",
  styleUrls: ["./purchase-subscription-success.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class PurchaseSubscriptionSuccessComponent implements OnInit, OnDestroy {
  isWaitingForWebhook = true;

  private intervalId = 0;

  private sessionId?: string;

  private readonly subscriptions = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.sessionId =
      this.activatedRoute.snapshot.queryParamMap.get("session") || undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
    this.stopPolling();
  }

  private stopPolling() {
    if (this.intervalId > 0) {
      window.clearInterval(this.intervalId);
    }
  }

  private startPolling() {
    this.intervalId = window.setInterval(() => this.triggerCheck(), 1000);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(LicenseSelectors.selectCurrentCheckoutSession)
        .subscribe((data) => {
          if (data.checkoutSession) {
            const oldState = this.isWaitingForWebhook;

            this.isWaitingForWebhook =
              data.checkoutSession.status !== "completed";

            if (
              oldState !== this.isWaitingForWebhook &&
              !this.isWaitingForWebhook
            ) {
              this.store.dispatch(LicenseActions.subscriptionPurchased());
              this.store.dispatch(LicenseActions.loadFallbackAndUpgrade());
            }
            if (!this.isWaitingForWebhook) {
              this.stopPolling();
            }
          }
        }),
    );

    this.triggerCheck();
    this.startPolling();
  }

  private triggerCheck() {
    if (this.sessionId) {
      this.store.dispatch(
        LicenseActions.checkCheckoutSessionStatus({
          stripeCheckoutSessionId: this.sessionId,
        }),
      );
    }
  }
}
