import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  OnInit,
  output,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AppState } from "src/app/ngrx/reducers";
import { LicenseSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { CustomArtistUrlComponent } from "../custom-artist-url/custom-artist-url.component";
import { MaterialModule } from "src/app/modules/material.module";
import { SetVideoFeedbackOfferingComponent } from "../set-video-feedback-offering/set-video-feedback-offering.component";

@Component({
  selector: "yr-artist-page-controls",
  templateUrl: "./artist-page-controls.component.html",
  styleUrls: ["./artist-page-controls.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    CustomArtistUrlComponent,
    SetVideoFeedbackOfferingComponent,
  ],
})
export class ArtistPageControlsComponent implements OnInit, OnDestroy {
  @Input()
  existingCustomUrl?: string;

  @Input()
  isOwner = false;

  @Output()
  editModeChange = new EventEmitter<boolean>();

  isInEditMode = false;

  @Input()
  customUrl?: string;

  @Output()
  customUrlChanged = new EventEmitter<string>();

  canSetCustomUrl = false;

  isOfferingVideoFeedback = input.required<boolean>();
  videoFeedbackPrice = input.required<number>();
  minSellingPrice = input.required<number>();

  videoFeedbackOfferingChanged = output<{
    isOfferingVideoFeedback: boolean;
    videoFeedbackPrice: number;
  }>();

  private readonly subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(LicenseSelectors.selectUserLicense)
        .subscribe((userLicense) => {
          this.canSetCustomUrl = userLicense.license.canSetCustomUrl;
        }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  handleEditModeChange(isEditMode: boolean) {
    this.isInEditMode = isEditMode;
    this.editModeChange.emit(isEditMode);
  }
}
