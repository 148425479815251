import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { NewsDialogData } from "src/app/models/news/news-dialog-data";
import { NewsService } from "src/app/services/news/news.service";
import { NewsComponent } from "../news/news.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-news-dialog",
  templateUrl: "./news-dialog.component.html",
  styleUrls: ["./news-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, NewsComponent],
})
export class NewsDialogComponent implements OnInit {
  constructor(
    private readonly dialogRef: MatDialogRef<NewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly news: NewsDialogData,
    private readonly newsService: NewsService,
  ) {}

  ngOnInit(): void {}

  markAsRead() {
    this.newsService.markNewsAsRead().subscribe(
      () => {
        this.dialogRef.close();
      },
      (error) => {
        console.error(error);
        this.dialogRef.close();
      },
    );
  }
}
