import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map, tap } from "rxjs/operators";
import { Authentication } from "src/app/models/authentication/authentication";
import { AuthenticationResponseDto } from "src/app/dtos/authentication/authentication-response.dto";
import { UserDtoToUserConversion } from "src/app/conversions/user/user-dto-to-user-conversion";
import { EmptyUserData, UserData } from "src/app/models/user/user-data";

import { FrappeCrudHelper } from "../frappe-crud-helper";
import { dropConversion } from "../conversion-helper";
import { StoredUser } from "src/app/models/user/stored-user";
import { StoredUserDtoConversion } from "src/app/conversions/user/stored-user-dto-conversion";
import { LanguageService } from "../language/language.service";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import { CreatePotentialUser } from "src/app/models/user/create-potential-user";
import { StoredPotentialUser } from "src/app/models/user/stored-potential-user";
import { StoredPotentialUserDtoConversion } from "src/app/conversions/user/stored-potential-user-dto-conversion";
import { UnitService } from "../unit/unit.service";
import { MatomoTracker } from "ngx-matomo-client";
import { UpdatedPassword } from "src/app/models/user/updated-password";
import { UpdatedPasswordDtoConversion } from "src/app/conversions/user/updated-password-conversion";
import { StripeCustomer } from "src/app/models/user/stripe-customer";
import { StripeCustomerConversion } from "src/app/conversions/user/stripe-customer-conversion";
import { FrappeRequestHelper } from "../frappe-request-helper";
import { GoogleSignInConversion } from "src/app/conversions/user/google-sign-in-response-conversion";
import { GoogleSignInResponse } from "src/app/models/user/google-sign-in-response";
import { FinishGoogleSignUp } from "src/app/models/user/finish-google-sign-up";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly authenticationSubject = new BehaviorSubject<UserData>(
    EmptyUserData,
  );

  private static readonly USER_AUTH = "aht-user";

  private readonly controllerUrl =
    "/api/method/yobi_rocks.controllers.auth_controller.";

  constructor(
    private http: HttpClient,
    private router: Router,
    private userDtoToUserConversion: UserDtoToUserConversion,
    private readonly tracker: MatomoTracker,
    private readonly languageService: LanguageService,
    private readonly unitService: UnitService,
  ) {
    this.init();
  }

  private init() {
    const json = localStorage.getItem(UserService.USER_AUTH);
    if (json) {
      const item: UserData = JSON.parse(json);
      item.validUntil = new Date(item.validUntil); // otherwise the date is treated as string
      this.authenticationSubject.next(item);
    }
  }

  private isLoggedIn(): boolean {
    if (this.authenticationSubject.getValue() != EmptyUserData) {
      const currentAuth = this.authenticationSubject.getValue();
      return currentAuth.validUntil.getTime() > Date.now();
    }
    return false;
  }

  private currentAuth(): UserData {
    return this.authenticationSubject.getValue();
  }

  private authentication(): Observable<UserData> {
    return this.authenticationSubject.asObservable();
  }

  private handleAuthentication(data: UserData) {
    localStorage.setItem(UserService.USER_AUTH, JSON.stringify(data));
    this.authenticationSubject.next(data);
  }

  loginWithUsernameAndPassword(auth: Authentication): Observable<UserData> {
    const params = new HttpParams()
      .append("usr", auth.username)
      .append("pwd", auth.password);

    const res = this.http.get<AuthenticationResponseDto>(
      `${environment.baseUrl}${this.controllerUrl}login_user`,
      {
        params: params,
      },
    );

    return res.pipe(
      map((dto) => this.userDtoToUserConversion.convert(dto.auth_data)),
      tap((auth) => this.handleAuthentication(auth)),
      tap(
        (auth) => {
          this.tracker.setUserId(auth.email.toLowerCase());
          this.tracker.trackEvent(
            "login",
            "successful_login",
            auth.email.toLowerCase(),
          );
          this.languageService.useLanguage(auth.language);
        },
        (error) => {
          this.tracker.trackEvent("login", "failed_login", auth.username);
        },
      ),
    );
  }

  loginWithGoogle(credential: string): Observable<GoogleSignInResponse> {
    // either we need more to finish the registration or we are good already!
    const helper = new FrappeMethodHelper(
      this.http,
      new GoogleSignInConversion(),
    );
    const method =
      "yobi_rocks.controllers.auth_controller.login_with_google_jwt_token";

    const data = new MethodDataWrapper().withDto({
      credential: credential,
    });
    this.tracker.trackEvent("user", "loginWithGoogle");

    return helper.callMethod(method, data).pipe(
      tap((response) => {
        if (response.userData && !response.requiresSignUp) {
          this.handleAuthentication(response.userData);
        }
      }),
    );
  }

  logout() {
    localStorage.removeItem(UserService.USER_AUTH);
    this.authenticationSubject.next(EmptyUserData);
    this.router.navigate(["login"]);

    this.tracker.trackEvent(
      "login",
      "logout",
      this.authenticationSubject.value.username,
    );
    this.tracker.setUserId("");
  }

  registerNewUser(
    firstname: string,
    lastname: string,
    email: string,
  ): Observable<void> {
    const params = new HttpParams()
      .append("firstname", firstname)
      .append("lastname", lastname)
      .append("email", email);
    return this.http.get<void>(
      `${environment.baseUrl}${this.controllerUrl}register_new_user`,
      {
        params: params,
      },
    );
  }

  getUserDetails(): Observable<StoredUser> {
    const username = this.currentAuth().username;
    const helper = new FrappeCrudHelper(
      "User",
      this.http,
      new StoredUserDtoConversion(),
    );
    return helper.getSingle(username);
  }

  private updateStoredLanguage(languageCode: string) {
    const oldVal = this.authenticationSubject.value;
    const newVal = { ...oldVal, language: languageCode } as UserData;
    this.handleAuthentication(newVal);
  }

  switchLanguage(languageShortCode: string): Observable<void> {
    this.updateStoredLanguage(languageShortCode);

    const name = this.currentAuth().username;
    const dto = { language: languageShortCode };
    const helper = new FrappeCrudHelper("User", this.http, dropConversion);
    return helper.update(name, dto);
  }

  updatePassword(newPassword: string): Observable<UpdatedPassword> {
    const helper = new FrappeMethodHelper(
      this.http,
      new UpdatedPasswordDtoConversion(),
    );
    const method =
      "yobi_rocks.controllers.auth_controller.update_user_password";
    const data = new MethodDataWrapper().withDto({ new_password: newPassword });

    return helper.callMethod(method, data);
  }

  verifyEmailAndSetPassword(
    verificationCode: string,
    password: string,
  ): Observable<StoredPotentialUser> {
    const helper = new FrappeMethodHelper(
      this.http,
      new StoredPotentialUserDtoConversion(),
    );
    const method =
      "yobi_rocks.controllers.registration_controller.verify_email";
    const data = new MethodDataWrapper().withDto({
      password: password,
      verification_code: verificationCode,
    });
    this.tracker.trackEvent("potentialuser", "verify", verificationCode);
    return helper.callMethod(method, data);
  }

  finishGoogleSignUp(f: FinishGoogleSignUp): Observable<StoredPotentialUser> {
    const helper = new FrappeMethodHelper(
      this.http,
      new StoredPotentialUserDtoConversion(),
    );

    const method =
      "yobi_rocks.controllers.registration_controller.finish_google_sign_up";
    const data = new MethodDataWrapper().withDto({
      date_of_birth: this.unitService.dateToApiDate(f.dateOfBirth),
      phone: f.phone,
      zip: f.zip,
      address: f.address,
      city: f.city,
      country: f.country,
      accept_terms: f.acceptTerms,
      accept_gdpr: f.acceptGdpr,
      credential: f.credential,
      user_type: f.userType,
    });

    this.tracker.trackEvent("potentialuser", "finish-google-signup");
    return helper.callMethod(method, data);
  }

  registerPotentialUser(
    pu: CreatePotentialUser,
  ): Observable<StoredPotentialUser> {
    const conversion = new StoredPotentialUserDtoConversion();
    const helper = new FrappeCrudHelper(
      "Potential User",
      this.http,
      conversion,
    );

    this.tracker.trackEvent("potentialuser", "register", pu.email);

    return helper.create({
      first_name: pu.firstName,
      last_name: pu.lastName,
      date_of_birth: this.unitService.dateToApiDate(pu.dateOfBirth),
      email: pu.email,
      phone: pu.phone,
      zip: pu.zip,
      address: pu.address,
      city: pu.city,
      country: pu.country,
      accept_terms: pu.acceptTerms,
      accept_gdpr: pu.acceptGdpr,
      sign_up_type: "UserAndPassword",
      user_type: pu.userType,
    });
  }

  loadStripeCustomer(): Observable<StripeCustomer | undefined> {
    const result = new FrappeCrudHelper(
      "Stripe Customer",
      this.http,
      new StripeCustomerConversion(),
    ).queryAll(new FrappeRequestHelper().withFields(["*"]).withPageLength(1));

    return result.pipe(
      map((r) => {
        if (r.length == 1) {
          return r[0];
        } else {
          return undefined;
        }
      }),
    );
  }
}
