<mat-card appearance="raised">
  <mat-card-header>
    <mat-card-title>
      {{ "edit-collection.share-collection.share" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>
          {{ "edit-collection.share-collection.email" | translate }}
        </mat-label>
        <input
          matInput
          type="email"
          [formControl]="email"
          placeholder="{{
            'edit-collection.share-collection.email' | translate
          }}"
        />
      </mat-form-field>
      <button
        mat-raised-button
        (click)="share()"
        [disabled]="form.invalid || !this.collection"
      >
        {{ "edit-collection.share-collection.share" | translate }}
      </button>
    </form>
    <br />
    <yr-shared-collection-receivers></yr-shared-collection-receivers>
  </mat-card-content>
</mat-card>
<br /><!--  Otherwise the mat-card will look cropped -->
