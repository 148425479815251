import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import {
  filter,
  map,
  mergeMap,
  withLatestFrom,
  tap,
  debounceTime,
} from "rxjs/operators";
import { MetronomeService } from "src/app/services/player/metronome.service";
import { MetronomeActions } from "../actions";
import { musicPieceLoaded } from "../actions/player.actions";
import { AppState } from "../reducers";
import { selectMusicPiece } from "../reducers/player.reducer";

@Injectable()
export class MetronomeEffects {
  loadRecordingWhenMusicPieceIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(musicPieceLoaded),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) =>
        this.metronomeService.getMetronomesFromMusicPiece(
          action.musicPiece.name,
          state.linkedMusicPiece
        )
      ),
      map((r) => MetronomeActions.loadedMetronomes({ metronomes: r }))
    )
  );

  createMetronome$ = createEffect(() =>
    this.actions.pipe(
      ofType(MetronomeActions.createMetronome),
      withLatestFrom(this.store.select(selectMusicPiece)),
      filter(([action, musicPiece]) => musicPiece != undefined),
      mergeMap(([action, musicPiece]) =>
        this.metronomeService.addMetronomeInMusicPiece(
          musicPiece!,
          action.nameOfMetronome,
          action.endValue,
          action.bpm
        )
      ),
      tap(
        (success) => {
          this.toastrService.success(this.translate.instant("common.success"));
        },
        (error) => {
          console.error(error);
          this.toastrService.error(this.translate.instant("common.fail"));
        }
      ),
      map((metronome) =>
        MetronomeActions.createdMetronome({
          metronome: metronome,
        })
      )
    )
  );

  deleteMetronome$ = createEffect(() =>
    this.actions.pipe(
      ofType(MetronomeActions.deleteMetronome),
      mergeMap((action) =>
        this.metronomeService
          .deleteMetronome(action.metronome)
          .pipe(map((_) => action.metronome))
      ),
      map((metronome) =>
        MetronomeActions.deletedMetronome({
          metronome: metronome,
        })
      )
    )
  );

  updateMetronome$ = createEffect(() =>
    this.actions.pipe(
      ofType(MetronomeActions.updateMetronome),
      debounceTime(1000),
      mergeMap((action) =>
        this.metronomeService.updateMetronome(action.metronome)
      ),
      map((metronome) =>
        MetronomeActions.updatedMetronome({
          metronome: metronome,
        })
      )
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly metronomeService: MetronomeService,
    private readonly toastrService: ToastrService,
    private readonly translate: TranslateService
  ) {}
}
