import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store, createSelector } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import {
  AppState,
  CollectionSelectors,
  EditMusicPieceSelectors,
} from "src/app/ngrx/reducers";
import { ConfirmationDialogComponent } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-delete-music-piece",
  templateUrl: "./delete-music-piece.component.html",
  styleUrls: ["./delete-music-piece.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class DeleteMusicPieceComponent implements OnInit {
  isLoading?: boolean;
  isNew?: boolean;
  isLinkedMusicPiece?: boolean;

  musicPiece?: MusicPiece;
  linkedMusicPiece?: LinkedMusicPiece;
  isDeleteInProgress: boolean = false;
  hasListing: boolean = false;

  isLoadingIsPartOfSharedCollection = false;
  isPartOfSharedCollection = false;
  hasSharedCollectionReceivers = false;

  // we need to combine the two selectors, otherwise we can't "defer" the can-delete condition
  private readonly combinedDataAndCollectionSelector = createSelector(
    CollectionSelectors.selectLinkedCollectionItems,
    EditMusicPieceSelectors.selectEditMusicPieceData,
    (c, e) => {
      return {
        isLoadingLinkedCollectionItems: c.isLoadingLinkedCollectionItems,
        linkedCollectionItems: c.linkedCollectionItems,
        isLoading: e.isLoading,
        linkedMusicPiece: e.linkedMusicPiece,
        musicPiece: e.musicPiece,
      };
    },
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.store
      .select(EditMusicPieceSelectors.selectInitialEditMusicPieceData)
      .subscribe((data) => {
        this.isNew = data.isNew;
        this.isLinkedMusicPiece = data.isLinkedMusicPiece;
      });

    this.store
      .select(this.combinedDataAndCollectionSelector)
      .subscribe((data) => {
        this.musicPiece = data.musicPiece;
        this.linkedMusicPiece = data.linkedMusicPiece;
        this.isLoading = data.isLoading;
        this.isPartOfSharedCollection = data.linkedCollectionItems.some(
          // only targets the receiver side.
          (ci) => ci.linkedMusicPiece == data.linkedMusicPiece?.name,
        );
        this.isLoadingIsPartOfSharedCollection =
          data.isLoadingLinkedCollectionItems;
      });

    this.store
      .select(EditMusicPieceSelectors.selectListing)
      .subscribe((listing) => {
        this.hasListing = listing != undefined;
      });

    this.store
      .select(EditMusicPieceSelectors.selectSharedMusicPieceReceivers)
      .subscribe((data) => {
        this.hasSharedCollectionReceivers = data.some(
          (rmp) => rmp.isPartOfLinkedCollection,
        );
      });
  }

  private showDeleteDialog(): Observable<boolean> {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title: "edit-music-piece.delete-music-piece.delete-dialog.title",
        content: "edit-music-piece.delete-music-piece.delete-dialog.content",
      },
    });

    return ref.afterClosed().pipe(map((r) => r == true));
  }

  deleteMusicPiece() {
    this.showDeleteDialog().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditMusicPieceActions.deleteMusicPiece({
            musicPiece: this.linkedMusicPiece ? undefined : this.musicPiece,
            linkedMusicPiece: this.linkedMusicPiece,
          }),
        );
      }
    });
  }
}
