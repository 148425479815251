import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { EditCollectionComponent } from "../edit-collection/edit-collection.component";

@Component({
  selector: "yr-edit-collection-page",
  templateUrl: "./edit-collection-page.component.html",
  styleUrls: ["./edit-collection-page.component.scss"],
  standalone: true,
  imports: [EditCollectionComponent],
})
export class EditCollectionPageComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.handleRouteData();
  }

  handleRouteData() {
    const { isNew } = this.activatedRoute.snapshot.data;
    const name = this.activatedRoute.snapshot.paramMap.get("name") || "";

    this.store.dispatch(
      EditCollectionActions.openCollectionForEdit({ isNew, name }),
    );
  }
}
