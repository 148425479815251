import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { UploadEvent } from "src/app/models/music-piece/attachment-upload-event";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  constructor(private readonly http: HttpClient) {}

  uploadFile(file: File, uploadUrl: string): Observable<UploadEvent> {
    // for the upload to report the progress we have to add the ngsw-bypass header to skip the service worker
    // however the ngsw-bypass header is not a default header in the nginx configuration and thus breaks cors.
    // for that reason we configured nginx-ingress for the object Store to support the nginx-bypass header in the CORS Options request.
    // we can't use the ngsw-bypass query param because this would break the request signature (Signature Error from rados)
    // we also remove the authorization header (see token interceptor) to prevent Invalid Argument errors

    const headers = new HttpHeaders().append("ngsw-bypass", "");

    const request = new HttpRequest("PUT", uploadUrl, file, {
      reportProgress: true,
      responseType: "json",
      headers: headers,
    });

    return this.http.request(request).pipe(
      filter((event) => {
        return (
          event.type == HttpEventType.UploadProgress ||
          event instanceof HttpResponse
        );
      }),
      map((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          return {
            type: "Progress",
            uploadProgress: event.total ? event.loaded / event.total : 0,
          } as UploadEvent;
        } else {
          return {
            type: "Finished",
            uploadProgress: 1,
          } as UploadEvent;
        }
      })
    );
  }
}
