import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CollectionTypeData } from "src/app/models/collection/collection-type-data";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-type",
  templateUrl: "./music-piece-type.component.html",
  styleUrls: ["./music-piece-type.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class MusicPieceTypeComponent implements OnInit {
  @Input()
  isLinkedMusicPiece: boolean = false;

  @Input()
  isCopiedMusicPiece: boolean = false;

  @Input()
  collectionInfo: CollectionTypeData[] = [];

  @Input()
  showShortCollectionInfo: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
