<mat-dialog-content>
  <div *ngFor="let n of news.unreadNews; index as i">
    <mat-divider *ngIf="i > 0"></mat-divider>
    <br *ngIf="i > 0" />
    <yr-news [news]="n"></yr-news>
  </div>
</mat-dialog-content>

<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>
    {{ "common.close" | translate }}
  </button>
  <button mat-raised-button (click)="markAsRead()" color="primary">
    {{ "news.news-dialog.mark-as-read" | translate }}
  </button>
</div>
