import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export abstract class CustomUrlValidator {
  private static error() {
    return {
      customUrl: true,
    };
  }

  private static noError() {
    return null;
  }

  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value == "") return CustomUrlValidator.noError();

      const customUrlValidator = /^[a-zA-Z0-9]+[\w\.-]*[a-zA-Z0-9]+$/g;
      if (customUrlValidator.test(control.value)) {
        return CustomUrlValidator.noError();
      } else {
        return CustomUrlValidator.error();
      }
    };
  }
}
