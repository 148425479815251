import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState, EditMusicPieceSelectors } from "src/app/ngrx/reducers";
import { ConfirmationDialogHelper } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { LicenseSelectors } from "src/app/ngrx/selectors";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedMusicPieceReceiversComponent } from "../shared-music-piece-receivers/shared-music-piece-receivers.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-share-music-piece",
  templateUrl: "./share-music-piece.component.html",
  styleUrls: ["./share-music-piece.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    SharedMusicPieceReceiversComponent,
  ],
})
export class ShareMusicPieceComponent implements OnInit {
  isLinkedMusicPiece?: boolean;
  musicPiece?: MusicPiece;
  hasShareLicense: boolean = false;

  email = new UntypedFormControl("", [Validators.required]);

  form = new UntypedFormGroup({
    email: this.email,
  });

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.store
      .select(EditMusicPieceSelectors.selectEditMusicPieceData)
      .subscribe((data) => {
        this.musicPiece = data.musicPiece;
      });

    this.store
      .select(EditMusicPieceSelectors.selectInitialEditMusicPieceData)
      .subscribe((data) => {
        this.isLinkedMusicPiece = data.isLinkedMusicPiece;
      });

    this.store.select(LicenseSelectors.selectUserLicense).subscribe((data) => {
      this.hasShareLicense = data.license.canShare;
    });
  }

  share() {
    const helper = new ConfirmationDialogHelper(
      this.matDialog,
      "edit-music-piece.share-music-piece.confirmation-dialog-title",
      "edit-music-piece.share-music-piece.confirmation-dialog-content",
    );
    helper.show(() => {
      if (this.musicPiece) {
        this.store.dispatch(
          EditMusicPieceActions.shareMusicPiece({
            musicPiece: this.musicPiece,
            receiver: this.email.value,
          }),
        );
        this.email.setValue("");
      }
    });
  }
}

export interface ShareMusicPiece {
  musicPieceToShare: MusicPiece;
  email: string;
}
