import { Routes } from "@angular/router";
import { ArtistCertificationPageComponent } from "./components/artist-certification/artist-certification-page/artist-certification-page.component";
import { ArtistPageComponent } from "./components/artist/artist-page/artist-page.component";
import { CollectionPageComponent } from "./components/collection/collection-page/collection-page.component";
import { EditCollectionPageComponent } from "./components/edit-collection/edit-collection-page/edit-collection-page.component";
import { EditMusicPiecePageComponent } from "./components/edit-music-piece/edit-music-piece-page/edit-music-piece-page.component";
import { ExplorePageComponent } from "./components/explore/explore-page/explore-page.component";
import { FaqPageComponent } from "./components/faq/faq-page/faq-page.component";
import { MusicPiecePageComponent } from "./components/music-piece/music-piece-page/music-piece-page.component";
import { PurchaseStatusPageComponent } from "./components/purchase/purchase-status-page/purchase-status-page.component";
import { PurchasesPageComponent } from "./components/purchase/purchases-page/purchases-page.component";
import { ReadonlyCollectionPageComponent } from "./components/readonly-collection/readonly-collection-page/readonly-collection-page.component";
import { SalesPageComponent } from "./components/sales/sales-page/sales-page.component";
import { SettingsPageComponent } from "./components/settings/settings-page/settings-page.component";
import { PurchaseSubscriptionFailureComponent } from "./components/user/purchase-subscription-failure/purchase-subscription-failure.component";
import { PurchaseSubscriptionSuccessPageComponent } from "./components/user/purchase-subscription-success-page/purchase-subscription-success-page.component";
import { SubscriptionPageComponent } from "./components/user/subscription-page/subscription-page.component";
import { VerifyEmailPageComponent } from "./components/user/verify-email-page/verify-email-page.component";
import { AuthGuard } from "./guards/auth-guard";
import { SkipLoginGuard } from "./guards/skip-login.guard";
import { ArtistProfileUrlMatcher } from "./url-matcher/artist-profile-url-matcher";
import { NotificationPageComponent } from "./components/notification/notification-page/notification-page.component";

export const routes: Routes = [
  {
    path: "login",
    pathMatch: "full",
    canActivate: [SkipLoginGuard],
    loadComponent: () =>
      import("./components/user/login/login-page.component").then(
        (p) => p.LoginPageComponent,
      ),
  },
  {
    path: "player/:name",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
    },
  },
  {
    path: "player-feedback/:name/:feedback",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
      isFeedback: true,
    },
  },
  {
    path: "linked/:linked",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
      isLinkedMusicPiece: true,
    },
  },
  {
    path: "player-linked-feedback/:linked/:feedback",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
      isLinkedMusicPiece: true,
      isFeedback: true,
    },
  },
  {
    path: "pl/:playlistType/:playlist/linked/:linked",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
      isLinkedMusicPiece: true,
      loadPlaylist: true,
      canConfirmPracticeRoutine: true, // TODO: WRONG for collections
    },
  },
  {
    path: "pl/:playlistType/:playlist/player/:name",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("./components/player/player-page/player-page.component").then(
        (component) => component.PlayerPageComponent,
      ),
    data: {
      showWerkbankToolbar: true,
      loadPlaylist: true,
      canConfirmPracticeRoutine: true, // TODO: WRONG for collections
    },
  },
  {
    path: "registration",
    loadComponent: () =>
      import(
        "./components/user/register-new-user/register-new-user-page.component"
      ).then((c) => c.RegisterNewUserPageComponent),
    canActivate: [SkipLoginGuard],
  },
  {
    path: "registration-verify",
    component: VerifyEmailPageComponent,
    canActivate: [SkipLoginGuard],
  },
  {
    path: "musicpiece",
    canActivate: [AuthGuard],
    component: MusicPiecePageComponent,
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "musicpiece/edit/:name",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: EditMusicPiecePageComponent,
    data: {
      isNew: false,
      isLinkedMusicPiece: false,
    },
  },
  {
    path: "musicpiece/edit-share/:name",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: EditMusicPiecePageComponent,
    data: {
      isNew: false,
      isLinkedMusicPiece: true,
    },
  },
  {
    path: "musicpiece/new",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: EditMusicPiecePageComponent,
    data: {
      isNew: true,
      isLinkedMusicPiece: false,
    },
  },
  {
    path: "purchase-status/:purchase-type/:client-secret",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: PurchaseStatusPageComponent,
  },
  {
    path: "purchases",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: PurchasesPageComponent,
  },
  {
    path: "explore",
    pathMatch: "full",
    component: ExplorePageComponent,
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "settings",
    canActivate: [AuthGuard],
    component: SettingsPageComponent,
  },
  {
    path: "subscription",
    canActivate: [AuthGuard],
    component: SubscriptionPageComponent,
  },
  {
    path: "subscription-success",
    canActivate: [AuthGuard],
    component: PurchaseSubscriptionSuccessPageComponent,
  },
  {
    path: "subscription-failure",
    canActivate: [AuthGuard],
    component: PurchaseSubscriptionFailureComponent,
  },
  {
    path: "collection",
    canActivate: [AuthGuard],
    component: CollectionPageComponent,
    pathMatch: "full",
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "collection/new",
    canActivate: [AuthGuard],
    component: EditCollectionPageComponent,
    pathMatch: "full",
    data: {
      isNew: true,
    },
  },
  {
    path: "collection/edit/:name",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: EditCollectionPageComponent,
    data: {
      isNew: false,
    },
  },
  {
    path: "linked-collection/:name",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: ReadonlyCollectionPageComponent,
  },
  {
    path: "artists/:name",
    pathMatch: "full",
    component: ArtistPageComponent,
  },
  {
    path: "become-artist",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: ArtistCertificationPageComponent,
  },
  {
    path: "sales",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: SalesPageComponent,
  },
  {
    path: "faq",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: FaqPageComponent,
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "notification",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: NotificationPageComponent,
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "practice-routines",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        "./components/practice-routines/practice-routines-page/practice-routines-page.component"
      ).then((c) => c.PracticeRoutinesPageComponent),
  },
  {
    path: "practice-routines/:collectionType/:collection",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        "./components/practice-routines/practice-routine-details-page/practice-routine-details-page.component"
      ).then((c) => c.PracticeRoutineDetailsPageComponent),
  },
  {
    path: "video-feedback",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        "./components/video-feedback/video-feedback-page/video-feedback-page.component"
      ).then((c) => c.VideoFeedbackPageComponent),
    data: {
      hasSecondRowToolbar: true,
    },
  },
  {
    path: "video-feedback/:name",
    pathMatch: "full",
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        "./components/video-feedback/video-feedback-details-page/video-feedback-details-page.component"
      ).then((c) => c.VideoFeedbackDetailsPageComponent),
  },
  {
    matcher: ArtistProfileUrlMatcher.matcher(),
    component: ArtistPageComponent,
  },
  {
    path: "**",
    redirectTo: "/musicpiece",
  },
];
