import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceDetailsDialogData } from "src/app/models/music-piece/music-piece-details-dialog-data";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicPieceDetailsDialogComponent } from "../music-piece-details-dialog/music-piece-details-dialog.component";
import {
  ResizeDirective,
  YrResizeEvent,
} from "src/app/directives/resize.directive";
import {
  CollectionTypeData,
  MusicPieceToCollectionItemMapping,
} from "src/app/models/collection/collection-type-data";
import { CollectionInfoPipe } from "src/app/pipes/collection-info.pipe";
import { MusicPieceToCollectionItemPipe } from "src/app/pipes/music-piece-to-collection-item.pipe";
import { CommonModule } from "@angular/common";
import { MusicPieceTypeComponent } from "../music-piece-type/music-piece-type.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-pieces-table",
  templateUrl: "./music-pieces-table.component.html",
  styleUrls: ["./music-pieces-table.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    MusicPieceTypeComponent,
    ResizeDirective,
    MusicPieceToCollectionItemPipe,
    CollectionInfoPipe,
  ],
})
export class MusicPiecesTableComponent implements OnInit {
  dataSource: MatTableDataSource<MusicPieceToDisplay> =
    new MatTableDataSource<MusicPieceToDisplay>();

  filterText?: string;

  displayedColumnsFull: string[] = [
    "name",
    "type",
    "created",
    "lastViewed",
    "actions",
  ];

  useFixedLayout = false;

  displayedColumnsMedium = ["name", "lastViewed", "actions"];

  displayedColumnsSmall = ["name", "actions"];

  displayedColumns = this.displayedColumnsFull;

  _musicPiecesToDisplay: MusicPieceToDisplay[] = [];

  @Input()
  set musicPiecesToDisplay(value: MusicPieceToDisplay[]) {
    if (value !== this._musicPiecesToDisplay) {
      this._musicPiecesToDisplay = value;
      this.updateDataSource();
    }
  }

  get musicPiecesToDisplay() {
    return this._musicPiecesToDisplay;
  }

  @ViewChild(MatSort, { static: false })
  set sort(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  @Input()
  canCreateNew: boolean = false;

  @Input()
  canShare: boolean = false;

  @Input()
  isLoading: boolean = true;

  @Input()
  hasPracticeDurationLeft = true;

  @Input()
  hasFreeStorageLeft = true;

  @Output()
  editMusicPieceChange = new EventEmitter<MusicPiece>();

  @Output()
  editLinkedMusicPieceChange = new EventEmitter<LinkedMusicPiece>();

  @Output()
  navigateToMusicPieceChange =
    new EventEmitter<LinkedMusicPieceWithMusicPiece>();

  @Output()
  createCopyFromLinkedMusicPieceChange = new EventEmitter<LinkedMusicPiece>();

  @Input()
  disableCreateCopy = false;

  @Input()
  collectionData: CollectionTypeData[] = [];

  @Input()
  musicPieceToCollectionItemMapping: MusicPieceToCollectionItemMapping[] = [];

  private readonly collectionInfoPipe: CollectionInfoPipe;
  private readonly musicPieceToCollectionItemPipe: MusicPieceToCollectionItemPipe;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly ngZone: NgZone,
  ) {
    this.collectionInfoPipe = new CollectionInfoPipe();
    this.musicPieceToCollectionItemPipe = new MusicPieceToCollectionItemPipe();
  }

  ngOnInit(): void {
    this.initTable();
  }

  private updateDataSource() {
    this.dataSource.data = this.musicPiecesToDisplay;
  }

  private initTable() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case "name": {
          return data.musicPiece.nameOfPiece.toLocaleLowerCase();
        }
        case "created": {
          var d: Date = new Date(data.musicPiece.creation);
          return d.getTime();
        }
        case "lastViewed": {
          if (data.musicPiece.lastTimeViewed !== undefined) {
            var d: Date = new Date(data.musicPiece.lastTimeViewed);
            return d.getTime();
          } else {
            return 0;
          }
        }
      }
      return "";
    };

    this.dataSource.data = this.musicPiecesToDisplay;
  }

  editMusicPiece(musicPiece: MusicPiece) {
    this.editMusicPieceChange.emit(musicPiece);
  }

  editLinkedMusicPiece(linkedMusicPiece: LinkedMusicPiece) {
    this.editLinkedMusicPieceChange.emit(linkedMusicPiece);
  }

  navigateToPlayer(data: MusicPieceToDisplay) {
    this.navigateToMusicPieceChange.emit({
      linkedMusicPiece: data.linkedMusicPiece,
      musicPiece: data.musicPiece,
    });
  }

  createCopyFromLinkedMusicPiece(data: MusicPieceToDisplay) {
    if (data.linkedMusicPiece) {
      this.createCopyFromLinkedMusicPieceChange.emit(data.linkedMusicPiece);
    }
  }

  handleResize(event: YrResizeEvent) {
    // see: https://m2.material.io/design/layout/responsive-layout-grid.html#breakpoints
    // however since we are in an container, we have to switch it up a bit.
    console.log(event.width);
    this.ngZone.run(() => {
      if (event.width < 600) {
        this.useFixedLayout = true;
        this.displayedColumns = this.displayedColumnsSmall;
      } else if (event.width < 1240) {
        this.useFixedLayout = false;
        this.displayedColumns = this.displayedColumnsMedium;
      } else {
        this.useFixedLayout = false;
        this.displayedColumns = this.displayedColumnsFull;
      }
    });
  }

  showDetails(musicPiece: MusicPieceToDisplay) {
    this.matDialog.open<
      MusicPieceDetailsDialogComponent,
      MusicPieceDetailsDialogData
    >(MusicPieceDetailsDialogComponent, {
      data: {
        isListing: false,
        musicPiece: musicPiece.musicPiece,
        isLinkedMusicPiece: musicPiece.isLinkedMusicPiece,
        collectionInfo: this.collectionInfoPipe.transform(
          this.musicPieceToCollectionItemPipe.transform(
            musicPiece,
            this.musicPieceToCollectionItemMapping,
          ),
          this.collectionData,
        ),
      },
      maxWidth: "80vw",
    });
  }
}
