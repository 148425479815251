import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExploreActions } from "../actions";
import { debounceTime, map, mergeMap } from "rxjs/operators";
import { ExploreService } from "src/app/services/explore/explore.service";
import { ArtistProfileService } from "src/app/services/artist/artist-profile-service";

@Injectable({
  providedIn: "root",
})
export class ExploreEffects {
  load$ = createEffect(() =>
    this.actions.pipe(
      ofType(ExploreActions.load),
      mergeMap((_) =>
        this.exploreService
          .load()
          .pipe(map((r) => ExploreActions.loaded({ items: r }))),
      ),
    ),
  );

  searchPartners$ = createEffect(() =>
    this.actions.pipe(
      ofType(ExploreActions.search),
      debounceTime(200),
      mergeMap((a) =>
        this.exploreService
          .searchPartners(a.query)
          .pipe(
            map((r) => ExploreActions.searchedPartners({ searchResults: r })),
          ),
      ),
    ),
  );

  searchListedMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(ExploreActions.search),
      debounceTime(200),
      mergeMap((a) =>
        this.exploreService
          .searchListedMusicPieces(a.query)
          .pipe(
            map((r) =>
              ExploreActions.searchedListedMusicPieces({ searchResults: r }),
            ),
          ),
      ),
    ),
  );

  loadPreviews$ = createEffect(() =>
    this.actions.pipe(
      ofType(ExploreActions.searchedListedMusicPieces),
      mergeMap((a) =>
        this.exploreService.findPreviews(a.searchResults).pipe(
          map((previews) =>
            ExploreActions.loadedSearchedListedMusicPiecePreviews({
              previews: previews,
            }),
          ),
        ),
      ),
    ),
  );

  searchListedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(ExploreActions.search),
      debounceTime(200),
      mergeMap((a) =>
        this.exploreService
          .searchListedCollections(a.query)
          .pipe(
            map((r) =>
              ExploreActions.searchedListedCollections({ searchResults: r }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly exploreService: ExploreService,
  ) {}
}
