import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, of, zip } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { PlayerActions, RecordingPartGroupActions } from "../actions";
import { musicPieceLoaded, musicScoreLoaded } from "../actions/player.actions";
import { AppState } from "../reducers";
import { LinkedMusicPieceService } from "../../services/linked-music-piece/linked-music-piece.service";
import { MusicService } from "../../services/music-piece/music-piece.service";
import { MusicScoreService } from "../../services/music-score/music-score.service";
import { PageBreakService } from "../../services/player/page-break.service";
import { RecordingService } from "../../services/recording/recording.service";
import { RotationService } from "../../services/rotation/rotation.service";
import { RecordingPartGroupService } from "src/app/services/player/recording-part-group.service";
import { MarkerService } from "src/app/services/player/marker.service";
import { RecordingPartService } from "src/app/services/player/recording-part.service";
import {
  selectSelectedRecordingPartGroupWithDisplayPage,
  selectDisplayedPageWithOverlayAndRecordingPartGroup,
} from "../reducers/player.reducer";
import { MusicScoreOverlayService } from "src/app/services/music-score/music-score-overlay.service";
import { EmptyDisplayedPage } from "src/app/components/music-score/music-score/music-score.component";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { PlaylistService } from "src/app/services/player/playlist.service";

@Injectable()
export class PlayerEffects {
  loadMusicPieceEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.openPlayerAction),
      withLatestFrom(this.store.select((state) => state.player)),
      filter(([_, state]) => !state.hasLoadedMusicPiece), // we only load when necessary
      mergeMap(([action, _]) => {
        if (action.isLinkedMusicPiece) {
          // load linked music piece and the normal music piece
          return this.linkedMusicPieceService
            .getLinkedMusicPiece(action.name)
            .pipe(
              mergeMap((lmp) => {
                return zip(
                  this.musicPieceService.getMusicPiece(lmp.musicPiece),
                  of(lmp),
                );
              }),
              map(([mp, lmp]) =>
                PlayerActions.musicPieceLoaded({
                  musicPiece: mp,
                  linkedMusicPiece: lmp,
                }),
              ),
            );
        } else {
          // only load the normal music piece
          return this.musicPieceService.getMusicPiece(action.name).pipe(
            map((mp) =>
              PlayerActions.musicPieceLoaded({
                musicPiece: mp,
              }),
            ),
          );
        }
      }),
    ),
  );

  markMusicPieceAsViewedEffect$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(PlayerActions.openPlayerAction),
        mergeMap((action) => {
          if (action.isLinkedMusicPiece) {
            return this.musicPieceService.markLinkedMusicPieceAsViewed(
              action.name,
            );
          } else {
            return this.musicPieceService.markMusicPieceAsViewed(action.name);
          }
        }),
        catchError((error) => {
          console.error(`couldn't mark the music piece as viewed`);
          console.error(error);
          return EMPTY;
        }),
      ),
    {
      dispatch: false,
    },
  );

  loadRecordingWhenMusicPieceIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(musicPieceLoaded),
      mergeMap((action) =>
        this.recordingService.createRecordingDownloadLink(
          action.musicPiece,
          action.linkedMusicPiece,
        ),
      ),
      map((r) => PlayerActions.recordingLoaded({ recording: r })),
      catchError((error) => {
        console.log(`couldn't load recording (with Download link): ${error}`);
        return of(PlayerActions.recordingLoadingFailed());
      }),
    ),
  );

  loadPageBreaksWhenRecordingIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.recordingLoaded),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) =>
        this.pageBreakService.loadPageBreaksForRecording(
          action.recording,
          state.linkedMusicPiece,
        ),
      ),
      map((r) =>
        PlayerActions.pageBreaksLoaded({
          pageBreaks: r,
        }),
      ),
    ),
  );

  loadMusicScoreWhenMusicPieceIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(musicPieceLoaded),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) =>
        this.musicScoreService.createMusicScoreDownloadLink(
          action.musicPiece,
          state.linkedMusicPiece, // we can either use state or action. The Reducers are called before the effects are triggered.
        ),
      ),
      map((ms) =>
        musicScoreLoaded({
          musicScore: ms,
        }),
      ),
    ),
  );

  addPageBreak$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.addPageBreak),
      mergeMap((action) =>
        this.pageBreakService.addPageBreak(action.pageBreak),
      ),
      map((pb) =>
        PlayerActions.pageBreakAdded({
          pageBreak: pb,
        }),
      ),
    ),
  );

  deletePageBreak$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.deletePageBreak),
      mergeMap((action) =>
        this.pageBreakService
          .deletePageBreak(action.pageBreak)
          .pipe(
            map((_) =>
              PlayerActions.pageBreakDeleted({ pageBreak: action.pageBreak }),
            ),
          ),
      ),
    ),
  );

  loadOverlayWhenRecordingPartGroupIsSelected$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.selectRecordingPartGroup),
      withLatestFrom(
        this.store.select(selectSelectedRecordingPartGroupWithDisplayPage),
      ),
      filter(
        ([action, pair]) =>
          action.recordingPartGroup != undefined &&
          pair.displayedPage != undefined &&
          pair.recordingPartGroup != undefined,
      ),
      mergeMap(([_, pair]) =>
        this.musicScoreOverlayService.loadOverlayFor(
          pair.displayedPage!.pageNumber,
          pair.recordingPartGroup!.name,
          pair.linkedMusicPiece,
        ),
      ),
      map((musicScoreOverlay) =>
        PlayerActions.overlayLoaded({
          overlay: musicScoreOverlay,
        }),
      ),
    ),
  );

  loadOverlayWhenDisplayedPageChanges$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.displayedPageChanged),
      withLatestFrom(
        this.store.select(selectSelectedRecordingPartGroupWithDisplayPage),
      ),
      filter(
        ([action, pair]) =>
          pair.displayedPage != undefined &&
          pair.recordingPartGroup != undefined &&
          action.displayedPage != undefined &&
          action.displayedPage.pageNumber != action.previousPageNumber,
      ),
      mergeMap(([_, pair]) =>
        this.musicScoreOverlayService.loadOverlayFor(
          pair.displayedPage!.pageNumber,
          pair.recordingPartGroup!.name,
          pair.linkedMusicPiece,
        ),
      ),
      map((musicScoreOverlay) =>
        PlayerActions.overlayLoaded({
          overlay: musicScoreOverlay,
        }),
      ),
    ),
  );

  saveImage$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.saveImage),
      withLatestFrom(
        this.store.select(selectDisplayedPageWithOverlayAndRecordingPartGroup),
      ),
      filter(
        ([action, state]) =>
          state.displayedPage != EmptyDisplayedPage &&
          state.selectedGroup != undefined,
      ),
      mergeMap(([action, state]) =>
        this.musicScoreOverlayService.save({
          overlay: action.imageData,
          page: state.displayedPage!.pageNumber,
          recordingPartGroup: state.selectedGroup!.name,
          name: state.overlay?.name,
        }),
      ),
      tap(
        (_) => this.toastr.success(this.translate.instant("common.success")),
        (_) => this.toastr.error(this.translate.instant("common.fail")),
      ),
      map((overlay) => PlayerActions.savedImage({ image: overlay })),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly musicPieceService: MusicService,
    private readonly linkedMusicPieceService: LinkedMusicPieceService,
    private readonly recordingService: RecordingService,
    private readonly pageBreakService: PageBreakService,
    private readonly rotationService: RotationService,
    private readonly musicScoreService: MusicScoreService,
    private readonly recordingGroupPartService: RecordingPartGroupService,
    private readonly markerService: MarkerService,
    private readonly recordingPartService: RecordingPartService,
    private readonly musicScoreOverlayService: MusicScoreOverlayService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}
}
