<div *ngIf="musicPiece && musicPiece.acceptShareTerms">
  <!-- we allow to unlist and change the price even when the listing license is no longer available -->
  <div
    class="listing"
    *ngIf="!isNew && !isLinkedMusicPiece && !musicPiece.isCopy"
  >
    <ng-container
      *ngIf="
        canEditExisting() || (hasListingLicense && isCertified);
        then listingForm;
        else noProfileHint
      "
    ></ng-container>

    <ng-template #listingForm>
      <form [formGroup]="form">
        <div class="flex flex-col lg:flex-row">
          <div class="mb-2 mr-2 md:flex-none lg:mb-0">
            <mat-checkbox
              matInput
              [formControl]="isListingMusicPiece"
              (change)="handleListingChange()"
            >
              {{ "edit-music-piece.edit-listing.enable-listing" | translate }}
            </mat-checkbox>

            <p class="price-line">
              <span>
                <mat-checkbox
                  matInput
                  [formControl]="isFree"
                  (change)="calculateTotalPrice()"
                >
                  {{
                    "edit-music-piece.edit-listing.is-free" | translate
                  }}</mat-checkbox
                >
              </span>

              <span *ngIf="!isFree.value">
                <mat-form-field>
                  <mat-label>{{
                    "edit-music-piece.edit-listing.price" | translate
                  }}</mat-label>
                  <input
                    matInput
                    [formControl]="price"
                    type="number"
                    required
                    [min]="minSellingPrice"
                    (change)="calculateTotalPrice()"
                  />
                  <span matPrefix>€</span>
                </mat-form-field>
              </span>
            </p>
          </div>

          <div class="flex-grow">
            <yr-selling-price-calculator
              [totalPrice]="totalPrice"
            ></yr-selling-price-calculator>
          </div>
        </div>

        <p class="listing-hint">
          <span *ngIf="listing">
            {{ "edit-music-piece.edit-listing.listing-date" | translate }}
            {{ listing.creation | date: ("common.dateTimeFormat" | translate) }}
          </span>
          {{ "edit-music-piece.edit-listing.listing-hint" | translate }}
        </p>
      </form>
    </ng-template>

    <ng-template #noProfileHint>
      <yr-create-profile-hint></yr-create-profile-hint>
    </ng-template>
  </div>
</div>
