import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { environment } from "src/environments/environment";
import { StripePortalComponent } from "../../user/stripe-portal/stripe-portal.component";
import { NoPurchasesItemComponent } from "../no-purchases-item/no-purchases-item.component";
import { PurchasedItemComponent } from "../purchased-item/purchased-item.component";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-purchases",
  templateUrl: "./purchases.component.html",
  styleUrls: ["./purchases.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    StripePortalComponent,
    NoPurchasesItemComponent,
    PurchasedItemComponent,
  ],
})
export class PurchasesComponent implements OnInit {
  purchasedItems: PurchasedItem[] = [];

  isLoadingPurchases = true;

  stripePortalReturnUrl = `${environment.guiBaseUrl}/purchases`;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      PurchaseActions.loadPurchasedMusicPieces({
        includeUnfinished: false,
      }),
    );

    this.store.dispatch(
      PurchaseActions.loadPurchasedCollections({
        includeUnfinished: false,
      }),
    );

    this.store.dispatch(
      PurchaseActions.loadPurchasedVideoFeedbacks({
        includeUnfinished: false,
      }),
    );

    this.store
      .select(PurchaseSelectors.selectPurchasedItems)
      .subscribe((data) => {
        this.purchasedItems = data.items;
        this.isLoadingPurchases = data.isLoading;
      });
  }
}
