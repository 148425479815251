<div mat-dialog-title>
  <h1>
    {{ "music-piece.upload-dialog.title" | translate }}
  </h1>
</div>
<div mat-dialog-content class="w-60 p-4 md:w-[480px]">
  <div class="mb-4 whitespace-pre-line text-sm">
    {{ "music-piece.upload-dialog.hint" | translate }}
  </div>

  <mat-progress-bar
    class="w-full"
    *ngIf="isUploading"
    [value]="progressPercentage"
  ></mat-progress-bar>
</div>
