<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="flex flex-col gap-y-4">
  @for (tag of sortedTags(); track tag) {
    <h1>{{ tag }}</h1>
    @for (item of itemsGroupedByTags().get(tag); track $index) {
      <yr-faq-item [item]="item"></yr-faq-item>
    }
  }
</div>

<div class="my-16">
  <yr-feedback></yr-feedback>
</div>
