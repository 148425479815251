import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CollectionService } from "src/app/services/collection/collection.service";
import {
  ArtistProfileActions,
  CollectionActions,
  EditCollectionActions,
} from "../actions";
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  withLatestFrom,
} from "rxjs/operators";
import { CollectionSelectors } from "../selectors";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { StoredCollection } from "src/app/models/collection/stored-collection";

@Injectable({
  providedIn: "root",
})
export class CollectionEffects {
  triggerLoadCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        EditCollectionActions.deleteCollectionFailed,
        ArtistProfileActions.loadArtistProfile,
        ArtistProfileActions.loadArtistProfileByCustomUrl,
      ),
      map((_) => CollectionActions.loadCollections()),
    ),
  );

  loadCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.loadCollections),
      mergeMap((_) =>
        this.collectionService
          .loadCollections()
          .pipe(
            map((collections) =>
              CollectionActions.loadedCollections({ collections }),
            ),
          ),
      ),
    ),
  );

  loadLinkedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.loadCollections),
      mergeMap((_) =>
        this.collectionService.loadLinkedCollections().pipe(
          map((linkedCollections) =>
            CollectionActions.loadedLinkedCollections({
              linkedCollections: linkedCollections,
            }),
          ),
        ),
      ),
    ),
  );

  loadCollectionsForLinkedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.loadedLinkedCollections),
      mergeMap((action) =>
        this.collectionService.loadCollectionsForLinkedCollections().pipe(
          map((collections) =>
            CollectionActions.loadedCollectionsForLinkedCollections({
              collectionsForLinkedCollections: collections,
              linkedCollections: action.linkedCollections,
            }),
          ),
        ),
      ),
    ),
  );

  loadCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.loadCollectionItems),
      mergeMap((_) =>
        this.collectionService.loadCollectionItems().pipe(
          map((r) =>
            CollectionActions.loadedCollectionItems({
              collectionItems: r,
            }),
          ),
        ),
      ),
    ),
  );

  loadLinkedCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.loadLinkedCollectionItems),
      mergeMap((_) =>
        this.collectionService.loadLinkedCollectionItems().pipe(
          map((r) =>
            CollectionActions.loadedLinkedCollectionItems({
              linkedCollectionItems: r,
            }),
          ),
        ),
      ),
    ),
  );

  resetFilter$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        CollectionActions.loadedCollections,
        CollectionActions.loadedCollectionsForLinkedCollections,
      ),
      map((_) => CollectionActions.filter({ filter: "" })),
    ),
  );

  applyFilter$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollectionActions.filter),
      debounceTime(50),
      withLatestFrom(this.store.select(CollectionSelectors.selectCollections)),
      map(([action, collections]) => {
        // console.time("collections.applyFilter$");

        const filterPredicate = (collection: StoredCollection) => {
          const textRepresentation =
            "" +
            collection.name.toLowerCase() +
            (collection.title || "").toLowerCase() +
            (collection.instrument || "").toLowerCase() +
            (collection.temperament || "").toLowerCase() +
            (collection.artist || "").toLowerCase() +
            (collection.description || "").toLowerCase();

          return textRepresentation.includes(action.filter.toLowerCase());
        };

        const filterAndSort = (collections: StoredCollection[]) =>
          collections
            .filter(filterPredicate)
            .sort((a, b) => b.created.valueOf() - a.created.valueOf());

        const filteredCollections = filterAndSort(collections.collections);
        const filteredCollectionsForLinkedCollections = filterAndSort(
          collections.collectionForLinkedCollections,
        );

        const filteredLinkedCollections = collections.linkedCollections.filter(
          (c) =>
            filteredCollectionsForLinkedCollections.some(
              (sc) => sc.name == c.collection,
            ),
        );

        // console.timeEnd("collections.applyFilter$");

        return CollectionActions.filtered({
          filteredCollections: filteredCollections,
          filteredCollectionsForLinkedCollections:
            filteredCollectionsForLinkedCollections,
          filteredLinkedCollections: filteredLinkedCollections,
        });
      }),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly collectionService: CollectionService,
    private readonly store: Store<AppState>,
  ) {}
}
