import { CheckoutSessionDto } from "src/app/dtos/license/checkout-session.dto";
import { Conversion } from "../conversion";
import { CheckoutSession } from "src/app/models/license/checkout-session";

export class CheckoutSessionConversion
  implements Conversion<CheckoutSessionDto, CheckoutSession>
{
  convert(dto: CheckoutSessionDto): CheckoutSession {
    return {
      billingInterval: dto.billing_interval,
      status: dto.status,
      stripeCheckoutSessionId: dto.stripe_checkout_session_id,
      stripePriceId: dto.stripe_price_id,
    };
  }
}
