import { ValidatorFn } from "@angular/forms";

export class LinkValidator {
  constructor() {}

  private noError() {
    return null;
  }

  private error() {
    return {
      link: true,
    };
  }

  linkValidator(): ValidatorFn {
    return (control) => {
      if (!control.value || control.value == "") return this.noError();

      try {
        const url = new URL(control.value);

        // at this point we have a valid url, however we want to ensure that the url schema is either http or https
        if (url.protocol.includes("http")) {
          return this.noError();
        }
      } catch (err) {}

      return this.error();
    };
  }
}
