<br />
<yr-edit-collection-form></yr-edit-collection-form>

<br />

<yr-edit-collection-items
  *ngIf="!canShareCollections"
></yr-edit-collection-items>

<mat-tab-group
  dynamicHeight
  *ngIf="!isNew && canShareCollections"
  preserveContent
>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>library_music</mat-icon>
      &nbsp;
      {{ "edit-collection.edit-collection.music-tab" | translate }}
    </ng-template>
    <yr-edit-collection-items></yr-edit-collection-items>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>share</mat-icon>
      &nbsp;
      {{ "edit-collection.edit-collection.share-tab" | translate }}
    </ng-template>

    <yr-share-collection *ngIf="acceptShareTerms"></yr-share-collection>
    <yr-prepare-share-collection
      *ngIf="!acceptShareTerms"
    ></yr-prepare-share-collection>
  </mat-tab>
  <mat-tab *ngIf="canListCollections && canShareCollections && !isNew">
    <ng-template mat-tab-label>
      <mat-icon>shopping_cart</mat-icon>
      {{ "edit-collection.edit-collection.edit-listing-tab" | translate }}
    </ng-template>
    <yr-edit-collection-listing
      *ngIf="acceptShareTerms"
    ></yr-edit-collection-listing>
    <yr-prepare-share-collection
      *ngIf="!acceptShareTerms"
    ></yr-prepare-share-collection>
  </mat-tab>
</mat-tab-group>
