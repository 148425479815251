import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PurchaseSelectors } from "src/app/ngrx/selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "yr-purchase",
  templateUrl: "./purchase.component.html",
  styleUrls: ["./purchase.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PurchaseComponent implements OnInit {
  currentPurchase?: PurchasedItem;
  private readonly stripe: any;
  private elements?: any;
  isLoading = false;

  @Output()
  onSuccess = new EventEmitter<void>();

  @Output()
  onFailure = new EventEmitter<void>();

  constructor(private readonly store: Store<AppState>) {
    this.stripe = Stripe(environment.stripeApiKey) as any;
  }

  ngOnInit(): void {
    this.store
      .select(PurchaseSelectors.selectCurrentPurchase)
      .subscribe((data) => {
        const didChange = this.currentPurchase == undefined;
        this.currentPurchase = data.currentPurchase;
        if (didChange) {
          this.handlePurchase();
        }
      });
  }

  handlePurchase() {
    if (this.currentPurchase) {
      const appearance = {
        theme: "flat",
      };

      this.elements = this.stripe.elements({
        appearance: appearance,
        clientSecret: this.currentPurchase.item.clientSecret,
      });

      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
    }
  }

  async handleSubmit(e: Event) {
    e.preventDefault();
    this.isLoading = true;

    const { error } = await this.stripe.confirmPayment({
      redirect: "if_required",
      confirmParams: {
        return_url: `${environment.guiBaseUrl}/purchase-status/${
          this.currentPurchase?.type
        }/${this.currentPurchase?.item.clientSecret || "none"}`,
      },
      elements: this.elements,
    });

    if (error) {
      if (error.type && error.type === "validation_error") {
        console.error("card error");
      } else if (error.type && error.type == "card_error") {
        this.handleError(error.message);
      } else {
        this.handleError("An unexpected error occurred.");
      }
    } else {
      this.handleSuccess();
    }
    this.isLoading = false;
  }

  private handleSuccess() {
    this.onSuccess.emit();

    if (this.currentPurchase) {
      this.store.dispatch(
        PurchaseActions.purchaseSucceeded({
          purchase: this.currentPurchase,
        }),
      );
    }
  }

  private handleError(error: string) {
    this.onFailure.emit();

    if (this.currentPurchase) {
      console.error(error);
      this.store.dispatch(
        PurchaseActions.purchaseFailed({
          purchase: this.currentPurchase,
          error: error,
        }),
      );
    }
  }
}
