import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CollectionTypeData,
  MusicPieceToCollectionItemMapping,
} from "src/app/models/collection/collection-type-data";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { Preview } from "src/app/models/music-piece/preview";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicPieceCardComponent } from "../music-piece-card/music-piece-card.component";
import { PreviewPipe } from "src/app/pipes/preview.pipe";
import { MusicPieceToCollectionItemPipe } from "src/app/pipes/music-piece-to-collection-item.pipe";
import { CollectionInfoPipe } from "src/app/pipes/collection-info.pipe";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-cards",
  templateUrl: "./music-piece-cards.component.html",
  styleUrls: ["./music-piece-cards.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MusicPieceCardComponent,
    PreviewPipe,
    MusicPieceToCollectionItemPipe,
    CollectionInfoPipe,
  ],
})
export class MusicPieceCardsComponent implements OnInit {
  filterText?: string;

  @Input()
  canCreateNew: boolean = false;

  @Input()
  disableCreateCopy = false;

  @Input()
  musicPiecesToDisplay: MusicPieceToDisplay[] = [];

  @Input()
  canShare: boolean = false;

  @Input()
  isLoading: boolean = true;

  @Input()
  hasPracticeDurationLeft = true;

  @Input()
  hasFreeStorageLeft = true;

  @Output()
  addMusicPieceChange = new EventEmitter();

  @Output()
  editMusicPieceChange = new EventEmitter<MusicPiece>();

  @Output()
  editLinkedMusicPieceChange = new EventEmitter<LinkedMusicPiece>();

  @Output()
  navigateToMusicPieceChange =
    new EventEmitter<LinkedMusicPieceWithMusicPiece>();

  @Output()
  createCopyFromLinkedMusicPieceChange = new EventEmitter<LinkedMusicPiece>();

  @Input()
  collectionData: CollectionTypeData[] = [];

  @Input()
  musicPieceToCollectionItemMapping: MusicPieceToCollectionItemMapping[] = [];

  _previews: Preview[] = [];
  _linkedMusicPiecesPreviews: Preview[] = [];

  @Input()
  set previews(p: Preview[]) {
    this._previews = p;
    this.allPreviews = [...this._previews, ...this._linkedMusicPiecesPreviews];
  }

  @Input()
  set linkedMusicPiecePreviews(p: Preview[]) {
    this._linkedMusicPiecesPreviews = p;
    this.allPreviews = [...this._previews, ...this._linkedMusicPiecesPreviews];
  }

  allPreviews: Preview[] = [];

  constructor() {}

  ngOnInit(): void {}

  addMusicPiece() {
    this.addMusicPieceChange.emit();
  }

  editMusicPiece(musicPiece: MusicPiece) {
    this.editMusicPieceChange.emit(musicPiece);
  }

  editLinkedMusicPiece(linkedMusicPiece?: LinkedMusicPiece) {
    this.editLinkedMusicPieceChange.emit(linkedMusicPiece);
  }

  edit(mp: MusicPieceToDisplay) {
    if (mp.isLinkedMusicPiece) {
      this.editLinkedMusicPiece(mp.linkedMusicPiece);
    } else {
      this.editMusicPiece(mp.musicPiece);
    }
  }

  navigateToPlayer(
    musicPiece: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ) {
    this.navigateToMusicPieceChange.emit({
      musicPiece: musicPiece,
      linkedMusicPiece: linkedMusicPiece,
    });
  }

  createCopyFromLinkedMusicPiece(linkedMusicPiece?: LinkedMusicPiece) {
    if (linkedMusicPiece) {
      this.createCopyFromLinkedMusicPieceChange.emit(linkedMusicPiece);
    }
  }
}
