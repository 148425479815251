import { DIALOG_DATA } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CollectionInfoDialogData } from "src/app/models/collection/collection-info-dialog-data";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-collection-info-dialog",
  templateUrl: "./collection-info-dialog.component.html",
  styleUrls: ["./collection-info-dialog.component.scss"],
  standalone: true,
  imports: [TranslateModule, MaterialModule],
})
export class CollectionInfoDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) readonly data: CollectionInfoDialogData,
    private readonly ref: MatDialogRef<CollectionInfoDialogComponent>,
  ) {}

  close() {
    this.ref.close();
  }
}
