import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { CollectionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { CollectionSelectors } from "src/app/ngrx/selectors";
import { CollectionItemComponent } from "../collection-item/collection-item.component";
import { NoItemsIndicatorComponent } from "../../common/no-items-indicator/no-items-indicator.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

interface DisplayCollection {
  collection: StoredCollection;
  linkedCollection?: StoredLinkedCollection;
}

@Component({
  selector: "yr-collections",
  templateUrl: "./collections.component.html",
  styleUrls: ["./collections.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    CollectionItemComponent,
    NoItemsIndicatorComponent,
  ],
})
export class CollectionsComponent implements OnInit {
  isLoadingCollections = false;

  displayedCollections: DisplayCollection[] = [];

  @Output()
  editCollection = new EventEmitter<StoredCollection>();

  @Output()
  openLinkedCollection = new EventEmitter<{
    collection: StoredCollection;
    linkedCollection: StoredLinkedCollection;
  }>();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(CollectionActions.loadCollections());

    this.store
      .select(CollectionSelectors.selectFilteredCollections)
      .subscribe((data) => {
        const filteredCollections = data.filteredCollections.map((c) => {
          return {
            collection: c,
          } as DisplayCollection;
        });

        const filteredLinkedCollections =
          data.filteredCollectionsForLinkedCollections.map((c) => {
            return {
              collection: c,
              linkedCollection: data.filteredLinkedCollections.find(
                (lc) => lc.collection == c.name,
              ),
            } as DisplayCollection;
          });

        this.displayedCollections = [
          ...filteredCollections,
          ...filteredLinkedCollections,
        ].sort(
          (a, b) =>
            b.collection.created.valueOf() - a.collection.created.valueOf(),
        );

        this.isLoadingCollections = data.isLoadingCollections;
      });
  }

  handleEditCollection(collection: StoredCollection) {
    this.editCollection.emit(collection);
  }

  handleOpenCollection(
    collection: StoredCollection,
    linkedCollection: StoredLinkedCollection,
  ) {
    this.openLinkedCollection.emit({
      collection: collection,
      linkedCollection: linkedCollection,
    });
  }
}
