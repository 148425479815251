import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { PurchaseService } from "src/app/services/purchase/purchase.service";
import {
  ArtistProfileActions,
  EditCollectionActions,
  EditMusicPieceActions,
  MusicPieceActions,
  PurchaseActions,
} from "../actions";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";
import { ItemToPurchase } from "src/app/models/purchase/item-to-purchase";

@Injectable({
  providedIn: "root",
})
export class PurchaseEffects {
  triggerLoadAllPurchasedMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ArtistProfileActions.loadArtistProfile,
        ArtistProfileActions.loadArtistProfileByCustomUrl,
        PurchaseActions.purchaseSucceeded,
      ),
      map(() =>
        PurchaseActions.loadPurchasedMusicPieces({
          includeUnfinished: true,
        }),
      ),
    ),
  );

  triggerLoadAllPurchasedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ArtistProfileActions.loadArtistProfile,
        ArtistProfileActions.loadArtistProfileByCustomUrl,
        PurchaseActions.purchaseSucceeded,
      ),
      map(() =>
        PurchaseActions.loadPurchasedCollections({
          includeUnfinished: true,
        }),
      ),
    ),
  );

  triggerLoadPurchasedVIdeoFeedbacks$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ArtistProfileActions.loadArtistProfile,
        ArtistProfileActions.loadArtistProfileByCustomUrl,
        PurchaseActions.purchaseSucceeded,
      ),
      map(() =>
        PurchaseActions.loadPurchasedVideoFeedbacks({
          includeUnfinished: true,
        }),
      ),
    ),
  );

  triggerLoadOnlyCompletedPurchasedMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(MusicPieceActions.loadMusicPieces),
      map(() =>
        PurchaseActions.loadPurchasedMusicPieces({
          includeUnfinished: false,
        }),
      ),
    ),
  );

  loadPurchasedMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.loadPurchasedMusicPieces),
      mergeMap((action) =>
        this.purchaseService.loadPurchasedMusicPieces(action.includeUnfinished),
      ),
      map((purchasedMusicPieces) =>
        PurchaseActions.loadedPurchasedMusicPieces({
          purchasedMusicPieces: purchasedMusicPieces,
        }),
      ),
    ),
  );

  loadPurchasedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.loadPurchasedCollections),
      mergeMap((action) =>
        this.purchaseService.loadPurchasedCollections(action.includeUnfinished),
      ),
      map((collectionPurchases) =>
        PurchaseActions.loadedPurchasedCollections({
          purchasedCollections: collectionPurchases,
        }),
      ),
    ),
  );

  loadPurchaseVideoFeedbacks$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.loadPurchasedVideoFeedbacks),
      mergeMap((action) =>
        this.purchaseService.loadPurchasedVideoFeedbacks(
          action.includeUnfinished,
        ),
      ),
      map((videoFeedbackPurchases) =>
        PurchaseActions.loadedPurchasedVideoFeedbacks({
          videoFeedbackPurchases,
        }),
      ),
    ),
  );

  loadPurchaseSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        PurchaseActions.loadPurchaseSettings,
        EditMusicPieceActions.openMusicPieceForEdit,
        EditCollectionActions.openCollectionForEdit,
        ArtistProfileActions.loadArtistProfileForCurrentUser,
      ),
      mergeMap((_) => this.purchaseService.loadPurchaseSettings()),
      map((settings) =>
        PurchaseActions.loadedPurchaseSettings({ settings: settings }),
      ),
    ),
  );

  private isFreeItem(item: ItemToPurchase): boolean {
    if (item.type == "collection" || item.type == "music-piece") {
      const itemWithPrice = item.item as
        | MusicPieceListing
        | StoredCollectionListing;
      return itemWithPrice.price == 0;
    }
    return false;
  }

  preparePurchase$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.purchase),
      filter((action) => !this.isFreeItem(action.item)),
      mergeMap((action) => this.purchaseService.purchase(action.item)),
      map((result) => PurchaseActions.purchasePrepared({ item: result })),
    ),
  );

  takeFreeItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.purchase),
      filter((action) => this.isFreeItem(action.item)),
      mergeMap((action) => this.purchaseService.purchase(action.item)),
      map((purchase) =>
        PurchaseActions.purchaseSucceeded({ purchase: purchase }),
      ),
    ),
  );

  navigateToPurchaseStatusPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          PurchaseActions.purchaseFailed,
          PurchaseActions.purchaseSucceeded,
        ),
        tap((action) =>
          this.router.navigate([
            "/purchase-status",
            action.purchase.type,
            action.purchase.item.clientSecret,
          ]),
        ),
      ),
    { dispatch: false },
  );

  loadPurchaseDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseActions.loadPurchaseDetails),
      mergeMap((action) =>
        this.purchaseService.loadPurchaseByClientSecret(
          action.purchaseType,
          action.clientSecret,
        ),
      ),
      map((result) =>
        PurchaseActions.loadedPurchaseDetails({ purchase: result }),
      ),
    ),
  );

  downloadReceipt$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(PurchaseActions.downloadReceipt),
        mergeMap((action) =>
          this.purchaseService.downloadReceipt(action.purchase).pipe(
            map((blob) => {
              return {
                name: action.purchase.item.name,
                blob: blob,
              };
            }),
          ),
        ),
        tap((data) => {
          var link = document.createElement("a");
          link.href = URL.createObjectURL(data.blob);
          link.download = `receipt-${data.name}.pdf`;
          link.click();
          link.remove();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly purchaseService: PurchaseService,
    private readonly router: Router,
  ) {}
}
