import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import {
  AppActions,
  LicenseActions,
  NotificationSettingsActions,
  UserActions,
} from "../actions";
import { filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class AppEffects {
  loadUpgradeAndFallbackLicenseOnUpgrade$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.upgraded),
      map((_) => LicenseActions.loadFallbackAndUpgrade()),
    ),
  );

  logoutOnUpgrade$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.upgraded),
      filter((_) => environment.logoutOnUpgrade),
      map((_) => UserActions.logout()),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
  ) {}
}
