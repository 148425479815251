import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatomoTracker } from "ngx-matomo-client";
import { from, Observable, of } from "rxjs";
import { filter, map, mapTo, mergeMap, tap } from "rxjs/operators";
import { StoredLinkedMusicPieceDtoToLinkedMusicPieceConversion } from "src/app/conversions/linked-music-piece/stored-linked-music-piece-dto-to-linked-music-piece-conversion";
import { MusicPieceDtoToMusicPieceConversion } from "src/app/conversions/music-piece/music-piece-dto-to-music-piece-conversion";
import { PreviewDtoToPreviewConversion } from "src/app/conversions/music-piece/preview-dto-to-preview.conversion";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { Preview } from "src/app/models/music-piece/preview";
import { dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import {
  FrappeRequestFilters,
  FrappeRequestHelper,
} from "../frappe-request-helper";

export interface LinkedMusicPieceWithMusicPiece {
  linkedMusicPiece?: LinkedMusicPiece;
  musicPiece: MusicPiece;
}

@Injectable({
  providedIn: "root",
})
export class LinkedMusicPieceService {
  constructor(
    private http: HttpClient,
    private readonly conversion: StoredLinkedMusicPieceDtoToLinkedMusicPieceConversion,
    private tracker: MatomoTracker,
  ) {}

  private loadMusicPiecesForLinkedMusicPieces(): Observable<MusicPiece[]> {
    const queryHelper = new FrappeRequestHelper();
    const musicPieceConversion = new MusicPieceDtoToMusicPieceConversion();

    const method =
      "/api/method/yobi_rocks.controllers.music_piece_controller.music_pieces_for_linked_music_pieces";
    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      musicPieceConversion,
    );

    return crudHelper.queryAll(queryHelper, method);
  }

  private loadLinkedMusicPieces(): Observable<LinkedMusicPiece[]> {
    const queryHelper = new FrappeRequestHelper().withFields([
      "name",
      "music_piece",
      "user",
      "last_time_viewed",
    ]);

    const crudHelper = new FrappeCrudHelper(
      "Linked Music Piece",
      this.http,
      this.conversion,
    );

    return crudHelper.queryAll(queryHelper);
  }

  getLinkedMusicPiece(name: string): Observable<LinkedMusicPiece> {
    const crudHelper = new FrappeCrudHelper(
      "Linked Music Piece",
      this.http,
      this.conversion,
    );
    return crudHelper.getSingle(name);
  }

  getLinkedMusicPieceWithMusicPiece(
    name: string,
  ): Observable<LinkedMusicPieceWithMusicPiece> {
    const crudHelper = new FrappeCrudHelper(
      "Linked Music Piece",
      this.http,
      this.conversion,
    );

    return crudHelper.getSingle(name).pipe(
      mergeMap((linkedMusicPiece) => {
        const convertMusicPiece = new MusicPieceDtoToMusicPieceConversion();
        const musicPieceCrudHelper = new FrappeCrudHelper(
          "_",
          this.http,
          convertMusicPiece,
        );
        const method =
          "/api/method/yobi_rocks.controllers.music_piece_controller.music_piece_for_linked_music_piece";

        const helper = new FrappeRequestHelper().withParam(
          "name",
          linkedMusicPiece.name,
        );
        return musicPieceCrudHelper.queryAll(helper, method).pipe(
          map((result) => {
            const musicPiece = result.length > 0 ? result[0] : undefined;
            return {
              musicPiece: musicPiece,
              linkedMusicPiece: linkedMusicPiece,
            } as LinkedMusicPieceWithMusicPiece;
          }),
        );
      }),
    );
  }

  getLinkedMusicPieces(): Observable<LinkedMusicPieceWithMusicPiece[]> {
    return this.loadLinkedMusicPieces().pipe(
      mergeMap((linked) =>
        this.loadMusicPiecesForLinkedMusicPieces().pipe(
          map((musicPieces) => {
            return linked
              .map((lmp) => {
                const musicPiece = musicPieces.find(
                  (mp) => mp.name == lmp.musicPiece,
                );
                return {
                  linkedMusicPiece: lmp,
                  musicPiece: musicPiece,
                } as LinkedMusicPieceWithMusicPiece;
              })
              .filter((lmp) => lmp.musicPiece != undefined);
          }),
        ),
      ),
    );
  }

  createCopyFromLinkedMusicPiece(
    linkedMusicPiece: LinkedMusicPiece,
  ): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);

    const method =
      "yobi_rocks.controllers.music_piece_controller.create_copy_from_link_share";

    const dataWrapper = new MethodDataWrapper().withDto({
      link_share: linkedMusicPiece.name,
    });

    return helper.callMethod(method, dataWrapper).pipe(
      tap((_) => {
        this.tracker.trackEvent(
          "music-piece",
          "create-copy",
          linkedMusicPiece.name,
        );
      }),
    );
  }

  findPreviews(
    musicPieces: LinkedMusicPieceWithMusicPiece[],
  ): Observable<Preview[]> {
    const conversion = new PreviewDtoToPreviewConversion();
    const method =
      "yobi_rocks.controllers.music_piece_controller.linked_music_piece_previews";
    const helper = new FrappeMethodHelper(this.http, conversion.asMany());

    const dataWrapper = new MethodDataWrapper().withDto({
      linked_music_pieces: (musicPieces || []).map((lmp) => {
        return {
          name: lmp.linkedMusicPiece?.name || "",
        };
      }),
    });

    return helper.callMethod(method, dataWrapper).pipe();
  }
}
