<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ "edit-collection.prepare-share-collection.prepare-share" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="share-hint">
      {{ "edit-collection.prepare-share-collection.share-hint" | translate }}
    </p>

    <p
      *ngIf="
        linkedMusicPieces.length > 0 ||
        musicPiecesWithoutShareTerms.length > 0 ||
        privateCopies.length > 0
      "
      class="invalid-music-pieces"
    >
      {{
        "edit-collection.prepare-share-collection.invalid-music-pieces"
          | translate
      }}
    </p>
    <mat-error *ngFor="let linkedMusicPiece of linkedMusicPieces">
      {{ linkedMusicPiece.musicPiece.nameOfPiece }}
    </mat-error>

    <mat-error *ngFor="let musicPiece of musicPiecesWithoutShareTerms">
      {{ musicPiece.nameOfPiece }}
    </mat-error>

    <mat-error *ngFor="let privateCopy of privateCopies">
      {{ privateCopy.nameOfPiece }}
    </mat-error>

    <br />

    <button
      mat-raised-button
      color="primary"
      (click)="prepareShare()"
      [disabled]="
        linkedMusicPieces.length > 0 ||
        musicPiecesWithoutShareTerms.length > 0 ||
        collection == undefined ||
        isSaving
      "
    >
      {{ "edit-collection.prepare-share-collection.prepare-share" | translate }}
    </button>
  </mat-card-content>
</mat-card>
