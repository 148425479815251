<h1 matDialogTitle>
  {{ "edit-collection.edit-collection-items-dialog.title" | translate }}
</h1>

<div matDialogContent>
  <div *ngFor="let item of existingCollectionItems; let idx = index">
    <mat-divider *ngIf="idx > 0"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="item">
      <div class="title">
        {{ item.musicPiece.musicPiece.nameOfPiece }}
      </div>
      <div class="actions">
        <button
          mat-icon-button
          color="primary"
          (click)="up(item)"
          [disabled]="isFirst(item)"
        >
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="down(item)"
          [disabled]="isLast(item)"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div matDialogActions fxLayout="row" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]>
    {{ "common.cancel" | translate }}
  </button>
  <button mat-button (click)="update()" [disabled]="!isDirty">
    {{ "common.save" | translate }}
  </button>
</div>
