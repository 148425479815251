<form [formGroup]="form" (submit)="submit()">
  <div class="flex flex-col">
    <div class="my-2 whitespace-pre-line text-gray-500">
      {{ "artist.set-video-feedback-offering.hint" | translate }}
    </div>

    <label class="flex flex-row items-center gap-x-2">
      <input
        type="checkbox"
        class="checkbox-primary checkbox"
        [formControl]="isOfferingVideoFeedbackForm"
      />
      <div class="label">
        <span class="label-text">{{
          "artist.set-video-feedback-offering.offer-video-feedback" | translate
        }}</span>
      </div>
    </label>

    <br />

    <div>
      <div class="label">
        <span class="label-text">{{
          "artist.set-video-feedback-offering.video-feedback-price" | translate
        }}</span>
      </div>
      <label class="input input-bordered flex max-w-56 items-center gap-2">
        <span class="font-medium">€</span>
        <input
          type="number"
          [min]="minSellingPrice()"
          class=""
          [placeholder]="
            'artist.set-video-feedback-offering.video-feedback-price'
              | translate
          "
          [formControl]="videoFeedbackPriceForm"
        />
      </label>
    </div>

    @if (form.hasError("priceMustBeSet")) {
      <p class="text-sm text-error">
        {{
          "artist.set-video-feedback-offering.price-must-be-set-error"
            | translate: errorTextParam
        }}
      </p>
    }
  </div>

  <br />
  <button
    type="submit"
    [disabled]="form.invalid"
    [class.disabled]="form.invalid"
  >
    <ng-icon class="text-2xl" name="lucideCheck"></ng-icon>
  </button>
</form>
