import { Injectable } from "@angular/core";
import { StoredLinkedMusicPieceDto } from "src/app/dtos/linked-music-piece/stored-linked-music-piece-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class StoredLinkedMusicPieceDtoToLinkedMusicPieceConversion
  implements Conversion<StoredLinkedMusicPieceDto, LinkedMusicPiece>
{
  constructor(private readonly us: UnitService) {}

  convert(item: StoredLinkedMusicPieceDto): LinkedMusicPiece {
    return {
      musicPiece: item.music_piece,
      name: item.name,
      user: item.user,
      lastTimeViewed: this.us.parseOptionalApiDate(item.last_time_viewed),
    };
  }
}
