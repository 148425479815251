<h1 matDialogTitle>
  {{ "edit-collection.add-collection-item-dialog.title" | translate }}
</h1>

<div matDialogContent>
  <mat-progress-bar
    *ngIf="isLoadingCollectionItemsToAdd"
    mode="indeterminate"
  ></mat-progress-bar>
</div>

<div *ngIf="!isLoadingCollectionItemsToAdd" matDialogContent>
  <p *ngIf="data.acceptedShareTerms" class="share-hint">
    {{
      "edit-collection.add-collection-item-dialog.hint-for-shared-collection"
        | translate
    }}
  </p>

  <mat-form-field class="filter-field">
    <mat-label>
      {{ "edit-collection.add-collection-item-dialog.filter" | translate }}
    </mat-label>
    <input
      matInput
      [placeholder]="
        'edit-collection.add-collection-item-dialog.filter' | translate
      "
      [(ngModel)]="filterText"
      (keyup)="applyFilter()"
    />
  </mat-form-field>

  <div *ngFor="let item of filteredItemsToAdd; let idx = index">
    <mat-divider *ngIf="idx > 0"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="item">
      <div class="title">
        {{ item.musicPiece.nameOfPiece }}
      </div>
      <div class="actions">
        <button mat-button (click)="add(item)" color="primary">
          {{ "edit-collection.add-collection-item-dialog.add" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div matDialogActions fxLayout="row" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]>
    {{ "common.close" | translate }}
  </button>
</div>
