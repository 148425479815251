import { Component } from "@angular/core";
import { SubscriptionsComponent } from "../subscriptions/subscriptions.component";

@Component({
  selector: "yr-subscription-page",
  templateUrl: "./subscription-page.component.html",
  styleUrls: ["./subscription-page.component.scss"],
  standalone: true,
  imports: [SubscriptionsComponent],
})
export class SubscriptionPageComponent {}
