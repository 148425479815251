import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import {
  AppActions,
  NotificationSettingsActions,
  UserActions,
} from "../actions";
import { NotificationSettingsService } from "src/app/services/notification/notification-settings.service";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { NotificationSettingsSelectors } from "../selectors";
import { from, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationSettingsEffects {
  triggerLoadUserNotificationSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.loadedServerSettings),
      map((_) => NotificationSettingsActions.loadUserSettings()),
    ),
  );

  loadServerSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        NotificationSettingsActions.loadServerSettings,
        AppActions.upgraded,
      ),
      mergeMap((_) =>
        this.notificationSettingsService
          .loadServerPushSettings()
          .pipe(
            map((settings) =>
              NotificationSettingsActions.loadedServerSettings({ settings }),
            ),
          ),
      ),
    ),
  );

  loadUserSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.loadUserSettings),
      mergeMap((_) =>
        this.notificationSettingsService
          .loadUserSettings()
          .pipe(
            map((settings) =>
              NotificationSettingsActions.loadedUserSettings({ settings }),
            ),
          ),
      ),
    ),
  );

  saveUserSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.saveUserSettings),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.notificationUserSettings,
        ),
      ),
      mergeMap(([action, state]) =>
        this.notificationSettingsService
          .saveUserSettings({
            name: state.userSettings?.name,
            email: action.settings.email,
            enabled: action.settings.enabled,
            push: action.settings.push,
          })
          .pipe(
            map((settings) =>
              NotificationSettingsActions.savedUserSettings({ settings }),
            ),
          ),
      ),
    ),
  );

  triggerPushRegistrationAfterEnable$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.savedUserSettings),
      filter((action) => action.settings.push == true),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.notificationServerSettings,
        ),
      ),
      map(([_, state]) =>
        NotificationSettingsActions.tryRegisterPushSubscription({
          vapidPublicKey: state.serverSettings!.vapidPublicKey,
        }),
      ),
    ),
  );

  triggerPushRegistration$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.loadedUserSettings),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.pushNotificationSubscription,
        ),
      ),
      filter(
        ([action, state]) =>
          action.settings?.push == true &&
          state.activeSubscription == undefined,
      ),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.notificationServerSettings,
        ),
      ),
      map(([_, state]) =>
        NotificationSettingsActions.tryRegisterPushSubscription({
          vapidPublicKey: state.serverSettings!.vapidPublicKey,
        }),
      ),
    ),
  );

  tryFixPushRegistration$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.tryFixPushRegistration),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.notificationServerSettings,
        ),
      ),
      map(([_, state]) =>
        NotificationSettingsActions.tryRegisterPushSubscription({
          vapidPublicKey: state.serverSettings!.vapidPublicKey,
        }),
      ),
    ),
  );

  triggerUnregisterPushAfterDisable$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.savedUserSettings),
      filter((action) => action.settings.push != true),
      map((_) => NotificationSettingsActions.unregisterPushSubscription()),
    ),
  );

  triggerUnregisterPush$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.loadedUserSettings),
      filter((action) => action.settings?.push != true),
      map((_) => NotificationSettingsActions.unregisterPushSubscription()),
    ),
  );

  unregisterPushNotifications$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(NotificationSettingsActions.unregisterPushSubscription),
        tap(async () => {
          await this.notificationSettingsService.unregisterPushSubscription();
        }),
      ),
    {
      dispatch: false,
    },
  );

  registerPushNotifications$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.tryRegisterPushSubscription),
      mergeMap((action) => {
        console.log("registerPushNotifications$ registering....");

        return from(
          this.notificationSettingsService.requestPushSubscription(
            action.vapidPublicKey,
          ),
        ).pipe(
          map((_) => {
            console.log("registered push subscription");
            return NotificationSettingsActions.registerPushSubscriptionSuccess();
          }),
          catchError((error) => {
            console.error("registerPushNotifications$.error");
            console.error(error);
            return of(
              NotificationSettingsActions.registerPushSubscriptionFailed({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  triggerSavePushNotification$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.updatedPushSubscription),
      concatLatestFrom((_) =>
        this.store.select(
          NotificationSettingsSelectors.pushNotificationSubscription,
        ),
      ),
      filter(
        ([action, state]) =>
          action.subscription != undefined &&
          !state.pushNotificationSubscription,
      ),
      map(([action, _]) =>
        NotificationSettingsActions.createPushSubscription({
          subscription: action.subscription!,
        }),
      ),
    ),
  );

  // this works because the pushNotificationSubscription is stored in localStorage
  // therefore we don't need to communicate with the server (only when creating once)
  triggerDeletePushNotificationSubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.updatedPushSubscription),
      concatLatestFrom((_) =>
        this.store.select(
          NotificationSettingsSelectors.pushNotificationSubscription,
        ),
      ),
      filter(
        ([action, state]) =>
          action.subscription == undefined &&
          state.pushNotificationSubscription != undefined,
      ),
      map(([_, state]) =>
        NotificationSettingsActions.deletePushSubscription({
          subscription: state.pushNotificationSubscription!,
        }),
      ),
    ),
  );

  deleteBackendPushNotificationSubscriptionWhenEndpointChanges$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(NotificationSettingsActions.updatedPushSubscription),
        withLatestFrom(
          this.store.select(
            NotificationSettingsSelectors.pushNotificationSubscription,
          ),
        ),
        filter(
          ([action, state]) =>
            action.subscription != undefined &&
            state.pushNotificationSubscription != undefined &&
            state.pushNotificationSubscription.endpoint !=
              action.subscription.endpoint,
        ),
        tap(([action, state]) => {
          console.log(
            `deleteBackendPushNotificationSubscriptionWhenEndpointChanges$: ${action.subscription?.endpoint}, ${state.pushNotificationSubscription?.endpoint}`,
          );
        }),
        map(([_, state]) =>
          NotificationSettingsActions.deletePushSubscription({
            subscription: state.pushNotificationSubscription!,
          }),
        ),
      ),
  );

  savePushNotification$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.createPushSubscription),
      debounceTime(1000), // our android phone did strange things . . . .
      mergeMap((action) =>
        this.notificationSettingsService
          .createPushSubscription(action.subscription)
          .pipe(
            map((subscription) =>
              NotificationSettingsActions.createdPushSubscription({
                subscription,
              }),
            ),
            catchError((error) =>
              of(
                NotificationSettingsActions.createPushSubscriptionFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deletePushSubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationSettingsActions.deletePushSubscription),
      mergeMap((action) =>
        this.notificationSettingsService
          .deletePushSubscription(action.subscription.endpoint)
          .pipe(
            map((_) => NotificationSettingsActions.deletedPushSubscription()),
          ),
      ),
    ),
  );

  // special case I: we have to delete the subscription while we are logged in otherwise we do not have the perission to do so
  // we can't rely on the NotificationSettingsActions.unregisterPushSubscription() "unregister" to finish as this takes to long to finish
  // and we will logged out in the meantime :(
  deletePushSubscriptionOnLogout$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.triggerLogout),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.pushNotificationSubscription,
        ),
      ),
      filter(
        ([_, state]) => state.pushNotificationSubscription?.name != undefined,
      ),
      map(([_, state]) =>
        NotificationSettingsActions.deletePushSubscription({
          subscription: state.pushNotificationSubscription!,
        }),
      ),
    ),
  );

  // special case II: we will have to unregister the push notification when we log out (we know that the deletion will fail because we already did so in the other case)
  unregisterPushSubscriptionOnLogout$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.triggerLogout),
      withLatestFrom(
        this.store.select(
          NotificationSettingsSelectors.pushNotificationSubscription,
        ),
      ),
      filter(([_, state]) => state.activeSubscription != undefined),
      map((_) => NotificationSettingsActions.unregisterPushSubscription()),
    ),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly notificationSettingsService: NotificationSettingsService,
  ) {}
}
