<!-- VIEW MODE -->
<div
  *ngIf="!isInEditMode"
  class="flex w-full flex-col justify-center gap-x-24 md:flex-row"
>
  <div *ngIf="links.length > 0" class="text-4xl">
    {{ "artist.artist-profile-links.links" | translate }}
  </div>

  <!-- this truncate here will prevent the link to overflow the content -->
  <div class="truncate">
    <!-- ICON ONLY -->
    <div class="flex flex-row flex-wrap gap-2 rounded">
      <div *ngFor="let displayLink of displayLinks">
        <div *ngIf="displayLink.urlTypeHelper.icon">
          <a
            mat-icon-button
            [href]="displayLink.urlTypeHelper.urlMapper(displayLink.link.url)"
            target="_blank"
          >
            <mat-icon
              class="text-black"
              [svgIcon]="displayLink.urlTypeHelper.icon"
            ></mat-icon>
          </a>
        </div>
      </div>
    </div>

    <!-- NON ICON -->
    <div class="flex flex-col">
      <div *ngFor="let displayLink of displayLinks">
        <div
          *ngIf="!displayLink.urlTypeHelper.icon"
          class="my-4 flex flex-col gap-4 truncate rounded border-2 bg-white p-2"
        >
          <a
            [href]="displayLink.urlTypeHelper.urlMapper(displayLink.link.url)"
            target="_blank"
            class="truncate text-lg"
          >
            {{ displayLink.link.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EDIT MODE -->
<div *ngIf="isInEditMode">
  <div
    *ngFor="let displayLink of displayLinks"
    class="my-2 flex flex-col gap-2 truncate rounded bg-gray-50 p-2"
  >
    <yr-artist-profile-links-form
      [existingArtistProfileLink]="displayLink.link"
      (onSubmit)="update($event)"
      (onDelete)="remove($event)"
      [canDelete]="true"
      [supportedUrlTypes]="supportedUrlTypes"
    ></yr-artist-profile-links-form>
  </div>
</div>
<!-- EDIT MODE: NEW FORM  -->
<div *ngIf="isInEditMode" class="flex flex-col gap-2">
  <div class="text-lg">
    {{ "artist.artist-profile-links.add" | translate }}
  </div>

  <yr-artist-profile-links-form
    (onSubmit)="add($event)"
    [supportedUrlTypes]="supportedUrlTypes"
  ></yr-artist-profile-links-form>
</div>
