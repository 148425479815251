<button mat-raised-button [matMenuTriggerFor]="afterMenu">
  <span class="hidden xl:block">{{ email }}</span>
  <mat-icon mat-suffix>expand_more</mat-icon>
</button>
<mat-menu #afterMenu="matMenu" xPosition="after">
  <div class="xs:hidden block">
    <button mat-menu-item (click)="notification()">
      <mat-icon>notifications_active</mat-icon>
      <span>
        {{ "user.user-menu.notification" | translate }}
      </span>
    </button>
  </div>
  <button mat-menu-item (click)="faq()">
    <mat-icon>help</mat-icon>
    <span>{{ "user.user-menu.faq" | translate }}</span>
  </button>
  <button mat-menu-item (click)="settings()">
    <mat-icon>account_circle</mat-icon>
    <span>{{ "user.user-menu.settings" | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="artistProfile()"
    *ngIf="hasVerifiedProfile || hasProfile"
    [disabled]="!artistProfileLink"
  >
    <mat-icon>face</mat-icon>
    <span>{{ "user.user-menu.artist-profile" | translate }}</span>
  </button>

  <button mat-menu-item (click)="sales()" *ngIf="hasVerifiedProfile">
    <mat-icon>payments</mat-icon>
    <span>{{ "user.user-menu.sales" | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="becomeArtist()"
    *ngIf="canBecomeArtist && !hasProfile && !hasVerifiedProfile"
  >
    <mat-icon>face</mat-icon>
    <span>{{ "user.user-menu.become-artist" | translate }}</span>
  </button>

  <button mat-menu-item (click)="purchases()">
    <mat-icon>shopping_cart</mat-icon>
    <span>{{ "user.user-menu.purchases" | translate }}</span>
  </button>

  <button mat-menu-item (click)="subscription()">
    <mat-icon svgIcon="crown"></mat-icon>
    <span>{{ "user.user-menu.subscription" | translate }}</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{ "user.user-menu.logout" | translate }}</span>
  </button>
</mat-menu>
