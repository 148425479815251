import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeRequestHelper } from "../frappe-request-helper";
import { map, tap } from "rxjs/operators";
import { ExploreArtistProfileConversion } from "src/app/conversions/explore/explore-artist-profile.conversion";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";
import { MatomoTracker } from "ngx-matomo-client";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { ExploreCollectionConversion } from "src/app/conversions/explore/explore-collection.conversion";
import { ExploreMusicPieceDtoConversion } from "src/app/conversions/explore/explore-music-piece.conversion";
import { PreviewDtoToPreviewConversion } from "src/app/conversions/music-piece/preview-dto-to-preview.conversion";
import { Preview } from "src/app/models/music-piece/preview";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";

@Injectable({
  providedIn: "root",
})
export class ExploreService {
  constructor(
    private readonly http: HttpClient,
    private readonly tracker: MatomoTracker,
  ) {}

  private sortByListingsComp(a: ExploreArtistProfile, b: ExploreArtistProfile) {
    const listingsA = a.listedCollectionCount + a.listedMusicPieceCount;
    const listingsB = b.listedCollectionCount + b.listedMusicPieceCount;
    return listingsB - listingsA;
  }

  private sortByCreation(a: { creation: Date }, b: { creation: Date }) {
    return a.creation.valueOf() - b.creation.valueOf();
  }

  private sortByCreated(a: { created: Date }, b: { created: Date }) {
    return a.created.valueOf() - b.created.valueOf();
  }

  load(): Observable<ExploreArtistProfile[]> {
    const conversion = new ExploreArtistProfileConversion();

    const helper = new FrappeCrudHelper("", this.http, conversion);
    const data = new FrappeRequestHelper();
    const method = "/api/method/yobi_rocks.controllers.explore_controller.load";
    return helper
      .queryAll(data, method)
      .pipe(map((r) => r.sort((a, b) => this.sortByListingsComp(a, b))));
  }

  searchPartners(query: string): Observable<ExploreArtistProfile[]> {
    const conversion = new ExploreArtistProfileConversion();
    const helper = new FrappeCrudHelper("", this.http, conversion);

    const method =
      "/api/method/yobi_rocks.controllers.explore_controller.search_artist_profiles";
    const data = new FrappeRequestHelper().withParam("query", query);
    return helper.queryAll(data, method).pipe(
      map((r) => r.sort((a, b) => this.sortByListingsComp(a, b))),
      tap((r) =>
        this.tracker.trackEvent("explore", "search-partners", query, r.length),
      ),
    );
  }

  searchListedMusicPieces(query: string): Observable<ExploreMusicPiece[]> {
    const conversion = new ExploreMusicPieceDtoConversion();
    const helper = new FrappeCrudHelper("", this.http, conversion);

    const method =
      "/api/method/yobi_rocks.controllers.explore_controller.search_listed_music_pieces";
    const data = new FrappeRequestHelper().withParam("query", query);
    return helper.queryAll(data, method).pipe(
      map((r) => r.sort((a, b) => this.sortByCreation(a, b))),
      tap((r) =>
        this.tracker.trackEvent(
          "explore",
          "search-listed-music-pieces",
          query,
          r.length,
        ),
      ),
    );
  }

  searchListedCollections(query: string): Observable<ExploreCollection[]> {
    const conversion = new ExploreCollectionConversion();
    const helper = new FrappeCrudHelper("", this.http, conversion);

    const method =
      "/api/method/yobi_rocks.controllers.explore_controller.search_listed_collections";
    const data = new FrappeRequestHelper().withParam("query", query);
    return helper.queryAll(data, method).pipe(
      map((r) => r.sort((a, b) => this.sortByCreated(a, b))),
      tap((r) =>
        this.tracker.trackEvent(
          "explore",
          "search-listed-collections",
          query,
          r.length,
        ),
      ),
    );
  }

  findPreviews(listings: ExploreMusicPiece[]): Observable<Preview[]> {
    const conversion = new PreviewDtoToPreviewConversion();
    const method =
      "yobi_rocks.controllers.music_piece_controller.previews_for_listings";
    const helper = new FrappeMethodHelper(this.http, conversion.asMany());
    const dataWrapper = new MethodDataWrapper().withDto({
      listings: (listings || []).map((listing) => {
        return {
          music_piece: listing.musicPiece,
        };
      }),
    });
    return helper.callMethod(method, dataWrapper);
  }
}
