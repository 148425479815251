import {
  FrappeApiHelper,
  mappingBuilder,
  mappingHelper,
} from "@aht/frappe-client";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { PlaylistItem } from "src/app/models/player/playlist";

@Injectable({
  providedIn: "root",
})
export class PlaylistService {
  private readonly mapping = mappingBuilder<PlaylistItem>("", [
    mappingHelper.stringMapper("name"),
    mappingHelper.intMapper("order"),
    mappingHelper.stringMapper("type"),
  ]);

  constructor(private readonly helper: FrappeApiHelper) {}

  loadPlaylist(
    playlist: string,
    playlistType: string,
  ): Observable<PlaylistItem[]> {
    return this.helper.callWithResults(this.mapping.conversion(), {
      method: "yobi_rocks.controllers.playlist_controller.playlist",
      type: "GET",
      extraParams: new Map([
        ["playlist", playlist],
        ["playlist_type", playlistType],
      ]),
    });
  }
}
