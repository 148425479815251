import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import QRCodeStyling from "qr-code-styling";

@Component({
  selector: "yr-qrcode",
  templateUrl: "./qrcode.component.html",
  styleUrls: ["./qrcode.component.scss"],
  standalone: true,
  imports: [],
})
export class QRCodeComponent {
  private _data: string = "";

  @Input()
  set data(value: string) {
    if (value != this._data) {
      this._data = value;
      this.showQrCode();
    }
  }

  get data() {
    return this._data;
  }

  @Input()
  canDownload: boolean = false;

  @Input()
  size: number = 256;

  @ViewChild("canvas", { static: true })
  canvas?: ElementRef;

  constructor() {}

  private renderQrCode(size: number): QRCodeStyling {
    return new QRCodeStyling({
      width: size,
      height: size,
      data: this.data,
      image: "assets/logo/logo.png",
      dotsOptions: {
        color: "#f5860e",
        type: "square",
      },
      backgroundOptions: {
        color: "#fff",
      },
      imageOptions: {
        margin: 0,
      },
    });
  }

  showQrCode() {
    if (this.canvas) {
      const qrCode = this.renderQrCode(this.size);
      qrCode.append(this.canvas.nativeElement);
    }
  }

  downloadQrCode() {
    if (this.canDownload) {
      const qrCode = this.renderQrCode(this.size * 4);
      qrCode.download({ name: "qr", extension: "png" });
    }
  }
}
