<div class="container m-auto mt-4 flex flex-col items-center justify-center">
  <div
    class="m-12 flex aspect-square w-64 flex-col items-center justify-center rounded-full bg-red-300 text-9xl text-red-800 shadow-lg"
  >
    ✗
  </div>
  <div class="whitespace-pre-line text-center text-2xl text-gray-900">
    {{ "user.purchase-subscription-failure.failure" | translate }}
  </div>
  <!-- <div>
    <a routerLink="/" class="text-lg text-red-700 hover:decoration-red-900">{{
      "user.purchase-subscription-failure.music-pieces" | translate
    }}</a>
  </div> -->
</div>
