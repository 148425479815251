<mat-card
  appearance="outlined"
  fxFlex="700px"
  fxFlex.lt-sm="80vw"
  class="container"
>
  <mat-card-header>
    <mat-card-title>
      {{ "user.verify-email.title" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="isLoading$ | async">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="!(isLoading$ | async)">
      <form [formGroup]="form">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>
              {{ "user.verify-email.password" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="password"
              [placeholder]="'user.verify-email.password' | translate"
              type="password"
            />
            <mat-error *ngIf="password.hasError('minlength')">
              {{ "user.verify-email.min-length-error" | translate }}</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label>
              {{ "user.verify-email.password-confirmation" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="passwordConfirmation"
              [placeholder]="
                'user.verify-email.password-confirmation' | translate
              "
              type="password"
            />
            <mat-error *ngIf="passwordConfirmation.hasError('nonidentical')">{{
              "user.verify-email.passwords-not-identical" | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form.invalid || (isLoading$ | async)"
      (click)="finishRegistration()"
    >
      {{ "user.verify-email.finish" | translate }}
    </button>
  </mat-card-actions>
</mat-card>
