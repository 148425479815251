import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { CollectionPurchaseDto } from "src/app/dtos/collection/collection-purchase.dto";
import {
  CollectionPurchase,
  CollectionPurchaseStatus,
} from "src/app/models/collection/collection-purchase";

export class CollectionPurchaseDtoConversion
  implements Conversion<CollectionPurchaseDto, CollectionPurchase>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: CollectionPurchaseDto): CollectionPurchase {
    var purchaseStatus: CollectionPurchaseStatus;

    // 0 draft
    // 1 submitted
    // 2 cancelled
    if (dto.docstatus == 1) {
      purchaseStatus = CollectionPurchaseStatus.PURCHASED;
    } else if (dto.payment_intent_status == "requires_payment_method") {
      purchaseStatus = CollectionPurchaseStatus.REQUIRES_PAYMENT;
    } else {
      purchaseStatus = CollectionPurchaseStatus.PENDING_PAYMENT;
    }

    return {
      name: dto.name,
      collection: dto.collection,
      price: dto.price,
      purchasedBy: dto.purchased_by,
      creation: this.us.parseApiDate(dto.creation),
      clientSecret: dto.client_secret,
      purchaseStatus: purchaseStatus,
      nameOfCollection: dto.name_of_collection,
      platformFee: dto.platform_fee,
      totalSalesTax: dto.total_sales_tax,
      paymentIntentStatus: dto.payment_intent_status,
      paymentDate: this.us.parseOptionalApiDate(dto.payment_date),
    };
  }
}
