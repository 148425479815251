import { StoredArtistCertificationDto } from "src/app/dtos/user/stored-artist-certification-dto";
import {
  ArtistCertification,
  ArtistCertificationState,
} from "src/app/models/user/artist-certification";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class ArtistCertificationConversion
  implements Conversion<StoredArtistCertificationDto, ArtistCertification>
{
  private readonly us: UnitService = new UnitService();

  private stateFromDto(
    dto: StoredArtistCertificationDto,
  ): ArtistCertificationState {
    if (dto.docstatus == 1) {
      return ArtistCertificationState.Approved;
    } else if (dto.docstatus == 2) {
      return ArtistCertificationState.Rejected;
    } else {
      if (dto.workflow_state == "Started")
        return ArtistCertificationState.Started;
      if (dto.workflow_state == "Pending")
        return ArtistCertificationState.Pending;
      if (dto.workflow_state == "Approved")
        return ArtistCertificationState.Approved;
      if (dto.workflow_state == "Rejected")
        return ArtistCertificationState.Rejected;
    }
    return ArtistCertificationState.Started;
  }

  convert(dto: StoredArtistCertificationDto): ArtistCertification {
    return {
      name: dto.name,
      user: dto.user,
      state: this.stateFromDto(dto),
      created: this.us.parseApiDate(dto.creation),
    };
  }
}
