import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { SharedCollectionReceiversComponent } from "../shared-collection-receivers/shared-collection-receivers.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-share-collection",
  templateUrl: "./share-collection.component.html",
  styleUrls: ["./share-collection.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    SharedCollectionReceiversComponent,
  ],
})
export class ShareCollectionComponent implements OnInit, OnDestroy {
  collection?: StoredCollection;
  readonly email = new FormControl<string>("", [Validators.required]);

  readonly form = new UntypedFormGroup({
    email: this.email,
  });

  private readonly subscriptionService = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionData)
        .subscribe((data) => {
          this.collection = data.collection;
        }),
    );
  }

  share() {
    if (this.collection && this.email.value) {
      this.store.dispatch(
        EditCollectionActions.share({
          collection: this.collection,
          receiver: this.email.value,
        }),
      );
    }
  }
}
