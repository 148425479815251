<div fxLayout="column">
  <input
    *ngIf="!isUploading && !isUploadFinishedSuccessfully && !disabled"
    type="file"
    [accept]="accept"
    (change)="handleFileInput($event)"
  />

  <div class="uploadSuccess" *ngIf="isUploadFinishedSuccessfully">
    <mat-icon class="sucessIcon" color="primary">done</mat-icon>
  </div>
</div>
