import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { StoredRotationDtoConversion } from "src/app/conversions/rotation/stored-rotation-dto-conversion";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { Rotation } from "src/app/models/rotation/rotation";
import { dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class RotationService {
  constructor(private readonly http: HttpClient) {}

  loadRotation(
    recording: string,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<Rotation | undefined> {
    const conversion = new StoredRotationDtoConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.rotation_controller.get_rotation";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording", recording)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryFirst(queryHelper, customQueryMethod);
  }

  storeRotation(rotation: Rotation): Observable<Rotation> {
    const conversion = new StoredRotationDtoConversion();
    const helper = new FrappeCrudHelper("Rotation", this.http, conversion);
    const dto = {
      mirror_horizontal: rotation.mirrorHorizontal,
      mirror_vertical: rotation.mirrorVertical,
      rotation: rotation.rotation,
      recording: rotation.recording,
    };
    if (rotation.name) {
      return helper.update(rotation.name, dto);
    } else {
      return helper.create(dto);
    }
  }
}
