import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import { LicenseSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { EditCollectionFormComponent } from "../edit-collection-form/edit-collection-form.component";
import { EditCollectionItemsComponent } from "../edit-collection-items/edit-collection-items.component";
import { ShareCollectionComponent } from "../share-collection/share-collection.component";
import { PrepareShareCollectionComponent } from "../prepare-share-collection/prepare-share-collection.component";
import { EditCollectionListingComponent } from "../edit-collection-listing/edit-collection-listing.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-edit-collection",
  templateUrl: "./edit-collection.component.html",
  styleUrls: ["./edit-collection.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    EditCollectionFormComponent,
    EditCollectionItemsComponent,
    ShareCollectionComponent,
    PrepareShareCollectionComponent,
    EditCollectionListingComponent,
  ],
})
export class EditCollectionComponent implements OnInit, OnDestroy {
  isNew: boolean = false;
  canShareCollections: boolean = false;
  acceptShareTerms: boolean = false;
  canListCollections: boolean = false;

  private readonly subscriptionService = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionItems)
        .subscribe((data) => {
          this.isNew = data.isNew;
          this.acceptShareTerms = data.collection?.acceptShareTerms || false;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(LicenseSelectors.selectUserLicense)
        .subscribe((userLicense) => {
          this.canShareCollections = userLicense.license.canShareCollections;
          this.canListCollections = userLicense.license.canListCollections;
        }),
    );
  }
}
