<mat-card appearance="outlined" *ngIf="news.length > 0">
  <mat-card-header>
    <mat-icon class="card-icon" mat-card-avatar color="primary"
      >newspaper</mat-icon
    >
    <mat-card-title>{{ "news.news-list.title" | translate }}</mat-card-title>
    <mat-card-subtitle>{{
      "news.news-list.subtitle" | translate
    }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div *ngFor="let n of news; index as i">
      <mat-divider *ngIf="i > 0"></mat-divider>
      <br *ngIf="i > 0" />

      <yr-news [news]="n"></yr-news>
    </div>
  </mat-card-content>
</mat-card>
