import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { InformationDialogComponent } from "../components/common/information-dialog/information-dialog.component";
import { InformationDialogData } from "../models/dialog/information-dialog-data";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LicenseErrorInterceptor implements HttpInterceptor {
  private dialogIsOpen = false;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (
              error.error &&
              error.error.exc_type == "LicenseInsufficientError" &&
              !this.dialogIsOpen
            ) {
              this.dialogIsOpen = true;

              const ref = this.matDialog.open<
                InformationDialogComponent,
                InformationDialogData
              >(InformationDialogComponent, {
                data: {
                  content: this.translate.instant(
                    "common.license.insufficient-license"
                  ),
                  title: this.translate.instant(
                    "common.license.insufficient-license-title"
                  ),
                },
                disableClose: true,
                hasBackdrop: true,
              });

              ref.afterClosed().subscribe((_) => {
                this.dialogIsOpen = false;
              });
            }
          }
        }
      )
    );
  }
}
