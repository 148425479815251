import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MarkdownModule } from "ngx-markdown";
import { News } from "src/app/models/news/news";
import { NewsPipe } from "src/app/pipes/news.pipe";

@Component({
  selector: "yr-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
  standalone: true,
  imports: [DatePipe, TranslateModule, MarkdownModule, NewsPipe],
})
export class NewsComponent implements OnInit {
  @Input()
  news?: News;

  constructor() {}

  ngOnInit(): void {}
}
