<mat-card appearance="raised">
  <mat-card-header>
    <mat-card-title *ngIf="isNew">
      {{ "edit-collection.edit-collection-form.create-new-title" | translate }}
    </mat-card-title>
    <mat-card-title *ngIf="!isNew && !isLoadingCollection && collection">
      {{ collection.title }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ collection?.created | date: ("common.dateTimeFormat" | translate) }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingCollection"
    ></mat-progress-bar>
    <div fxLayout.gt-md="row" fxLayout="column" fxLayoutAlign="start top">
      <div class="image-container" fxLayout="column" fxLayoutAlign="start top">
        <div
          fxLayout="column"
          fxFlex
          fxLayoutAlign="center"
          *ngIf="
            collection &&
            isOwner() &&
            (!collection.uploadFinished || isUploadingPicture)
          "
        >
          <button mat-button (click)="openImageUploadDialog()" color="primary">
            {{
              "edit-collection.edit-collection-form.upload-image" | translate
            }}
          </button>
        </div>
        <img
          alt="collection picture"
          *ngIf="collection && collection.uploadFinished"
          [src]="collection.downloadLink"
          class="collection-image"
        />
        <div fxLayout="row" fxLayoutAlign="center">
          <button
            mat-icon-button
            *ngIf="
              isOwner() &&
              !isUploadingPicture &&
              collection &&
              collection.uploadFinished
            "
            [disabled]="isLoadingCollection"
            (click)="deleteProfilePicture()"
            color="warn"
          >
            <!-- {{ "edit-collection.edit-collection-form.delete-image" | translate }} -->
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="column" fxFlex>
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>{{
              "edit-collection.edit-collection-form.title" | translate
            }}</mat-label>
            <input
              matInput
              [formControl]="title"
              type="text"
              placeholder="{{
                'edit-collection.edit-collection-form.title' | translate
              }}"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "edit-collection.edit-collection-form.instrument" | translate
            }}</mat-label>
            <input
              matInput
              [formControl]="instrument"
              type="text"
              placeholder="{{
                'edit-collection.edit-collection-form.instrument' | translate
              }}"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "edit-collection.edit-collection-form.artist" | translate
            }}</mat-label>
            <input
              matInput
              [formControl]="artist"
              type="text"
              placeholder="{{
                'edit-collection.edit-collection-form.artist' | translate
              }}"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "edit-collection.edit-collection-form.description" | translate
            }}</mat-label>
            <textarea
              matInput
              [formControl]="description"
              type="text"
              placeholder="{{
                'edit-collection.edit-collection-form.description' | translate
              }}"
            ></textarea>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "edit-collection.edit-collection-form.temperament" | translate
            }}</mat-label>
            <input
              matInput
              [formControl]="temperament"
              type="text"
              placeholder="{{
                'edit-collection.edit-collection-form.temperament' | translate
              }}"
            />
          </mat-form-field>

          <mat-checkbox matInput [formControl]="isPracticeRoutine">
            {{
              "edit-collection.edit-collection-form.is-practice-routine"
                | translate
            }}
          </mat-checkbox>

          @if (collection?.isPracticeRoutine) {
            <button
              class="btn btn-primary btn-sm ml-2"
              (click)="navigateToPracticeRoutine()"
            >
              {{
                "edit-collection.edit-collection-form.go-to-practice-routine"
                  | translate
              }}
            </button>
          }
        </form>
        <br />
        <div class="button-controls">
          <button
            id="save-button"
            (click)="save()"
            mat-raised-button
            color="primary"
            [disabled]="form.invalid || !form.dirty"
          >
            {{ "common.save" | translate }}
          </button>
          <button
            *ngIf="!isNew"
            [disabled]="isLoadingCollection || hasLinkedCollectionReceivers"
            mat-raised-button
            (click)="delete()"
            color="warn"
          >
            {{
              "edit-collection.edit-collection-form.delete-collection"
                | translate
            }}
          </button>
          <p *ngIf="hasLinkedCollectionReceivers" class="can-not-delete-hint">
            {{
              "edit-collection.edit-collection-form.can-not-delete-because-linked-collection-receivers-hint"
                | translate
            }}
          </p>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
