import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { RecordingUploadPreviewDialogData } from "src/app/models/common/recording-upload-preview-dialog-data";
import { RecordingUploadPreviewDialogResult } from "src/app/models/common/recording-upload-preview-dialog-result";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-recording-upload-preview-dialog",
  templateUrl: "./recording-upload-preview-dialog.component.html",
  styleUrls: ["./recording-upload-preview-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class RecordingUploadPreviewDialogComponent {
  recordingTrustedBlobUrl?: SafeUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: RecordingUploadPreviewDialogData,
    private readonly dialogRef: MatDialogRef<
      RecordingUploadPreviewDialogComponent,
      RecordingUploadPreviewDialogResult
    >,
    private dom: DomSanitizer,
  ) {
    const url = URL.createObjectURL(data.file);
    this.recordingTrustedBlobUrl = this.dom.bypassSecurityTrustUrl(url);
  }

  upload() {
    this.dialogRef.close({ shouldUpload: true });
  }
}
