<div class="card card-compact mb-2 rounded-lg bg-white">
  <div class="card-body flex-col md:flex-row md:flex-wrap">
    <yr-music-pieces-controls
      [canCreateNew]="false"
      [showNew]="false"
      (filterChange)="applyFilter($event.filter)"
      (displayTypeChange)="changeDisplayType($event)"
    ></yr-music-pieces-controls>

    <button
      class="control-button"
      mat-raised-button
      color="primary"
      (click)="addCollectionItem()"
      [disabled]="
        isLoadingCollectionItems ||
        licensedCollectionItemsPerCollection <= collectionItems.length
      "
    >
      {{ "edit-collection.edit-collection-items.add" | translate }}
    </button>

    <button
      class="control-button"
      mat-raised-button
      color="primary"
      (click)="removeCollectionItems()"
      [disabled]="isLoadingCollectionItems"
    >
      {{ "edit-collection.edit-collection-items.remove" | translate }}
    </button>

    <button
      class="control-button"
      mat-raised-button
      color="primary"
      (click)="editCollectionItems()"
      [disabled]="isLoadingCollectionItems"
    >
      {{ "edit-collection.edit-collection-items.edit" | translate }}
    </button>

    @if (collection?.isPracticeRoutine) {
      <button
        class="control-button"
        color="primary"
        mat-raised-button
        [disabled]="isNew || isLoadingCollectionItems"
        (click)="assignPracticeInterval()"
      >
        {{
          "edit-collection.edit-collection-items.practice-interval" | translate
        }}
      </button>
    }
  </div>
</div>

<yr-music-piece-cards
  *ngIf="displayType === 'cards'"
  [musicPiecesToDisplay]="filteredMusicPieces"
  [hasPracticeDurationLeft]="hasPracticeDurationLeft"
  (editMusicPieceChange)="editMusicPiece($event)"
  (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
  (navigateToMusicPieceChange)="navigateToPlayer($event)"
  [linkedMusicPiecePreviews]="linkedMusicPiecePreviews"
  [previews]="previews"
  [disableCreateCopy]="true"
  [isLoading]="isLoadingCollectionItems"
></yr-music-piece-cards>

<yr-music-pieces-table
  *ngIf="displayType === 'list'"
  [musicPiecesToDisplay]="filteredMusicPieces"
  [hasPracticeDurationLeft]="hasPracticeDurationLeft"
  [isLoading]="false"
  (editMusicPieceChange)="editMusicPiece($event)"
  (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
  (navigateToMusicPieceChange)="navigateToPlayer($event)"
  [disableCreateCopy]="true"
></yr-music-pieces-table>
