import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgIcon, provideIcons } from "@ng-icons/core";
import { lucideBell, lucideBellRing } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { ArtistCertificationState } from "src/app/models/user/artist-certification";
import { MaterialModule } from "src/app/modules/material.module";
import {
  ArtistCertificationActions,
  ArtistProfileActions,
  UserActions,
} from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import {
  ArtistCertificationSelectors,
  ArtistProfileSelectors,
} from "src/app/ngrx/selectors";

@Component({
  selector: "yr-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, NgIcon],
  providers: [provideIcons({ lucideBellRing })],
})
export class UserMenuComponent implements OnInit {
  email = "";
  canBecomeArtist = false;
  hasProfile = false;
  hasVerifiedProfile = false;

  artistProfileLink: string | undefined;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.store
      .select(UserSelectors.currentUser)
      .subscribe((user) => (this.email = user?.email || ""));

    this.store.dispatch(
      ArtistProfileActions.loadArtistProfileForCurrentUser({}),
    );
    this.store.dispatch(ArtistCertificationActions.loadArtistCertification());

    this.store
      .select(ArtistProfileSelectors.selectArtistProfileForCurrentUser)
      .subscribe((data) => {
        if (data.profile?.customUrl) {
          this.artistProfileLink = `/@${data.profile.customUrl}`;
        } else {
          this.artistProfileLink = data.profile
            ? `/artists/${data.profile.name}`
            : undefined;
        }
      });
    this.store
      .select(ArtistCertificationSelectors.selectArtistCertificationWithLicense)
      .subscribe((state) => {
        const s = state.artistCertification?.state;
        this.hasProfile =
          s == ArtistCertificationState.Started ||
          s == ArtistCertificationState.Pending;
        this.hasVerifiedProfile = s == ArtistCertificationState.Approved;

        this.canBecomeArtist = state.license.canBecomeArtist;
      });
  }

  logout() {
    this.store.dispatch(UserActions.triggerLogout());
  }

  settings() {
    this.router.navigate(["/settings"]);
  }

  purchases() {
    this.router.navigate(["/purchases"]);
  }

  subscription() {
    this.router.navigate(["/subscription"]);
  }

  becomeArtist() {
    this.router.navigate(["/become-artist"]);
  }

  artistProfile() {
    this.router.navigate([this.artistProfileLink]);
  }

  sales() {
    this.router.navigate(["/sales"]);
  }

  faq() {
    this.router.navigate(["/faq"]);
  }

  notification() {
    this.router.navigate(["/notification"]);
  }
}
