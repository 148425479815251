import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState, UserSelectors } from "../reducers";
import { VideoFeedbackActions } from "../actions";
import { catchError, map, mergeMap, of, tap, withLatestFrom } from "rxjs";
import { VideoFeedbackService } from "src/app/services/video-feedback/video-feedbacks.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { VideoFeedbackSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class VideoFeedbackEffects {
  loadVideoFeedbacks$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.load),
      mergeMap((action) =>
        this.videoFeedbackService.loadVideoFeedback(action.videoFeedback).pipe(
          map((videoFeedback) =>
            VideoFeedbackActions.loaded({
              videoFeedback,
            }),
          ),
        ),
      ),
    ),
  );

  triggerLoadFeedbackItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.load),
      map((action) =>
        VideoFeedbackActions.loadItems({ videoFeedback: action.videoFeedback }),
      ),
    ),
  );

  loadVideoFeedbackItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.loadItems),
      mergeMap((action) =>
        this.videoFeedbackService
          .loadVideoFeedbackItems(action.videoFeedback)
          .pipe(
            map((videoFeedbackItems) =>
              VideoFeedbackActions.loadedItems({ videoFeedbackItems }),
            ),
          ),
      ),
    ),
  );

  createVideoFeedbackItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.createVideoFeedbackItem),
      mergeMap((action) =>
        this.videoFeedbackService
          .createVideoFeedbackItem(
            action.videoFeedback,
            action.message,
            action.musicPiece,
          )
          .pipe(
            map((videoFeedbackItem) =>
              VideoFeedbackActions.createdVideoFeedbackItem({
                videoFeedbackItem,
              }),
            ),
            catchError((error) =>
              of(VideoFeedbackActions.createVideoFeedbackItemFailed({ error })),
            ),
          ),
      ),
    ),
  );

  failureNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(VideoFeedbackActions.createVideoFeedbackItemFailed),
        tap((action) => {
          console.error(action.error);
          this.toastr.error(this.translate.instant("common.fail"));
        }),
      ),
    { dispatch: false },
  );

  markAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.markAsRead),
      withLatestFrom(
        this.store.select(VideoFeedbackSelectors.videoFeedbackItems),
      ),
      withLatestFrom(this.store.select(UserSelectors.currentUserDetails)),
      mergeMap(([[action, state], currentUserState]) =>
        this.videoFeedbackService.markAsRead(action.videoFeedback).pipe(
          map((_) => {
            const items = state.videoFeedbackItems.map((i) => ({
              ...i,
              read: i.sender != currentUserState?.email ? true : i.read,
            }));

            return VideoFeedbackActions.markedAsRead({
              videoFeedbackItems: items,
            });
          }),
        ),
      ),
    ),
  );

  markAsFinished$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.markAsFinished),
      mergeMap((action) =>
        this.videoFeedbackService.markAsFinished(action.videoFeedback).pipe(
          map((videoFeedback) =>
            VideoFeedbackActions.markedAsFinished({
              videoFeedback,
            }),
          ),
        ),
      ),
    ),
  );
  updateTheme$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbackActions.updateTheme),
      mergeMap((action) =>
        this.videoFeedbackService
          .updateTheme(action.videoFeedback, action.theme)
          .pipe(
            map((videoFeedback) =>
              VideoFeedbackActions.updatedTheme({
                videoFeedback,
              }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly videoFeedbackService: VideoFeedbackService,
    private readonly store: Store<AppState>,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}
}
