import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { StoredCollectionDto } from "src/app/dtos/collection/stored-collection.dto";
import { StoredCollection } from "src/app/models/collection/stored-collection";

export class StoredCollectionConversion
  implements Conversion<StoredCollectionDto, StoredCollection>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredCollectionDto): StoredCollection {
    return {
      name: dto.name,
      created: this.us.parseApiDate(dto.creation),
      artist: dto.artist,
      description: dto.description,
      instrument: dto.instrument,
      temperament: dto.temperament,
      title: dto.title,
      owner: dto.owner,
      contentType: dto.content_type,
      downloadLink: dto.download_link,
      uploadFinished: dto.upload_finished > 0,
      acceptShareTerms: dto.accept_share_terms > 0,
      isPracticeRoutine: dto.is_practice_routine > 0,
    };
  }
}
