import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Teaser } from "src/app/models/artist/teaster";
import { Recording } from "src/app/models/recording/recording";
import { PlaybackActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PlaybackSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import {
  registerRotateMirror,
  RotateMirrorOptions,
} from "src/app/videojs-plugin/rotate-mirror-plugin";
import videojs from "video.js";

@Component({
  selector: "yr-teaser-video",
  templateUrl: "./teaser-video.component.html",
  styleUrl: "./teaser-video.component.scss",
  standalone: true,
  imports: [CommonModule],
})
export class TeaserVideoComponent implements OnInit, OnDestroy {
  _teaser?: Teaser;

  private ranSetup = false;
  isVisible = false;

  private player?: videojs.Player;

  @Input()
  set teaser(teaser: Teaser | undefined) {
    this._teaser = teaser;
    this.hasThumbnail = teaser?.musicPiece.uploadFinished == true;
    this.setup();
  }

  get teaser() {
    return this._teaser;
  }

  private _videoElement?: ElementRef;

  @ViewChild("video", { static: false })
  set videoElement(data: ElementRef | undefined) {
    this._videoElement = data;
    this.setup();
  }

  get videoElement() {
    return this._videoElement;
  }

  hasThumbnail = false;
  thumbnailPlayClicked = false;

  private readonly subscriptionService = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(PlaybackSelectors.selectCurrentPlayback)
        .subscribe((url) => {
          if (
            this.teaser &&
            this.teaser.recording.downloadLink != url &&
            this.player
          ) {
            this.player.pause();
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  private setup() {
    if (!this.ranSetup && this.videoElement && this.teaser) {
      this.ranSetup = true;

      const player = videojs(
        this.videoElement.nativeElement,
        {
          autoplay: false,
          loop: true,
          controls: true,
          fluid: false,
          fill: true,
          responsive: true,
          bigPlayButton: true,
          controlBar: {
            pictureInPictureToggle: false,
            playToggle: true,
            fullscreenToggle: true,
            progressControl: {
              seekBar: true,
            },
          },
          userActions: {
            doubleClick: false,
          },
          preload: "metadata", // TODO: #73: when we set the preload to "none", the preview is scaled incorrectly
          playbackRates: [],
          inactivityTimeout: 0, // never hides controlbar
        },
        () => {
          this.player = player;
          player.rotateMirror();
        },
      );

      player.on("posterchange", () => {
        this.applyMirrorRotate();
      });

      player.on("loadedmetadata", () => {
        // at this point we have the dimensions of the video
        // https://docs.videojs.com/player#event:loadedmetadata
        this.applyMirrorRotate();

        if (this.thumbnailPlayClicked) {
          this.player?.play();
        }
      });

      player.on("loaded", (a) => {
        console.log(a);
      });

      player.on("play", () => {
        if (this.teaser && this.teaser.recording.downloadLink) {
          this.store.dispatch(
            PlaybackActions.play({
              playingUrl: this.teaser.recording.downloadLink,
            }),
          );
        }
      });

      this.player = player;
      registerRotateMirror();
    }
  }

  private applyMirrorRotate() {
    this.player?.trigger("rotateMirror", {
      mirrorHorizontally: this.teaser?.recording.mirrorHorizontal || false,
      mirrorVertically: this.teaser?.recording.mirrorVertical || false,
      rotation: this.teaser?.recording.rotation || 0,
    } as RotateMirrorOptions);
  }

  play() {
    this.thumbnailPlayClicked = true;
  }
}
