import { Component, OnInit } from "@angular/core";
import { CreateProfileHintComponent } from "../create-profile-hint/create-profile-hint.component";

@Component({
  selector: "yr-artist-certification-page",
  templateUrl: "./artist-certification-page.component.html",
  styleUrls: ["./artist-certification-page.component.scss"],
  standalone: true,
  imports: [CreateProfileHintComponent],
})
export class ArtistCertificationPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
