<div
  class="mb-2 mt-2 flex flex-row items-center rounded-lg bg-zinc-500 p-4 text-white shadow"
  *ngIf="!isLoading && canCreate && !isApproved"
>
  <div
    class="mr-4 flex h-20 w-20 flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-white shadow-inner"
  >
    <img alt="logo" src="assets/logo/logo.png" class="w-16" />
  </div>

  <div class="flex-shrink flex-grow">
    <div *ngIf="!isStarted && !isPending">
      <!-- we can create a profile -->
      <div class="mb-4 whitespace-pre-line text-base">
        {{
          "artist-certification.create-profile-hint.create-profile-hint"
            | translate
        }}
      </div>

      <button mat-raised-button color="primary" (click)="createProfile()">
        {{
          "artist-certification.create-profile-hint.create-profile" | translate
        }}
      </button>
    </div>
    <div *ngIf="isStarted">
      <!-- we can request review -->
      <div class="mb-4 whitespace-pre-line text-base">
        {{
          "artist-certification.create-profile-hint.request-review-hint"
            | translate
        }}
      </div>

      <yr-artist-profile-link></yr-artist-profile-link>

      <button
        mat-raised-button
        color="primary"
        (click)="requestReview()"
        class="mt-4"
      >
        {{
          "artist-certification.create-profile-hint.request-review" | translate
        }}
      </button>
    </div>
    <div *ngIf="isPending">
      <!-- we can only wait for the review -->
      <div class="mb-4 whitespace-pre-line text-base">
        {{
          "artist-certification.create-profile-hint.pending-hint" | translate
        }}
      </div>
      <yr-artist-profile-link></yr-artist-profile-link>
    </div>
  </div>
</div>
