import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { interval } from "rxjs";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import {
  elapsedPracticeTimeAction,
  loggedElapsedPlaybackTimeSuccessfullyAction,
  loggedPracticedTimeSuccessfullyAction,
  stopPracticeTimeAction,
} from "../actions/used-resources.actions";
import { AppState } from "../reducers";
import { UsedResourcesService } from "../../services/user/used-resources.service";

@Injectable()
export class UsedResourcesEffects {
  private usedResources = (state: AppState) => state.usedResources;

  logRemainingPracticeTime = createEffect(() =>
    this.actions.pipe(
      ofType(stopPracticeTimeAction),
      withLatestFrom(this.store.select(this.usedResources)),
      map(([action, state]) =>
        elapsedPracticeTimeAction({
          practiceTime: new Date().valueOf() - state.lastIsPracticingStart,
          nextStartTime: new Date().valueOf(),
        })
      )
    )
  );

  logPracticeTimeEffect = createEffect(() =>
    interval(30000).pipe(
      withLatestFrom(this.store.select(this.usedResources)),
      filter(([ticks, state]) => {
        return state.isPracticing;
      }),
      map(([ticks, state]) =>
        elapsedPracticeTimeAction({
          practiceTime: new Date().valueOf() - state.lastIsPracticingStart,
          nextStartTime: new Date().valueOf(),
        })
      )
    )
  );

  elapsedPracticeTimeEffect = createEffect(() =>
    this.actions.pipe(
      ofType(elapsedPracticeTimeAction),
      mergeMap((action) => {
        return this.usedResourcesService
          .logPracticeTime(action.practiceTime)
          .pipe(
            map((_) =>
              loggedPracticedTimeSuccessfullyAction({
                nextStartTime: action.nextStartTime,
              })
            )
          );
      })
    )
  );

  logElapsedPlaybackTimeEffect = createEffect(() =>
    interval(35000).pipe(
      withLatestFrom(this.store.select(this.usedResources)),
      filter(([ticks, state]) => {
        return state.elapsedPlaybackTime > 0;
      }),
      mergeMap(([ticks, state]) => {
        const ms = state.elapsedPlaybackTime;
        // console.log(`logging: ${ms} ms`);
        return this.usedResourcesService.logVideoPlaybackTime(ms).pipe(
          map((_) =>
            loggedElapsedPlaybackTimeSuccessfullyAction({
              loggedPlaybackTime: ms,
            })
          )
        );
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly usedResourcesService: UsedResourcesService
  ) {}
}
