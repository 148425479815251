<div class="yr-container">
  <div class="text-lg font-light text-gray-600">
    <span *ngIf="currentLicense">
      {{ "user.license-header.your-current-license-hint" | translate }}
    </span>
    <span>&nbsp;</span>
  </div>
  <div class="text-as-400 mb-2 text-center text-3xl">
    <div *ngIf="license">
      {{ license.title }}
    </div>
  </div>
  <div class="my-2 text-center text-2xl text-gray-700">
    <div *ngIf="!currentLicense && license && billingInterval == 'yearly'">
      <span *ngIf="license.pricePerYear == 'free' || license.pricePerYear <= 0">
        {{ "user.license-header.free" | translate }}
      </span>
      <span *ngIf="license.pricePerYear != 'free'">
        {{
          "user.license-header.price-per-year"
            | translate
              : {
                  price: license.pricePerYear | number: "1.2-2"
                }
        }}
      </span>
    </div>
    <div *ngIf="!currentLicense && license && billingInterval == 'monthly'">
      <span
        *ngIf="license.pricePerMonth == 'free' || license.pricePerMonth <= 0"
      >
        {{ "user.license-header.free" | translate }}
      </span>
      <span *ngIf="license.pricePerMonth != 'free'" class="">
        {{
          "user.license-header.price-per-month"
            | translate
              : {
                  price: license.pricePerMonth | number: "1.2-2"
                }
        }}
      </span>
    </div>
  </div>
  <div
    *ngIf="currentLicense && isEndDateThatIsNotFallbackDate(currentLicense.end)"
    class="my-2 flex flex-col"
  >
    <span class="text-lg font-light text-gray-600">
      {{ "user.license.valid-until" | translate }}
    </span>
    <span class="text-as-400 text-xl">
      {{
        currentLicense.end | date: ("common.dateTimeFormat" | translate)
      }}</span
    >
  </div>
</div>
