import {
  FrappeApiHelper,
  FrappeRequestListOptions,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";
import { Injectable } from "@angular/core";
import { MatomoTracker } from "ngx-matomo-client";
import { Observable, tap } from "rxjs";
import { NotificationTrigger } from "src/app/models/notification/notification-trigger";

@Injectable({ providedIn: "root" })
export class NotificationService {
  private readonly notificationTriggerMapper =
    mappingBuilderWithDefaultMappings<NotificationTrigger>(
      "Notification Trigger",
      [
        mappingHelper.stringMapper("reference_type", "referenceType"),
        mappingHelper.stringMapper("reference"),
        mappingHelper.stringMapper("action"),
        mappingHelper.booleanMapper("sent"),
        mappingHelper.booleanMapper("triggered"),
        mappingHelper.booleanMapper("read"),
        mappingHelper.optionalDateMapper("valid_until", "validUntil"),
        mappingHelper.stringMapper("title"),
        mappingHelper.stringMapper("message"),
        mappingHelper.stringMapper("click_url", "clickUrl"),
      ],
    );

  constructor(
    private readonly helper: FrappeApiHelper,
    private readonly tracker: MatomoTracker,
  ) {}

  load(lastN: number = 50): Observable<NotificationTrigger[]> {
    return this.helper.getList(
      this.notificationTriggerMapper,
      new FrappeRequestListOptions()
        .withPageLength(lastN)
        .withOrderBy("creation", "desc"),
    );
  }

  markAsRead(notification: string): Observable<NotificationTrigger> {
    return this.helper
      .update(
        notification,
        {
          read: true,
        },
        this.notificationTriggerMapper,
      )
      .pipe(
        tap((r) =>
          this.tracker.trackEvent("notification", "mark_as_read", notification),
        ),
      );
  }

  markAllAsRead(): Observable<void> {
    return this.helper
      .call({
        method:
          "yobi_rocks.controllers.notification_controller.mark_all_as_read",
        type: "POST",
      })
      .pipe(
        tap((_) => this.tracker.trackEvent("notification", "mark_all_as_read")),
      );
  }
}
