import { Pipe, PipeTransform } from "@angular/core";
import { MusicPieceThumbnail } from "../models/music-piece/music-piece-thumbnail";
import { ExploreMusicPiece } from "../models/explore/explore-music-piece";

@Pipe({
  name: "exploreMusicPieceThumbnail",
  standalone: true,
})
export class ExploreMusicPieceThumbnailPipe implements PipeTransform {
  transform(musicPiece: ExploreMusicPiece): MusicPieceThumbnail | undefined {
    return MusicPieceThumbnail.fromExploreMusicPiece(musicPiece);
  }
}
