import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { BillingInterval } from "src/app/models/license/billing-interval";
import { UsedResourcesForUser } from "src/app/models/user/used-resources-for-user";
import { License, UserLicense } from "src/app/models/user/user-license";
import {
  LicenseActions,
  UsedResourcesForUserActions,
} from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import {
  LicenseSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { environment } from "src/environments/environment";
import { StripePortalComponent } from "../stripe-portal/stripe-portal.component";
import { LicenseHeaderComponent } from "../license-header/license-header.component";
import { LicenseComponent } from "../license/license.component";
import { PurchaseSubscriptionComponent } from "../purchase-subscription/purchase-subscription.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-subscriptions",
  templateUrl: "./subscriptions.component.html",
  styleUrls: ["./subscriptions.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    StripePortalComponent,
    LicenseHeaderComponent,
    LicenseComponent,
    PurchaseSubscriptionComponent,
  ],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  termsLink = environment.terms;

  upgrade?: License;
  fallback?: License;
  currentLicense?: UserLicense;
  currentUsedResources?: UsedResourcesForUser;

  stripePortalReturnUrl = `${environment.guiBaseUrl}/subscription`;

  billingInterval: BillingInterval = "yearly";

  private readonly subscriptionHelper = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(UsedResourcesForUserActions.loadUsedResourcesAction());
    this.store.dispatch(LicenseActions.loadLicenseAction());
    this.store.dispatch(LicenseActions.loadFallbackAndUpgrade());

    this.subscriptionHelper.add(
      this.store
        .select(UsedResourcesSelectors.selectUsedResourcesForUser)
        .subscribe((ur) => {
          this.currentUsedResources = ur;
        }),
    );

    this.subscriptionHelper.add(
      this.store.select(LicenseSelectors.selectUserLicense).subscribe((ul) => {
        this.currentLicense = ul;
      }),
    );

    this.subscriptionHelper.add(
      this.store
        .select(LicenseSelectors.selectFallbackAndUpgrade)
        .subscribe((data) => {
          this.fallback = data.fallback;
          this.upgrade = data.upgrade;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptionHelper.unsubscribeAll();
  }
}
