<form (submit)="handleSubmit($event)">
  <div id="payment-element"></div>
  <div id="payment-message"></div>

  <button
    id="submit"
    *ngIf="currentPurchase"
    color="primary"
    mat-raised-button
    [disabled]="isLoading"
  >
    <div class="spinner hidden" id="spinner"></div>
    <span id="button-text">{{
      "artist.purchase.purchase-now" | translate
    }}</span>
  </button>
</form>
