import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Conversion } from "../conversions/conversion";
import { ResponseDto } from "../dtos/response-dto";
import { SingleResponseDto } from "../dtos/single-response-dto";
import { ConversionHelper } from "./conversion-helper";
import { FrappeRequestHelper } from "./frappe-request-helper";
import { UrlSanitizer } from "./url-sanitizer";

export class FrappeCrudHelper<DTO, T> {
  private readonly conversionHelper?: ConversionHelper<DTO, T>;

  constructor(
    private readonly docType: string,
    private readonly http: HttpClient,
    readonly conversion?: Conversion<DTO, T>,
  ) {
    if (conversion) {
      this.conversionHelper = new ConversionHelper(conversion);
    }
  }

  private ensureCanConvert() {
    if (!this.conversionHelper) {
      throw "conversion must be initialized! in constructor";
    }
  }

  getSingle(
    name: string,
    subPathOverwrite?: string,
    existingRequestHelper?: FrappeRequestHelper,
  ): Observable<T> {
    this.ensureCanConvert();
    const requestHelper =
      existingRequestHelper ?? new FrappeRequestHelper().withFields(["*"]);

    const querySubPath =
      subPathOverwrite ?? `/api/resource/${this.docType}/${name}`;

    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart(querySubPath);

    const res = this.http.get<SingleResponseDto<DTO>>(urlSanitizer.url(), {
      params: requestHelper.withPageLength(1).buildParams(),
    });

    return this.conversionHelper!.convertSingleObservable(res);
  }

  queryAll(
    requestHelper: FrappeRequestHelper,
    subPathOverwrite?: string,
  ): Observable<T[]> {
    this.ensureCanConvert();

    var params = requestHelper.buildParams();

    if (!params.has("limit_page_length")) {
      // frappe limits the entries to 20 per default.
      // see: https://frappeframework.com/docs/v13/user/en/api/rest#:~:text=the%20limit_start%20and-,limit_page_length,-params.
      params = params.set("limit_page_length", "None");
    }

    const querySubPath = subPathOverwrite ?? `/api/resource/${this.docType}`;
    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart(querySubPath);
    const res = this.http.get<ResponseDto<DTO>>(urlSanitizer.url(), {
      params: params,
    });

    return this.conversionHelper!.convertObservable(res);
  }

  queryFirst(
    requestHelper: FrappeRequestHelper,
    subPathOverwrite?: string,
  ): Observable<T | undefined> {
    return this.queryAll(
      requestHelper.withPageLength(1),
      subPathOverwrite,
    ).pipe(
      map((data) => {
        if (data.length > 0) {
          return data[0];
        } else {
          return undefined;
        }
      }),
    );
  }

  create(dto: any, subPathOverwrite?: string): Observable<T> {
    this.ensureCanConvert();

    const createSubPath = subPathOverwrite ?? `/api/resource/${this.docType}`;
    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart(createSubPath);

    const res = this.http.post<SingleResponseDto<DTO>>(urlSanitizer.url(), dto);

    return this.conversionHelper!.convertSingleObservable(res);
  }

  update(name: string, dto: any, subPathOverwrite?: string): Observable<T> {
    this.ensureCanConvert();
    const updateSubPath =
      subPathOverwrite ?? `/api/resource/${this.docType}/${name}`;
    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart(updateSubPath);
    const res = this.http.put<SingleResponseDto<DTO>>(urlSanitizer.url(), dto);

    return this.conversionHelper!.convertSingleObservable(res);
  }

  delete(name: string, subPathOverwrite?: string): Observable<void> {
    const deleteSubPath =
      subPathOverwrite ?? `/api/resource/${this.docType}/${name}`;
    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart(deleteSubPath);
    return this.http.delete<void>(urlSanitizer.url());
  }
}
