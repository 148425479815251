import { PurchaseSettingsDto } from "src/app/dtos/purchase/purchase-settings-dto";
import { PurchaseSettings } from "src/app/models/purchase/purchase-settings";
import { Conversion } from "../conversion";

export class PurchaseSettingsDtoConversion
  implements Conversion<PurchaseSettingsDto, PurchaseSettings>
{
  convert(dto: PurchaseSettingsDto): PurchaseSettings {
    return {
      fee: dto.fee,
      fixedFee: dto.fixed_fee,
      fixedPaymentFee: dto.fixed_payment_fee,
      paymentFee: dto.payment_fee,
      minSellingPrice: dto.min_selling_price,
      salesTax: dto.sales_tax,
    };
  }
}
