import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "yr-version",
  templateUrl: "./version.component.html",
  styleUrls: ["./version.component.scss"],
  standalone: true,
  imports: [],
})
export class VersionComponent implements OnInit {
  versionName = environment.versionName;
  commitSha = environment.version;

  constructor() {}

  ngOnInit(): void {}
}
