<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="music-pieces mx-2">
  <div class="w-full" *ngFor="let mp of musicPiecesToDisplay">
    <yr-music-piece-card
      [musicPiece]="mp.musicPiece"
      [canPractice]="hasPracticeDurationLeft"
      [canEdit]="true"
      [canCreateNew]="canCreateNew"
      [hasFreeStorageLeft]="hasFreeStorageLeft"
      [isLinkedMusicPiece]="mp.isLinkedMusicPiece"
      [disableCreateCopy]="disableCreateCopy"
      (editMusicPiece)="edit(mp)"
      (createCopyFromLinkedMusicPieceChange)="
        createCopyFromLinkedMusicPiece(mp.linkedMusicPiece)
      "
      (navigateToPlayerChange)="
        navigateToPlayer(mp.musicPiece, mp.linkedMusicPiece)
      "
      [preview]="mp.musicPiece | preview: allPreviews"
      [collectionInfo]="
        mp
          | musicPieceToCollectionItem: musicPieceToCollectionItemMapping
          | collectionInfo: collectionData
      "
    >
    </yr-music-piece-card>
  </div>
</div>
