import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { PracticeRoutinesService } from "src/app/services/practice-routine/practice-routines.service";
import { MusicPieceActions, PracticeRoutineActions } from "../actions";
import { filter, map, mergeMap, tap, withLatestFrom } from "rxjs";
import { CollectionService } from "src/app/services/collection/collection.service";
import { MusicPieceSelectors } from "../selectors";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class PracticeRoutineEffects {
  loadPracticeRoutines$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.load),
      mergeMap((action) =>
        this.practiceRoutinesService
          .loadPracticeRoutine(action.name, action.collectionType)
          .pipe(
            filter((result) => result != undefined),
            map((result) =>
              PracticeRoutineActions.loaded({
                practiceRoutine: result!,
              }),
            ),
          ),
      ),
    ),
  );

  triggerLoadPracticeConfirmations$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.load),
      map((action) =>
        PracticeRoutineActions.loadPracticeConfirmations({
          collectionType: action.collectionType,
          name: action.name,
        }),
      ),
    ),
  );

  loadPracticeConfirmations$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.loadPracticeConfirmations),
      mergeMap((action) =>
        this.practiceRoutinesService
          .loadPracticeConfirmations(action.name, action.collectionType)
          .pipe(
            map((practiceConfirmations) =>
              PracticeRoutineActions.loadedPracticeConfirmations({
                practiceConfirmations,
              }),
            ),
          ),
      ),
    ),
  );

  loadMusicPiecesForCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.load),
      withLatestFrom(
        this.store.select(
          MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
        ),
      ),
      filter(
        ([_, data]) =>
          // we might load too often in case the user has no linked music pieces and no music pieces, but that's ok
          data.musicPieces.length == 0 && data.linkedMusicPieces.length == 0,
      ),
      map((_) => MusicPieceActions.loadMusicPieces()),
    ),
  );

  triggerLoadPracticeItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.load),
      map((action) =>
        PracticeRoutineActions.loadCollectionItems({
          name: action.name,
          collectionType: action.collectionType,
        }),
      ),
    ),
  );

  loadLinkedCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.loadCollectionItems),
      filter((action) => action.collectionType == "linkedCollection"),
      mergeMap((action) =>
        this.collectionService
          .loadLinkedCollectionItemsForCollection(action.name)
          .pipe(
            map((result) =>
              PracticeRoutineActions.loadedCollectionItems({
                linkedCollectionItems: result,
                collectionItems: [],
              }),
            ),
          ),
      ),
    ),
  );

  loadCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.loadCollectionItems),
      filter((action) => action.collectionType == "collection"),
      mergeMap((action) =>
        this.collectionService
          .loadCollectionItemsForCollection(action.name)
          .pipe(
            map((result) =>
              PracticeRoutineActions.loadedCollectionItems({
                linkedCollectionItems: [],
                collectionItems: result,
              }),
            ),
          ),
      ),
    ),
  );

  markAsPracticed$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.markAsPracticed),
      mergeMap((action) =>
        this.practiceRoutinesService
          .markAsPracticed(
            action.collectionItem,
            action.linkedCollectionItem,
            action.rating,
            action.summary,
          )
          .pipe(
            map((practiceConfirmation) =>
              PracticeRoutineActions.markedAsPracticed({
                confirmation: practiceConfirmation,
              }),
            ),
          ),
      ),
    ),
  );

  loadPlayerPracticeRoutine$ = createEffect(() =>
    this.actions.pipe(
      ofType(PracticeRoutineActions.loadPlayerCollectionItem),
      mergeMap((action) =>
        this.practiceRoutinesService
          .loadCollectionItemForPracticeRoutineItem(
            action.musicPieceOrLinkedMusicPiece,
            action.collectionOrLinkedCollection,
          )
          .pipe(
            filter((result) => result != undefined),
            map((result) =>
              PracticeRoutineActions.loadedPlayerCollectionItem({
                collectionType:
                  result!.collectionType == "collection"
                    ? "collection"
                    : "linkedCollection",
                collectionItem: result!.item,
              }),
            ),
          ),
      ),
    ),
  );

  successToasts$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(PracticeRoutineActions.markedAsPracticed),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly practiceRoutinesService: PracticeRoutinesService,
    private readonly collectionService: CollectionService,
    private readonly store: Store<AppState>,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}
}
