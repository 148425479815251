import { DatePipe, CurrencyPipe } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  Provider,
  importProvidersFrom,
  isDevMode,
} from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { FrappeErrorInterceptor } from "./interceptors/frappe-error.interceptor";
import { LicenseErrorInterceptor } from "./interceptors/license-error.interceptor";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import {
  NoPreloading,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from "@angular/router";
import { routes } from "./app.routes";
import { provideEffects } from "@ngrx/effects";
import { provideStore } from "@ngrx/store";
import { EFFECTS } from "./ngrx/effects";
import { REDUCERS, META_REDUCERS } from "./ngrx/reducers";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideToastr } from "ngx-toastr";
import { MatomoRouterModule, provideMatomo } from "ngx-matomo-client";
import { environment } from "src/environments/environment";
import { provideMarkdown } from "ngx-markdown";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { ImageCropperModule } from "ngx-image-cropper";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideServiceWorker } from "@angular/service-worker";
import {
  FrappeApiHelper,
  FrappeConfiguration,
  FrappeCrudService,
  FrappeMethodService,
} from "@aht/frappe-client";

const frappeClientConfiguration = {
  baseUrl: environment.baseUrl,
} as FrappeConfiguration;

const frappeClientLibraryProviders = [
  {
    provide: FrappeCrudService,
    useFactory: (client: HttpClient) =>
      new FrappeCrudService(client, frappeClientConfiguration),
    deps: [HttpClient],
  },
  {
    provide: FrappeMethodService,
    useFactory: (client: HttpClient) =>
      new FrappeMethodService(client, frappeClientConfiguration),
    deps: [HttpClient],
  },
  {
    provide: FrappeApiHelper,
    useFactory: (crud: FrappeCrudService, method: FrappeMethodService) =>
      new FrappeApiHelper(crud, method),
    deps: [FrappeCrudService, FrappeMethodService],
  },
] as Provider[];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({ anchorScrolling: "enabled" }),
      withPreloading(NoPreloading),
    ),
    provideStore(REDUCERS, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
      },
      metaReducers: META_REDUCERS,
    }),
    provideEffects(EFFECTS),
    provideStoreDevtools({
      connectInZone: true,
      logOnly: !isDevMode(),
    }),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideToastr(),
    provideMatomo({
      siteId: "1", // your Matomo's site ID (find it in your Matomo's settings)
      trackerUrl: environment.matomoUrl,
    }),
    importProvidersFrom(MatomoRouterModule),
    provideMarkdown(),
    importProvidersFrom(ImageCropperModule),
    provideAnimations(),
    provideServiceWorker("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LicenseErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FrappeErrorInterceptor,
      multi: true,
    },
    DatePipe,
    CurrencyPipe,
    { provide: DEFAULT_CURRENCY_CODE, useValue: "EUR" },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: "dynamic", hideRequiredMarker: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-AT" },
    ...frappeClientLibraryProviders,
  ],
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
