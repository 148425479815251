<div *ngIf="stripeCustomer && stripeCustomer.stripeCustomerId">
  <button
    mat-raised-button
    color="primary"
    (click)="openCustomerPortal()"
    [disabled]="hasClickedOpenPortal"
    class="mb-1"
  >
    {{ "user.stripe-portal.open-customer-portal" | translate }}
  </button>
  <p class="text-gray-600">
    {{ "user.stripe-portal.customer-portal-hint" | translate }}
  </p>
</div>
