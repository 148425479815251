<div class="w-full rounded-lg bg-white shadow">
  <div class="relative overflow-clip rounded-lg">
    <yr-preview
      [preview]="preview"
      [musicPieceThumbnail]="musicPiece | thumbnail"
    ></yr-preview>
    <div
      class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
    >
      <button
        mat-icon-button
        (click)="handleEditMusicPiece()"
        [hidden]="!canEdit"
        [disabled]="!canEdit"
      >
        <mat-icon>edit</mat-icon></button
      ><button mat-icon-button (click)="showDetails()">
        <mat-icon>info</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="createCopyFromLinkedMusicPieceChange.emit()"
        *ngIf="isLinkedMusicPiece && !disableCreateCopy"
        [disabled]="!canCreateNew || !hasFreeStorageLeft"
      >
        <mat-icon>add_to_photos</mat-icon>
      </button>
    </div>
  </div>

  <div class="flex flex-grow flex-col truncate p-2">
    <span class="truncate text-lg">
      {{ musicPiece.nameOfPiece }}
    </span>
    <div class="flex flex-row">
      <div class="flex flex-grow flex-row items-center truncate"></div>

      <button
        class="mx-2 mb-1 flex rounded bg-as-500 p-1 align-middle text-base text-white hover:bg-as-600 disabled:bg-zinc-200 disabled:text-zinc-300"
        (click)="navigateToPlayer(musicPiece)"
        [disabled]="!canPractice"
      >
        <mat-icon class="items-center">music_note</mat-icon>
        <span class="items-center">Werkbank</span>
      </button>
    </div>
  </div>
</div>
