import { ShowcaseCollectionListingDto } from "src/app/dtos/collection/showcase-collection.dto";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { UnitService } from "src/app/services/unit/unit.service";
import { ExtendableConversion } from "../extendable-conversion";
import { PreviewDtoToPreviewConversion } from "../music-piece/preview-dto-to-preview.conversion";
import { MusicPieceDtoToMusicPieceConversion } from "../music-piece/music-piece-dto-to-music-piece-conversion";

export class ShowcaseCollectionListingConversion extends ExtendableConversion<
  ShowcaseCollectionListingDto,
  ShowcaseCollectionListing
> {
  private readonly us = new UnitService();

  private readonly previewConversion = new PreviewDtoToPreviewConversion();
  private readonly musicPieceConversion =
    new MusicPieceDtoToMusicPieceConversion();

  convert(dto: ShowcaseCollectionListingDto): ShowcaseCollectionListing {
    return {
      created: this.us.parseApiDate(dto.creation),
      description: dto.description,
      contentType: dto.content_type,
      downloadLink: dto.download_link,
      name: dto.name,
      collection: dto.collection,
      title: dto.title,
      price: dto.price,
      artist: dto.artist,
      instrument: dto.instrument,
      temperament: dto.temperament,
      items: dto.items
        .map((i) => ({
          order: i.order,
          musicPiece: this.musicPieceConversion.convert(i.music_piece),
          preview:
            i.preview != undefined
              ? this.previewConversion.convert(i.preview)
              : undefined,
        }))
        .sort((a, b) => a.order - b.order),
      isPracticeRoutine: dto.is_practice_routine > 0,
    };
  }
}
