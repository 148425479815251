import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TeaserSelectionDialogComponent } from "../teaser-selection-dialog/teaser-selection-dialog.component";
import { TeaserSelectionDialogData } from "src/app/models/artist/teaser-selection-dialog-data";
import { TeaseSelectionDialogResult } from "src/app/models/artist/teaser-selection-dialog-result";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { AppState } from "src/app/ngrx/reducers";
import { Store } from "@ngrx/store";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ArtistProfileSelectors } from "src/app/ngrx/selectors";
import { Teaser } from "src/app/models/artist/teaster";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { TeaserVideoComponent } from "../teaser-video/teaser-video.component";
import { ArtistProfileLinksComponent } from "../artist-profile-links/artist-profile-links.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-teaser",
  templateUrl: "./teaser.component.html",
  styleUrl: "./teaser.component.scss",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TeaserVideoComponent,
    ArtistProfileLinksComponent,
    MaterialModule,
  ],
})
export class TeaserComponent implements OnInit, OnDestroy {
  @Input()
  isInEditMode = false;

  @Output()
  teaserChanged = new EventEmitter<string>();

  _artistProfile?: StoredArtistProfile;

  get artistProfile() {
    return this._artistProfile;
  }

  @Input()
  set artistProfile(artistProfile: StoredArtistProfile | undefined) {
    const oldArtistProfile = this._artistProfile;
    this._artistProfile = artistProfile;

    if (
      (oldArtistProfile == undefined && artistProfile != undefined) ||
      oldArtistProfile?.name != artistProfile?.name ||
      oldArtistProfile?.teaser != artistProfile?.teaser
    ) {
      this.loadTeaserVideo();
    }
  }

  teaser?: Teaser;

  isLoadingTeaser: boolean = false;

  hasVideo = false;

  private readonly subscriptions = new SubscriptionService();

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(ArtistProfileSelectors.selectTeaser)
        .subscribe((data) => {
          this.teaser = data.teaser;
          this.hasVideo =
            data.teaser?.recording.contentTypeOriginalFile?.includes("video") ||
            false;
          this.isLoadingTeaser = data.isLoadingTeaser;
        }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  chooseTeaser() {
    if (this.artistProfile) {
      const ref = this.matDialog.open<
        TeaserSelectionDialogComponent,
        TeaserSelectionDialogData,
        TeaseSelectionDialogResult
      >(TeaserSelectionDialogComponent, {
        data: {
          artistProfile: this.artistProfile,
        },
        minWidth: "50vw",
        maxWidth: "65vw",
      });

      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.teaserChanged.emit(result.teaser);
        }
      });
    }
  }

  deleteTeaser() {
    this.teaserChanged.emit("");
  }

  private loadTeaserVideo() {
    if (this.artistProfile) {
      this.store.dispatch(
        ArtistProfileActions.loadTeaser({
          artistProfile: this.artistProfile,
        }),
      );
    }
  }
}
