import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import {
  CollectionItemToDisplay,
  CollectionItemToDisplayHelper,
} from "src/app/models/collection/collection-item-to-display";
import { RemoveCollectionItemDialogData } from "src/app/models/collection/remove-collection-item-dialog-data";
import { RemoveCollectionItemDialogResult } from "src/app/models/collection/remove-collection-item-dialog-result";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-remove-collection-item-dialog",
  templateUrl: "./remove-collection-item-dialog.component.html",
  styleUrls: ["./remove-collection-item-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class RemoveCollectionItemDialogComponent {
  existingCollectionItems: CollectionItemToDisplay[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: RemoveCollectionItemDialogData,
    private readonly dialogRef: MatDialogRef<
      RemoveCollectionItemDialogComponent,
      RemoveCollectionItemDialogResult
    >,
  ) {
    this.existingCollectionItems = CollectionItemToDisplayHelper.from(
      data.existingCollectionItems,
      data.musicPieces,
      data.linkedMusicPieces,
    );
  }

  remove(item: StoredCollectionItem) {
    this.dialogRef.close({
      itemToRemove: item,
    });
  }
}
