import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BillingInterval } from "src/app/models/license/billing-interval";
import { License, UserLicense } from "src/app/models/user/user-license";

@Component({
  selector: "yr-license-header",
  templateUrl: "./license-header.component.html",
  styleUrls: ["./license-header.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class LicenseHeaderComponent {
  @Input()
  currentLicense?: UserLicense;

  @Input()
  license?: License;

  @Input()
  billingInterval: BillingInterval | "none" = "none";

  isEndDateThatIsNotFallbackDate(date: Date): boolean {
    return date.getFullYear() < 9000; // the fallback license has a license of 9999 years
  }
}
