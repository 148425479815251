<yr-upload
  *ngIf="recording == emptyRecording || isUploadingRecording"
  accept="video/mp4,video/x-m4v,audio/3gpp,audio/3gpp2,audio/aiff,audio/x-aiff,audio/mp3,audio/mpeg3,audio/x-mp3,audio/x-mpeg3,audio/mp4,audio/mpeg,audio/wav,audio/x-wav,audio/x-m4a,audio/x-m4b,audio/x-m4p,video/*,audio/*"
  [createOrGetDocument]="getOrCreateRecordingIn"
  [prepareUpload]="prepareRecordingUpload"
  [beforeUploadConfirmation]="beforeUploadConfirmation"
  [disabled]="isLoadingRecording"
  [finishUpload]="finishUpload"
  (uploadChange)="uploadChanged($event)"
  (uploadInProgress)="uploadRecordingInProgress($event)"
></yr-upload>
