import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { FaqActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { FaqSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-faq-search",
  templateUrl: "./faq-search.component.html",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class FaqSearchComponent implements OnInit, OnDestroy {
  isLoadingSearchResults: boolean = false;

  searchQuery = new FormControl("");

  searchForm = new UntypedFormGroup({
    searchQuery: this.searchQuery,
  });

  private readonly subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptions.addMany(
      this.store.select(FaqSelectors.searchResults).subscribe((data) => {
        this.isLoadingSearchResults = data.isLoadingSearchResults;
      }),
      this.searchQuery.valueChanges.subscribe((val) => {
        this.store.dispatch(FaqActions.search({ query: val ?? "" }));
      }),
    );
  }
}
