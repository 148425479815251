import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import {
  EmptyMusicScore,
  MusicScore,
} from "src/app/models/music-score/music-score";
import { EmptyRecording, Recording } from "src/app/models/recording/recording";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState, EditMusicPieceSelectors } from "src/app/ngrx/reducers";
import { MusicScoreService } from "src/app/services/music-score/music-score.service";
import { ConfirmationDialogComponent } from "../../common/confirmation-dialog/confirmation-dialog.component";
import {
  CreateOrGetDocument,
  PrepareUpload,
  FinishUpload,
} from "src/app/models/common/upload-types";
import { ImageUploadDialogData } from "src/app/models/common/image-upload-dialog-data";
import { ImageUploadDialogResult } from "src/app/models/common/image-upoload-dialog-result";
import { ImageUploadDialogComponent } from "../../common/image-upload-dialog/image-upload-dialog.component";
import { MusicService } from "src/app/services/music-piece/music-piece.service";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { LicenseSelectors } from "src/app/ngrx/selectors";
import { CommonModule } from "@angular/common";
import { UploadAttachmentComponent } from "../../music-piece/upload/upload.component";
import { RecordingUploadComponent } from "../../common/recording-upload/recording-upload.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-uploads",
  templateUrl: "./music-piece-uploads.component.html",
  styleUrls: ["./music-piece-uploads.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    UploadAttachmentComponent,
    RecordingUploadComponent,
  ],
})
export class MusicPieceUploadsComponent implements OnInit, OnDestroy {
  readonly emptyMusicScore = EmptyMusicScore; // required, otherwise we can't reference in html
  musicScore: MusicScore = EmptyMusicScore;

  readonly emptyRecording = EmptyRecording; // required, otherwise we can't reference in html
  recording: Recording = EmptyRecording;

  isUploadingMusicScore: boolean = false;
  isUploadingRecording: boolean = false;

  isLoadingMusicScore: boolean = true;
  isLoadingRecording: boolean = true;

  musicPiece?: MusicPiece;
  private pollingTimeout: number | undefined;

  isUploadingThumbnail: boolean = false;

  canSetupAudioTracks: boolean = false;

  private readonly subscriptions = new SubscriptionService();
  isUpdatingMultipleTracks: boolean = false;

  constructor(
    // we still require the music score service because the upload component heavily relies on the promises and
    // does not fit well with ngrx
    private readonly musicScoreService: MusicScoreService,
    private readonly musicPieceService: MusicService,
    private readonly store: Store<AppState>,
    private readonly translate: TranslateService,
    private readonly matDialog: MatDialog,
    private readonly toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.addMany(
      this.store
        .select(EditMusicPieceSelectors.selectMusicScoreAndRecording)
        .subscribe((data) => {
          this.musicScore = data.musicScore ?? EmptyMusicScore;
          this.recording = data.recording ?? EmptyRecording;
          this.isLoadingMusicScore = data.isLoadingMusicScore;
          this.isLoadingRecording = data.isLoadingRecording;
          this.isUpdatingMultipleTracks = data.isUpdatingRecording;

          if (data.recording) {
            if (data.recording.renderFinished) {
              this.stopPollingRenderUpdates();
            } else {
              this.startPollingRenderUpdates();
            }
          }
        }),

      this.store
        .select(EditMusicPieceSelectors.selectEditMusicPieceData)
        .subscribe((data) => {
          this.musicPiece = data.musicPiece;
        }),
      this.store
        .select(LicenseSelectors.selectUserLicense)
        .subscribe((userLicense) => {
          this.canSetupAudioTracks = userLicense.license.canSetupAudioTracks;
        }),
    );
  }

  ngOnDestroy(): void {
    this.stopPollingRenderUpdates();
    this.subscriptions.unsubscribeAll();
  }

  getOrCreateMusicScoreIn(): CreateOrGetDocument {
    return () => {
      if (this.musicScore == EmptyMusicScore) {
        const obs = this.musicScoreService
          .createMusicScore(this.musicPiece?.name ?? "") // should never be empty
          .pipe(
            tap((musicScore) => {
              this.store.dispatch(
                EditMusicPieceActions.createdMusicScore({
                  musicScore: musicScore,
                }),
              );
            }),
          );
        return firstValueFrom(obs);
      } else {
        return Promise.resolve(this.musicScore);
      }
    };
  }

  prepareMusicScoreUpload(): PrepareUpload {
    return (musicScore: string) => {
      return this.musicScoreService.prepareMusicScoreUpload(musicScore);
    };
  }

  uploadChanged(result: AttachmentUploadResult) {
    if (result) {
      if (result.result == "Success") {
        this.toastrService.success(this.translate.instant("common.success"));
      } else {
        console.log(`upload failed: ${result?.error}`);
        this.toastrService.error(this.translate.instant("common.fail"));
      }
    }
  }

  finishMusicScoreUpload(): FinishUpload {
    return (name) => {
      return this.musicScoreService.markUploadAsFinished(name);
    };
  }

  startPollingRenderUpdates() {
    if (!this.pollingTimeout) {
      this.pollingTimeout = window.setInterval(() => {
        this.store.dispatch(EditMusicPieceActions.checkIfRenderIsFinished());
      }, 2000);
    }
  }

  stopPollingRenderUpdates() {
    if (this.pollingTimeout) {
      window.clearTimeout(this.pollingTimeout);
    }
  }

  uploadMusicScoreInProgress(isUploading: boolean) {
    this.isUploadingMusicScore = isUploading;
  }

  private showDeleteDialog(): Observable<boolean> {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title: "edit-music-piece.music-piece-uploads.delete-dialog.title",
        content: "edit-music-piece.music-piece-uploads.delete-dialog.content",
      },
    });

    return ref.afterClosed().pipe(map((r) => r == true));
  }

  deleteMusicScore() {
    this.showDeleteDialog().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditMusicPieceActions.deleteMusicScore({
            musicScore: this.musicScore,
          }),
        );
      }
    });
  }

  deleteRecording() {
    this.showDeleteDialog().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditMusicPieceActions.deleteRecording({
            recording: this.recording,
          }),
        );
        this.recording = EmptyRecording;
        this.isUploadingRecording = false;
      }
    });
  }

  handleIsUploadingRecordingChange(isUploading: boolean) {
    this.isUploadingRecording = isUploading;
  }

  deleteThumbnail() {
    this.showDeleteDialog().subscribe(async (result) => {
      if (result && this.musicPiece) {
        await this.musicPieceService.deleteProfilePicture(this.musicPiece.name);
        this.store.dispatch(
          EditMusicPieceActions.deletedThumbnail({
            musicPiece: this.musicPiece,
          }),
        );
      }
    });
  }

  prepareThumbnailUploadUrl() {
    return (name: string) => this.musicPieceService.prepareUploadUrl(name);
  }

  finishThumbnailUpload() {
    return (name: string) => {
      return this.musicPieceService.markUploadAsFinished(name);
    };
  }

  handleThumbnailUploadChange(result: AttachmentUploadResult) {
    if (result) {
      if (result.result == "Success") {
        this.toastrService.success(this.translate.instant("common.success"));

        if (this.musicPiece) {
          this.store.dispatch(
            EditMusicPieceActions.thumbnailUploadFinished({
              musicPiece: this.musicPiece!,
            }),
          );
        }
      } else {
        this.toastrService.error(this.translate.instant("common.fail"));
      }
    }
  }

  uploadThumbnail() {
    if (this.musicPiece) {
      const uploadInProgress = new Subject<boolean>();
      const uploadChanged = new Subject<AttachmentUploadResult>();

      uploadInProgress.subscribe((e) => (this.isUploadingThumbnail = e));
      uploadChanged.subscribe((e) => this.handleThumbnailUploadChange(e));

      const getMusicPiece = () => Promise.resolve(this.musicPiece!);

      const ref = this.matDialog.open<
        ImageUploadDialogComponent,
        ImageUploadDialogData,
        ImageUploadDialogResult
      >(ImageUploadDialogComponent, {
        data: {
          height: (500 * 9) / 16,
          width: 500,
          accept: "image/png,image/jpeg",
          prepareUpload: this.prepareThumbnailUploadUrl(),
          finishUpload: this.finishThumbnailUpload(),
          createOrGetDocument: getMusicPiece,
          uploadChange: uploadChanged,
          uploadInProgress: uploadInProgress,
        },
        maxHeight: "80vh",
        minWidth: "20vw",
        maxWidth: "80vw",
      });

      const subscription = ref.afterClosed().subscribe((result) => {
        uploadChanged.unsubscribe();
        uploadInProgress.unsubscribe();
        subscription.unsubscribe();
      });
    }
  }

  setMultipleAudioTracks(enable: boolean) {
    this.isUpdatingMultipleTracks = true;

    this.store.dispatch(
      EditMusicPieceActions.updateRecordingUsesMultipleTracks({
        recording: this.recording.name,
        enableMultipleTracks: enable,
      }),
    );
  }
}
