import { CommonModule } from "@angular/common";
import { Component, computed, OnDestroy, OnInit, signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { FaqItem } from "src/app/models/faq/faq-item";
import { FaqActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { FaqSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { FaqItemComponent } from "../faq-item/faq-item.component";
import { FeedbackComponent } from "../../user/feedback/feedback.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-faq",
  templateUrl: "./faq.component.html",
  standalone: true,
  imports: [CommonModule, MaterialModule, FaqItemComponent, FeedbackComponent],
})
export class FaqComponent implements OnInit, OnDestroy {
  isLoading = true;
  private items = signal<FaqItem[]>([]);

  itemsGroupedByTags = computed(() => {
    const items = this.items();

    const result = new Map<string, FaqItem[]>();

    items.forEach((i) => {
      i.tags.forEach((tag) => {
        const existingFaqs = result.get(tag) || [];
        result.set(tag, [...existingFaqs, i]);
      });
    });

    return result;
  });

  sortedTags = computed(() => {
    const keys = Array.from(this.itemsGroupedByTags().keys());
    keys.sort((a, b) => a.localeCompare(b));
    return keys;
  });

  private readonly subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(FaqActions.load());
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(FaqSelectors.searchResults).subscribe((r) => {
        this.items.set(r.searchResults);
        this.isLoading = r.isLoadingSearchResults;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  search(query: string) {
    this.store.dispatch(FaqActions.search({ query: query }));
  }
}
