import { Injectable } from "@angular/core";
import { MetronomeDto } from "src/app/dtos/metronome/metronome-dto";
import { Metronome } from "src/app/models/metronome/metronome";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class MetronomeDtoToMetronomeConversion
  implements Conversion<MetronomeDto, Metronome>
{
  convert(item: MetronomeDto): Metronome {
    return {
      name: item.name,
      musicPiece: item.music_piece,
      nameOfMetronome: item.name_of_metronome,
      endValue: item.end_value,
      startValue: item.start_value,
      bpm: item.bpm,
    };
  }
}
