import { Component, OnInit } from "@angular/core";
import { PurchasesComponent } from "../purchases/purchases.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-purchases-page",
  templateUrl: "./purchases-page.component.html",
  styleUrls: ["./purchases-page.component.scss"],
  standalone: true,
  imports: [PurchasesComponent],
})
export class PurchasesPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
