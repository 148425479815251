import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";

@Injectable({
  providedIn: "root",
})
export class ArtistProfileLinkService {
  constructor(private readonly router: Router) {}

  goToArtist(artist: StoredArtistProfile) {
    if (artist.customUrl) {
      this.router.navigate([`/@${artist.customUrl}`]);
    } else {
      this.router.navigate(["/artists", artist]);
    }
  }
}
