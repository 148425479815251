import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { SalesActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { SalesComponent } from "../sales/sales.component";
import { CreditStatementsComponent } from "../credit-statements/credit-statements.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-sales-page",
  templateUrl: "./sales-page.component.html",
  styleUrls: ["./sales-page.component.scss"],
  standalone: true,
  imports: [SalesComponent, CreditStatementsComponent],
})
export class SalesPageComponent implements OnInit {
  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(SalesActions.loadSales());
    this.store.dispatch(SalesActions.loadCreditStatements());
  }
}
