<div class="container" fxLayout="column">
  <input
    type="file"
    (change)="fileChangeEvent($event)"
    [accept]="accept"
    *ngIf="!cropped && !imageLoaded"
  />

  <image-cropper
    *ngIf="!cropped"
    format="png"
    output="blob"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="width / height"
    [resizeToWidth]="width"
    [resizeToHeight]="height"
    [autoCrop]="false"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="handleImageLoaded()"
  ></image-cropper>

  <div fxLayout="row" fxLayoutAlign="end">
    <button
      mat-icon-button
      color="primary"
      (click)="crop()"
      *ngIf="imageLoaded && !cropped && !hideControls"
      [disabled]="isUploading"
    >
      <mat-icon>done</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="reset()"
      *ngIf="imageLoaded && !cropped && !hideControls"
      [disabled]="isUploading"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    *ngIf="isUploading"
    [value]="progressPercentage"
  ></mat-progress-bar>
  <div class="uploadSuccess" *ngIf="isUploadFinishedSuccessfully">
    <mat-icon class="sucessIcon" color="primary">done</mat-icon>
  </div>
</div>
