import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageUploadDialogData } from "src/app/models/common/image-upload-dialog-data";
import { ImageUploadDialogResult } from "src/app/models/common/image-upoload-dialog-result";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { ImageUploadComponent } from "../image-upload/image-upload.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-image-upload-dialog",
  templateUrl: "./image-upload-dialog.component.html",
  styleUrls: ["./image-upload-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ImageUploadComponent,
    MaterialModule,
  ],
})
export class ImageUploadDialogComponent {
  width: number = 400;
  height: number = 400;
  accept: string = "image/*";
  imageSelected: boolean = false;
  isUploading: boolean = false;

  @ViewChild(ImageUploadComponent)
  imageUploadComponent?: ImageUploadComponent;

  constructor(
    private readonly dialogRef: MatDialogRef<
      ImageUploadDialogComponent,
      ImageUploadDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public readonly data: ImageUploadDialogData,
  ) {
    this.width = data.width ?? this.width;
    this.height = data.height ?? this.height;
    this.accept = data.accept ?? this.accept;
  }

  handleUploadChange(event: AttachmentUploadResult) {
    this.data.uploadChange?.next(event);

    if (event.result == "Success" || event.result == "Failure") {
      this.dialogRef.close({});
    }
  }

  handleUploadInProgress(event: boolean) {
    this.isUploading = event;
    this.data.uploadInProgress?.next(event);
  }

  handleImageSelected(event: boolean) {
    this.imageSelected = event;
  }

  handleClose() {
    this.dialogRef.close();
  }

  handleCrop() {
    this.imageUploadComponent?.crop();
  }

  handleReset() {
    this.imageUploadComponent?.reset();
  }
}
