<div *ngIf="artistProfile" class="flex flex-col items-center">
  <button
    mat-button
    (click)="uploadProfilePicture()"
    *ngIf="
      isOwner &&
      isInEditMode &&
      (!artistProfile.uploadFinished || isUploadingProfilePicture)
    "
  >
    {{ "artist.artist-profile.upload-profile-image" | translate }}
  </button>

  <img
    class="aspect-square w-64 rounded-full object-cover"
    [alt]="artistProfile.artistName"
    *ngIf="artistProfile.uploadFinished"
    [src]="artistProfile.downloadLink"
  />
  <img
    src="assets/logo/logo-hands-only.png"
    alt="profile picture"
    class="aspect-square w-64 rounded-full object-cover"
    *ngIf="!artistProfile.uploadFinished && !isInEditMode"
  />
  <button
    mat-icon-button
    *ngIf="isOwner && isInEditMode && artistProfile.uploadFinished"
    (click)="deleteProfilePicture()"
    color="warn"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>

<!-- skeleton -->
<div
  *ngIf="!artistProfile"
  class="aspect-square w-64 animate-pulse rounded-full bg-gray-200"
></div>
