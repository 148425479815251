import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AddCollectionItemDialogData } from "src/app/models/collection/add-collection-item-dialog-data";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { AppState } from "src/app/ngrx/reducers";
import {
  EditCollectionSelectors,
  LicenseSelectors,
  MusicPieceSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { AddCollectionItemDialogComponent } from "../add-collection-item-dialog/add-collection-item-dialog.component";
import { AddCollectionItemDialogResult } from "src/app/models/collection/add-collection-item-dialog-result";
import { EditCollectionActions, PlayerActions } from "src/app/ngrx/actions";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { RemoveCollectionItemDialogComponent } from "../remove-collection-item-dialog/remove-collection-item-dialog.component";
import { RemoveCollectionItemDialogData } from "src/app/models/collection/remove-collection-item-dialog-data";
import { RemoveCollectionItemDialogResult } from "src/app/models/collection/remove-collection-item-dialog-result";
import { EditCollectionItemsDialogComponent } from "../edit-collection-items-dialog/edit-collection-items-dialog.component";
import { EditCollectionItemDialogData } from "src/app/models/collection/edit-collection-items-dialog-data";
import { EditCollectionItemsDialogResult } from "src/app/models/collection/edit-collection-items-dialog-result";
import { Router } from "@angular/router";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { Preview } from "src/app/models/music-piece/preview";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPiecesControlsComponent } from "../../music-piece/music-pieces-controls/music-pieces-controls.component";
import { MusicPieceCardsComponent } from "../../music-piece/music-piece-cards/music-piece-cards.component";
import { MusicPiecesTableComponent } from "../../music-piece/music-pieces-table/music-pieces-table.component";
import { MaterialModule } from "src/app/modules/material.module";
import { PracticeIntervalCollectionItemDialogComponent } from "../practice-interval-collection-item-dialog/practice-interval-collection-item-dialog.component";

@Component({
  selector: "yr-edit-collection-items",
  templateUrl: "./edit-collection-items.component.html",
  styleUrls: ["./edit-collection-items.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    MusicPiecesControlsComponent,
    MusicPieceCardsComponent,
    MusicPiecesTableComponent,
  ],
})
export class EditCollectionItemsComponent implements OnInit, OnDestroy {
  isLoadingCollectionItems: boolean = false;
  isNew: boolean = false;

  displayType: "list" | "cards" = "cards";

  collection?: StoredCollection;
  collectionItems: StoredCollectionItem[] = [];

  private readonly subscriptionService = new SubscriptionService();

  musicPieces: MusicPiece[] = []; // required for remove for remove and edit
  linkedMusicPieces: LinkedMusicPieceWithMusicPiece[] = []; // required for remove and edit

  filteredMusicPieces: MusicPieceToDisplay[] = [];

  hasPracticeDurationLeft = false;

  previews: Preview[] = [];
  linkedMusicPiecePreviews: Preview[] = [];
  licensedCollectionItemsPerCollection: number = 0;

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionItems)
        .subscribe((data) => {
          this.isLoadingCollectionItems = data.isLoadingCollectionItems;
          this.isNew = data.isNew;
          this.collectionItems = data.collectionItems;
          this.collection = data.collection;
          this.linkedMusicPieces = data.linkedMusicPieces;
          this.musicPieces = data.musicPieces;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectFilteredCollectionItems)
        .subscribe((items) => {
          this.filteredMusicPieces = items.filteredCollectionItems.map(
            (i) => i.musicPiece,
          );
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(MusicPieceSelectors.selectPreviews)
        .subscribe((previews) => {
          this.previews = previews;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(MusicPieceSelectors.selectLinkedMusicPiecePreviews)
        .subscribe((previews) => {
          this.linkedMusicPiecePreviews = previews;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(UsedResourcesSelectors.hasPracticeDurationLeft)
        .subscribe((data) => {
          this.hasPracticeDurationLeft = data.hasPracticeDurationLeft;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(LicenseSelectors.selectUserLicense)
        .subscribe((userLicense) => {
          this.licensedCollectionItemsPerCollection =
            userLicense.license.collectionItems == "unlimited"
              ? Number.MAX_VALUE
              : userLicense.license.collectionItems;
        }),
    );
  }

  addCollectionItem() {
    const dialog = this.matDialog.open<
      AddCollectionItemDialogComponent,
      AddCollectionItemDialogData,
      AddCollectionItemDialogResult
    >(AddCollectionItemDialogComponent, {
      data: {
        existingCollectionItems: this.collectionItems,
        acceptedShareTerms: this.collection?.acceptShareTerms || false,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result && this.collection) {
        this.store.dispatch(
          EditCollectionActions.addCollectionItem({
            collection: this.collection,
            collectionItemToAdd: result.itemToAdd,
            order: this.collectionItems.length,
          }),
        );
      }
    });
  }
  removeCollectionItems() {
    const dialog = this.matDialog.open<
      RemoveCollectionItemDialogComponent,
      RemoveCollectionItemDialogData,
      RemoveCollectionItemDialogResult
    >(RemoveCollectionItemDialogComponent, {
      data: {
        existingCollectionItems: this.collectionItems,
        linkedMusicPieces: this.linkedMusicPieces,
        musicPieces: this.musicPieces,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditCollectionActions.removeCollectionItem({
            collectionItemToRemove: result.itemToRemove,
          }),
        );
      }
    });
  }

  editCollectionItems() {
    const dialog = this.matDialog.open<
      EditCollectionItemsDialogComponent,
      EditCollectionItemDialogData,
      EditCollectionItemsDialogResult
    >(EditCollectionItemsDialogComponent, {
      data: {
        existingCollectionItems: this.collectionItems,
        linkedMusicPieces: this.linkedMusicPieces,
        musicPieces: this.musicPieces,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditCollectionActions.editCollectionItems({
            updateCollectionItems: result.updatedCollectionItems,
          }),
        );
      }
    });
  }

  assignPracticeInterval() {
    const dialog = this.matDialog.open<
      PracticeIntervalCollectionItemDialogComponent,
      EditCollectionItemDialogData,
      EditCollectionItemsDialogResult
    >(PracticeIntervalCollectionItemDialogComponent, {
      data: {
        existingCollectionItems: this.collectionItems,
        linkedMusicPieces: this.linkedMusicPieces,
        musicPieces: this.musicPieces,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          EditCollectionActions.editCollectionItems({
            updateCollectionItems: result.updatedCollectionItems,
          }),
        );
      }
    });
  }

  applyFilter(filter: string) {
    this.store.dispatch(
      EditCollectionActions.filterCollectionItems({ filter }),
    );
  }

  changeDisplayType(type: "list" | "cards") {
    this.displayType = type;
  }

  editMusicPiece(musicPiece: MusicPiece) {
    this.router.navigate(["musicpiece", "edit", musicPiece.name]);
  }

  editLinkedMusicPiece(linkedMusicPiece: LinkedMusicPiece) {
    this.router.navigate(["musicpiece", "edit-share", linkedMusicPiece.name]);
  }

  navigateToPlayer(pair: LinkedMusicPieceWithMusicPiece) {
    if (!this.collection) return;

    this.store.dispatch(
      PlayerActions.musicPieceLoaded({
        musicPiece: pair.musicPiece,
        linkedMusicPiece: pair.linkedMusicPiece,
      }),
    );

    if (pair.linkedMusicPiece) {
      this.router.navigate([
        "/pl",
        "collection",
        this.collection.name,
        "linked",
        pair.linkedMusicPiece.name,
      ]);
    } else {
      this.router.navigate([
        "/pl",
        "collection",
        this.collection.name,
        "player",
        pair.musicPiece.name,
      ]);
    }
  }
}
