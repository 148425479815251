<div class="mx-2 mb-2">
  <form [formGroup]="searchForm">
    <div class="flex flex-row items-center">
      <input
        class="h-10 flex-grow rounded px-2 text-base text-zinc-700 caret-as-700 outline-none placeholder:text-base placeholder:text-zinc-500 sm:flex-none"
        type="text"
        id="filter"
        [placeholder]="'search.search.search' | translate"
        [formControl]="searchQuery"
      />

      <mat-spinner [diameter]="24" *ngIf="isLoadingSearchResults"></mat-spinner>
    </div>
  </form>
</div>
