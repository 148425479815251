<div>
  <mat-progress-bar
    *ngIf="isLoadingCollections"
    mode="indeterminate"
  ></mat-progress-bar>

  <div class="collection-items mx-2">
    <div class="w-full" *ngFor="let displayCollection of displayedCollections">
      <yr-collection-item
        [collection]="displayCollection.collection"
        [linkedCollection]="displayCollection.linkedCollection"
        (editCollection)="handleEditCollection($event)"
        (openLinkedCollection)="
          handleOpenCollection($event.collection, $event.linkedCollection)
        "
      ></yr-collection-item>
    </div>
  </div>
</div>

<yr-no-items-indicator
  *ngIf="displayedCollections.length == 0 && !isLoadingCollections"
  icon="video_library"
  text="{{ 'collection.collections.no-collections-hint' | translate }}"
></yr-no-items-indicator>
