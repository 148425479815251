<yr-tool-bar-second-row>
  <div class="mx-auto w-full md:w-4/5">
    <yr-mark-as-read></yr-mark-as-read>
  </div>
</yr-tool-bar-second-row>
<div class="mx-auto mt-2 w-full md:w-4/5">
  <div class="mx-4">
    <yr-notification-list></yr-notification-list>
    <yr-notification-settings></yr-notification-settings>
  </div>
</div>
