import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CollectionService } from "src/app/services/collection/collection.service";
import { AppState } from "../reducers";
import { Store } from "@ngrx/store";
import {
  EditCollectionActions,
  MusicPieceActions,
  ReadonlyCollectionActions,
} from "../actions";
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { MusicPieceSelectors, ReadonlyCollectionSelectors } from "../selectors";
import {
  CollectionItemToDisplayHelper,
  LinkedCollectionItemToDisplay,
} from "src/app/models/collection/collection-item-to-display";
import { MusicPieceFilterService } from "src/app/services/music-piece/music-piece-filter.service";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ReadonlyCollectionEffects {
  loadCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.open),
      mergeMap((action) => {
        return this.collectionService
          .loadLinkedCollection(action.linkedCollection)
          .pipe(
            mergeMap((linkedCollection) =>
              this.collectionService
                .loadCollection(linkedCollection.collection)
                .pipe(
                  map((collection) =>
                    ReadonlyCollectionActions.loadedReadonlyCollection({
                      collection: collection,
                      linkedCollection: linkedCollection,
                    }),
                  ),
                ),
            ),
          );
      }),
    ),
  );

  loadMusicPiecesForCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.open),
      withLatestFrom(
        this.store.select(
          MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
        ),
      ),
      filter(
        ([_, data]) =>
          // we might load too often in case the user has no linked music pieces and no music pieces, but that's ok
          data.musicPieces.length == 0 && data.linkedMusicPieces.length == 0,
      ),
      map((_) => MusicPieceActions.loadMusicPieces()),
    ),
  );

  triggerLoadCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.loadedReadonlyCollection),
      map((action) =>
        ReadonlyCollectionActions.loadCollectionItems({
          linkedCollection: action.linkedCollection,
        }),
      ),
    ),
  );

  loadCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.loadCollectionItems),
      mergeMap((action) =>
        this.collectionService
          .loadLinkedCollectionItemsForCollection(action.linkedCollection.name)
          .pipe(
            map((data) =>
              ReadonlyCollectionActions.loadedCollectionItems({
                linkedCollectionItems: data,
              }),
            ),
          ),
      ),
    ),
  );

  resetFilterCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ReadonlyCollectionActions.loadedCollectionItems,
        MusicPieceActions.loadedMusicPieces,
        MusicPieceActions.loadedLinkedMusicPieces,
      ),
      map(() =>
        ReadonlyCollectionActions.filterCollectionItems({ filter: "" }),
      ),
    ),
  );

  filterCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.filterCollectionItems),
      debounceTime(50),
      withLatestFrom(
        this.store.select(
          ReadonlyCollectionSelectors.selectReadonlyCollectionItems,
        ),
      ),
      map(([action, state]) => {
        const collectionItemsToDisplay =
          CollectionItemToDisplayHelper.fromLinkedCollectionItems(
            state.collectionItems,
            state.linkedMusicPieces,
          );

        const selector = (i: LinkedCollectionItemToDisplay) => i.musicPiece;
        const filtered =
          this.musicPieceFilterService.filterByMusicPieceToDisplay(
            action.filter,
            collectionItemsToDisplay,
            selector,
          );
        const filteredAndSorted = filtered.sort(
          (a, b) => a.collectionItem.order - b.collectionItem.order,
        );
        return ReadonlyCollectionActions.filteredCollectionItems({
          filteredCollectionItems: filteredAndSorted,
        });
      }),
    ),
  );

  deleteCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.deleteCollection),
      mergeMap((action) =>
        this.collectionService.deleteCollection(action.collection.name).pipe(
          map((_) => ReadonlyCollectionActions.deletedCollection()),
          catchError((error) =>
            of(ReadonlyCollectionActions.deletionFailed(error)),
          ),
        ),
      ),
    ),
  );

  deleteLinkedCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.deleteLinkedCollection),
      mergeMap((action) =>
        this.collectionService
          .deleteLinkedCollection(action.linkedCollection.name)
          .pipe(
            map((_) => ReadonlyCollectionActions.deletedLinkedCollection()),
            catchError((error) =>
              of(ReadonlyCollectionActions.deleteLinkedFailed(error)),
            ),
          ),
      ),
    ),
  );

  navigateBackAfterSuccessfulDeletion$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ReadonlyCollectionActions.deletedCollection,
          ReadonlyCollectionActions.deletedLinkedCollection,
        ),
        tap((_) => this.router.navigate(["collection"])),
      ),
    { dispatch: false },
  );

  successNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ReadonlyCollectionActions.deletedCollection,
          ReadonlyCollectionActions.deletedLinkedCollection,
          ReadonlyCollectionActions.updatedCollection,
          ReadonlyCollectionActions.updatedLinkedCollection,
          ReadonlyCollectionActions.editedLinkedCollectionItems,
        ),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  failureNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ReadonlyCollectionActions.deletionFailed,
          ReadonlyCollectionActions.deleteLinkedFailed,
          ReadonlyCollectionActions.editLinkedCollectionItemsFailed,
        ),
        tap((action) => {
          console.error(action.error);
          this.toastr.error(this.translate.instant("common.fail"));
        }),
      ),
    { dispatch: false },
  );

  updateLinkedCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.updateLinkedCollection),
      mergeMap((action) =>
        this.collectionService
          .updateLinkedCollection(action.name, action.isPracticeRoutine)
          .pipe(
            map((linkedCollection) =>
              ReadonlyCollectionActions.updatedLinkedCollection({
                collection: linkedCollection,
              }),
            ),
          ),
      ),
    ),
  );

  updateCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.updateCollection),
      mergeMap((action) =>
        this.collectionService
          .updateCollection(
            action.name,
            action.title,
            action.instrument,
            action.temperament,
            action.artist,
            action.description,
            action.acceptShareTerms,
            action.isPracticeRoutine,
          )
          .pipe(
            map((collection) =>
              ReadonlyCollectionActions.updatedCollection({
                collection: collection,
              }),
            ),
          ),
      ),
    ),
  );

  updateLinkedCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReadonlyCollectionActions.editLinkedCollectionItems),
      mergeMap((action) =>
        this.collectionService
          .updateLinkedCollectionItems(action.updateCollectionItems)
          .pipe(
            map((result) =>
              ReadonlyCollectionActions.editedLinkedCollectionItems({
                updatedCollectionItems: result,
              }),
            ),
            catchError((error) =>
              of(
                ReadonlyCollectionActions.editLinkedCollectionItemsFailed({
                  error: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly collectionService: CollectionService,
    private readonly store: Store<AppState>,
    private readonly musicPieceFilterService: MusicPieceFilterService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
  ) {}
}
