import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { withLatestFrom, mergeMap, map } from "rxjs/operators";
import { RotationService } from "src/app/services/rotation/rotation.service";
import { PlayerActions, RotationActions } from "../actions";
import { AppState } from "../reducers";

@Injectable()
export class RotationEffects {
  loadRotationsWhenRecordingIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.recordingLoaded),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) =>
        this.rotationService.loadRotation(
          action.recording.name,
          state.linkedMusicPiece
        )
      ),
      map((rotation) => RotationActions.loadedRotation({ rotation: rotation }))
    )
  );

  storeRotation$ = createEffect(() =>
    this.actions.pipe(
      ofType(RotationActions.storeRotation),
      mergeMap((action) => this.rotationService.storeRotation(action.rotation)),
      map((rotation) => RotationActions.storedRotation({ rotation: rotation }))
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly rotationService: RotationService
  ) {}
}
