export class UrlSanitizer {
  private tmp = "";

  addUrlPart(part: string): UrlSanitizer {
    if (part) {
      if (this.tmp.endsWith("/")) {
        this.tmp = this.tmp.substr(0, this.tmp.length - 1);
      }

      if (part.startsWith("/")) {
        part = part.substring(1);
      }

      this.tmp = this.tmp.length > 0 ? this.tmp + "/" + part : part;
    }
    return this;
  }

  url(): string {
    return this.tmp;
  }
}
