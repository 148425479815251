import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CollectionActions, EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { EditMusicPieceComponent } from "../edit-music-piece/edit-music-piece.component";
import { MusicPieceUploadsComponent } from "../music-piece-uploads/music-piece-uploads.component";
import { ShareMusicPieceComponent } from "../share-music-piece/share-music-piece.component";
import { DeleteMusicPieceComponent } from "../delete-music-piece/delete-music-piece.component";
import { CommonModule } from "@angular/common";

@Component({
  selector: "yr-edit-music-piece-page",
  templateUrl: "./edit-music-piece-page.component.html",
  styleUrls: ["./edit-music-piece-page.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    EditMusicPieceComponent,
    MusicPieceUploadsComponent,
    ShareMusicPieceComponent,
    DeleteMusicPieceComponent,
  ],
})
export class EditMusicPiecePageComponent implements OnInit {
  isNew: boolean = false;
  isLinkedMusicPiece: boolean = false;
  name?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.handleRoutes();
  }

  private handleRoutes() {
    const routeData = this.activatedRoute.snapshot.data;

    this.name = this.activatedRoute.snapshot.paramMap.get("name") || "";
    this.isLinkedMusicPiece = routeData && routeData.isLinkedMusicPiece;
    this.isNew = routeData && routeData.isNew;

    this.store.dispatch(
      EditMusicPieceActions.openMusicPieceForEdit({
        isLinkedMusicPiece: this.isLinkedMusicPiece,
        isNew: this.isNew,
        name: this.name,
      }),
    );

    this.store.dispatch(CollectionActions.loadLinkedCollectionItems());
  }
}
