import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FaqItem } from "src/app/models/faq/faq-item";
import { MatomoTracker } from "ngx-matomo-client";
import {
  FrappeApiHelper,
  FrappeRequestListOptions,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";

@Injectable({
  providedIn: "root",
})
export class FaqService {
  private readonly faqItemMapper = mappingBuilderWithDefaultMappings<FaqItem>(
    "FAQ",
    [
      mappingHelper.booleanMapper("visible"),
      mappingHelper.stringMapper("faq"),
      {
        frappeName: "_user_tags",
        name: "tags",
        map: (frappe) => {
          const tags = ((frappe as string | undefined) || "")
            .split(",")
            .filter((tag) => tag.length > 0);

          if (tags.length > 0) {
            return tags;
          } else {
            return ["Allgemein"]; // dummy
          }
        },
        mapToFrappe: (tags) => {
          throw "can't save tags";
        },
      },
    ],
  );

  constructor(
    private readonly helper: FrappeApiHelper,
    private readonly tracker: MatomoTracker,
  ) {}

  load(): Observable<FaqItem[]> {
    return this.helper.getList(
      this.faqItemMapper,
      new FrappeRequestListOptions()
        .withPageLength(0)
        .withOrderBy("creation", "desc"),
    );
  }

  search(query: string, items: FaqItem[]): Observable<FaqItem[]> {
    return of(items).pipe(
      map((items) =>
        items.filter(
          (i) =>
            i.faq.toLowerCase().includes(query.toLowerCase()) ||
            i.tags.join(",").toLowerCase().includes(query.toLowerCase()),
        ),
      ),
      tap((_) => {
        if (query && query.length > 0) {
          this.tracker.trackEvent("faq", "search", query);
        }
      }),
    );
  }
}
