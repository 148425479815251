import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyPipe } from "@angular/common";
import { __values } from "tslib";

@Pipe({
  name: "price",
  standalone: true,
})
export class PricePipe implements PipeTransform {
  constructor(
    private readonly translationService: TranslateService,
    private readonly currencyPipe: CurrencyPipe,
  ) {}

  transform(price: number | undefined): string | null | undefined {
    if (price !== undefined) {
      if (price === 0) {
        return this.translationService.instant("common.free");
      } else {
        return this.currencyPipe.transform(price);
      }
    }
    return;
  }
}
