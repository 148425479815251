<form (submit)="submit()" [formGroup]="form">
  <div class="my-2 truncate rounded bg-gray-50 p-2">
    <div class="flex flex-col gap-2 md:flex-row">
      <div>
        <mat-form-field>
          <mat-label>{{
            "artist.artist-profile-events-form.date" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="picker" [formControl]="date" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="date.hasError('required')">
            {{ "errors.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-grow flex-col">
        <mat-form-field>
          <mat-label>{{
            "artist.artist-profile-events-form.title" | translate
          }}</mat-label>
          <input
            matInput
            [formControl]="title"
            type="text"
            [placeholder]="
              'artist.artist-profile-events-form.title' | translate
            "
          />
          <mat-error *ngIf="title.hasError('required')">
            {{ "errors.required" | translate }}
          </mat-error>
          <mat-error *ngIf="title.hasError('maxlength')">
            {{ "errors.max-length" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>
            {{ "artist.artist-profile-events-form.description" | translate }}
          </mat-label>
          <textarea
            matInput
            [formControl]="description"
            [placeholder]="
              'artist.artist-profile-events-form.description' | translate
            "
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            maxlength="2000"
          ></textarea>
          <mat-error *ngIf="description.hasError('maxlength')">
            {{ "errors.max-length" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            "artist.artist-profile-events-form.url" | translate
          }}</mat-label>
          <input
            matInput
            [formControl]="url"
            type="url"
            [placeholder]="'artist.artist-profile-events-form.url' | translate"
          />
          <mat-error *ngIf="url.hasError('link')">
            {{ "errors.invalid-url" | translate }}
          </mat-error>
          <mat-error *ngIf="url.hasError('maxlength')">
            {{ "errors.max-length" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <button mat-icon-button type="submit" [disabled]="form.invalid">
        <mat-icon>done</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        *ngIf="canDelete"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</form>
