import { MusicPieceListingDto } from "src/app/dtos/music-piece/music-piece-listing-dto";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class MusicPieceListingDtoConversion
  implements Conversion<MusicPieceListingDto, MusicPieceListing>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: MusicPieceListingDto): MusicPieceListing {
    return {
      name: dto.name,
      creation: this.us.parseApiDate(dto.creation),
      musicPiece: dto.music_piece,
      musicPieceName: dto.music_piece_name,
      price: dto.price,
      artist: dto.artist,
      artistName: dto.artist_name,
      collection: dto.collection,
      description: dto.description,
      instrument: dto.instrument,
      musicPieceArtist: dto.music_piece_artist,
      temperament: dto.temperament,
      artistProfilePictureDownloadLink:
        dto.artist_profile_picture_download_link,
      musicPieceThumbnailDownloadLink: dto.music_piece_thumbnail_download_link,
      musicPieceThumbnailUploadFinished:
        dto.music_piece_thumbnail_upload_finished > 0,
    };
  }
}
