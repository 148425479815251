import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  withLatestFrom,
  filter,
  mergeMap,
  map,
  tap,
  debounceTime,
} from "rxjs/operators";
import { selectMarkerInEditModeWithSelectedRecordingPartGroup } from "../reducers/marker.reducer";
import { MarkerService } from "src/app/services/player/marker.service";
import { MarkerActions, RecordingPartGroupActions } from "../actions";
import { AppState } from "../reducers";

@Injectable()
export class MarkerEffects {
  addMarker$ = createEffect(() =>
    this.actions.pipe(
      ofType(MarkerActions.addMarker),
      withLatestFrom(
        this.store.select(
          (s) => s.recordingPartGroups.selectedRecordingPartGroup
        )
      ),
      filter(([_, selectedGroup]) => selectedGroup !== undefined),
      mergeMap(([action, selectedGroup]) =>
        this.markerService.addMarker(selectedGroup!, action.marker)
      ),
      map((addedMarker) => MarkerActions.addedMarker({ marker: addedMarker }))
    )
  );

  loadMarkersForSelectedRecordingPartGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.selectRecordingPartGroup),
      filter((action) => action.recordingPartGroup !== undefined),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) => {
        return this.markerService.loadMarkers(
          action.recordingPartGroup!,
          state.linkedMusicPiece
        );
      }),
      map((markers) => MarkerActions.markersLoaded({ markers: markers }))
    )
  );

  deleteMarker$ = createEffect(() =>
    this.actions.pipe(
      ofType(MarkerActions.deleteMarker),
      mergeMap((action) =>
        this.markerService
          .deleteMarker(action.marker)
          .pipe(map((_) => action.marker))
      ),
      map((deletedMarker) =>
        MarkerActions.deletedMarker({ marker: deletedMarker })
      )
    )
  );

  updateMarker$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MarkerActions.moveMarker),
        debounceTime(250),
        withLatestFrom(
          this.store.select(
            selectMarkerInEditModeWithSelectedRecordingPartGroup
          )
        ),
        filter(
          ([_, pair]) =>
            pair.marker != undefined &&
            pair.selectedRecordingPartGroup != undefined
        ),
        tap(([_, pair]) => {
          console.log(`marker: ms: ${pair.marker?.timestampMs}`);
          this.markerService
            .updateMarker(pair.selectedRecordingPartGroup!, pair.marker!)
            .subscribe(
              (_) => console.log("updated marker successfully"),
              (error) => console.error(`update marker failed: ${error}`)
            );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly markerService: MarkerService
  ) {}
}
