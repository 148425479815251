export interface UserData {
  email: string;
  sid: string;
  username: string;
  apiKey: string;
  apiSecret: string;
  roles: string[];
  validUntil: Date;
  language: string;
}

export const EmptyUserData: UserData = {
  apiKey: "",
  apiSecret: "",
  email: "",
  sid: "",
  username: "",
  roles: [],
  validUntil: new Date(0),
  language: "",
};
