<yr-tool-bar-second-row>
  <div class="mx-auto w-full md:w-4/5">
    <yr-explore-search></yr-explore-search>
  </div>
</yr-tool-bar-second-row>

<div class="mx-auto mt-2 w-full md:w-4/5">
  <div class="mx-2">
    <yr-explore></yr-explore>
  </div>
</div>
