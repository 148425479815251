<div
  *ngIf="item()"
  class="flex flex-col items-center rounded-lg bg-white p-4 shadow hover:cursor-pointer"
  (click)="navigateToArtist()"
>
  <img
    class="aspect-square w-48 rounded-full object-cover"
    [alt]="item().artist.artistName"
    *ngIf="item().artist.uploadFinished"
    [src]="item().artist.downloadLink"
  />
  <img
    src="assets/logo/logo.png"
    class="aspect-square w-48 rounded-full object-cover"
    [alt]="item().artist.artistName"
    *ngIf="!item().artist.uploadFinished"
  />

  <span class="my-2 max-w-full truncate text-lg">
    {{ item().artist.artistName }}
  </span>
  <div class="flex flex-row text-gray-400">
    @if (item().artist.offersVideoFeedback) {
      <div
        class="tooltip flex flex-row items-center"
        [attr.data-tip]="
          'explore.explore-item.offers-video-feedback' | translate
        "
      >
        <div class="mx-3 flex flex-row items-center">
          <ng-icon
            name="lucideMessagesSquare"
            class="text-xl text-primary"
          ></ng-icon>
        </div>
      </div>
    }
    <div class="mx-3 flex flex-row items-center">
      <mat-icon>music_note</mat-icon>
      <span class="mx-1 text-lg">{{ item().listedMusicPieceCount }}</span>
    </div>
    <div class="mx-3 flex flex-row items-center">
      <mat-icon>video_library</mat-icon>
      <span class="mx-1 text-lg">{{ item().listedCollectionCount }}</span>
    </div>
  </div>
  <a mat-button [routerLink]="artistUrl()" class="mt-2" color="primary">
    {{ "explore.explore-item.navigate-to-partner" | translate }}
  </a>
</div>
