import { PreviewDto } from "src/app/dtos/music-piece/preview.dto";
import { Preview } from "src/app/models/music-piece/preview";
import { ExtendableConversion } from "../extendable-conversion";

export class PreviewDtoToPreviewConversion extends ExtendableConversion<
  PreviewDto,
  Preview
> {
  convert(item: PreviewDto): Preview {
    return {
      contentType: item.content_type,
      musicPiece: item.music_piece,
      url: item.url,
      mirrorHorizontal: item.mirror_horizontal > 0,
      mirrorVertical: item.mirror_vertical > 0,
      rotation: item.rotation as 0 | 90 | 180 | 270,
      thumbnailContentType: item.thumbnail_content_type,
      thumbnailDownloadLink: item.thumbnail_download_link,
      thumbnailRenderFinished: item.thumbnail_render_finished > 0,
    };
  }
}
