import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "../services/authentication/user.service";
import {
  ActivatedRoute,
  Router,
  RouterModule,
  RoutesRecognized,
} from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { LanguageService } from "../services/language/language.service";
import { SwPush, SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { AppState, UserSelectors } from "../ngrx/reducers";
import { Store } from "@ngrx/store";
import { tap, filter, map } from "rxjs/operators";
import {
  AppActions,
  NotificationActions,
  NotificationSettingsActions,
} from "../ngrx/actions";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { OfflineIndicatorComponent } from "./common/offline-indicator/offline-indicator.component";
import { UpgradeSubscriptionHintComponent } from "./user/upgrade-subscription-hint/upgrade-subscription-hint.component";
import { UserMenuComponent } from "./user/user-menu/user-menu.component";
import { MaterialModule } from "../modules/material.module";
import { NotificationMenuComponent } from "./notification/notification-menu/notification-menu.component";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideListTodo, lucideMessagesSquare } from "@ng-icons/lucide";

@Component({
  selector: "yr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OfflineIndicatorComponent,
    RouterModule,
    MaterialModule,
    UpgradeSubscriptionHintComponent,
    UserMenuComponent,
    NotificationMenuComponent,
    NgIconComponent,
  ],
  providers: [provideIcons({ lucideListTodo, lucideMessagesSquare })],
})
export class AppComponent implements OnInit {
  title = "applaus.schule";

  hideToolbar: boolean = false;
  showWerkbankToolbar: boolean = false;
  isLoggedIn: boolean = false;
  hasSecondRowToolbar: boolean = false;

  constructor(
    readonly userService: UserService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly languageService: LanguageService,
    private readonly updates: SwUpdate,
    private readonly store: Store<AppState>,
    private readonly pushService: SwPush,
  ) {
    pushService.subscription.subscribe((data) => {
      console.log("pushService.subscription.subscribe: ", data);

      store.dispatch(
        NotificationSettingsActions.updatedPushSubscription({
          subscription: data || undefined,
        }),
      );
    });

    this.pushService.messages.subscribe((message) => {
      console.log("AppComponent.received: ", message);
    });

    this.registerIcons();
  }

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      "applause",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/applause.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "marker_connect",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/marker_connect.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "marker_plus",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/marker_plus.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "marker",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/marker.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "marker_play",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/marker_play.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "add_page_break",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/add_page_break.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "page_break",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/page_break.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "page_break_timeline",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/page_break_timeline.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "metronome_plus",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/metronome_plus.svg",
      ),
    );

    this.matIconRegistry.addSvgIcon(
      "flip_vertical",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/flip_vertical.svg",
      ),
    );

    this.matIconRegistry.addSvgIcon(
      "pitch_up",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/pitch_up.svg",
      ),
    );

    this.matIconRegistry.addSvgIcon(
      "pitch_down",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/pitch_down.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "crown",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/crown.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "WhatsApp",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/link-types/WhatsApp.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "YouTube",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/link-types/YouTube.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "Instagram",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/link-types/Instagram.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "Facebook",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/link-types/Facebook.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "Mail",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/icons/link-types/Mail.svg",
      ),
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data && data instanceof RoutesRecognized) {
        const routeData = data!.state!.root!.firstChild!.data;
        this.showWerkbankToolbar = routeData.showWerkbankToolbar || false;
        this.hasSecondRowToolbar = routeData.hasSecondRowToolbar || false;
        this.hideToolbar = routeData.hideToolbar || false;
      }
    });

    this.activatedRoute.queryParamMap
      .pipe(
        filter((p) => p.has("nt")),
        map((p) => p.get("nt")),
      )
      .subscribe((notificationTrigger) => {
        this.store.dispatch(
          NotificationActions.markAsReadByName({ name: notificationTrigger! }),
        );

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { nt: undefined },
          queryParamsHandling: "merge",
        });
      });

    this.languageService.initLanguage();

    this.store.select(UserSelectors.currentUser).subscribe((userData) => {
      if (userData && userData.language) {
        console.log(`app.component: useLanguage: ${userData.language}`);
        this.languageService.useLanguage(userData.language);
      }
      this.isLoggedIn = userData != undefined;
    });

    this.updates.versionUpdates
      .pipe(
        tap((e) => console.info(e)),
        filter(
          (evt): evt is VersionReadyEvent => evt.type === "VERSION_DETECTED",
        ),
      )
      .subscribe((_) => {
        console.info(`new version detected!`);
      });

    this.updates.versionUpdates
      .pipe(
        tap((e) => console.info(e)),
        filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
      )
      .subscribe((_) => {
        console.info(`update service worker!`);
        this.updates.activateUpdate().then(() => {
          console.log("FORCE RELOAD");

          this.store.dispatch(AppActions.upgraded()); // XXX: careful: network requests can be interrupted as we reload!
          setTimeout(() => {
            document.location.reload();
          }, 1000);
        });
      });

    this.updates.unrecoverable.subscribe((event) => {
      console.error(`unrecoverable state: ${event}`);
      setTimeout(() => document.location.reload(), 1000);
    });
  }
}
