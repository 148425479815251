import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { Preview } from "src/app/models/music-piece/preview";
import { SearchResultDetailsDialogData } from "src/app/models/search/search-result-details-dialog-data";
import { AppState } from "src/app/ngrx/reducers";
import {
  ArtistProfileSelectors,
  PurchaseSelectors,
} from "src/app/ngrx/selectors";
import { SearchResultDetailsDialogComponent } from "../../search/search-result-details-dialog/search-result-details-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { ShowcaseCollectionInfoDialogComponent } from "../showcase-collection-info-dialog/showcase-collection-info-dialog.component";
import { ShowcaseCollectionListingDialogData } from "src/app/models/collection/showcase-collection-listing-info-dialog-data";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { ArtistProfileSeoService } from "src/app/services/artist/artist-profile-seo.service";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { PurchaseButtonComponent } from "../purchase-button/purchase-button.component";
import { PreviewComponent } from "../../music-piece/preview/preview.component";
import { PricePipe } from "src/app/pipes/price.pipe";
import { PreviewForListingPipe } from "src/app/pipes/preview-for-listing.pipe";
import { MusicPieceThumbnailPipe } from "src/app/pipes/music-piece-thumbnail.pipe";
import { MaterialModule } from "src/app/modules/material.module";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideListTodo } from "@ng-icons/lucide";

@Component({
  selector: "yr-listed-music-pieces-and-collections",
  templateUrl: "./listed-music-pieces-and-collections.component.html",
  styleUrls: ["./listed-music-pieces-and-collections.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PurchaseButtonComponent,
    PreviewComponent,
    PricePipe,
    PreviewForListingPipe,
    MusicPieceThumbnailPipe,
    MaterialModule,
    NgIconComponent,
  ],
  providers: [provideIcons({ lucideListTodo })],
})
export class ListedMusicPiecesAndCollectionsComponent
  implements OnInit, OnDestroy
{
  listedMusicPieces: MusicPieceListing[] = [];
  isLoadingListedMusicPieces = true;

  currentPurchase?: PurchasedItem;
  previews: Preview[] = [];
  focusMusicPiece?: string;

  listedCollections: ShowcaseCollectionListing[] = [];
  isLoadingCollections = true;

  private readonly subscriptionService = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
    private readonly seo: ArtistProfileSeoService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(ArtistProfileSelectors.selectListedMusicPieces)
        .subscribe((data) => {
          this.listedMusicPieces = data.listedMusicPieces;
          this.isLoadingListedMusicPieces = data.isLoadingListedMusicPieces;
          this.previews = data.previews;

          if (!data.isLoadingListedMusicPieces) {
            this.seo.addListedMusicPieces(data.listedMusicPieces);
          }
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(PurchaseSelectors.selectCurrentPurchase)
        .subscribe((data) => {
          this.currentPurchase = data.currentPurchase;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(ArtistProfileSelectors.selectFocusMusicPiece)
        .subscribe((focus) => {
          if (focus.focusMusicPiece && !this.focusMusicPiece) {
            // DO something fancy?
          }
          this.focusMusicPiece = focus.focusMusicPiece;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(ArtistProfileSelectors.selectListedCollections)
        .subscribe((data) => {
          this.isLoadingCollections = data.isLoadingListedCollections;
          this.listedCollections = data.listedCollections;

          if (!data.isLoadingListedCollections) {
            this.seo.addListedCollections(data.listedCollections);
          }
        }),
    );
  }

  showDetails(listing: MusicPieceListing) {
    this.matDialog.open<
      SearchResultDetailsDialogComponent,
      SearchResultDetailsDialogData
    >(SearchResultDetailsDialogComponent, {
      data: {
        details: listing,
      },
      minWidth: "30vw",
      maxWidth: "80vw",
    });
  }

  showCollectionDetails(collection: ShowcaseCollectionListing) {
    this.matDialog.open<
      ShowcaseCollectionInfoDialogComponent,
      ShowcaseCollectionListingDialogData,
      void
    >(ShowcaseCollectionInfoDialogComponent, {
      data: {
        showcaseCollectionListing: collection,
      },
      minWidth: "50vw",
      maxWidth: "80vw",
    });
  }
}
