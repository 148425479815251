import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { StripeCustomerDto } from "src/app/dtos/user/stripe-customer.dto";
import { StripeCustomer } from "src/app/models/user/stripe-customer";

export class StripeCustomerConversion
  implements Conversion<StripeCustomerDto, StripeCustomer>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StripeCustomerDto): StripeCustomer {
    return {
      name: dto.name,
      customerName: dto.customer_name,
      stripeCustomerId: dto.stripe_customer_id,
      modified: this.us.parseApiDate(dto.modified),
      creation: this.us.parseApiDate(dto.creation),
    };
  }
}
