import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { StoredArtistProfileEvent } from "src/app/models/artist/stored-artist-profile";
import { LinkValidator } from "src/app/validators/link-validator";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-artist-profile-events-form",
  templateUrl: "./artist-profile-events-form.component.html",
  styleUrl: "./artist-profile-events-form.component.scss",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class ArtistProfileEventsFormComponent {
  @Output()
  onSubmit = new EventEmitter<StoredArtistProfileEvent>();

  @Output()
  onDelete = new EventEmitter<StoredArtistProfileEvent>();

  @Input()
  isInEditMode: boolean = false;

  @Input()
  canDelete: boolean = false;

  private _existingArtistProfile?: StoredArtistProfileEvent;

  @Input()
  set existingArtistProfile(event: StoredArtistProfileEvent) {
    this._existingArtistProfile = event;

    this.date.setValue(event.date);
    this.description.setValue(event.description || null);
    this.title.setValue(event.title);
    this.url.setValue(event.url || null);
  }

  get existingArtistProfile(): StoredArtistProfileEvent | undefined {
    return this._existingArtistProfile;
  }

  title = new FormControl("", {
    validators: [Validators.required, Validators.maxLength(140)],
  });

  url = new FormControl("", {
    validators: [
      Validators.maxLength(140),
      new LinkValidator().linkValidator(),
    ],
  });

  description = new FormControl("", { validators: Validators.maxLength(2000) });
  date = new FormControl(new Date(), { validators: [Validators.required] });

  form = new FormGroup({
    title: this.title,
    url: this.url,
    description: this.description,
    date: this.date,
  });

  submit() {
    if (this.form.valid) {
      this.onSubmit.emit({
        name: this.existingArtistProfile?.name || "",
        date: this.date.value!,
        title: this.title.value!,
        description: this.description.value || undefined,
        url: this.url.value || undefined,
      });

      if (this.existingArtistProfile == undefined) {
        this.form.reset();
      }
    }
  }

  delete() {
    this.onDelete.emit(this.existingArtistProfile);
  }
}
