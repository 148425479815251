import { Pipe, PipeTransform } from "@angular/core";
import { MusicPieceListing } from "../models/music-piece/music-piece-listing";
import { Preview } from "../models/music-piece/preview";
import { ExploreMusicPiece } from "../models/explore/explore-music-piece";

@Pipe({
  name: "previewForListing",
  standalone: true,
})
export class PreviewForListingPipe implements PipeTransform {
  transform(
    musicPiece: MusicPieceListing | ExploreMusicPiece,
    previews: Preview[],
  ): Preview | undefined {
    return previews.find((p) => p.musicPiece == musicPiece.musicPiece);
  }
}
