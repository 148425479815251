import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import {
  loadUsedResourcesAction,
  usedResourcesLoadedSuccessfullyAction,
} from "../actions/used-resources-for-user.actions";

import { UsedResourcesService } from "../../services/user/used-resources.service";
import {
  EditCollectionActions,
  EditMusicPieceActions,
  MusicPieceActions,
} from "../actions";

@Injectable()
export class UsedResourcesForUsersEffects {
  loadUsedResourcesEffect = createEffect(() =>
    this.actions.pipe(
      ofType(
        loadUsedResourcesAction,
        MusicPieceActions.createdCopyFromLinkedMusicPiece,
        EditMusicPieceActions.deletedMusicPiece,
        EditCollectionActions.savedCollection,
        EditCollectionActions.deletedCollection
      ),
      mergeMap(() => {
        return this.usedResourcesService
          .usedResourcesForUser()
          .pipe(map((ur) => usedResourcesLoadedSuccessfullyAction(ur)));
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly usedResourcesService: UsedResourcesService
  ) {}
}
