import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageCropperModule,
} from "ngx-image-cropper";
import { firstValueFrom } from "rxjs";
import { tap, filter, take } from "rxjs/operators";
import {
  CreateOrGetDocument,
  PrepareUpload,
  FinishUpload,
} from "src/app/models/common/upload-types";
import { UploadEvent } from "src/app/models/music-piece/attachment-upload-event";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { MaterialModule } from "src/app/modules/material.module";
import { AttachmentService } from "src/app/services/music-piece/attachment.service";

@Component({
  selector: "yr-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
  standalone: true,
  imports: [CommonModule, ImageCropperModule, MaterialModule],
})
export class ImageUploadComponent {
  imageChangedEvent: any = "";

  @ViewChild(ImageCropperComponent)
  cropper?: ImageCropperComponent;

  imageLoaded = false;

  cropped = false;

  @Input()
  width: number = 400;

  @Input()
  height: number = 400;

  @Input()
  accept: string = "image/*";

  @Output()
  imageSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  uploadChange: EventEmitter<AttachmentUploadResult> =
    new EventEmitter<AttachmentUploadResult>();

  @Output()
  uploadInProgress = new EventEmitter<boolean>();

  @Input()
  createOrGetDocument: CreateOrGetDocument = () =>
    Promise.resolve({ name: "" });

  @Input()
  prepareUpload: PrepareUpload = (name) => Promise.resolve({ uploadUrl: "" });

  @Input()
  finishUpload: FinishUpload = (name) => Promise.resolve();

  _isUploading = false;

  @Input()
  hideControls: boolean = false;

  set isUploading(val: boolean) {
    if (val != this._isUploading) {
      this._isUploading = val;
      this.uploadInProgress.emit(val);
    }
  }

  get isUploading() {
    return this._isUploading;
  }
  isUploadFinishedSuccessfully: boolean = false;

  progressPercentage: number = 0;

  constructor(private readonly attachmentService: AttachmentService) {}

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);

    if (event.blob) {
      this.cropped = true;
      this.upload(event.blob);
    }
  }

  public crop() {
    if (this.cropper) {
      this.cropper.crop();
    }
  }

  public reset() {
    if (this.cropper) {
      this.imageChangedEvent = null;
      this.isUploading = false;
      this.imageLoaded = false;
      this.isUploadFinishedSuccessfully = false;
      this.imageSelected.emit(false);
    }
  }

  handleImageLoaded() {
    this.imageLoaded = true;
    this.imageSelected.emit(true);
  }

  private handleUploadProgress(event: UploadEvent) {
    console.log(`handleUploadProgress: ${event.type}, ${event.uploadProgress}`);
    this.progressPercentage = Math.round(event.uploadProgress * 100);
  }

  private handleUploadFinished() {
    this.uploadChange.emit({ result: "Success" });
    this.isUploading = false;
    this.isUploadFinishedSuccessfully = true;
  }

  private handleError(error: any) {
    console.error(error);
    this.uploadChange.emit({
      error: error,
      result: "Failure",
    });
    this.isUploading = false;
  }

  async upload(blob: Blob) {
    const file = new File([blob], "image.png", { type: "image/png" }); // we do accept all images, but receive a png

    // XXX: duplicate of upload.component.ts
    try {
      this.isUploading = true;
      const doc = await this.createOrGetDocument();
      const uploadPrepared = await this.prepareUpload(doc.name);
      const uploadObservable = this.attachmentService
        .uploadFile(file, uploadPrepared.uploadUrl)
        .pipe(
          tap((ue) => {
            this.handleUploadProgress(ue);
          }),
          filter((ue) => ue.type == "Finished"),
          take(1),
        );

      await firstValueFrom(uploadObservable);
      await this.finishUpload(doc.name);
      this.handleUploadFinished();
    } catch (error) {
      this.handleError(error);
    }
  }
}
