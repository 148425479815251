import { SharedMusicPieceReceiverDto } from "src/app/dtos/music-piece/shared-music-piece-receiver-dto";
import { SharedMusicPieceReceiver } from "src/app/models/music-piece/shared-music-piece-receiver";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class SharedMusicPieceDtoConversion
  implements Conversion<SharedMusicPieceReceiverDto, SharedMusicPieceReceiver>
{
  private readonly us: UnitService = new UnitService();

  convert(item: SharedMusicPieceReceiverDto): SharedMusicPieceReceiver {
    return {
      email: item.email,
      name: item.name,
      creation: this.us.parseApiDate(item.creation),
      isPartOfLinkedCollection: item.is_part_of_linked_collection,
      linkedMusicPiece: item.linked_music_piece,
    };
  }
}
