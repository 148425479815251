import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
  StoredArtistProfileLink,
  UrlTypeHelper,
} from "src/app/models/artist/stored-artist-profile";
import { LinkValidator } from "src/app/validators/link-validator";
import { MailValidator } from "src/app/validators/mail-validator";
import { WhatsAppNumberValidator } from "src/app/validators/whatsapp-number-validator";
import { YoutubeChannelValidator } from "src/app/validators/youtube-channel-validator";
import { ArtistProfileLinksFormComponent } from "../artist-profile-links-form/artist-profile-links-form.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-artist-profile-links",
  templateUrl: "./artist-profile-links.component.html",
  styleUrls: ["./artist-profile-links.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ArtistProfileLinksFormComponent,
    MaterialModule,
  ],
})
export class ArtistProfileLinksComponent implements OnInit {
  _links: StoredArtistProfileLink[] = [];

  displayLinks: {
    link: StoredArtistProfileLink;
    urlTypeHelper: UrlTypeHelper;
  }[] = [];

  private sortByOrder(
    a: { urlTypeHelper: UrlTypeHelper },
    b: { urlTypeHelper: UrlTypeHelper },
  ) {
    return a.urlTypeHelper.order - b.urlTypeHelper.order;
  }

  @Input()
  set links(links: StoredArtistProfileLink[]) {
    this._links = links;

    this.displayLinks = this.links
      .map((l) => ({
        link: l,
        urlTypeHelper:
          this.supportedUrlTypes.find((sut) => sut.urlType == l.urlType) ||
          this.linkUrlTypeHelper,
      }))
      .sort((a, b) => this.sortByOrder(a, b));
  }

  get links() {
    return this._links;
  }

  @Output()
  linksChange = new EventEmitter<StoredArtistProfileLink[]>();

  @Input()
  isInEditMode: boolean = false;

  readonly linkUrlTypeHelper: UrlTypeHelper = {
    helpText: "artist.artist-profile-links.link-help",
    order: 100,
    icon: "",
    urlMapper: (url) => url,
    urlType: "Link",
    validator: () =>
      Validators.compose([
        Validators.maxLength(140),
        new LinkValidator().linkValidator(),
      ])!,
  };

  readonly supportedUrlTypes: UrlTypeHelper[] = [
    this.linkUrlTypeHelper, // we use this as fallback
    {
      helpText: "artist.artist-profile-links.whatsapp-help",
      icon: "WhatsApp", // registered via mat-icon-registry
      order: 1,
      // see: https://faq.whatsapp.com/5913398998672934/?helpref=uf_share
      // the doc link from above is not entirely true: when we have a phone number which contains a `0` in the middle it did not work!
      // however, when we don't follow the docs and just remove all non-number characters and make sure that we start with the country code, it seems to work
      urlMapper: (number) => {
        const replacementRegex = /([^\w\s]|\s)/gi;
        const sanitizedNumber = number.replace(replacementRegex, "");
        return `https://wa.me/${sanitizedNumber}`;
      },
      urlType: "WhatsApp",
      validator: () =>
        Validators.compose([
          Validators.maxLength(140),
          WhatsAppNumberValidator.validator(),
        ])!,
    },
    {
      helpText: "artist.artist-profile-links.youtube-help",
      icon: "YouTube",
      order: 2,
      urlMapper: (url) => `https://www.youtube.com/${url}`,
      urlType: "YouTube",
      validator: () =>
        Validators.compose([
          Validators.maxLength(140),
          YoutubeChannelValidator.validator(),
        ])!,
    },
    {
      helpText: "artist.artist-profile-links.facebook-help",
      icon: "Facebook",
      order: 3,
      urlMapper: (url) => `https://www.facebook.com/${url}`,
      urlType: "Facebook",
      validator: () => Validators.compose([Validators.maxLength(140)])!,
    },
    {
      helpText: "artist.artist-profile-links.instagram-help",
      icon: "Instagram",
      order: 4,
      urlMapper: (url) => `https://www.instagram.com/${url}`,
      urlType: "Instagram",
      validator: () => Validators.compose([Validators.maxLength(140)])!,
    },
    {
      helpText: "artist.artist-profile-links.mail-help",
      icon: "Mail",
      order: 5,
      urlMapper: (url) => `mailto:${url}`,
      urlType: "Mail",
      validator: () =>
        Validators.compose([
          Validators.maxLength(140),
          MailValidator.validator(),
        ])!,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  remove(link: StoredArtistProfileLink) {
    this.links = this.links.filter((l) => l.name != link.name);
    this.linksChange.emit(this.links);
  }

  add(link: StoredArtistProfileLink) {
    this.links = [...this.links, link];
    this.linksChange.emit(this.links);
  }

  update(link: StoredArtistProfileLink) {
    this.links = [...this.links.filter((e) => e.name != link.name), link];
    this.linksChange.emit(this.links);
  }
}
