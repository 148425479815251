import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { News } from "../models/news/news";
import { AppState, UserSelectors } from "../ngrx/reducers";

@Pipe({
  name: "news",
  standalone: true,
})
export class NewsPipe implements PipeTransform {
  private language: string = "de";

  constructor(private readonly store: Store<AppState>) {
    this.store.select(UserSelectors.currentUser).subscribe((data) => {
      this.language = data?.language || "de";
    });
  }

  transform(news?: News): string | undefined {
    if (this.language == "de") {
      return news?.german;
    } else if (this.language == "en") {
      return news?.english;
    }
    return;
  }
}
