import { Conversion } from "../conversion";
import { UpdatedPasswordDto } from "src/app/dtos/user/updated-password.dto";
import { UpdatedPassword } from "src/app/models/user/updated-password";

export class UpdatedPasswordDtoConversion
  implements Conversion<UpdatedPasswordDto, UpdatedPassword>
{
  convert(dto: UpdatedPasswordDto): UpdatedPassword {
    return {
      apiSecret: dto.api_secret,
    };
  }
}
