import { Component, computed, input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  templateUrl: "./purchase-status-page-link.component.html",
  selector: "yr-purchase-status-page-link",
  standalone: true,
  imports: [RouterModule, TranslateModule],
  styleUrls: [],
})
export class PurchaseStatusPageLinkComponent {
  purchaseType = input.required<string>();
  clientSecret = input.required<string>();

  purchaseStatusLink = computed(() => {
    return ["/purchase-status", this.purchaseType(), this.clientSecret()];
  });
}
