import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MaterialModule } from "src/app/modules/material.module";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-prepare-share-collection",
  templateUrl: "./prepare-share-collection.component.html",
  styleUrls: ["./prepare-share-collection.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PrepareShareCollectionComponent implements OnInit, OnDestroy {
  linkedMusicPieces: LinkedMusicPieceWithMusicPiece[] = [];
  musicPiecesWithoutShareTerms: MusicPiece[] = [];
  privateCopies: MusicPiece[] = [];

  collection?: StoredCollection;
  isSaving: boolean = false;

  private readonly subscriptionService = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }
  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionItems)
        .subscribe((data) => {
          this.linkedMusicPieces = data.collectionItems
            .filter((ci) => ci.linkedMusicPiece != undefined)
            .map((ci) =>
              data.linkedMusicPieces.find(
                (lmp) =>
                  lmp.linkedMusicPiece &&
                  lmp.linkedMusicPiece.name == ci.linkedMusicPiece,
              ),
            )
            .filter((lmp) => lmp != null)
            .map((lmp) => lmp!);

          this.musicPiecesWithoutShareTerms = data.collectionItems
            .filter((ci) => ci.musicPice != undefined)
            .map((ci) => data.musicPieces.find((mp) => mp.name == ci.musicPice))
            .filter((mp) => mp != null)
            .map((mp) => mp!)
            .filter((mp) => mp.acceptShareTerms == false);

          this.privateCopies = data.collectionItems
            .filter((ci) => ci.musicPice != undefined)
            .map((ci) => data.musicPieces.find((mp) => mp.name == ci.musicPice))
            .filter((mp) => mp != null)
            .map((mp) => mp!)
            .filter((mp) => mp.isCopy == true);

          this.collection = data.collection;
          this.isSaving = data.isSaving;
        }),
    );
  }

  prepareShare() {
    if (this.collection) {
      this.store.dispatch(
        EditCollectionActions.saveCollection({
          acceptShareTerms: true,
          isNew: false,
          title: this.collection.title,
          artist: this.collection.artist,
          description: this.collection.description,
          instrument: this.collection.instrument,
          temperament: this.collection.temperament,
          name: this.collection.name,
          isPracticeRoutine: this.collection.isPracticeRoutine,
        }),
      );
    }
  }
}
