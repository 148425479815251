import { Conversion } from "./conversion";

export abstract class ExtendableConversion<FROM, TO>
  implements Conversion<FROM, TO>
{
  abstract convert(item: FROM): TO;

  asMany(): Conversion<FROM[], TO[]> {
    const self = this;
    return {
      convert(items: FROM[]): Array<TO> {
        return (items || []).map((i) => self.convert(i));
      },
    } as Conversion<FROM[], TO[]>;
  }
}
