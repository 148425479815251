import { Injectable } from "@angular/core";
import { Actions, act, createEffect, ofType } from "@ngrx/effects";
import { CollectionService } from "src/app/services/collection/collection.service";
import {
  EditCollectionActions,
  EditMusicPieceActions,
  MusicPieceActions,
} from "../actions";
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { merge, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MusicService } from "src/app/services/music-piece/music-piece.service";
import { LinkedMusicPieceService } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { EditCollectionSelectors, MusicPieceSelectors } from "../selectors";
import { MusicPieceFilterService } from "src/app/services/music-piece/music-piece-filter.service";
import {
  CollectionItemToDisplay,
  CollectionItemToDisplayHelper,
} from "src/app/models/collection/collection-item-to-display";

@Injectable({
  providedIn: "root",
})
export class EditCollectionEffects {
  loadCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.openCollectionForEdit),
      filter((action) => !action.isNew),
      mergeMap((action) => this.collectionService.loadCollection(action.name!)),
      map((collection) =>
        EditCollectionActions.loadedCollection({ collection }),
      ),
    ),
  );

  loadCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.openCollectionForEdit),
      filter((action) => !action.isNew),
      mergeMap((action) =>
        this.collectionService.loadCollectionItemsForCollection(action.name!),
      ),
      map((collectionItems) =>
        EditCollectionActions.loadedCollectionItems({
          collectionItems: collectionItems,
        }),
      ),
    ),
  );

  loadCollectionListingOnOpen$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.openCollectionForEdit),
      filter((action) => !action.isNew),
      map((action) =>
        EditCollectionActions.loadCollectionListing({
          collection: action.name!,
        }),
      ),
    ),
  );

  loadCollectionListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.loadCollectionListing),
      mergeMap((action) =>
        this.collectionService.loadCollectionListing(action.collection).pipe(
          map((listing) =>
            EditCollectionActions.loadedCollectionListing({
              listing: listing,
            }),
          ),
        ),
      ),
    ),
  );

  reloadCollectionItemsAfterRemove$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.removedCollectionItem),
      mergeMap((action) =>
        this.collectionService.loadCollectionItemsForCollection(
          action.collectionItemToRemove.collection,
        ),
      ),
      map((collectionItems) =>
        EditCollectionActions.loadedCollectionItems({
          collectionItems: collectionItems,
        }),
      ),
    ),
  );

  loadCollectionItemsToAdd$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.loadCollectionItemsToAdd),
      mergeMap((action) =>
        this.musicPieceService.getMusicPieces().pipe(
          map((mp) =>
            mp.filter((mp) => {
              if (action.acceptedShareTerms) {
                return mp.acceptShareTerms && !mp.isCopy;
              } else {
                return true;
              }
            }),
          ),
          map((mp) => {
            return {
              musicPieces: mp,
              existingCollectionItems: action.existingCollectionItems,
              acceptedShareTerms: action.acceptedShareTerms,
            };
          }),
        ),
      ),
      mergeMap((acc) => {
        if (!acc.acceptedShareTerms) {
          return this.linkedMusicPieceService.getLinkedMusicPieces().pipe(
            map((lmp) => {
              return {
                ...acc,
                linkedMusicPieces: lmp,
              };
            }),
          );
        } else {
          return of({
            ...acc,
            linkedMusicPieces: [],
          });
        }
      }),
      map((acc) => {
        const displayMusicPieces = acc.musicPieces
          .filter(
            (mp) =>
              !acc.existingCollectionItems.some(
                (ci) => ci.musicPice == mp.name,
              ),
          )
          .map((mp) => {
            return {
              isLinkedMusicPiece: false,
              musicPiece: mp,
            } as MusicPieceToDisplay;
          });

        const displayLinkedMusicPieces = acc.linkedMusicPieces
          .filter(
            (lmp) =>
              !acc.existingCollectionItems.some(
                (ci) =>
                  ci.musicPice == lmp.musicPiece.name ||
                  (ci.linkedMusicPiece &&
                    lmp.linkedMusicPiece &&
                    ci.linkedMusicPiece == lmp.linkedMusicPiece.name),
              ),
          )
          .map((lmp) => {
            return {
              isLinkedMusicPiece: true,
              musicPiece: lmp.musicPiece,
              linkedMusicPiece: lmp.linkedMusicPiece,
            } as MusicPieceToDisplay;
          });

        const musicPiecesToDisplay = [
          ...displayMusicPieces,
          ...displayLinkedMusicPieces,
        ];

        return EditCollectionActions.loadedCollectionItemsToAdd({
          collectionItemsToAdd: musicPiecesToDisplay,
        });
      }),
    ),
  );

  resetFilterCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        EditCollectionActions.addedCollectionItem,
        EditCollectionActions.removedCollectionItem,
        EditCollectionActions.loadedCollectionItems,
        EditCollectionActions.editedCollectionItems,
        MusicPieceActions.loadedMusicPieces,
        MusicPieceActions.loadedLinkedMusicPieces,
      ),
      map(() => EditCollectionActions.filterCollectionItems({ filter: "" })),
    ),
  );

  filterCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.filterCollectionItems),
      debounceTime(50),
      withLatestFrom(
        this.store.select(EditCollectionSelectors.selectEditCollectionItems),
      ),
      map(([action, state]) => {
        const collectionItemsToDisplay = CollectionItemToDisplayHelper.from(
          state.collectionItems,
          state.musicPieces,
          state.linkedMusicPieces,
        );

        const selector = (i: CollectionItemToDisplay) => i.musicPiece;
        const filtered =
          this.musicPieceFilterService.filterByMusicPieceToDisplay(
            action.filter,
            collectionItemsToDisplay,
            selector,
          );
        const filteredAndSorted = filtered.sort(
          (a, b) => a.collectionItem.order - b.collectionItem.order,
        );
        return EditCollectionActions.filteredCollectionItems({
          filteredCollectionItems: filteredAndSorted,
        });
      }),
    ),
  );

  resetFilterCollectionItemsToAdd$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.loadedCollectionItemsToAdd),
      map((_) =>
        EditCollectionActions.filterCollectionItemsToAdd({
          filter: "",
        }),
      ),
    ),
  );

  filterCollectionItemsToAdd$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.filterCollectionItemsToAdd),
      debounceTime(50),
      withLatestFrom(
        this.store.select(
          EditCollectionSelectors.selectEditCollectionItemsToAdd,
        ),
      ),
      map(([action, state]) => {
        return EditCollectionActions.filteredCollectionItemsToAdd({
          filteredMusicPiecesToAdd: this.musicPieceFilterService.filter(
            action.filter,
            state.collectionItemsToAdd,
            true,
          ),
        });
      }),
    ),
  );

  saveCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.saveCollection),
      mergeMap((action) => {
        // see:  https://v7.ngrx.io/guide/effects#handling-errors
        const saveAction = () => {
          if (!action.isNew && action.name) {
            return this.collectionService.updateCollection(
              action.name,
              action.title,
              action.instrument,
              action.temperament,
              action.artist,
              action.description,
              action.acceptShareTerms,
              action.isPracticeRoutine,
            );
          } else {
            return this.collectionService.createCollection(
              action.title,
              action.instrument,
              action.temperament,
              action.artist,
              action.description,
              action.isPracticeRoutine,
            );
          }
        };

        // we must deal with the error before the "flattening" operator, hence here.
        return saveAction().pipe(
          map((collection) =>
            EditCollectionActions.savedCollection({
              collection: collection,
              wasNew: action.isNew,
            }),
          ),
          catchError((error) =>
            of(EditCollectionActions.saveCollectionFailed(error)),
          ),
        );
      }),
    ),
  );

  navigationAfterCreate$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditCollectionActions.savedCollection),
        filter((action) => action.wasNew),
        tap((action) =>
          this.router.navigate(["collection", "edit", action.collection.name]),
        ),
      ),
    { dispatch: false },
  );

  successNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditCollectionActions.savedCollection,
          EditCollectionActions.addedCollectionItem,
          EditCollectionActions.removedCollectionItem,
          EditCollectionActions.editedCollectionItems,
          EditCollectionActions.deletedCollection,
          EditCollectionActions.shared,
          EditCollectionActions.unshared,
          EditCollectionActions.createdCollectionListing,
          EditCollectionActions.updatedCollectionListing,
          EditCollectionActions.deletedCollectionListing,
        ),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  failureNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditCollectionActions.saveCollectionFailed,
          EditCollectionActions.addCollectionItemFailed,
          EditCollectionActions.removeCollectionItemFailed,
          EditCollectionActions.editCollectionItemsFailed,
          EditCollectionActions.deleteCollectionFailed,
          EditCollectionActions.shareFailed,
          EditCollectionActions.loadedLinkedCollectionsFailed,
          EditCollectionActions.unshareFailed,
          EditCollectionActions.saveCollectionListingFailed,
        ),
        tap((action) => {
          console.error(action.error);
          this.toastr.error(this.translate.instant("common.fail"));
        }),
      ),
    { dispatch: false },
  );

  addCollectionItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.addCollectionItem),
      mergeMap((action) =>
        this.collectionService
          .addCollectionItem(
            action.collection.name,
            action.collectionItemToAdd,
            action.order,
          )
          .pipe(
            map((ci) =>
              EditCollectionActions.addedCollectionItem({ collectionItem: ci }),
            ),
            catchError((error) =>
              of(
                EditCollectionActions.addCollectionItemFailed({ error: error }),
              ),
            ),
          ),
      ),
    ),
  );

  loadMusicPiecesForCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.openCollectionForEdit),
      withLatestFrom(
        this.store.select(
          MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
        ),
      ),
      filter(
        ([_, data]) =>
          // we might load too often in case the user has no linked music pieces and no music pieces, but that's ok
          data.musicPieces.length == 0 && data.linkedMusicPieces.length == 0,
      ),
      map((_) => MusicPieceActions.loadMusicPieces()),
    ),
  );

  removeCollectionItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.removeCollectionItem),
      mergeMap((action) =>
        this.collectionService
          .removeCollectionItem(action.collectionItemToRemove.name)
          .pipe(
            map((_) =>
              EditCollectionActions.removedCollectionItem({
                collectionItemToRemove: action.collectionItemToRemove,
              }),
            ),
            catchError((error) =>
              of(
                EditCollectionActions.removeCollectionItemFailed({
                  error: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updateCollectionItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.editCollectionItems),
      mergeMap((action) =>
        this.collectionService
          .updateCollectionItems(action.updateCollectionItems)
          .pipe(
            map((result) =>
              EditCollectionActions.editedCollectionItems({
                updatedCollectionItems: result,
              }),
            ),
            catchError((error) =>
              of(
                EditCollectionActions.editCollectionItemsFailed({
                  error: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.deleteCollection),
      mergeMap((action) =>
        this.collectionService.deleteCollection(action.collection.name).pipe(
          map((_) =>
            EditCollectionActions.deletedCollection({
              collection: action.collection,
            }),
          ),
          catchError((error) =>
            of(EditCollectionActions.deleteCollectionFailed({ error })),
          ),
        ),
      ),
    ),
  );

  navigateBackAfterDelete$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditCollectionActions.deletedCollection),
        tap((_) => this.router.navigate(["collection"])),
      ),
    { dispatch: false },
  );

  shareCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.share),
      mergeMap((action) =>
        this.collectionService
          .share(action.collection.name, action.receiver)
          .pipe(
            map((linkedCollection) =>
              EditCollectionActions.shared({
                collection: action.collection,
                receiver: linkedCollection,
              }),
            ),
            catchError((error) =>
              of(EditCollectionActions.shareFailed({ error })),
            ),
          ),
      ),
    ),
  );

  unshareCollection$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.unshare),
      withLatestFrom(
        this.store.select(EditCollectionSelectors.selectEditCollectionData),
      ),
      mergeMap(([action, state]) =>
        this.collectionService.unshare(action.linkedCollection.name).pipe(
          map(
            (_) =>
              EditCollectionActions.unshared({
                collection: state.collection!,
                linkedCollection: action.linkedCollection,
              }),
            catchError((error) =>
              of(EditCollectionActions.unshareFailed({ error })),
            ),
          ),
        ),
      ),
    ),
  );

  triggerLoadLinkedCollectionsWhenCollectionIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.loadedCollection),
      filter((action) => action.collection.acceptShareTerms),
      map((action) =>
        EditCollectionActions.loadLinkedCollections({
          collection: action.collection,
        }),
      ),
    ),
  );

  triggerLoadWhenCollectionIsShared$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.shared),
      map((action) =>
        EditCollectionActions.loadLinkedCollections({
          collection: action.collection,
        }),
      ),
    ),
  );

  triggerLoadLinkedCollectionWhenCollectionIsUnshared$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.unshared),
      map((action) =>
        EditCollectionActions.loadLinkedCollections({
          collection: action.collection,
        }),
      ),
    ),
  );

  loadLinkedCollections$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.loadLinkedCollections),
      mergeMap((action) =>
        this.collectionService
          .loadLinkedCollectionsFor(action.collection.name)
          .pipe(
            map((linkedCollections) =>
              EditCollectionActions.loadedLinkedCollections({
                linkedCollections: linkedCollections,
              }),
            ),
            catchError((error) =>
              of(
                EditCollectionActions.loadedLinkedCollectionsFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.saveCollectionListing),
      filter((action) => action.name == undefined && action.shouldList),
      mergeMap((action) =>
        this.collectionService
          .createListing(action.collection, action.price)
          .pipe(
            map((result) =>
              EditCollectionActions.createdCollectionListing({
                listing: result,
              }),
            ),
            catchError((error) =>
              of(EditCollectionActions.saveCollectionListingFailed({ error })),
            ),
          ),
      ),
    ),
  );

  updateListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.saveCollectionListing),
      filter((action) => action.name != undefined && action.shouldList),
      mergeMap((action) =>
        this.collectionService.updateListing(action.name!, action.price).pipe(
          map((result) =>
            EditCollectionActions.updatedCollectionListing({
              listing: result,
            }),
          ),
          catchError((error) =>
            of(EditCollectionActions.saveCollectionListingFailed({ error })),
          ),
        ),
      ),
    ),
  );

  deleteListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditCollectionActions.saveCollectionListing),
      filter((action) => action.name != undefined && !action.shouldList),
      mergeMap((action) =>
        this.collectionService.deleteListing(action.name!).pipe(
          map((_) => EditCollectionActions.deletedCollectionListing()),
          catchError((error) =>
            of(EditCollectionActions.saveCollectionListingFailed({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly collectionService: CollectionService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly musicPieceService: MusicService,
    private readonly linkedMusicPieceService: LinkedMusicPieceService,
    private readonly store: Store<AppState>,
    private readonly musicPieceFilterService: MusicPieceFilterService,
  ) {}
}
