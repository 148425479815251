<div fxLayout="column">
  <yr-offline-indicator></yr-offline-indicator>

  <!-- toolbar for not logged in users -->
  <div
    *ngIf="!isLoggedIn && !showWerkbankToolbar"
    class="flex h-24 w-full flex-row items-center bg-zinc-500"
    [class.border-b-2]="!hasSecondRowToolbar"
    [class.border-zinc-700]="!hasSecondRowToolbar"
    [class.shadow]="!hasSecondRowToolbar"
  >
    <div class="mx-auto flex w-full flex-row items-center rounded md:w-4/5">
      <div class="mx-2 my-2">
        <a href="https://applaus.schule" target="_blank">
          <img
            src="assets/logo/logo-white.png"
            alt="applaus.schule Logo"
            class="h-20"
          />
        </a>
      </div>

      <a
        class="text-bl mx-2 flex rounded p-2 text-base text-white no-underline"
        routerLink="explore"
        routerLinkActive="bg-zinc-700 !text-white"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon class="items-center">explore</mat-icon>
        <span class="ml-1 hidden items-center truncate lg:block">{{
          "app.explore" | translate
        }}</span>
      </a>

      <span class="flex-grow"></span>

      <a
        class="mx-2 flex rounded p-2 text-base text-white no-underline"
        routerLink="/login"
        routerLinkActive="bg-zinc-700 !text-white"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon class="items-center">login</mat-icon>
        <span class="ml-1 hidden items-center truncate lg:block">{{
          "app.login" | translate
        }}</span>
      </a>
      <a
        class="mx-2 flex rounded p-2 text-base text-white no-underline"
        routerLink="/registration"
        routerLinkActive="bg-zinc-700 !text-white"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon class="items-center">person_add</mat-icon>
        <span class="ml-1 hidden items-center truncate lg:block">{{
          "app.register" | translate
        }}</span>
      </a>
    </div>
  </div>

  <!-- toolbar for logged in users -->
  <div *ngIf="isLoggedIn">
    <div
      *ngIf="!showWerkbankToolbar"
      class="flex h-14 w-full flex-row items-center bg-zinc-500"
      [class.border-b-2]="!hasSecondRowToolbar"
      [class.border-zinc-700]="!hasSecondRowToolbar"
      [class.shadow]="!hasSecondRowToolbar"
    >
      <div
        class="mx-auto flex w-full flex-row items-center gap-x-2 px-2 md:w-4/5 md:px-0"
      >
        <a
          class="flex rounded p-2 text-base text-white no-underline"
          routerLink="/musicpiece"
          routerLinkActive="bg-zinc-700 !text-white"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon class="items-center">home</mat-icon>
          <span class="ml-1 hidden items-center truncate xl:block">{{
            "app.home" | translate
          }}</span>
        </a>
        <a
          class="flex rounded p-2 text-base text-white no-underline"
          routerLink="/collection"
          routerLinkActive="bg-zinc-700 !text-white"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon class="items-center">video_library</mat-icon>
          <span class="ml-1 hidden items-center truncate xl:block">{{
            "app.collection" | translate
          }}</span>
        </a>
        <a
          class="flex rounded p-2 text-base text-white no-underline"
          routerLink="/practice-routines"
          routerLinkActive="bg-zinc-700 !text-white"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <ng-icon name="lucideListTodo" size="24px"></ng-icon>
          <span class="ml-1 hidden items-center truncate xl:block">{{
            "app.practice-routines" | translate
          }}</span>
        </a>
        <a
          class="flex rounded p-2 text-base text-white no-underline"
          routerLink="/video-feedback"
          routerLinkActive="bg-zinc-700 !text-white"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <ng-icon name="lucideMessagesSquare" size="24px"></ng-icon>
          <span class="ml-1 hidden items-center truncate xl:block">{{
            "app.video-feedback" | translate
          }}</span>
        </a>
        <a
          class="text-bl flex rounded p-2 text-base text-white no-underline"
          routerLink="explore"
          routerLinkActive="bg-zinc-700 !text-white"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon class="items-center">explore</mat-icon>
          <span class="ml-1 hidden items-center truncate xl:block">{{
            "app.explore" | translate
          }}</span>
        </a>

        <span class="flex-grow"></span>

        <div class="xs:block hidden">
          <yr-notification-menu></yr-notification-menu>
        </div>
        <yr-upgrade-subscription-hint></yr-upgrade-subscription-hint>
        <yr-user-menu class="mr-2"></yr-user-menu>
      </div>
    </div>
  </div>

  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>
