import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import { ArtistCertificationService } from "src/app/services/user/artist-certification.service";
import { ArtistCertificationActions, ArtistProfileActions } from "../actions";
import { AppState, UserSelectors } from "../reducers";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ArtistCertificationEffects {
  loadArtistCertification$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistCertificationActions.loadArtistCertification),
      throttleTime(100),
      mergeMap((_) =>
        this.artistCertificationService.loadArtistCertification(),
      ),
      map((ac) =>
        ArtistCertificationActions.loadedArtistCertification({
          certification: ac,
        }),
      ),
    ),
  );

  createCertification$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistCertificationActions.createCertification),
      withLatestFrom(this.store.select(UserSelectors.currentUser)),
      mergeMap(([_, user]) =>
        this.artistCertificationService
          .createCertification(user!.username)
          .pipe(
            map((result) =>
              ArtistCertificationActions.createdCertification({
                certification: result,
              }),
            ),
          ),
      ),
    ),
  );

  requestReview$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistCertificationActions.requestReview),
      mergeMap((_) =>
        this.artistCertificationService
          .requestReview()
          .pipe(
            map((r) =>
              ArtistCertificationActions.reviewRequested({ certification: r }),
            ),
          ),
      ),
    ),
  );

  succesToasts$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ArtistCertificationActions.createdCertification,
          ArtistCertificationActions.reviewRequested,
        ),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  triggerArtistProfileLoadAfterCreation$ = createEffect(() =>
    this.actions.pipe(
      ofType(ArtistCertificationActions.createdCertification),
      map((_) =>
        ArtistProfileActions.loadArtistProfileForCurrentUser({
          redirectToArtistProfileAfterLoading: true,
        }),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly artistCertificationService: ArtistCertificationService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}
}
