import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { noConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  constructor(private readonly http: HttpClient) {}

  sendFeedback(feedback: String, anonymous: boolean): Observable<void> {
    const dto = {
      feedback: feedback,
      anonymous: anonymous,
    };
    const helper = new FrappeCrudHelper(
      "User Feedback",
      this.http,
      noConversion
    );
    return helper.create(dto);
  }
}
