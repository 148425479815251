import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Conversion } from "../conversions/conversion";
import { SingleResponseDto } from "../dtos/single-response-dto";
import { ConversionHelper } from "./conversion-helper";
import { UrlSanitizer } from "./url-sanitizer";

export class FrappeMethodHelper<DTO, T> {
  private readonly conversionHelper: ConversionHelper<DTO, T>;

  constructor(
    private readonly http: HttpClient,
    readonly conversion: Conversion<DTO, T>
  ) {
    this.conversionHelper = new ConversionHelper(conversion);
  }

  private tryToFillDataFromMessage<T>(result: SingleResponseDto<T>) {
    if ((result as any).message && result.data == undefined) {
      result.data = (result as any).message;
    }
    return result;
  }

  /**
   * @param httpMethod: to use GET use FrappeCrudHelper with subPathOverwrite
   */
  callMethod(
    frappeMethod: string,
    methodDataWrapper: MethodDataWrapper,
    httpMethod: "POST" | "PUT" | "DELETE" = "POST"
  ): Observable<T> {
    const urlSanitizer = new UrlSanitizer()
      .addUrlPart(environment.baseUrl)
      .addUrlPart("/api/method")
      .addUrlPart(frappeMethod);

    if (httpMethod == "POST") {
      const res = this.http
        .post<SingleResponseDto<DTO>>(
          urlSanitizer.url(),
          methodDataWrapper.getDto() || {},
          {
            params: methodDataWrapper.buildParams(),
          }
        )
        .pipe(map((res) => this.tryToFillDataFromMessage(res)));

      return this.conversionHelper.convertSingleObservable(res);
    } else if (httpMethod == "PUT") {
      const res = this.http
        .put<SingleResponseDto<DTO>>(
          urlSanitizer.url(),
          methodDataWrapper.getDto() || {},
          {
            params: methodDataWrapper.buildParams(),
          }
        )
        .pipe(map((res) => this.tryToFillDataFromMessage(res)));
      return this.conversionHelper.convertSingleObservable(res);
    } else if (httpMethod == "DELETE") {
      const res = this.http
        .delete<SingleResponseDto<DTO>>(urlSanitizer.url(), {
          params: methodDataWrapper.buildParams(),
        })
        .pipe(map((res) => this.tryToFillDataFromMessage(res)));
      return this.conversionHelper.convertSingleObservable(res);
    }
    throw `invalid method ${httpMethod}`;
  }
}

export class MethodDataWrapper {
  private params = new HttpParams();
  private dto?: any;

  withDto(dto: any): MethodDataWrapper {
    this.dto = dto;
    return this;
  }

  withParam(key: string, value: string): MethodDataWrapper {
    this.params = this.params.append(key, value);
    return this;
  }

  buildParams(): HttpParams {
    return this.params;
  }

  getDto(): any | undefined {
    return this.dto;
  }
}
