import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { CreditPayout } from "src/app/models/sales/credit-payout";
import { CreditStatement } from "src/app/models/sales/credit-statement";
import { SalesActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { SalesSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { CreditStatementComponent } from "../credit-statement/credit-statement.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-credit-statements",
  templateUrl: "./credit-statements.component.html",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    CreditStatementComponent,
  ],
})
export class CreditStatementsComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new SubscriptionService();
  creditStatements: {
    creditStatement: CreditStatement;
    payout?: CreditPayout;
  }[] = [];
  isLoadingCreditStatements: boolean = true;
  isLoadingCreditPayouts: boolean = true;

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(SalesSelectors.selectCreditStatements)
        .subscribe((data) => {
          this.isLoadingCreditStatements = data.isLoadingCreditStatements;
          this.isLoadingCreditPayouts = data.isLoadingCreditPayouts;

          this.creditStatements = data.creditStatements.map((cs) => ({
            creditStatement: cs,
            payout: data.creditPayouts.find(
              (cp) => cp.creditStatement == cs.name,
            ),
          }));
        }),
    );
  }

  handleDownload(event: { creditStatement: CreditStatement }) {
    this.store.dispatch(
      SalesActions.downloadCreditStatement({
        creditStatement: event.creditStatement,
      }),
    );
  }
}
