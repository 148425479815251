import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

interface FrappeRequestFilterInterface {
  filter(): string[];
  filterType(): FilterType;
}

export class FrappeRequestHelper {
  private filters: string[][] = [];
  private orFilters: string[][] = [];

  private fields: string[] = [];
  private params = new HttpParams();
  private pageLength?: number;
  private orderBy?: string;

  withFilters(filters: FrappeRequestFilterInterface[]): FrappeRequestHelper {
    filters.forEach((filter) => this.withFilter(filter));
    return this;
  }

  withOrderBy(field: string, direction: "asc" | "desc"): FrappeRequestHelper {
    this.orderBy = `${field} ${direction}`;
    return this;
  }

  withFilter(filter: FrappeRequestFilterInterface): FrappeRequestHelper {
    if (filter.filterType() == "AND") {
      this.filters = [...this.filters, filter.filter()];
    } else {
      this.orFilters = [...this.orFilters, filter.filter()];
    }
    return this;
  }

  withFields(fields: string[]): FrappeRequestHelper {
    this.fields = fields;
    return this;
  }

  withPageLength(length: number): FrappeRequestHelper {
    if (length > 0) {
      this.pageLength = length;
    }
    return this;
  }

  withParam(key: string, value: string): FrappeRequestHelper {
    this.params = this.params.append(key, value);
    return this;
  }

  buildParams(): HttpParams {
    if (this.filters && this.filters.length > 0) {
      this.params = this.params.append("filters", JSON.stringify(this.filters));
    }

    if (this.orFilters && this.orFilters.length > 0) {
      this.params = this.params.append(
        "or_filters",
        JSON.stringify(this.orFilters),
      );
    }
    if (this.fields && this.fields.length > 0) {
      this.params = this.params.append("fields", JSON.stringify(this.fields));
    }

    if (this.pageLength) {
      this.params = this.params.append("limit_page_length", this.pageLength);
    }
    if (this.orderBy) {
      this.params = this.params.append("order_by", this.orderBy);
    }
    return this.params;
  }
}

// not sure about the valid operators but for now we only have "=" and "!="
export type FrappeRequestFilterOperator = "=" | "!=";
export type FilterType = "AND" | "OR";

export abstract class FrappeRequestFilters {
  private constructor() {}

  static in(names: string[]): FrappeRequestFilterInterface[] {
    return names.map((name) => {
      return {
        filter() {
          return ["name", "=", name];
        },
        filterType() {
          return "OR";
        },
      };
    });
  }
}

export class FrappeRequestFilter implements FrappeRequestFilterInterface {
  constructor(
    private readonly field: string,
    private readonly operator: FrappeRequestFilterOperator,
    private readonly filterValue: string,
  ) {}

  filterType(): FilterType {
    return "AND";
  }

  filter(): string[] {
    return [this.field, this.operator, this.filterValue];
  }

  static byName(name: string): FrappeRequestFilter {
    return new FrappeRequestFilter("name", "=", name);
  }

  static submitted(): FrappeRequestFilter {
    return new FrappeRequestFilter("docstatus", "=", "1");
  }

  static byOwner(owner: string): FrappeRequestFilter {
    return new FrappeRequestFilter("owner", "=", owner);
  }
}
