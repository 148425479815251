import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { PurchasedItemDtoConversion } from "src/app/conversions/purchase/purchased-item-dto.conversion";
import { CreditStatement } from "src/app/models/sales/credit-statement";
import { CreditStatementConversion } from "src/app/conversions/sales/credit-statement-conversion";
import { CreditPayout } from "src/app/models/sales/credit-payout";
import { CreditPayoutConversion } from "src/app/conversions/sales/credit-payout-conversion";
import { map } from "rxjs/operators";
import { purchase } from "src/app/ngrx/actions/purchase.actions";
import { noConversion } from "../conversion-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor(private readonly http: HttpClient) {}

  loadSales(): Observable<PurchasedItem[]> {
    const conversion = new PurchasedItemDtoConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.sales_controller.sold_items";

    const queryHelper = new FrappeRequestHelper();
    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  loadCreditStatements(): Observable<CreditStatement[]> {
    const conversion = new CreditStatementConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.sales_controller.credit_statements";

    const queryHelper = new FrappeRequestHelper();
    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  loadCreditPayouts(): Observable<CreditPayout[]> {
    const conversion = new CreditPayoutConversion();
    const crudHelper = new FrappeCrudHelper(
      "Credit Payout",
      this.http,
      conversion,
    );
    const queryHelper = new FrappeRequestHelper()
      .withFields(["*"])
      .withFilter(FrappeRequestFilter.submitted());

    return crudHelper.queryAll(queryHelper);
  }

  downloadCreditStatement(creditStatement: CreditStatement): Observable<Blob> {
    const methodHelper = new FrappeMethodHelper(this.http, noConversion);
    const method =
      "yobi_rocks.controllers.sales_controller.download_credit_statement";
    const data = new MethodDataWrapper().withDto({
      credit_statement: creditStatement.name,
    });
    return methodHelper.callMethod(method, data).pipe(
      map((data) => {
        const array = new Uint8Array(data);
        const blob = new Blob([array], { type: "application/pdf" });
        return blob;
      }),
    );
  }
}
