import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import {
  ArtistCertification,
  ArtistCertificationState,
} from "src/app/models/user/artist-certification";
import {
  ArtistProfileActions,
  ArtistCertificationActions,
} from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import {
  ArtistCertificationSelectors,
  LicenseSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ConfirmationDialogComponent } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ArtistProfileLinkComponent } from "../artist-profile-link/artist-profile-link.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-create-profile-hint",
  templateUrl: "./create-profile-hint.component.html",
  styleUrl: "./create-profile-hint.component.scss",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ArtistProfileLinkComponent,
    MaterialModule,
  ],
})
export class CreateProfileHintComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new SubscriptionService();
  private artistCertification?: ArtistCertification;

  isLoading: boolean = true;

  canCreate = false;
  isStarted = false;
  isPending = false;
  isApproved = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      ArtistProfileActions.loadArtistProfileForCurrentUser({}),
    );
    this.store.dispatch(ArtistCertificationActions.loadArtistCertification());

    this.subscriptions.addMany(
      this.store
        .select(ArtistCertificationSelectors.selectArtistCertificationState)
        .subscribe((state) => {
          this.artistCertification = state.artistCertification;
          this.isLoading = state.isLoading;

          this.isStarted =
            state.artistCertification?.state ==
            ArtistCertificationState.Started;
          this.isPending =
            state.artistCertification?.state ==
            ArtistCertificationState.Pending;
          this.isApproved =
            state.artistCertification?.state ==
            ArtistCertificationState.Approved;
        }),
      this.store.select(LicenseSelectors.selectUserLicense).subscribe((ul) => {
        this.canCreate = ul.license.canBecomeArtist;
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  createProfile() {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title: "",
        content:
          "artist-certification.create-profile-hint.create-profile-confirmation",
      },
    });

    return ref.afterClosed().subscribe((shouldCreate) => {
      if (shouldCreate) {
        this.store.dispatch(ArtistCertificationActions.createCertification());
      }
    });
  }

  requestReview() {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title:
          "artist-certification.create-profile-hint.request-review-confirmation-title",
        content:
          "artist-certification.create-profile-hint.request-review-confirmation-content",
      },
    });

    return ref.afterClosed().subscribe((shouldReview) => {
      if (shouldReview) {
        this.store.dispatch(ArtistCertificationActions.requestReview());
      }
    });
  }
}
