import { ExploreMusicPiece } from "../explore/explore-music-piece";
import { MusicPiece } from "./music-piece";
import { MusicPieceListing } from "./music-piece-listing";

/// this interface is used for the thumbnail of the music piece and has nothing to do with the recording's thumbnail
export interface MusicPieceThumbnail {
  uploadFinished: boolean;
  downloadLink: string;
  contentType: string;
}

export abstract class MusicPieceThumbnail {
  static fromMusicPiece(
    musicPiece: MusicPiece,
  ): MusicPieceThumbnail | undefined {
    if (musicPiece && musicPiece.uploadFinished) {
      return {
        contentType: musicPiece.contentType,
        downloadLink: musicPiece.downloadLink,
        uploadFinished: musicPiece.uploadFinished,
      };
    }
    return;
  }

  static fromMusicPieceListing(
    musicPieceListing: MusicPieceListing,
  ): MusicPieceThumbnail | undefined {
    if (
      musicPieceListing &&
      musicPieceListing.musicPieceThumbnailUploadFinished &&
      musicPieceListing.musicPieceThumbnailDownloadLink
    ) {
      return {
        contentType: "", // missing from musicPieceListing??
        downloadLink: musicPieceListing.musicPieceThumbnailDownloadLink,
        uploadFinished: musicPieceListing.musicPieceThumbnailUploadFinished,
      };
    }
    return;
  }

  static fromExploreMusicPiece(
    exploreMusicPiece: ExploreMusicPiece,
  ): MusicPieceThumbnail | undefined {
    if (
      exploreMusicPiece &&
      exploreMusicPiece.musicPieceThumbnailUploadFinished &&
      exploreMusicPiece.musicPieceThumbnailDownloadLink
    ) {
      return {
        contentType: "",
        downloadLink: exploreMusicPiece.musicPieceThumbnailDownloadLink,
        uploadFinished: exploreMusicPiece.musicPieceThumbnailUploadFinished,
      };
    }
    return;
  }
}
