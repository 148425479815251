import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StoredMarkerDtoToMarkerConversion } from "src/app/conversions/marker/stored-marker-dto-to-marker-conversion";
import { CreateMarkerDto } from "src/app/dtos/marker/create-marker-dto";
import { UpdateMarkerDto } from "src/app/dtos/marker/update-marker-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { Marker } from "src/app/models/marker/marker";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  constructor(private readonly http: HttpClient) {}

  loadMarkers(
    recordingPartGroup: RecordingPartGroup,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<Marker[]> {
    const conversion = new StoredMarkerDtoToMarkerConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.marker_controller.get_markers";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording_part_group", recordingPartGroup.name)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  addMarker(inGroup: RecordingPartGroup, marker: Marker): Observable<Marker> {
    const dto: CreateMarkerDto = {
      recording_part_group: inGroup.name,
      can_delete: marker.canDelete ? 1 : 0,
      timestamp: marker.timestampMs,
      title: marker.title,
    };
    const conversion = new StoredMarkerDtoToMarkerConversion();
    const queryHelper = new FrappeCrudHelper("Marker", this.http, conversion);
    return queryHelper.create(dto);
  }

  updateMarker(
    inGroup: RecordingPartGroup,
    marker: Marker
  ): Observable<Marker> {
    const dto: UpdateMarkerDto = {
      recording_part_group: inGroup.name,
      can_delete: marker.canDelete ? 1 : 0,
      timestamp: marker.timestampMs,
      title: marker.title,
    };
    const conversion = new StoredMarkerDtoToMarkerConversion();
    const queryHelper = new FrappeCrudHelper("Marker", this.http, conversion);
    return queryHelper.update(marker.name, dto);
  }

  deleteMarker(marker: Marker): Observable<void> {
    const queryHelper = new FrappeCrudHelper("Marker", this.http);
    return queryHelper.delete(marker.name);
  }
}
