import { Component } from "@angular/core";
import { ToolBarSecondRowComponent } from "../../common/tool-bar-second-row/tool-bar-second-row.component";
import { ExploreSearchComponent } from "../explore-search/explore-search.component";
import { ExploreComponent } from "../explore/explore.component";

@Component({
  selector: "yr-explore-page",
  templateUrl: "./explore-page.component.html",
  styleUrls: ["./explore-page.component.scss"],
  standalone: true,
  imports: [
    ToolBarSecondRowComponent,
    ExploreSearchComponent,
    ExploreComponent,
  ],
})
export class ExplorePageComponent {}
