<h1>{{ "purchase.purchases.title" | translate }}</h1>

<yr-stripe-portal [returnUrl]="stripePortalReturnUrl"></yr-stripe-portal>

<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingPurchases"
></mat-progress-bar>

<yr-no-purchases-item
  *ngIf="!isLoadingPurchases && purchasedItems.length == 0"
></yr-no-purchases-item>

<yr-purchased-item
  *ngFor="let purchase of purchasedItems"
  [purchasedItem]="purchase"
></yr-purchased-item>
