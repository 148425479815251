import { StoredArtistProfileDto } from "src/app/dtos/artist/stored-artist-profile-dto";
import {
  StoredArtistProfile,
  StoredArtistProfileEvent,
  StoredArtistProfileLink,
  UrlTypeHelper,
} from "src/app/models/artist/stored-artist-profile";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class StoredArtistProfileConversion
  implements Conversion<StoredArtistProfileDto, StoredArtistProfile>
{
  private readonly us: UnitService = new UnitService();

  sortEventByDate(a: StoredArtistProfileEvent, b: StoredArtistProfileEvent) {
    return b.date.valueOf() - a.date.valueOf();
  }
  sortLinkByDate(a: StoredArtistProfileLink, b: StoredArtistProfileLink) {
    return b.created.valueOf() - a.created.valueOf();
  }

  convert(dto: StoredArtistProfileDto): StoredArtistProfile {
    return {
      name: dto.name,
      artistName: dto.artist_name,
      user: dto.user,
      created: this.us.parseApiDate(dto.creation),
      uploadFinished: dto.upload_finished > 0,
      downloadLink: dto.download_link,
      contentType: dto.content_type,
      aboutMe: dto.about_me,
      instruments: dto.instruments,
      motto: dto.motto,
      teachingLocation: dto.teaching_location,
      website: dto.website,
      links: (dto.links || [])
        .map((l) => {
          return {
            url: l.url,
            title: l.title,
            name: l.name,
            urlType: UrlTypeHelper.urlType(l.url_type),
            created: this.us.parseApiDate(l.creation),
          };
        })
        .sort((a, b) => this.sortLinkByDate(a, b)),
      events: (dto.events || [])
        .map((e) => ({
          date: this.us.parseApiDate(e.date),
          name: e.name,
          title: e.title,
          description: e.description,
          url: e.url,
        }))
        .sort((a, b) => this.sortEventByDate(a, b)),
      teaser: dto.teaser,
      customUrl: dto.custom_url,
      logoContentType: dto.logo_content_type,
      logoDownloadLink: dto.logo_download_link,
      logoUploadFinished: dto.logo_upload_finished > 0,
      offersVideoFeedback: dto.offers_video_feedback > 0,
      videoFeedbackPrice: dto.video_feedback_price || 0,
    };
  }
}
