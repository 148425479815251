<div visibility (visible)="handleVisibility($event)" class="video-container">
  <!-- <pre class="text-white">{{ whatToShow | json }}</pre> -->

  <video
    *ngIf="whatToShow.showPreview"
    #video
    class="video-js vjs-big-play-centered"
    [class.vjs-big-play-centered]="!whatToShow.showThumbnailPlay"
    playsinline
    webkit-playsinline
  >
    <source
      *ngIf="preview && preview.url"
      [src]="preview.url"
      [type]="preview.contentType"
    />
  </video>

  <div
    class="hidden-placeholder"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="whatToShow.showHiddenPlaceholder"
  >
    <mat-icon>perm_media</mat-icon>
  </div>

  <div
    class="no-video-placeholder"
    *ngIf="whatToShow.showNoVideoPlaceholder"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>videocam_off</mat-icon>
  </div>

  <div
    class="thumbnail-container video-js vjs-big-play-centered"
    *ngIf="whatToShow.showThumbnail"
  >
    <img
      class="aspect-video w-full object-cover"
      *ngIf="musicPieceThumbnail"
      [src]="musicPieceThumbnail.downloadLink"
    />
    <button
      *ngIf="whatToShow.showThumbnailPlay"
      class="vjs-big-play-button"
      type="button"
      title="Play Video"
      aria-disabled="false"
      (click)="play()"
    >
      <span class="vjs-icon-placeholder" aria-hidden="true"></span
      ><span class="vjs-control-text" aria-live="polite">Play Video</span>
    </button>
  </div>
</div>
