import { ExtendableConversion } from "../extendable-conversion";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";

export class ExploreCollectionToShowcaseCollectionConversion extends ExtendableConversion<
  ExploreCollection,
  ShowcaseCollectionListing
> {
  convert(ec: ExploreCollection): ShowcaseCollectionListing {
    return {
      artist: ec.artist.artistName,
      collection: ec.collection,
      contentType: ec.contentType,
      created: ec.created,
      description: ec.description,
      downloadLink: ec.artist.downloadLink,
      instrument: ec.instrument,
      items: ec.items,
      name: ec.name,
      temperament: ec.temperament,
      title: ec.title,
      price: ec.price,
      isPracticeRoutine: ec.isPracticeRoutine,
    };
  }
}
