<div class="flex w-full flex-row justify-center bg-gray-200">
  <div class="mx-4 pb-16 pt-8 md:mx-0 md:w-4/5 md:max-w-7xl">
    <div class="grid grid-cols-1 gap-x-16 xl:grid-cols-4">
      <div>
        <div
          class="my-4 break-normal text-xl font-semibold leading-normal text-black md:text-2xl lg:text-3xl 2xl:text-4xl"
        >
          <!-- claim -->
          {{ "artist.partner-footer.claim" | translate }}
        </div>
      </div>
      <div>
        <!-- partner -->
        <div class="my-4 text-2xl font-semibold text-gray-800">
          {{ "artist.partner-footer.become-partner-title" | translate }}
        </div>
        <div
          class="whitespace-pre-line break-normal text-lg leading-normal text-gray-500"
        >
          {{ "artist.partner-footer.become-partner-text" | translate }}
        </div>
      </div>
      <div>
        <!-- about -->
        <div class="my-4 text-2xl font-semibold text-gray-800">
          {{ "artist.partner-footer.about-title" | translate }}
        </div>
        <div class="break-normal text-lg text-gray-500">
          {{ "artist.partner-footer.about-hint" | translate }}
        </div>
        <div class="break-normal text-lg leading-normal text-gray-800">
          {{ artistProfile?.artistName }}
        </div>
      </div>
      <div>
        <!-- terms -->
        <div class="my-4 text-2xl font-semibold text-gray-800">
          {{ "artist.partner-footer.terms-title" | translate }}
        </div>
        <div class="break-normal text-lg leading-normal">
          <a [href]="termsLink" class="text-gray-500 hover:text-as-500">{{
            "artist.partner-footer.terms" | translate
          }}</a>
          <br />
          <a [href]="gdprLink" class="text-gray-500 hover:text-as-500">{{
            "artist.partner-footer.gdpr" | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
