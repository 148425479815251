import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserLicenseDtoToUserLicenseConversion } from "src/app/conversions/user/user-license-dto-to-user-license-conversion";
import { License, UserLicense } from "src/app/models/user/user-license";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FallbackAndUpgradeLicenseConversion } from "src/app/conversions/user/fallback-and-upgrade-license-dto-conversion";
import { FallbackAndUpgradeLicense } from "src/app/models/user/fallback-and-upgrade-license";
import { BillingInterval } from "src/app/models/license/billing-interval";
import { SubscriptionCheckoutSession } from "src/app/models/license/subscription-checkout-session";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import { SubscriptionCheckoutSessionConversion } from "src/app/conversions/license/subscription-checkout-session-conversion";
import { environment } from "src/environments/environment";
import { CustomerPortalSessionConversion } from "src/app/conversions/license/customer-portal-session-conversion";
import { CustomerPortalSession } from "src/app/models/license/customer-portal-session";
import { map, tap } from "rxjs/operators";
import { MatomoTracker } from "ngx-matomo-client";
import { CheckoutSession } from "src/app/models/license/checkout-session";
import { CheckoutSessionConversion } from "src/app/conversions/license/chekcout-session-conversion";
import {
  FrappeRequestFilter,
  FrappeRequestFilters,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class LicenseService {
  constructor(
    private readonly http: HttpClient,
    private readonly tracker: MatomoTracker,
  ) {}

  licenseForUser(): Observable<UserLicense> {
    const method =
      "api/method/yobi_rocks.controllers.license_controller.user_license";
    const conversion = new UserLicenseDtoToUserLicenseConversion();
    const helper = new FrappeCrudHelper(
      "", // not required
      this.http,
      conversion,
    );

    return helper.getSingle("", method); // name is not required
  }

  loadFallbackAndUpgrade(): Observable<FallbackAndUpgradeLicense> {
    const method =
      "api/method/yobi_rocks.controllers.license_controller.fallback_and_upgrade";
    const conversion = new FallbackAndUpgradeLicenseConversion();
    const helper = new FrappeCrudHelper(
      "", // not required as we override the method
      this.http,
      conversion,
    );

    return helper.getSingle("", method);
  }

  purchaseSubscription(
    license: string,
    billingInterval: BillingInterval,
  ): Observable<SubscriptionCheckoutSession> {
    const method =
      "yobi_rocks.controllers.license_controller.purchase_subscription";
    const data = new MethodDataWrapper().withDto({
      license: license,
      billing_interval: billingInterval,
      success_url: `${environment.guiBaseUrl}/subscription-success?session={CHECKOUT_SESSION_ID}`,
      cancel_url: `${environment.guiBaseUrl}/subscription-failure`,
    });

    const conversion = new SubscriptionCheckoutSessionConversion();

    return new FrappeMethodHelper(this.http, conversion)
      .callMethod(method, data)
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("stripe", "purchase-license", license);
        }),
      );
  }

  openCustomerPortal(returnUrl: String): Observable<CustomerPortalSession> {
    const method =
      "yobi_rocks.controllers.subscription_controller.customer_portal";
    const data = new MethodDataWrapper().withDto({
      return_url: returnUrl,
    });

    const conversion = new CustomerPortalSessionConversion();

    return new FrappeMethodHelper(this.http, conversion)
      .callMethod(method, data)
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("stripe", "open-customer-portal");
        }),
      );
  }

  loadCheckoutSessionStatus(
    stripeCheckoutSessionId: string,
  ): Observable<CheckoutSession | undefined> {
    const crud = new FrappeCrudHelper(
      "Stripe Checkout Session",
      this.http,
      new CheckoutSessionConversion(),
    );
    const requestHelper = new FrappeRequestHelper()
      .withFields(["*"])
      .withFilter(
        new FrappeRequestFilter(
          "stripe_checkout_session_id",
          "=",
          stripeCheckoutSessionId,
        ),
      );

    return crud.queryFirst(requestHelper);
  }
}
