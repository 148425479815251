import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { SharedMusicPieceReceiver } from "src/app/models/music-piece/shared-music-piece-receiver";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState, EditMusicPieceSelectors } from "src/app/ngrx/reducers";
import { ConfirmationDialogHelper } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-shared-music-piece-receivers",
  templateUrl: "./shared-music-piece-receivers.component.html",
  styleUrls: ["./shared-music-piece-receivers.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class SharedMusicPieceReceiversComponent implements OnInit {
  receivers: SharedMusicPieceReceiver[] = [];
  musicPiece?: MusicPiece;

  dataSource: MatTableDataSource<SharedMusicPieceReceiver> =
    new MatTableDataSource<SharedMusicPieceReceiver>();

  displayedColumns: string[] = ["receiver", "name", "sharedDate", "actions"];

  filterText?: string;

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.store
      .select(EditMusicPieceSelectors.selectSharedMusicPieceReceivers)
      .subscribe((data) => {
        this.receivers = data;
        this.dataSource.data = data;
      });

    this.store
      .select(EditMusicPieceSelectors.selectEditMusicPieceData)
      .subscribe((data) => {
        this.musicPiece = data.musicPiece;
      });

    this.dataSource.filterPredicate = (receiver, filter) => {
      return `${receiver.email}.${receiver.name}.${receiver.creation}`
        .toLowerCase()
        .includes(filter.toLowerCase());
    };
  }

  deleteShare(data: SharedMusicPieceReceiver) {
    const helper = new ConfirmationDialogHelper(
      this.matDialog,
      "edit-music-piece.shared-music-piece-receivers.delete-dialog-title",
      "edit-music-piece.shared-music-piece-receivers.delete-dialog-content",
    );
    helper.show(() => {
      if (this.musicPiece) {
        this.store.dispatch(
          EditMusicPieceActions.unshareMusicPiece({
            musicPiece: this.musicPiece,
            linkedMusicPiece: data.linkedMusicPiece,
          }),
        );
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.filterText || "";
  }
}
