import { Component, Input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { ImageUploadDialogData } from "src/app/models/common/image-upload-dialog-data";
import { ImageUploadDialogResult } from "src/app/models/common/image-upoload-dialog-result";
import {
  PrepareUpload,
  FinishUpload,
  CreateOrGetDocument,
} from "src/app/models/common/upload-types";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { ImageUploadDialogComponent } from "../../common/image-upload-dialog/image-upload-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ArtistProfileService } from "src/app/services/artist/artist-profile-service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/ngrx/reducers";
import { map } from "rxjs/operators";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import { ConfirmationDialogComponent } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-partner-logo",
  templateUrl: "./partner-logo.component.html",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PartnerLogoComponent {
  @Input()
  isInEditMode = false;

  @Input()
  isOwner = false;

  @Input()
  artistProfile?: StoredArtistProfile;

  isUploadingLogo: boolean = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly matDialog: MatDialog,
    private readonly toastr: ToastrService,
    private readonly artistProfileService: ArtistProfileService,
    private readonly store: Store<AppState>,
  ) {}

  uploadLogo() {
    const uploadInProgress = new Subject<boolean>();
    const uploadChanged = new Subject<AttachmentUploadResult>();

    uploadInProgress.subscribe((e) => this.uploadInProgress(e));
    uploadChanged.subscribe((e) => this.uploadChanged(e));

    const ref = this.matDialog.open<
      ImageUploadDialogComponent,
      ImageUploadDialogData,
      ImageUploadDialogResult
    >(ImageUploadDialogComponent, {
      data: {
        height: 200,
        width: 200,
        accept: "image/png,image/jpeg",
        prepareUpload: this.prepareUploadUrl(),
        finishUpload: this.finishUpload(),
        createOrGetDocument: this.getArtistProfile(),
        uploadChange: uploadChanged,
        uploadInProgress: uploadInProgress,
      },
      maxHeight: "80vh",
      minWidth: "20vw",
      maxWidth: "80vw",
    });

    const subscription = ref.afterClosed().subscribe((result) => {
      uploadChanged.unsubscribe();
      uploadInProgress.unsubscribe();
      subscription.unsubscribe();
    });
  }

  getArtistProfile(): CreateOrGetDocument {
    return () => {
      if (!this.artistProfile) {
        return Promise.reject("artist profile not set");
      }

      return Promise.resolve(this.artistProfile);
    };
  }

  prepareUploadUrl(): PrepareUpload {
    return (name: string) =>
      this.artistProfileService.prepareLogoUploadUrl(name);
  }

  finishUpload(): FinishUpload {
    return (name: string) => {
      return this.artistProfileService.markLogoUploadAsFinished(name);
    };
  }

  uploadChanged(result: AttachmentUploadResult) {
    if (result) {
      if (result.result == "Success") {
        this.toastr.success(this.translate.instant("common.success"));

        if (this.artistProfile) {
          this.store.dispatch(
            ArtistProfileActions.loadArtistProfile({
              name: this.artistProfile.name,
            }),
          );
        }
      } else {
        console.log(`upload failed: ${result?.error}`);
        this.toastr.error(this.translate.instant("common.fail"));
      }
    }
  }

  uploadInProgress(isUploading: boolean) {
    this.isUploadingLogo = isUploading;
  }

  private showDeleteDialog(): Observable<boolean> {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title: "artist.artist-profile.delete-logo-dialog.title",
        content: "artist.artist-profile.delete-logo-dialog.content",
      },
    });

    return ref.afterClosed().pipe(map((r) => r == true));
  }

  deleteLogo() {
    this.showDeleteDialog().subscribe((result) => {
      if (result && this.artistProfile) {
        this.store.dispatch(
          ArtistProfileActions.deleteLogo({
            profile: this.artistProfile,
          }),
        );
      }
    });
  }
}
