<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title *ngIf="isNew">
      {{ "edit-music-piece.edit-music-piece.create-new-title" | translate }}
    </mat-card-title>

    <mat-card-title *ngIf="!isNew && !isLoading && musicPiece">
      <div fxLayout="row" fxLayoutAlign="start center">
        <yr-music-piece-type
          *ngIf="!isLoading"
          [isCopiedMusicPiece]="musicPiece.isCopy"
          [isLinkedMusicPiece]="linkedMusicPiece != undefined"
        ></yr-music-piece-type>
        {{ musicPiece.nameOfPiece }}
      </div>
    </mat-card-title>

    <mat-card-subtitle>
      <div *ngIf="musicPiece" fxLayout="row" fxLayoutAlign="start" fxFill>
        <span *ngIf="musicPiece.creation" class="date">
          {{ "music-piece.music-piece-card.createdAt" | translate }}
          {{
            musicPiece.creation | date: ("common.dateTimeFormat" | translate)
          }}
        </span>
        <span *ngIf="musicPiece.lastTimeViewed" class="date">
          {{ "music-piece.music-piece-card.lastViewed" | translate }}
          {{
            musicPiece.lastTimeViewed
              | date: ("common.dateTimeFormat" | translate)
          }}
        </span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    @if (!isLoading) {
      <button
        class="my-2 flex rounded bg-as-500 p-1 align-middle text-base text-white hover:bg-as-600 disabled:bg-zinc-200 disabled:text-zinc-300"
        (click)="navigateToPlayer()"
        [disabled]="!hasPracticeDurationLeft()"
      >
        <mat-icon class="items-center">music_note</mat-icon>
        <span class="items-center">Werkbank</span>
      </button>
    }
    <div *ngIf="isNew || !isLinkedMusicPiece">
      <form [formGroup]="form">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>
              {{
                "edit-music-piece.edit-music-piece.name-of-music-piece"
                  | translate
              }}
            </mat-label>
            <input
              matInput
              [formControl]="nameOfPiece"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.name-of-music-piece'
                  | translate
              }}"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              {{ "edit-music-piece.edit-music-piece.artist" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="artist"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.artist' | translate
              }}"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              {{ "edit-music-piece.edit-music-piece.instrument" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="instrument"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.instrument' | translate
              }}"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              {{ "edit-music-piece.edit-music-piece.temperament" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="temperament"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.temperament' | translate
              }}"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              {{ "edit-music-piece.edit-music-piece.description" | translate }}
            </mat-label>
            <textarea
              matInput
              [formControl]="description"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.description' | translate
              }}"
            ></textarea>
          </mat-form-field>

          <!-- <mat-form-field>
            <mat-label>
              {{ "edit-music-piece.edit-music-piece.collection" | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="collection"
              type="text"
              placeholder="{{
                'edit-music-piece.edit-music-piece.collection' | translate
              }}"
            />
          </mat-form-field> -->

          <div
            *ngIf="
              !isNew &&
              !musicPiece?.isCopy &&
              !isLinkedMusicPiece &&
              hasShareLicense
            "
          >
            <mat-checkbox
              matInput
              [formControl]="acceptShareTerms"
              [disabled]="musicPiece?.acceptShareTerms == true"
            >
              {{
                "edit-music-piece.edit-music-piece.accept-share-terms"
                  | translate
              }}
            </mat-checkbox>
            <p
              class="can-not-revoke-terms-hint"
              *ngIf="musicPiece?.acceptShareTerms == true"
            >
              {{
                "edit-music-piece.edit-music-piece.can-not-revoke-terms-hint"
                  | translate
              }}
            </p>

            <p
              class="share-terms-hint"
              *ngIf="musicPiece?.acceptShareTerms == false"
            >
              {{ "edit-music-piece.edit-music-piece.share-terms" | translate }}
            </p>
          </div>

          <yr-edit-listing></yr-edit-listing>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row" fxLayoutAlign="end">
      <button
        *ngIf="isNew || !isLinkedMusicPiece"
        class="save-button"
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="form.invalid"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-card>
