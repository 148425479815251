<div class="mx-auto w-full md:w-4/5">
  <div class="mx-2 mt-2 flex flex-col">
    <yr-user-settings></yr-user-settings>
    <yr-notification-settings></yr-notification-settings>
    <yr-update-password></yr-update-password>
    <yr-feedback></yr-feedback>
    <yr-news-list></yr-news-list>
    <div class="fx-row flex justify-center">
      <yr-version></yr-version>
    </div>
  </div>
</div>
