import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { StoredUser } from "src/app/models/user/stored-user";
import { UserActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import {
  LanguageData,
  LanguageService,
} from "src/app/services/language/language.service";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class UserSettingsComponent implements OnInit {
  isLoading = true;

  storedUser?: StoredUser;
  languages: LanguageData[] = [];

  langSelect = new UntypedFormControl();

  form = new UntypedFormGroup({
    langSelect: this.langSelect,
  });

  constructor(
    private readonly store: Store<AppState>,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.languages = this.languageService.availableLanguages();
    if (this.languages.length > 0) {
      this.langSelect.patchValue(this.languages[0]);
    }

    this.store.dispatch(UserActions.loadUserDetails());

    this.store.select(UserSelectors.currentUserDetails).subscribe((user) => {
      this.storedUser = user;
      this.setUpUserLanguage();
      this.isLoading = false;
    });

    this.langSelect.valueChanges.subscribe((language: LanguageData) => {
      if (
        language &&
        !this.isLoading &&
        this.storedUser?.language != language.lang
      ) {
        console.log(`switch language to: ${language.name} - ${language.lang}`);
        this.store.dispatch(
          UserActions.switchLanguage({ language: language.lang }),
        );
      }
    });
  }

  setUpUserLanguage() {
    if (this.storedUser) {
      const languageCode = this.storedUser.language;
      const language = this.languages.find((l) => l.lang == languageCode);
      this.langSelect.patchValue(language);
    }
  }
}
