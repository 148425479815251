import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MusicPieceDtoToMusicPieceConversion } from "../../conversions/music-piece/music-piece-dto-to-music-piece-conversion";

import { MusicPiece } from "src/app/models/music-piece/music-piece";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import { DateTime } from "luxon";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import { asStringConversion, dropConversion } from "../conversion-helper";
import { SharedMusicPieceReceiver } from "src/app/models/music-piece/shared-music-piece-receiver";
import { SharedMusicPieceDtoConversion } from "src/app/conversions/music-piece/shared-music-piece-dto-converstion";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { MusicPieceListingDtoConversion } from "src/app/conversions/music-piece/music-piece-listing-dto-to-music-piece-listing-conversion";
import { Preview } from "src/app/models/music-piece/preview";
import { PreviewDtoToPreviewConversion } from "src/app/conversions/music-piece/preview-dto-to-preview.conversion";
import { MatomoTracker } from "ngx-matomo-client";

@Injectable({
  providedIn: "root",
})
export class MusicService {
  constructor(
    private http: HttpClient,
    private dtoToMusicPieceConversion: MusicPieceDtoToMusicPieceConversion,
    private tracker: MatomoTracker,
  ) {}

  getMusicPieces(): Observable<MusicPiece[]> {
    const queryHelper = new FrappeRequestHelper().withFields([
      "name",
      "name_of_piece",
      "last_time_viewed",
      "creation",
      "is_shared",
      "shared_from",
      "is_copy",
      "instrument",
      "temperament",
      "artist",
      "description",
      "collection",
      "accept_share_terms",
      "upload_finished",
      "download_link",
      "content_type",
    ]);

    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      this.dtoToMusicPieceConversion,
    );
    return crudHelper.queryAll(queryHelper);
  }

  getMusicPiece(name: string): Observable<MusicPiece> {
    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      this.dtoToMusicPieceConversion,
    );
    return crudHelper.getSingle(name);
  }

  createMusicPiece(
    nameOfPiece: string,
    instrument: string,
    temperament: string,
    artist: string,
    description: string,
    collection: string,
  ): Observable<MusicPiece> {
    const conversion = new MusicPieceDtoToMusicPieceConversion();
    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      conversion,
    );

    return crudHelper
      .create({
        name_of_piece: nameOfPiece,
        instrument: instrument,
        temperament: temperament,
        artist: artist,
        description: description,
        collection: collection,
      })
      .pipe(
        tap((piece) => {
          this.tracker.trackEvent("music-piece", "create", piece.name);
        }),
      );
  }

  updateMusicPiece(
    name: string,
    musicPieceName: string,
    acceptShareTerms: boolean,
    instrument: string,
    temperament: string,
    artist: string,
    description: string,
    collection: string,
  ): Observable<MusicPiece> {
    const conversion = new MusicPieceDtoToMusicPieceConversion();
    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      conversion,
    );

    return crudHelper
      .update(name, {
        name_of_piece: musicPieceName,
        accept_share_terms: acceptShareTerms ? 1 : 0,
        instrument: instrument,
        temperament: temperament,
        artist: artist,
        description: description,
        collection: collection,
      })
      .pipe(
        tap((_) => {
          this.tracker.trackEvent("music-piece", "edit", name);
        }),
      );
  }

  markLinkedMusicPieceAsViewed(name: string): Observable<void> {
    const formattedLastTimeViewed = DateTime.now().toFormat(
      environment.apiDateFormat,
    );
    const crud = new FrappeCrudHelper(
      "Linked Music Piece",
      this.http,
      dropConversion,
    );
    return crud
      .update(name, {
        last_time_viewed: formattedLastTimeViewed,
      })
      .pipe(
        tap((_) => this.tracker.trackEvent("linked-music-piece", "view", name)),
      );
  }

  markMusicPieceAsViewed(name: string): Observable<void> {
    const formattedLastTimeViewed = DateTime.now().toFormat(
      environment.apiDateFormat,
    );

    const crudHelper = new FrappeCrudHelper(
      "Music Piece",
      this.http,
      dropConversion,
    );

    return crudHelper
      .update(name, {
        last_time_viewed: formattedLastTimeViewed,
      })
      .pipe(
        tap((_) => {
          this.tracker.trackEvent("music-piece", "view", name);
        }),
      );
  }

  deleteMusicPiece(
    musicPiece?: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method = "yobi_rocks.controllers.music_piece_controller.soft_delete";
    const data = new MethodDataWrapper()
      .withParam("music_piece", musicPiece?.name || "")
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return helper.callMethod(method, data, "DELETE").pipe(
      tap((_) => {
        this.tracker.trackEvent(
          "music-piece",
          "delete",
          musicPiece?.name || linkedMusicPiece?.name || "", // XXX: should we distinguish between music piece and linked music piece?
        );
      }),
    );
  }

  share(name: string, email: string): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method = "yobi_rocks.controllers.music_piece_controller.share";

    const dataWrapper = new MethodDataWrapper().withDto({
      name: name,
      email: email,
    });

    return helper.callMethod(method, dataWrapper).pipe(
      tap((_) => {
        this.tracker.trackEvent("music-piece", "share", name);
        this.tracker.trackEvent("music-piece", "share-received", email);
      }),
    );
  }

  unshare(linkedMusicPiece: string): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method = "yobi_rocks.controllers.music_piece_controller.unshare";

    const dataWrapper = new MethodDataWrapper().withDto({
      linked_music_piece: linkedMusicPiece,
    });

    return helper.callMethod(method, dataWrapper).pipe(
      tap((_) => {
        this.tracker.trackEvent(
          "music-piece",
          "unshare-linked-music-piece",
          linkedMusicPiece,
        );
      }),
    );
  }

  getReceivers(musicPiece: string): Observable<SharedMusicPieceReceiver[]> {
    const conversion = new SharedMusicPieceDtoConversion();
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.music_piece_controller.shared_music_piece_receivers";

    const helper = new FrappeCrudHelper("", this.http, conversion);
    const requestHelper = new FrappeRequestHelper().withParam(
      "name",
      musicPiece,
    );
    return helper.queryAll(requestHelper, customQueryMethod);
  }

  getListingForMusicPiece(
    musicPiece: string,
  ): Observable<MusicPieceListing | undefined> {
    const conversion = new MusicPieceListingDtoConversion();
    const crud = new FrappeCrudHelper(
      "Music Piece Listing",
      this.http,
      conversion,
    );

    const helper = new FrappeRequestHelper()
      .withFilter(new FrappeRequestFilter("music_piece", "=", musicPiece))
      .withFields([
        "name",
        "music_piece",
        "price",
        "creation",
        "instrument",
        "temperament",
        "music_piece_artist",
        "description",
        "collection",
        "artist_profile_picture_download_link",
        "music_piece_thumbnail_download_link",
        "music_piece_thumbnail_upload_finished",
      ]);
    return crud.queryFirst(helper);
  }

  createListing(
    musicPiece: string,
    price: number,
  ): Observable<MusicPieceListing> {
    const conversion = new MusicPieceListingDtoConversion();
    const crud = new FrappeCrudHelper(
      "Music Piece Listing",
      this.http,
      conversion,
    );

    return crud.create({
      music_piece: musicPiece,
      price: price,
      is_listing: true,
    });
  }

  updateListing(name: string, price: number): Observable<MusicPieceListing> {
    const conversion = new MusicPieceListingDtoConversion();
    const crud = new FrappeCrudHelper(
      "Music Piece Listing",
      this.http,
      conversion,
    );

    return crud.update(name, {
      price: price,
      is_listing: true,
    });
  }

  deleteListing(name: string): Observable<void> {
    const crud = new FrappeCrudHelper(
      "Music Piece Listing",
      this.http,
      dropConversion,
    );

    return crud.delete(name);
  }

  findPreviews(musicPieces: MusicPiece[]): Observable<Preview[]> {
    const conversion = new PreviewDtoToPreviewConversion();
    const method = "yobi_rocks.controllers.music_piece_controller.previews";
    const helper = new FrappeMethodHelper(this.http, conversion.asMany());

    const dataWrapper = new MethodDataWrapper().withDto({
      music_pieces: (musicPieces || []).map((mp) => {
        return {
          name: mp.name,
        };
      }),
    });

    return helper.callMethod(method, dataWrapper);
  }

  prepareUploadUrl(musicPiece: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.music_piece_controller.create_upload_file_url";

    const helper = new FrappeMethodHelper(this.http, asStringConversion);
    const obs = helper
      .callMethod(
        method,
        new MethodDataWrapper().withDto({
          name: musicPiece,
        }),
      )
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markUploadAsFinished(musicPiece: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.music_piece_controller.upload_finished";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: musicPiece }),
    );
    return firstValueFrom(obs);
  }

  deleteProfilePicture(musicPiece: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.music_piece_controller.delete_profile_picture";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: musicPiece }),
    );
    return firstValueFrom(obs);
  }
}
