import { StoredMarkerDto } from "src/app/dtos/marker/stored-marker-dto";
import { Marker } from "src/app/models/marker/marker";
import { Conversion } from "../conversion";

export class StoredMarkerDtoToMarkerConversion
  implements Conversion<StoredMarkerDto, Marker>
{
  convert(item: StoredMarkerDto): Marker {
    return {
      canDelete: item.can_delete == 1,
      name: item.name,
      title: item.title,
      timestampMs: item.timestamp,
    };
  }
}
