<form [formGroup]="form" (submit)="submit()">
  <div class="my-2 whitespace-pre-line text-gray-500">
    {{ "artist.custom-artist-url.hint" | translate }}
  </div>
  <div *ngIf="customUrl && customUrl.length > 0" class="my-2 text-gray-500">
    <ul>
      <li>https://app.applaus.schule/&#64;{{ customUrl }}</li>
      <li>https://artist.applaus.schule/{{ customUrl }}</li>
      <li>https://artist.applaus.schule/&#64;{{ customUrl }}</li>
      <li>https://partner.applaus.schule/{{ customUrl }}</li>
      <li>https://partner.applaus.schule/&#64;{{ customUrl }}</li>
    </ul>
  </div>

  <mat-form-field>
    <input
      matInput
      [formControl]="customUrlForm"
      [placeholder]="
        'artist.custom-artist-url.custom-url-placeholder' | translate
      "
    />

    <!-- <span matTextPrefix class="text-gray-500"
      >https://app.applaus.schule/&#64;</span
    > -->
    <!--  &#64; is @ Sign -->

    <mat-error
      *ngIf="customUrlForm.hasError('customUrl')"
      class="whitespace-pre-line"
      >{{ "errors.custom-url" | translate }}
    </mat-error>

    <mat-error *ngIf="customUrlForm.hasError('maxlength')">
      {{ "errors.max-length" | translate }}
    </mat-error>
  </mat-form-field>

  <button mat-icon-button type="submit" [disabled]="form.invalid">
    <mat-icon>done</mat-icon>
  </button>
</form>
