import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StoredMusicScoreOverlayDtoConversion } from "src/app/conversions/music-score/stored-music-score-overlay-dto-conversion";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicScoreOverlay } from "src/app/models/music-score/music-score-overlay";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class MusicScoreOverlayService {
  constructor(private http: HttpClient) {}

  save(overlay: MusicScoreOverlay): Observable<MusicScoreOverlay> {
    const conversion = new StoredMusicScoreOverlayDtoConversion();
    const crudHelper = new FrappeCrudHelper(
      "Music Score Overlay",
      this.http,
      conversion
    );

    const dto = {
      overlay: overlay.overlay,
      recording_part_group: overlay.recordingPartGroup,
      page: overlay.page,
    };

    if (overlay.name) {
      return crudHelper.update(overlay.name, dto);
    } else {
      return crudHelper.create(dto);
    }
  }

  loadOverlayFor(
    page: number,
    recordingPartGroup: string,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<MusicScoreOverlay | undefined> {
    const conversion = new StoredMusicScoreOverlayDtoConversion();

    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.music_score_overlay_controller.get_overlay";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording_part_group", recordingPartGroup)
      .withParam("page", "" + page)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryFirst(queryHelper, customQueryMethod);
  }
}
