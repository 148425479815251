import { UnitService } from "src/app/services/unit/unit.service";
import { StoredLinkedCollectionDto } from "src/app/dtos/collection/stored-linked-collection.dto";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { ExtendableConversion } from "../extendable-conversion";

export class StoredLinkedCollectionConversion extends ExtendableConversion<
  StoredLinkedCollectionDto,
  StoredLinkedCollection
> {
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredLinkedCollectionDto): StoredLinkedCollection {
    return {
      name: dto.name,
      created: this.us.parseApiDate(dto.creation),
      collection: dto.collection,
      modified: this.us.parseApiDate(dto.modified),
      owner: dto.owner,
      user: dto.user,
      isPracticeRoutine: dto.is_practice_routine > 0,
    };
  }
}
