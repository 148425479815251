import { Injectable } from "@angular/core";
import { UserDataDto } from "src/app/dtos/authentication/user-data.dto";
import { UserData } from "src/app/models/user/user-data";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class UserDtoToUserConversion
  implements Conversion<UserDataDto, UserData>
{
  private parseExpiresDuration(duration: string, fallback: number): number {
    // time format like: 6:00 or 06:00
    const timeRegex = /(\d+).*/;
    const match = duration.match(timeRegex);
    if (match && match.length > 1) {
      return Number.parseInt(match[1], 10);
    }
    return fallback;
  }

  convert(item: UserDataDto): UserData {
    const MILLIS_IN_HOUR = 1 * 60 * 60 * 1000;
    const expiresInHours = this.parseExpiresDuration(item.expires_in, 6);
    const validUntil = new Date(Date.now() + expiresInHours * MILLIS_IN_HOUR);

    return {
      username: item.username,
      apiKey: item.api_key,
      apiSecret: item.api_secret,
      email: item.email,
      sid: item.sid,
      roles: item.roles,
      validUntil: validUntil,
      language: item.language,
    };
  }
}
