import { StoredMusicScoreOverlayDto } from "src/app/dtos/music-score/stored-music-score-overlay-dto";
import { MusicScoreOverlay } from "src/app/models/music-score/music-score-overlay";
import { Conversion } from "../conversion";

export class StoredMusicScoreOverlayDtoConversion
  implements Conversion<StoredMusicScoreOverlayDto, MusicScoreOverlay>
{
  convert(dto: StoredMusicScoreOverlayDto): MusicScoreOverlay {
    return {
      overlay: dto.overlay,
      page: dto.page,
      recordingPartGroup: dto.recording_part_group,
      name: dto.name,
    };
  }
}
