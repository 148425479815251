import { Conversion } from "../conversion";
import { CustomerPortalSession } from "src/app/models/license/customer-portal-session";
import { CustomerPortalSessionDto } from "src/app/dtos/license/customer-portal-session.dto";

export class CustomerPortalSessionConversion
  implements Conversion<CustomerPortalSessionDto, CustomerPortalSession>
{
  convert(dto: CustomerPortalSessionDto): CustomerPortalSession {
    return {
      customerPortalUrl: dto.customer_portal_url,
    };
  }
}
