import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import {
  licenseLoadedSuccessfullyAction,
  loadLicenseAction,
} from "../actions/license.actions";
import { LicenseService } from "../../services/user/license.service";
import { LicenseActions, MusicPieceActions, UserActions } from "../actions";
import { of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class LicenseEffects {
  loadLicenseEffect = createEffect(() =>
    this.actions.pipe(
      ofType(loadLicenseAction),
      mergeMap((_) => {
        return this.licenseService
          .licenseForUser()
          .pipe(map((license) => licenseLoadedSuccessfullyAction(license)));
      }),
    ),
  );

  loadFallbackAndUpgradeLicense$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.loadFallbackAndUpgrade),
      mergeMap((_) =>
        this.licenseService.loadFallbackAndUpgrade().pipe(
          map((fallbackAndUpgrade) =>
            LicenseActions.loadedFallbackAndUpgrade({
              fallback: fallbackAndUpgrade.fallback,
              upgrade: fallbackAndUpgrade.upgrade,
            }),
          ),
        ),
      ),
    ),
  );

  purchaseSubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.purchaseSubscription),
      mergeMap((action) =>
        this.licenseService
          .purchaseSubscription(action.license.name, action.billingInterval)
          .pipe(
            map((result) =>
              LicenseActions.checkoutSessionCreated({
                session: result,
              }),
            ),
            catchError((error) =>
              of(
                LicenseActions.checkoutSessionFailed({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  checkoutSessionCreated$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LicenseActions.checkoutSessionCreated),
        tap((action) =>
          window.location.assign(action.session.checkoutSessionUrl),
        ),
      ),
    {
      dispatch: false,
    },
  );

  loadCheckoutSessionStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.checkCheckoutSessionStatus),
      mergeMap((action) =>
        this.licenseService
          .loadCheckoutSessionStatus(action.stripeCheckoutSessionId)
          .pipe(
            map((data) =>
              LicenseActions.checkoutSessionStatusReceived({
                checkoutSession: data,
              }),
            ),
          ),
      ),
    ),
  );

  triggerLoadStripeCustomerWhenPurchaseSucceeded$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.subscriptionPurchased),
      map((_) => UserActions.loadStripeCustomer()),
    ),
  );

  triggerLoadLicenseWhenPurchaseSucceeded$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.subscriptionPurchased),
      map((_) => LicenseActions.loadLicenseAction()),
    ),
  );

  failureNotification$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LicenseActions.checkoutSessionFailed),
        ofType(LicenseActions.openCustomerPortalFailed),
        tap((action) => {
          console.error(action.error);
          this.toastr.error(this.translate.instant("common.fail"));
        }),
      ),
    { dispatch: false },
  );

  openCustomerPortal$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicenseActions.openCustomerPortal),
      mergeMap((action) =>
        this.licenseService.openCustomerPortal(action.returnUrl).pipe(
          map((result) =>
            LicenseActions.customerPortalSessionCreated({
              customerPortal: result,
            }),
          ),
          catchError((error) =>
            of(
              LicenseActions.openCustomerPortalFailed({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  navigateToCustomerPortal$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LicenseActions.customerPortalSessionCreated),
        tap((action) =>
          window.location.assign(action.customerPortal.customerPortalUrl),
        ),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions: Actions,
    private readonly licenseService: LicenseService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
  ) {}
}
