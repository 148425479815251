import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, Observer, firstValueFrom, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { StoredArtistProfileConversion } from "src/app/conversions/artist/stored-artist-profile-conversion";
import { MusicPieceListingDtoConversion } from "src/app/conversions/music-piece/music-piece-listing-dto-to-music-piece-listing-conversion";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { UserService } from "../authentication/user.service";
import { asStringConversion, dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { ShowcaseCollectionListingConversion } from "src/app/conversions/collection/showcase-collection-listing-conversion";
import { UnitService } from "../unit/unit.service";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceDtoToMusicPieceConversion } from "src/app/conversions/music-piece/music-piece-dto-to-music-piece-conversion";
import { PreviewDtoToPreviewConversion } from "src/app/conversions/music-piece/preview-dto-to-preview.conversion";
import { Preview } from "src/app/models/music-piece/preview";
import { Teaser } from "src/app/models/artist/teaster";
import { TeaserConversion } from "src/app/conversions/artist/teaser-conversion";

@Injectable({
  providedIn: "root",
})
export class ArtistProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly unitService: UnitService,
  ) {}

  loadArtistProfileForCurrentUser(
    username: string,
  ): Observable<StoredArtistProfile | undefined> {
    const conversion = new StoredArtistProfileConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Profile",
      this.http,
      conversion,
    );
    const requestHelper = new FrappeRequestHelper()
      .withFields([
        // we can't load the child tables with the list query, we have to load the child tables when we load the doc by name
        "user",
        "artist_name",
        "creation",
        "name",
        "download_link",
        "content_type",
        "upload_finished",
        "instruments",
        "teaching_location",
        "motto",
        "about_me",
        "website",
        "teaser",
        "custom_url",
        "offers_video_feedback",
        "video_feedback_price",
      ])
      .withFilter(new FrappeRequestFilter("user", "=", username));

    return crudHelper.queryFirst(requestHelper);
  }

  loadArtistProfileByCustomUrl(
    customUrl: string,
  ): Observable<StoredArtistProfile | undefined> {
    const conversion = new StoredArtistProfileConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Profile",
      this.http,
      conversion,
    );
    const requestHelper = new FrappeRequestHelper().withParam(
      "custom_url",
      customUrl,
    );
    const method =
      "/api/method/yobi_rocks.controllers.artist_profile_controller.artist_profile_by_custom_url";

    return crudHelper.queryFirst(requestHelper, method);
  }

  loadArtistProfile(
    artistProfile: string,
  ): Observable<StoredArtistProfile | undefined> {
    const conversion = new StoredArtistProfileConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Profile",
      this.http,
      conversion,
    );

    return crudHelper.getSingle(artistProfile).pipe(
      catchError((error) => {
        console.error(error);
        return of(undefined);
      }),
    );
  }

  loadListedMusicPiecesForArtist(
    artist: string,
  ): Observable<MusicPieceListing[]> {
    const conversion = new MusicPieceListingDtoConversion();
    const crud = new FrappeCrudHelper(
      "Music Piece Listing",
      this.http,
      conversion,
    );
    const helper = new FrappeRequestHelper()
      .withFilter(new FrappeRequestFilter("artist", "=", artist))
      .withFields([
        "name",
        "price",
        "artist",
        "artist_name",
        "creation",
        "music_piece_name",
        "music_piece",
        "description",
        "music_piece_artist",
        "collection",
        "instrument",
        "temperament",
        "artist_profile_picture_download_link",
        "music_piece_thumbnail_download_link",
        "music_piece_thumbnail_upload_finished",
      ]);

    return crud.queryAll(helper);
  }

  loadListedCollectionsForArtist(
    artist: string,
  ): Observable<ShowcaseCollectionListing[]> {
    const helper = new FrappeCrudHelper(
      "",
      this.http,
      new ShowcaseCollectionListingConversion(),
    );
    const method =
      "/api/method/yobi_rocks.controllers.artist_profile_controller.listed_collections";
    const data = new FrappeRequestHelper().withParam("artist", artist);
    return helper.queryAll(data, method);
  }

  update(artist: StoredArtistProfile): Observable<StoredArtistProfile> {
    const conversion = new StoredArtistProfileConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Profile",
      this.http,
      conversion,
    );

    return crudHelper.update(artist.name, {
      artist_name: artist.artistName,
      about_me: artist.aboutMe,
      instruments: artist.instruments,
      motto: artist.motto,
      teaching_location: artist.teachingLocation,
      website: artist.website,
      links: (artist.links || []).map((l) => {
        return {
          name: l.name != "" ? l.name : undefined,
          url: l.url,
          title: l.title,
          url_type: l.urlType || "Link",
        };
      }),
      events: (artist.events || []).map((e) => ({
        name: e.name != "" ? e.name : undefined,
        url: e.url,
        title: e.title,
        description: e.description,
        date: this.unitService.dateToApiDate(e.date),
      })),
      teaser: artist.teaser,
      custom_url: artist.customUrl,
      offers_video_feedback: artist.offersVideoFeedback,
      video_feedback_price: artist.videoFeedbackPrice,
    });
  }

  prepareUploadUrl(artistProfile: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.create_upload_file_url";

    const helper = new FrappeMethodHelper(this.http, asStringConversion);
    const obs = helper
      .callMethod(
        method,
        new MethodDataWrapper().withDto({
          name: artistProfile,
        }),
      )
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markUploadAsFinished(artistProfile: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.upload_finished";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: artistProfile }),
    );

    return firstValueFrom(obs);
  }

  deleteProfilePicture(artistProfile: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.delete_profile_picture";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: artistProfile }),
    );
    return firstValueFrom(obs);
  }

  prepareLogoUploadUrl(artistProfile: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.create_logo_upload_file_url";

    const helper = new FrappeMethodHelper(this.http, asStringConversion);
    const obs = helper
      .callMethod(
        method,
        new MethodDataWrapper().withDto({
          name: artistProfile,
        }),
      )
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markLogoUploadAsFinished(artistProfile: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.logo_upload_finished";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: artistProfile }),
    );
    return firstValueFrom(obs);
  }

  deleteLogo(artistProfile: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.artist_profile_controller.delete_logo";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: artistProfile }),
    );
    return firstValueFrom(obs);
  }

  loadPossibleTeasers(
    artistProfile: StoredArtistProfile,
  ): Observable<MusicPiece[]> {
    const conversion = new MusicPieceDtoToMusicPieceConversion();

    const crud = new FrappeCrudHelper("Music Piece", this.http, conversion);

    const requestHelper = new FrappeRequestHelper()
      .withFields(["*"])
      .withFilters([
        FrappeRequestFilter.byOwner(artistProfile.user),
        new FrappeRequestFilter("accept_share_terms", "=", "1"),
      ]);
    return crud.queryAll(requestHelper);
  }

  loadTeaser(
    artistProfile: StoredArtistProfile,
  ): Observable<Teaser | undefined> {
    const helper = new FrappeCrudHelper("", this.http, new TeaserConversion());
    const method =
      "/api/method/yobi_rocks.controllers.artist_profile_controller.teaser";
    const data = new FrappeRequestHelper().withParam(
      "artist",
      artistProfile.name,
    );
    return helper.queryFirst(data, method);
  }

  findPreviews(listings: MusicPieceListing[]): Observable<Preview[]> {
    const conversion = new PreviewDtoToPreviewConversion();
    const method =
      "yobi_rocks.controllers.music_piece_controller.previews_for_listings";
    const helper = new FrappeMethodHelper(this.http, conversion.asMany());
    const dataWrapper = new MethodDataWrapper().withDto({
      listings: (listings || []).map((listing) => {
        return {
          music_piece: listing.musicPiece,
        };
      }),
    });
    return helper.callMethod(method, dataWrapper);
  }
}
