<div>
  @if (isLoading()) {
    <span class="loading loading-spinner"></span>
  } @else {
    @if (hasUnreadNotifications()) {
      <div class="indicator">
        <span
          class="badge indicator-item badge-primary indicator-end indicator-bottom"
          >{{ unreadNotificationCount() }}</span
        >
        <button
          class="btn btn-circle h-[32px] min-h-[32px] w-[32px] text-sm text-primary md:h-[40px] md:min-h-[40px] md:w-[40px] md:text-lg"
          routerLink="/notification"
        >
          <ng-icon name="lucideBellRing"></ng-icon>
        </button>
      </div>
    } @else {
      <button
        class="btn btn-circle h-[32px] min-h-[32px] w-[32px] text-sm md:h-[40px] md:min-h-[40px] md:w-[40px] md:text-lg"
        routerLink="/notification"
      >
        <ng-icon name="lucideBellRing"></ng-icon>
      </button>
    }
  }
</div>
