import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MetronomeDtoToMetronomeConversion } from "src/app/conversions/metronome/metronome-dto-to-metronome-conversion";
import { CreateMetronomeDto } from "src/app/dtos/metronome/create-metronome-dto";
import { UpdateMetronomeDto } from "src/app/dtos/metronome/update-metronome-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { Metronome } from "src/app/models/metronome/metronome";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class MetronomeService {
  constructor(private readonly http: HttpClient) {}

  getMetronomesFromMusicPiece(
    musicPiece: string,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<Metronome[]> {
    const conversion = new MetronomeDtoToMetronomeConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.metronome_controller.get_metronomes";

    const queryHelper = new FrappeRequestHelper()
      .withParam("music_piece", musicPiece)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  addMetronomeInMusicPiece(
    musicPiece: MusicPiece,
    name: string,
    endValue: number,
    bpm: number
  ): Observable<Metronome> {
    const dto = {
      name_of_metronome: name,
      music_piece: musicPiece.name,
      bpm: bpm,
      start_value: 1,
      end_value: endValue,
    } as CreateMetronomeDto;

    const conversion = new MetronomeDtoToMetronomeConversion();
    const queryHelper = new FrappeCrudHelper(
      "Metronome",
      this.http,
      conversion
    );
    return queryHelper.create(dto);
  }

  updateMetronome(metronome: Metronome): Observable<Metronome> {
    const dto = {
      bpm: metronome.bpm,
      end_value: metronome.endValue,
      music_piece: metronome.musicPiece,
      name_of_metronome: metronome.nameOfMetronome,
      start_value: metronome.startValue,
    } as UpdateMetronomeDto;

    const conversion = new MetronomeDtoToMetronomeConversion();
    const queryHelper = new FrappeCrudHelper(
      "Metronome",
      this.http,
      conversion
    );
    return queryHelper.update(metronome.name, dto);
  }

  deleteMetronome(selectedMetronome: Metronome): Observable<void> {
    const queryHelper = new FrappeCrudHelper("Metronome", this.http);
    return queryHelper.delete(selectedMetronome.name);
  }
}
