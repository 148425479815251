@if (item()) {
  <div class="rounded bg-white p-4 shadow">
    <markdown [data]="item()?.faq"></markdown>

    <div class="mt-2 flex flex-row flex-wrap gap-x-2">
      @for (tag of item()?.tags; track $index) {
        <span class="badge badge-primary">{{ tag }}</span>
      }
    </div>
  </div>
}
