import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { debounceTime, map, mergeMap } from "rxjs/operators";
import { NotificationActions } from "../actions";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { NotificationService } from "src/app/services/notification/notification.service";

@Injectable({
  providedIn: "root",
})
export class NotificationEffects {
  loadNotifications$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationActions.loadNotifications),
      debounceTime(100),
      mergeMap((_) =>
        this.notificationService
          .load()
          .pipe(
            map((notifications) =>
              NotificationActions.loadedNotifications({ notifications }),
            ),
          ),
      ),
    ),
  );

  markAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationActions.markAsRead),
      map((a) =>
        NotificationActions.markAsReadByName({ name: a.notification.name }),
      ),
    ),
  );

  markAsReadByName$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationActions.markAsReadByName),
      mergeMap((action) =>
        this.notificationService
          .markAsRead(action.name)
          .pipe(
            map((notification) =>
              NotificationActions.markedAsRead({ notification }),
            ),
          ),
      ),
    ),
  );

  markAllAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotificationActions.markAllAsRead),
      mergeMap((_) =>
        this.notificationService
          .markAllAsRead()
          .pipe(map((_) => NotificationActions.markedAllAsRead())),
      ),
    ),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly notificationService: NotificationService,
  ) {}
}
