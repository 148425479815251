<mat-card appearance="outlined" id="license">
  <mat-card-content>
    <yr-stripe-portal [returnUrl]="stripePortalReturnUrl"></yr-stripe-portal>

    <div class="flex flex-col flex-wrap xl:flex-row">
      <!-- <div
        class="pd-2 m-2 hidden flex-none rounded bg-gray-50 transition-all hover:bg-gray-100 2xl:block"
        *ngIf="fallback"
      >
        <yr-license-header [license]="fallback"></yr-license-header>
        <yr-license [license]="fallback"></yr-license>
      </div> -->

      <div
        class="pd-2 border-as-200 m-1 flex-grow rounded border-2 bg-gray-50 p-2 transition-all hover:bg-gray-100"
      >
        <yr-license-header
          [currentLicense]="currentLicense"
          [license]="currentLicense?.license"
        ></yr-license-header>
        <yr-license
          [license]="currentLicense?.license"
          [usedResources]="currentUsedResources"
        ></yr-license>
      </div>
      <div
        *ngIf="upgrade"
        class="pd-2 m-1 flex-none rounded bg-gray-50 p-2 transition-all hover:bg-gray-100 2xl:flex-grow"
      >
        <div class="text-center">
          <div class="grid w-full place-content-center">
            <div
              class="my-2 flex w-fit max-w-full flex-row rounded-full border-2 border-gray-300 bg-gray-200"
            >
              <button
                (click)="billingInterval = 'monthly'"
                class="mr-2 flex-none shrink rounded-full p-2 text-lg transition-colors"
                [class.bg-as-500]="billingInterval == 'monthly'"
                [class.text-white]="billingInterval == 'monthly'"
              >
                {{ "user.subscriptions.monthly-billing-interval" | translate }}
              </button>
              <button
                (click)="billingInterval = 'yearly'"
                class="flex-none shrink rounded-full p-2 text-lg transition-colors"
                [class.bg-as-500]="billingInterval == 'yearly'"
                [class.text-white]="billingInterval == 'yearly'"
              >
                {{ "user.subscriptions.yearly-billing-interval" | translate }}
                <span
                  *ngIf="
                    upgrade.pricePerYear != 'free' &&
                    upgrade.pricePerMonth != 'free' &&
                    upgrade.pricePerMonth * 12 > upgrade.pricePerYear
                  "
                  class="text-as-500 rounded-full bg-slate-50 p-1 text-sm"
                >
                  {{
                    -(12 * upgrade.pricePerMonth - upgrade.pricePerYear) /
                      (12 * upgrade.pricePerMonth) | percent
                  }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <yr-license-header
          [license]="upgrade"
          [billingInterval]="billingInterval"
        ></yr-license-header>
        <yr-purchase-subscription
          [license]="upgrade"
          [billingInterval]="billingInterval"
        ></yr-purchase-subscription>

        <yr-license [license]="upgrade"></yr-license>
      </div>
    </div>

    <p>
      {{ "user.license.agb-text" | translate }}
      <a [href]="termsLink" target="_blank">{{
        "user.license.agb" | translate
      }}</a>
    </p>
  </mat-card-content>
</mat-card>
