import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export abstract class YoutubeChannelValidator {
  private static error() {
    return {
      youtube: true,
    };
  }

  private static noError() {
    return null;
  }

  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const youtubeRegex = /^@.+$/g;
      if (youtubeRegex.test(control.value)) {
        return YoutubeChannelValidator.noError();
      } else {
        return YoutubeChannelValidator.error();
      }
    };
  }
}
