import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { InformationDialogComponent } from "../components/common/information-dialog/information-dialog.component";
import { InformationDialogData } from "../models/dialog/information-dialog-data";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class FrappeErrorInterceptor implements HttpInterceptor {
  private dialogIsOpen = false;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly translate: TranslateService,
  ) {}

  private showDialog(title: string, content?: string) {
    this.dialogIsOpen = true;

    console.log("custom error handler");

    const ref = this.matDialog.open<
      InformationDialogComponent,
      InformationDialogData
    >(InformationDialogComponent, {
      data: {
        title: title,
        content: content || "",
      },
      disableClose: true,
      hasBackdrop: true,
    });

    ref.afterClosed().subscribe((_) => {
      this.dialogIsOpen = false;
    });
  }

  private parseException(error: any): { content?: string; title: string } {
    if (error.exc_type) {
      return {
        title: error.exc_type,
      };
    } else if (error.exception) {
      const regex = /^frappe\.exceptions\.(\w+):.*$/;
      const match = regex.exec(error.exception);
      if (match && match[1]) {
        return {
          title: match[1],
        };
      }
    }

    return {
      title: "Error",
    };
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (
              error.error &&
              ((error.error.exception as string | undefined) || "").includes(
                "frappe.exceptions",
              ) &&
              error.error.exc_type != "LicenseInsufficientError" && // we have a custom error interceptor for this: license-error.interceptor.ts
              !this.dialogIsOpen
            ) {
              const { title, content } = this.parseException(error.error);
              const translationKeyTitle = `exceptions.${title}`;

              // we only show the error when we have a translation - this can be achieved by comparing the key to the translation:
              // we can safely assume that the translation will not be in the form exceptions.${title}
              if (
                this.translate.instant(translationKeyTitle) !=
                translationKeyTitle
              ) {
                this.showDialog(translationKeyTitle, content);
              }
            }
          }
        },
      ),
    );
  }
}
