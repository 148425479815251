import { UrlMatcher, UrlSegment } from "@angular/router";

export abstract class ArtistProfileUrlMatcher {
  static matcher(): UrlMatcher {
    // the artist short name (a.k.a url must be the first and only part of the url segment and must start with an @)
    return (segments) => {
      if (segments.length == 1) {
        const urlMatcherRegex = /^@+(.+)$/g;
        const res = urlMatcherRegex.exec(segments[0].path);

        if (res && res.length == 2) {
          const artistCustomUrl = res[1];
          return {
            consumed: segments,
            posParams: {
              artistCustomUrl: new UrlSegment(artistCustomUrl, {}),
            },
          };
        }
      }
      return null;
    };
  }
}
