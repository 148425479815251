import {
  FrappeApiHelper,
  FrappeRequestListOptions,
  mappingBuilder,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";
import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { Observable, map } from "rxjs";
import { PushNotificationSubscription } from "src/app/models/notification/push-subscription";
import { ServerPushNotificationSettings } from "src/app/models/notification/server-push-notification-settings";
import { UserNotificationSettings } from "src/app/models/notification/user-notification-settings";

@Injectable({ providedIn: "root" })
export class NotificationSettingsService {
  private readonly serverPushNotificationSettingsMapper =
    mappingBuilder<ServerPushNotificationSettings>("Push Settings", [
      mappingHelper.stringMapper("vapid_public_key", "vapidPublicKey"),
    ]);

  private readonly userNotificationSettingsMapper =
    mappingBuilderWithDefaultMappings<UserNotificationSettings>(
      "User Notification Settings",
      [
        mappingHelper.booleanMapper("enabled"),
        mappingHelper.booleanMapper("email"),
        mappingHelper.booleanMapper("push"),
      ],
    );

  private readonly pushNotificationSubscriptionWrapper =
    mappingBuilderWithDefaultMappings<PushNotificationSubscription>(
      "Push Subscription",
      [
        mappingHelper.jsonMapper("subscription"),
        mappingHelper.stringMapper("endpoint"),
        mappingHelper.stringMapper("platform"),
        mappingHelper.optionalDateMapper("valid_until", "validUntil"),
      ],
    );

  constructor(
    private readonly helper: FrappeApiHelper,
    private readonly swPush: SwPush,
  ) {}

  loadServerPushSettings(): Observable<ServerPushNotificationSettings> {
    return this.helper.callWithResult(
      this.serverPushNotificationSettingsMapper.conversion(),
      {
        method: "yobi_rocks.controllers.push_settings_controller.push_settings",
        type: "GET",
      },
    );
  }

  loadUserSettings(): Observable<UserNotificationSettings | undefined> {
    return this.helper
      .getList(
        this.userNotificationSettingsMapper,
        new FrappeRequestListOptions().withPageLength(1),
      )
      .pipe(
        map((r) => {
          if (r.length > 0) {
            return r[0];
          } else {
            return undefined;
          }
        }),
      );
  }

  saveUserSettings(data: {
    name: string | undefined;
    enabled: boolean;
    email: boolean;
    push: boolean;
  }): Observable<UserNotificationSettings> {
    const dto = {
      push: data.push,
      email: data.email,
      enabled: data.enabled,
    };

    if (data.name) {
      return this.helper.update(
        data.name,
        dto,
        this.userNotificationSettingsMapper,
      );
    } else {
      return this.helper.create(dto, this.userNotificationSettingsMapper);
    }
  }

  async requestPushSubscription(publicKey: string) {
    await this.swPush.requestSubscription({
      serverPublicKey: publicKey,
    });
  }

  async unregisterPushSubscription() {
    try {
      console.log("unregister push subscription");
      await this.swPush.unsubscribe();
      console.log("success");
    } catch (error) {
      console.error("unregister push subscription", error);
      /* we don't care */
    }
  }

  createPushSubscription(
    subscription: PushSubscription,
  ): Observable<PushNotificationSubscription> {
    return this.helper.create(
      {
        platform: navigator.userAgent,
        endpoint: subscription.endpoint,
        subscription: subscription.toJSON(),
        validUntil:
          subscription.expirationTime != null
            ? new Date(subscription.expirationTime)
            : undefined,
      },
      this.pushNotificationSubscriptionWrapper,
    );
  }

  deletePushSubscription(endpoint: string): Observable<void> {
    return this.helper.call({
      method:
        "yobi_rocks.controllers.push_settings_controller.delete_subscription_by_endpoint",
      type: "POST",
      body: {
        endpoint: endpoint,
      },
    });
  }
}
