<div class="mb-4 text-center">
  <button
    mat-raised-button
    color="primary"
    (click)="purchase()"
    [disabled]="!license || hasClickedPurchase"
  >
    {{ "user.purchase-subscription.purchase" | translate }}
  </button>
</div>
