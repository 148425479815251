<div *ngIf="artistProfile" class="flex flex-col items-center">
  <button
    mat-button
    (click)="uploadLogo()"
    *ngIf="
      isOwner &&
      isInEditMode &&
      (!artistProfile.logoUploadFinished || isUploadingLogo)
    "
  >
    {{ "artist.artist-profile.upload-logo" | translate }}
  </button>

  <img
    class="aspect-square w-64 object-cover"
    [alt]="artistProfile.artistName"
    *ngIf="artistProfile.logoUploadFinished"
    [src]="artistProfile.logoDownloadLink"
  />
  <img
    src="assets/logo/logo-hands-only.png"
    alt="partner logo"
    class="aspect-square w-64 object-cover"
    *ngIf="!artistProfile.logoUploadFinished && !isInEditMode"
  />
  <button
    mat-icon-button
    *ngIf="isOwner && isInEditMode && artistProfile.logoUploadFinished"
    (click)="deleteLogo()"
    color="warn"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>

<!-- skeleton -->
<div
  *ngIf="!artistProfile"
  class="aspect-square w-64 animate-pulse rounded-full bg-gray-200"
></div>
