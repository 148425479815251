import { StoredRecordingPartGroupDto } from "src/app/dtos/recording-part-group/stored-recording-part-group-dto";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { Conversion } from "../conversion";

export class StoredRecordingPartGroupDtoToRecordingPartGroupConversion
  implements Conversion<StoredRecordingPartGroupDto, RecordingPartGroup>
{
  convert(item: StoredRecordingPartGroupDto): RecordingPartGroup {
    return {
      name: item.name,
      recording: item.recording,
      title: item.title,
    };
  }
}
