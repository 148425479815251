import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { ExploreActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ExploreSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-explore-search",
  templateUrl: "./explore-search.component.html",
  styleUrls: ["./explore-search.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule],
})
export class ExploreSearchComponent implements OnInit, OnDestroy {
  isLoadingSearchResults: boolean = false;

  searchQuery = new FormControl("");

  searchForm = new UntypedFormGroup({
    searchQuery: this.searchQuery,
  });

  private readonly subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  ngOnInit(): void {
    this.searchQuery.valueChanges.subscribe((val) => {
      this.store.dispatch(ExploreActions.search({ query: val ?? "" }));
    });
    this.subscriptions.addMany(
      this.store.select(ExploreSelectors.searchResults).subscribe((data) => {
        this.isLoadingSearchResults = data.isLoadingSearchResults;
      }),
    );
  }
}
