<div class="w-full rounded-lg bg-white shadow" *ngIf="musicPiece">
  <div class="relative overflow-clip rounded-lg">
    <yr-preview
      [preview]="preview"
      [musicPieceThumbnail]="musicPiece | exploreMusicPieceThumbnail"
    ></yr-preview>

    <div
      class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
    >
      <button mat-icon-button (click)="showDetails()">
        <mat-icon>info</mat-icon>
      </button>
    </div>
  </div>

  <div class="flex flex-row p-2">
    <div
      class="flex flex-row items-center hover:cursor-pointer"
      (click)="goToArtist()"
    >
      @if(musicPiece.artist && musicPiece.artist.downloadLink &&
      musicPiece.artist.downloadLink.length > 0){
      <img
        #artistProfile
        [src]="musicPiece.artist.downloadLink"
        [alt]="musicPiece.artist.artistName"
        class="mr-2 h-10 w-10 rounded-full"
      />
      } @else {
      <img
        src="assets/logo/logo.png"
        [alt]="musicPiece.artist.artistName"
        class="mr-2 h-10 w-10 rounded-full"
      />
      }
    </div>

    <div class="flex flex-1 flex-col truncate">
      <button class="truncate text-start text-lg" (click)="goToArtist()">
        {{ musicPiece.musicPieceName }}
      </button>
      <div class="flex flex-1 flex-row truncate">
        <button
          class="flex-1 truncate text-start text-base font-light"
          (click)="goToArtist()"
        >
          {{ musicPiece.artist.artistName }}
        </button>

        <div class="flex flex-row">
          <button
            class="mx-2 mb-1 rounded bg-as-500 px-1 text-base text-white hover:bg-as-600"
            (click)="goToArtist()"
          >
            {{ musicPiece.price | price }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
