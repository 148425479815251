import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(private readonly translate: TranslateService) {}

  private findBrowserLanguage(fallback: string): string {
    const language = window.navigator.language;
    const languageRegex = /(\w\w)-*.*/;
    const match = language.match(languageRegex);
    if (match && match.length > 1) {
      return match[1];
    } else {
      return fallback;
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  availableLanguages(): LanguageData[] {
    return [
      {
        name: "Deutsch",
        lang: "de",
      },
      {
        name: "English",
        lang: "en",
      },
    ];
  }

  initLanguage() {
    const browserLanguage = this.findBrowserLanguage("de");
    this.translate.setDefaultLang(browserLanguage);
  }
}

export interface LanguageData {
  name: string;
  lang: string;
}
