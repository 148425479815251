import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export abstract class MailValidator {
  private static error() {
    return {
      mail: true,
    };
  }

  private static noError() {
    return null;
  }

  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,100}$/g;
      if (emailRegex.test(control.value)) {
        return MailValidator.noError();
      } else {
        return MailValidator.error();
      }
    };
  }
}
