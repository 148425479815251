<mat-card
  appearance="outlined"
  *ngIf="
    musicPiece && !musicPiece.isCopy && !isLinkedMusicPiece && hasShareLicense
  "
>
  <mat-card-header>
    <mat-card-title>
      {{ "edit-music-piece.share-music-piece.share" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div
      *ngIf="!musicPiece.acceptShareTerms"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon color="warn">link_off</mat-icon>
      {{
        "edit-music-piece.share-music-piece.accept-share-terms-to-share"
          | translate
      }}
    </div>

    <div *ngIf="musicPiece.acceptShareTerms && hasShareLicense">
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>
            {{
              "edit-music-piece.share-music-piece.share-placeholder" | translate
            }}
          </mat-label>
          <input
            matInput
            type="text"
            formControlName="email"
            placeholder="{{
              'edit-music-piece.share-music-piece.share-placeholder' | translate
            }}"
          />
        </mat-form-field>

        <button mat-raised-button (click)="share()" [disabled]="form.invalid">
          {{ "edit-music-piece.share-music-piece.share" | translate }}
        </button>
      </form>

      <yr-shared-music-piece-receivers></yr-shared-music-piece-receivers>
    </div>
  </mat-card-content>
</mat-card>
