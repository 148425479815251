import { ExtendableConversion } from "../extendable-conversion";
import { TeaserDto } from "src/app/dtos/artist/teaser.dto";
import { Teaser } from "src/app/models/artist/teaster";
import { RecordingDtoToRecordingConversion } from "../recording/recording-dto-to-recording-conversion";
import { MusicPieceDtoToMusicPieceConversion } from "../music-piece/music-piece-dto-to-music-piece-conversion";

export class TeaserConversion extends ExtendableConversion<
  TeaserDto,
  Teaser | undefined
> {
  private readonly recordingConversion =
    new RecordingDtoToRecordingConversion();
  private readonly musicPieceConversion =
    new MusicPieceDtoToMusicPieceConversion();

  convert(dto: TeaserDto): Teaser | undefined {
    if (dto && dto.music_piece && dto.recording) {
      return {
        recording: this.recordingConversion.convert(dto.recording),
        musicPiece: this.musicPieceConversion.convert(dto.music_piece),
      };
    }
    return;
  }
}
