<mat-card appearance="outlined">
  <mat-card-header>
    <mat-icon class="card-icon" mat-card-avatar color="primary"
      >vpn_key</mat-icon
    >
    <mat-card-title>
      {{ "user.update-password.title" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>
            {{ "user.update-password.password" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="password"
            [placeholder]="'user.update-password.password' | translate"
            type="password"
          />
          <mat-error *ngIf="password.hasError('minlength')">
            {{ "user.update-password.min-length-error" | translate }}</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label>
            {{ "user.update-password.password-confirmation" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="passwordConfirmation"
            [placeholder]="
              'user.update-password.password-confirmation' | translate
            "
            type="password"
          />
          <mat-error *ngIf="form.hasError('passwordDoNotMatch')">{{
            "user.update-password.passwords-not-identical" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </form>
    <p class="logout-hint">
      {{ "user.update-password.logout-hint" | translate }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form.invalid"
      (click)="updatePassword()"
    >
      {{ "user.update-password.update" | translate }}
    </button>
  </mat-card-actions>
</mat-card>
