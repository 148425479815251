import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { AppState, UserSelectors } from "../ngrx/reducers";
import { UserData } from "../models/user/user-data";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  currentAuth?: UserData;

  constructor(private readonly store: Store<AppState>) {
    this.store.select(UserSelectors.currentUser).subscribe((userData) => {
      this.currentAuth = userData;
    });
  }

  private shouldAddHeader(request: HttpRequest<unknown>): boolean {
    // the Authorization header can mess up backends where it is not supposed to be sent to (e.g. Rados GW)
    // in the case of rados we would receive an "Invalid Argument Error"
    return request.url.startsWith(environment.baseUrl);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldAddHeader(request) && this.currentAuth) {
      request = request.clone({
        setHeaders: {
          Authorization: `token ${this.currentAuth.apiKey}:${this.currentAuth.apiSecret}`,
        },
      });
      return next.handle(request);
    }
    return next.handle(request);
  }
}
