import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import {
  debounce,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import { LinkedMusicPieceService } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicService } from "src/app/services/music-piece/music-piece.service";
import { ArtistProfileActions, MusicPieceActions } from "../actions";
import { AppState, UserSelectors } from "../reducers";
import { MusicPieceSelectors } from "../selectors";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { MusicPieceFilterService } from "src/app/services/music-piece/music-piece-filter.service";
import { state } from "@angular/animations";

@Injectable()
export class MusicPieceEffects {
  loadMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        MusicPieceActions.loadMusicPieces,
        MusicPieceActions.createdCopyFromLinkedMusicPiece,
        ArtistProfileActions.loadArtistProfile, // required, otherwise we don't know what we have already purchased
        ArtistProfileActions.loadArtistProfileByCustomUrl, // required, otherwise we don't know what we have already purchased
      ),
      withLatestFrom(this.store.select(UserSelectors.currentUser)),
      filter(([_, user]) => user != undefined),
      mergeMap((_) =>
        this.musicService.getMusicPieces().pipe(
          tap(
            (_) => {},
            (error) => {
              console.error(error);
              this.toastrService.error(
                this.translate.instant("musicPiecesTable.dataCouldNotBeLoaded"), // XXX: refactor
              );
            },
          ),
        ),
      ),
      map((musicPieces) =>
        MusicPieceActions.loadedMusicPieces({
          musicPieces: musicPieces,
        }),
      ),
    ),
  );

  loadLinkedMusicPieces$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        MusicPieceActions.loadMusicPieces,
        ArtistProfileActions.loadArtistProfile, // required, otherwise we can't navigate to the linked music piece (practice)
        ArtistProfileActions.loadArtistProfileByCustomUrl, // required, otherwise we can't navigate to the linked music piece (practice)
      ),
      withLatestFrom(this.store.select(UserSelectors.currentUser)),
      filter(([_, user]) => user != undefined),
      mergeMap((_) =>
        this.linkedMusicPieceService.getLinkedMusicPieces().pipe(
          tap(
            (_) => {},
            (error) => {
              console.error(error);
              this.toastrService.error(
                this.translate.instant("musicPiecesTable.dataCouldNotBeLoaded"),
              );
            },
          ),
        ),
      ),
      map((linkedMusicPieces) =>
        MusicPieceActions.loadedLinkedMusicPieces({
          linkedMusicPieces: linkedMusicPieces,
        }),
      ),
    ),
  );

  loadMusicPiecePreviews$ = createEffect(() =>
    this.actions.pipe(
      ofType(MusicPieceActions.loadedMusicPieces),
      mergeMap((action) => this.musicService.findPreviews(action.musicPieces)),
      map((previews) =>
        MusicPieceActions.loadedMusicPiecePreviews({
          previews: previews,
        }),
      ),
    ),
  );

  loadLinkedMusicPiecePreviews$ = createEffect(() =>
    this.actions.pipe(
      ofType(MusicPieceActions.loadedLinkedMusicPieces),
      mergeMap((action) =>
        this.linkedMusicPieceService.findPreviews(action.linkedMusicPieces),
      ),
      map((previews) =>
        MusicPieceActions.loadedLinkedMusicPiecePreviews({
          previews: previews,
        }),
      ),
    ),
  );

  createCopyFromLinkedMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(MusicPieceActions.createCopyFromLinkedMusicPiece),
      mergeMap((action) =>
        this.linkedMusicPieceService
          .createCopyFromLinkedMusicPiece(action.linkedMusicPiece)
          .pipe(
            map((_) => MusicPieceActions.createdCopyFromLinkedMusicPiece()),
            tap(
              (_) => {
                this.toastrService.success(
                  this.translate.instant(
                    "music-piece-effect.createdCopyFromLinkedMusicPiece",
                  ),
                );
              },
              (error) => {
                console.error(error);
                this.toastrService.error(
                  this.translate.instant(
                    "music-piece-effect.failedToCreateCopyFromLinkedMusicPiece",
                  ),
                );
              },
            ),
          ),
      ),
    ),
  );

  triggerResetFilterWhenLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        MusicPieceActions.loadedLinkedMusicPieces,
        MusicPieceActions.loadedMusicPieces,
      ),
      withLatestFrom(
        this.store.select(MusicPieceSelectors.selectIsLoadingMusicPieces),
      ),
      filter(([_, isLoading]) => !isLoading), // only trigger when we have both the linked music pieces and the music pieces to reduce it down to one layout paint!
      map((_) =>
        MusicPieceActions.filterMusicPieces({
          filter: "",
          includeLinkedMusicPieces: true,
        }),
      ),
    ),
  );

  applyFilter$ = createEffect(() =>
    this.actions.pipe(
      ofType(MusicPieceActions.filterMusicPieces),
      debounceTime(100),
      withLatestFrom(
        this.store.select(
          MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
        ),
      ),
      map(([action, musicPiecesAndLinkedMusicPieces]) => {
        const musicPieces = musicPiecesAndLinkedMusicPieces.musicPieces;
        const linkedMusicPieces =
          musicPiecesAndLinkedMusicPieces.linkedMusicPieces;

        const displayMusicPieces = musicPieces.map((mp) => {
          return {
            isLinkedMusicPiece: false,
            musicPiece: mp,
          } as MusicPieceToDisplay;
        });

        const displayLinkedMusicPieces = linkedMusicPieces.map((lmp) => {
          return {
            isLinkedMusicPiece: true,
            musicPiece: lmp.musicPiece,
            linkedMusicPiece: lmp.linkedMusicPiece,
          } as MusicPieceToDisplay;
        });

        const musicPiecesToDisplay = [
          ...displayMusicPieces,
          ...displayLinkedMusicPieces,
        ];

        const filtered = this.musicPieceFilterService.filter(
          action.filter,
          musicPiecesToDisplay,
          action.includeLinkedMusicPieces,
        );
        const filteredAndSorted =
          this.musicPieceFilterService.sortByLastTimeViewed(filtered);

        return MusicPieceActions.filteredMusicPieces({
          filteredMusicPieces: filteredAndSorted,
        });
      }),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly toastrService: ToastrService,
    private readonly translate: TranslateService,
    private readonly linkedMusicPieceService: LinkedMusicPieceService,
    private readonly musicService: MusicService,
    private readonly musicPieceFilterService: MusicPieceFilterService,
  ) {}
}
