import { StoredPotentialUserDto } from "src/app/dtos/user/stored-potential-user-dto";
import { StoredPotentialUser } from "src/app/models/user/stored-potential-user";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class StoredPotentialUserDtoConversion
  implements Conversion<StoredPotentialUserDto, StoredPotentialUser>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredPotentialUserDto): StoredPotentialUser {
    return {
      name: dto.name,
      firstName: dto.first_name,
      lastName: dto.last_name,
      isStudent: dto.is_student,
      primaryInstrument: dto.primary_instrument,
      otherInstruments: dto.other_instruments,
      practiceSince: dto.practice_since,
      practiceHoursPerWeek: dto.practice_hours_per_week,
      whoIsMyTeacher: dto.who_is_my_teacher,
      dateOfBirth: this.us.parseApiDate(dto.date_of_birth),
      email: dto.email,
      phone: dto.phone,
      zip: dto.zip,
      address: dto.address,
      city: dto.city,
      country: dto.country,
      acceptTerms: dto.accept_terms,
      acceptGdpr: dto.accept_gdpr,
      created: this.us.parseApiDate(dto.creation),
      license: dto.license,
      signUpType: dto.sign_up_type,
      userType: dto.user_type,
    };
  }
}
