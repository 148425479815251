import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-collection-control",
  templateUrl: "./collection-control.component.html",
  styleUrls: ["./collection-control.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class CollectionControlComponent {
  filterText: string = "";

  @Input()
  canCreateNew: boolean = false;

  @Output()
  addCollection = new EventEmitter<void>();

  @Output()
  filterChange = new EventEmitter<string>();

  @Input()
  canFilter: boolean = true;

  add() {
    this.addCollection.emit();
  }

  applyFilter() {
    this.filterChange.emit(this.filterText);
  }
}
