import { Component, effect, OnDestroy, OnInit, signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { AppState } from "src/app/ngrx/reducers";
import {
  ReadonlyCollectionSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ConfirmationDialogHelper } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {
  EditCollectionActions,
  ReadonlyCollectionActions,
} from "src/app/ngrx/actions";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "yr-readonly-collection-details",
  templateUrl: "./readonly-collection-details.component.html",
  styleUrls: ["./readonly-collection-details.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class ReadonlyCollectionDetailsComponent implements OnInit, OnDestroy {
  isLoadingCollection: boolean = false;
  isDeletingCollection: boolean = false;
  collection?: StoredCollection;
  linkedCollection?: StoredLinkedCollection;

  isPracticeRoutine = new FormControl(false);

  form = new FormGroup({
    isPracticeRoutine: this.isPracticeRoutine,
  });

  hasFreePracticeRoutines = signal<boolean>(false);

  private readonly subscriptionService = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
  ) {
    effect(() => {
      this.setDisabledStateOfPracticeRoutine();
    });
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(ReadonlyCollectionSelectors.selectReadonlyCollection)
        .subscribe((data) => {
          this.isLoadingCollection = data.isLoadingCollection;
          this.collection = data.collection;
          this.linkedCollection = data.linkedCollection;

          if (data.linkedCollection) {
            // see delete() and save()
            this.isPracticeRoutine.setValue(
              data.linkedCollection.isPracticeRoutine,
              { emitEvent: false },
            );
          } else if (data.collection) {
            // fallback: see delete() and save()
            this.isPracticeRoutine.setValue(data.collection.isPracticeRoutine, {
              emitEvent: false,
            });
          }

          this.setDisabledStateOfPracticeRoutine();
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(UsedResourcesSelectors.hasFreePracticeRoutines)
        .subscribe((hasFreePracticeRoutines) => {
          this.hasFreePracticeRoutines.set(hasFreePracticeRoutines);
        }),
    );
  }

  delete() {
    const helper = new ConfirmationDialogHelper(
      this.matDialog,
      "edit-collection.edit-collection-form.delete-dialog.title",
      "edit-collection.edit-collection-form.delete-dialog.content",
    );
    helper.show(() => {
      this.isDeletingCollection = true;
      if (this.linkedCollection) {
        this.store.dispatch(
          ReadonlyCollectionActions.deleteLinkedCollection({
            linkedCollection: this.linkedCollection,
          }),
        );
      } else if (this.collection) {
        // fallback: when we have a linked collection we also use the collection to display the data.
        // however, currently this component is only used for linked collections (could be used for collection tho)
        // when we have a linked collection we can assume that we can't delete the collection.
        // when we have the collection and no linked collection we can assume that we can delete the collection.
        this.store.dispatch(
          ReadonlyCollectionActions.deleteCollection({
            collection: this.collection,
          }),
        );
      }
    });
  }

  private setDisabledStateOfPracticeRoutine() {
    if (!this.hasFreePracticeRoutines() && !this.isPracticeRoutine.value) {
      this.isPracticeRoutine.disable();
    } else {
      this.isPracticeRoutine.enable();
    }
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    // we will use the same logic as in delete(<- see above):
    // the component does (in theory) support both linked collections and collections.
    // When the component is only used for linked collections the collection case is never used.
    if (this.linkedCollection) {
      this.store.dispatch(
        ReadonlyCollectionActions.updateLinkedCollection({
          name: this.linkedCollection.name,
          isPracticeRoutine: this.isPracticeRoutine.value || false,
        }),
      );
    } else if (this.collection) {
      this.store.dispatch(
        ReadonlyCollectionActions.updateCollection({
          ...this.collection,
          isPracticeRoutine: this.isPracticeRoutine.value || false,
        }),
      );
    }
  }

  navigateToPracticeRoutine() {
    // practice-routines/:collectionType/:collection
    if (this.linkedCollection) {
      this.router.navigate([
        "/practice-routines",
        "lc",
        this.linkedCollection.name,
      ]);
    }
  }
}
