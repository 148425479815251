<div mat-dialog-title>
  <h1>{{ "common.recording-upload-preview-dialog.title" | translate }}</h1>
</div>
<div matDialogContent>
  <video
    *ngIf="data"
    autoplay
    controls
    loop
    disableRemotePlayback
    playsinline
    webkit-playsinline
    class="aspect-video w-60 md:w-[480px]"
  >
    <source [src]="recordingTrustedBlobUrl" />
  </video>
</div>

<div matDialogActions>
  <div class="flex w-full flex-row justify-end">
    <button mat-button [mat-dialog-close]>
      {{ "common.recording-upload-preview-dialog.discard" | translate }}
    </button>
    <button mat-button (click)="upload()" color="primary">
      {{ "common.recording-upload-preview-dialog.upload" | translate }}
    </button>
  </div>
</div>
