import { SubscriptionHelper } from "@aht/frappe-client";
import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideBellOff } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { NotificationSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-mark-as-read",
  templateUrl: "./mark-as-read.component.html",
  standalone: true,
  imports: [NgIconComponent, TranslateModule],
  providers: [provideIcons({ lucideBellOff })],
  styleUrls: [],
})
export class MarkAsReadComponent implements OnInit, OnDestroy {
  isMarkingAsRead = signal(false);
  hasUnreadNotifications = signal(false);

  private readonly helper = new SubscriptionHelper();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store.select(NotificationSelectors.markAsRead).subscribe((data) => {
        this.isMarkingAsRead.set(
          data.isMarkingAllAsRead || data.isMarkingAsRead,
        );
      }),

      this.store
        .select(NotificationSelectors.unreadNotifications)
        .subscribe((data) => {
          this.hasUnreadNotifications.set(data.hasUnreadNotifications);
        }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  markAllAsRead(): void {
    this.store.dispatch(NotificationActions.markAllAsRead());
  }
}
