import { Injectable } from "@angular/core";
import { act, Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { PlaylistActions } from "../actions";
import { PlaylistService } from "src/app/services/player/playlist.service";
import { Store } from "@ngrx/store";
import { PlaylistSelectors } from "../selectors";
import { AppState } from "../reducers";
import { of } from "rxjs";

@Injectable()
export class PlaylistEffects {
  loadPlaylist$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlaylistActions.loadPlaylist),
      withLatestFrom(this.store.select(PlaylistSelectors.playlist)),
      mergeMap(([action, state]) => {
        if (state.playlist == action.playlist) {
          console.log("cache!");
          // we use the old state
          return of(
            PlaylistActions.loadedPlaylist({
              playlistItems: state.playlistItems,
              playlist: action.playlist,
              playlistType: action.playlistType,
            }),
          );
        } else {
          return this.playlistService
            .loadPlaylist(action.playlist, action.playlistType)
            .pipe(
              map((playlistItems) =>
                PlaylistActions.loadedPlaylist({
                  playlist: action.playlist,
                  playlistItems: playlistItems,
                  playlistType: action.playlistType,
                }),
              ),
            );
        }
      }),
    ),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly playlistService: PlaylistService,
  ) {}
}
