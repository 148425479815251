<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingCollectionListing"
></mat-progress-bar>

<mat-card
  *ngIf="hasListingLicense && isCertified && !isLoadingCollectionListing"
>
  <mat-card-content>
    <form [formGroup]="form">
      <mat-checkbox
        matInput
        [formControl]="isListingCollection"
        (change)="handleListingChange()"
      >
        {{
          "edit-collection.edit-collection-listing.enable-listing" | translate
        }}
      </mat-checkbox>
      <div *ngIf="isListingCollection.value == true">
        <!-- price line -->
        <mat-checkbox
          class="mr-4"
          matInput
          [formControl]="isFree"
          (change)="setTotalPrice()"
        >
          {{ "edit-collection.edit-collection-listing.is-free" | translate }}
        </mat-checkbox>
        <mat-form-field *ngIf="isFree.value == false">
          <mat-label>
            {{ "edit-collection.edit-collection-listing.price" | translate }}
          </mat-label>
          <input
            matInput
            [formControl]="price"
            type="number"
            required
            [min]="minSellingPrice"
            (change)="setTotalPrice()"
          />
          <span matPrefix>€</span>
        </mat-form-field>
      </div>
    </form>
    <div
      *ngIf="isFree.value == false && isListingCollection.value == true"
      class="my-4 rounded bg-zinc-100 p-2"
    >
      <yr-selling-price-calculator
        [totalPrice]="totalPrice"
      ></yr-selling-price-calculator>
    </div>
    <div>
      <span *ngIf="listing">
        {{ "edit-collection.edit-collection-listing.listing-date" | translate }}
        {{ listing.created | date: ("common.dateTimeFormat" | translate) }}
      </span>
    </div>

    <div class="mt-2">
      <button
        mat-raised-button
        color="primary"
        [disabled]="
          !form.dirty ||
          form.invalid ||
          isLoadingCollectionListing ||
          isSavingCollectionListing
        "
        (click)="save()"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
<div *ngIf="hasListingLicense && !isCertified">
  <yr-create-profile-hint></yr-create-profile-hint>
</div>
