import { StoredNewsDto } from "src/app/dtos/news/stored-news-dto";
import { News } from "src/app/models/news/news";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class StoredNewsDtoToNewsConversion
  implements Conversion<StoredNewsDto, News>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredNewsDto): News {
    return {
      name: dto.name,
      english: dto.english,
      german: dto.german,
      modified: this.us.parseApiDate(dto.modified),
    };
  }
}
