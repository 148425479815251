import { CommonModule } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { CollectionPurchase } from "src/app/models/collection/collection-purchase";
import { MusicPiecePurchase } from "src/app/models/music-piece/music-piece-purchase";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PricePipe } from "src/app/pipes/price.pipe";

@Component({
  selector: "yr-purchased-item",
  templateUrl: "./purchased-item.component.html",
  styleUrls: ["./purchased-item.component.scss"],
  standalone: true,
  imports: [CommonModule, PricePipe, TranslateModule, MaterialModule],
})
export class PurchasedItemComponent {
  purchasedItem = input.required<PurchasedItem>();

  title = computed(() => {
    const item = this.purchasedItem();
    switch (item.type) {
      case "collection":
        return (item.item as CollectionPurchase).nameOfCollection;
      case "music-piece":
        return (item.item as MusicPiecePurchase).nameOfMusicPiece;
      case "video-feedback":
        return "Video Feedback";
      default:
        return "";
    }
  });

  constructor(private readonly store: Store<AppState>) {}

  downloadReceipt() {
    if (this.purchasedItem) {
      this.store.dispatch(
        PurchaseActions.downloadReceipt({
          purchase: this.purchasedItem(),
        }),
      );
    }
  }
}
