<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<div *ngIf="linkedCollections.length > 0">
  <mat-form-field>
    <mat-label>
      {{ "edit-collection.shared-collection-receivers.filter" | translate }}
    </mat-label>
    <input
      matInput
      [placeholder]="
        'edit-collection.shared-collection-receivers.filter' | translate
      "
      [(ngModel)]="filterText"
      (keyup)="applyFilter()"
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table mat-table style="width: 100%" [dataSource]="dataSource">
    <ng-container matColumnDef="receiver">
      <th mat-header-cell *matHeaderCellDef>
        {{ "edit-collection.shared-collection-receivers.receiver" | translate }}
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.user }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sharedDate">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "edit-collection.shared-collection-receivers.shared-date" | translate
        }}
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.created | date : ("common.dateTimeFormat" | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "edit-collection.shared-collection-receivers.actions" | translate }}
      </th>
      <td mat-cell *matCellDef="let data">
        <div class="actions">
          <button mat-icon-button (click)="unshare(data)" color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
