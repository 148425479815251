import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { MarkdownModule } from "ngx-markdown";
import { FaqItem } from "src/app/models/faq/faq-item";

@Component({
  selector: "yr-faq-item",
  templateUrl: "./faq-item.component.html",
  standalone: true,
  imports: [CommonModule, MarkdownModule],
})
export class FaqItemComponent {
  item = input<FaqItem>();
}
