import { ValidatorFn } from "@angular/forms";

export interface StoredArtistProfile {
  created: Date;
  name: string;
  artistName: string;
  user: string;
  downloadLink: string;
  contentType: string;
  uploadFinished: boolean;
  instruments: string;
  teachingLocation: string;
  motto: string;
  aboutMe: string;
  website: string;
  links: StoredArtistProfileLink[];
  events: StoredArtistProfileEvent[];
  teaser?: string;
  customUrl?: string;
  logoDownloadLink: string;
  logoContentType: string;
  logoUploadFinished: boolean;
  offersVideoFeedback: boolean;
  videoFeedbackPrice: number;
}

export interface StoredArtistProfileLink {
  title: string;
  url: string;
  name: string;
  urlType: UrlType;
  created: Date;
}

export interface StoredArtistProfileEvent {
  name: string;
  title: string;
  date: Date;
  url?: string;
  description?: string;
}

export interface UrlTypeHelper {
  urlType: UrlType;
  icon: string;
  order: number;

  helpText: string;
  urlMapper: (url: string) => string;
  validator: () => ValidatorFn;
}

export type UrlType =
  | "Link"
  | "WhatsApp"
  | "YouTube"
  | "Facebook"
  | "Instagram"
  | "Mail";

export class UrlTypeHelper {
  static urlType(link: string | undefined): UrlType {
    if (link) {
      switch (link) {
        case "WhatsApp":
          return "WhatsApp";
        case "YouTube":
          return "YouTube";
        case "Facebook":
          return "Facebook";
        case "Instagram":
          return "Instagram";
        case "Mail":
          return "Mail";
      }
    }
    return "Link";
  }
}
