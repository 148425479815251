<div *ngIf="musicPiece">
  <div fxLayout="row wrap" fxLayout.lt-md="column">
    <p
      class="detail"
      *ngIf="artistName()"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>album</mat-icon>
      {{ artistName() }}
    </p>

    <p
      class="detail"
      *ngIf="musicPiece.instrument"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>piano</mat-icon>
      {{ musicPiece.instrument }}
    </p>
    <p
      class="detail"
      *ngIf="musicPiece.collection"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>library_books</mat-icon>{{ musicPiece.collection }}
    </p>
    <p
      class="detail"
      *ngIf="musicPiece.temperament"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>music_note</mat-icon>{{ musicPiece.temperament }}
    </p>
  </div>

  <p
    class="detail"
    *ngIf="musicPiece.description"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon>description</mat-icon>{{ musicPiece.description }}
  </p>
</div>
