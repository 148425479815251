<div class="flex w-full flex-col items-center">
  <div class="md:w-4/5 md:max-w-7xl">
    <yr-create-profile-hint *ngIf="isOwner()"></yr-create-profile-hint>
  </div>
</div>

<div class="my-2" *ngIf="isOwner()">
  <yr-artist-page-controls
    [isOwner]="isOwner()"
    (editModeChange)="editMode = $event"
    [customUrl]="artistProfile?.customUrl"
    (customUrlChanged)="updateCustomUrl($event)"
    [isOfferingVideoFeedback]="artistProfile?.offersVideoFeedback || false"
    [videoFeedbackPrice]="artistProfile?.videoFeedbackPrice || 0"
    [minSellingPrice]="minSellingPrice"
    (videoFeedbackOfferingChanged)="updateFeedbackOffering($event)"
  ></yr-artist-page-controls>
</div>

<div
  class="mt-2 flex flex-col items-center"
  *ngIf="artistProfile && !isLoading"
>
  <!-- Heading -->
  <div
    class="flex w-full flex-col items-center bg-[url('/assets/partner/partner-header-background.png')] bg-cover py-16"
  >
    <div
      class="flex w-full flex-col gap-y-16 md:w-4/5 md:max-w-7xl md:flex-row md:justify-between"
    >
      <yr-profile-picture
        [isInEditMode]="editMode"
        [artistProfile]="artistProfile"
        [isOwner]="isOwner()"
      ></yr-profile-picture>

      <yr-qrcode [data]="profileLink()" [canDownload]="true"></yr-qrcode>
    </div>
    <div>
      <yr-editable-text-field
        [canEdit]="isOwner() && editMode"
        [value]="artistProfile.artistName"
        (valueChange)="updateArtistName($event)"
        [placeholder]="'artist.artist-profile.artist-name' | translate"
      >
        <h1
          class="mt-8 break-normal text-center !text-4xl !font-bold md:!text-6xl lg:!text-8xl"
        >
          <!--  style="word-spacing: 999999px" -->
          {{ artistProfile.artistName }}
        </h1>
      </yr-editable-text-field>
    </div>
  </div>

  <!-- Feedback -->
  @if (artistProfile.offersVideoFeedback && !editMode) {
    <div class="flex w-full flex-row justify-center py-8 md:w-4/5 md:max-w-7xl">
      <yr-purchase-video-feedback
        [artistProfile]="artistProfile"
        [loggedInUser]="loggedInUser"
      ></yr-purchase-video-feedback>
    </div>
  }

  <!-- Partner text & link down-->
  <div class="flex w-full flex-col items-center bg-slate-100 py-4 md:py-16">
    <button
      (click)="goToItems(listedItemsContainer)"
      class="my-16 rounded bg-as-600 p-2 text-lg text-white transition-shadow hover:shadow-lg"
    >
      ↓ {{ "artist.artist-profile.show-pieces" | translate }} ↓
    </button>

    <div class="text-6xl font-bold uppercase text-white md:text-9xl">
      Partner
    </div>
  </div>

  <!-- artist logo picture overlay -->
  <div
    class="w-full -translate-y-6 rounded-lg bg-white shadow-lg md:w-4/5 md:max-w-7xl md:-translate-y-[110px]"
  >
    <div
      class="flex flex-col justify-between md:flex-row md:gap-x-16 md:px-16 xl:px-28"
    >
      <div class="my-2 flex flex-col justify-center">
        <yr-partner-logo
          [isInEditMode]="editMode"
          [artistProfile]="artistProfile"
          [isOwner]="isOwner()"
        ></yr-partner-logo>
      </div>
      <div class="flex flex-col items-center">
        <yr-editable-text-field
          [canEdit]="isOwner() && editMode"
          [value]="artistProfile.motto"
          (valueChange)="updateMotto($event)"
          [placeholder]="'artist.artist-profile.motto' | translate"
        >
          <div>
            <div class="mb-2 text-center font-serif text-9xl">”</div>
            <div class="mx-4 mt-2 break-normal text-center text-xl">
              {{ artistProfile.motto }}
            </div>
          </div>
        </yr-editable-text-field>

        <yr-editable-text-field
          [canEdit]="isOwner() && editMode"
          [value]="artistProfile.instruments"
          (valueChange)="updateInstruments($event)"
          [placeholder]="'artist.artist-profile.instruments' | translate"
        >
          <div class="mx-4 my-8 text-center font-semibold">
            {{ "artist.artist-profile.instruments" | translate }}
            <br />
            {{ artistProfile.instruments }}
          </div>
        </yr-editable-text-field>
      </div>
    </div>
  </div>

  <!-- teaser -->
  <div class="flex w-full flex-row justify-center md:w-4/5 md:max-w-7xl">
    <div class="mx-4 w-full md:mx-0">
      <yr-teaser
        [isInEditMode]="isOwner() && editMode"
        (teaserChanged)="updateTeaser($event)"
        [artistProfile]="artistProfile"
      ></yr-teaser>
    </div>
  </div>

  <!-- Links -->
  <div
    class="mt-16 flex w-full flex-col items-center bg-slate-100 py-16"
    *ngIf="(artistProfile.links && artistProfile.links.length > 0) || editMode"
  >
    <div class="flex w-full flex-row justify-center md:w-4/5 md:max-w-7xl">
      <yr-artist-profile-links
        class="mx-4 w-full md:mx-0"
        [links]="artistProfile.links"
        (linksChange)="updateLinks($event)"
        [isInEditMode]="editMode"
      ></yr-artist-profile-links>
    </div>
  </div>

  <!-- teaching location -->
  <div
    class="flex w-full flex-col items-center py-32"
    *ngIf="artistProfile.teachingLocation || editMode"
  >
    <yr-editable-text-field
      [canEdit]="isOwner() && editMode"
      [value]="artistProfile.teachingLocation"
      [placeholder]="'artist.artist-profile.teaching-location' | translate"
      (valueChange)="updateTeachingLocation($event)"
    >
      <div
        class="flex flex-col items-center space-y-8 py-8 text-3xl text-gray-500"
      >
        <div class="h-[2px] w-16 bg-as-500"></div>
        <div
          *ngIf="artistProfile.teachingLocation"
          class="mx-4 break-normal text-center"
        >
          {{ "artist.artist-profile.teaching-location-full" | translate }}
        </div>
        <div class="mx-4 break-normal text-center uppercase text-black">
          {{ artistProfile.teachingLocation }}
        </div>
        <div class="h-[2px] w-16 bg-as-500"></div>
      </div>
    </yr-editable-text-field>
  </div>

  <!-- events -->
  <div
    class="flex w-full flex-col items-center bg-slate-100 pb-16"
    *ngIf="
      (artistProfile.events && artistProfile.events.length > 0) || editMode
    "
  >
    <yr-artist-profile-events
      class="mx-4 w-full md:mx-0"
      [events]="artistProfile.events"
      (eventsChange)="updateEvents($event)"
      [isInEditMode]="editMode"
    ></yr-artist-profile-events>
  </div>

  <!-- about me -->
  <div
    class="flex w-full flex-row justify-center bg-white py-16 md:w-4/5 md:max-w-7xl"
  >
    <yr-editable-text-field
      class="w-full"
      [canEdit]="isOwner() && editMode"
      [value]="artistProfile.aboutMe"
      (valueChange)="updateAboutMe($event)"
      [placeholder]="'artist.artist-profile.about-me' | translate"
      [type]="'TextArea'"
      [maxLength]="2000"
    >
      <div class="mx-4 flex flex-col md:mx-0">
        <div class="mb-8 text-4xl" *ngIf="!editMode">
          {{ "artist.artist-profile.about-me" | translate }}
        </div>
        <div
          class="mb-4 whitespace-pre-line break-normal text-justify text-lg leading-loose"
        >
          {{ artistProfile.aboutMe }}
        </div>
      </div>
    </yr-editable-text-field>
  </div>

  <!-- Feedback -->
  @if (artistProfile.offersVideoFeedback && !editMode) {
    <div class="flex w-full flex-row justify-center py-8 md:w-4/5 md:max-w-7xl">
      <yr-purchase-video-feedback
        [artistProfile]="artistProfile"
        [loggedInUser]="loggedInUser"
      ></yr-purchase-video-feedback>
    </div>
  }

  <div class="w-full bg-gray-50 py-8" #listedItemsContainer>
    <div
      class="mx-auto flex w-full flex-col items-stretch justify-start md:w-4/5 md:max-w-7xl"
    >
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoading"
      ></mat-progress-bar>

      <yr-listed-music-pieces-and-collections></yr-listed-music-pieces-and-collections>

      <mat-card appearance="outlined" *ngIf="!artistProfile && !isLoading">
        <mat-card-content>
          {{ "artist.artist-profile.not-found" | translate }}
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <yr-partner-footer
    class="w-full"
    [artistProfile]="artistProfile"
  ></yr-partner-footer>
</div>
