import { SubscriptionHelper } from "@aht/frappe-client";
import { JsonPipe, NgTemplateOutlet } from "@angular/common";
import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideBellRing } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import { NotificationSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-notification-menu",
  templateUrl: "./notification-menu.component.html",
  standalone: true,
  imports: [
    TranslateModule,
    JsonPipe,
    NgIconComponent,
    NgTemplateOutlet,
    RouterModule,
  ],
  providers: [provideIcons({ lucideBellRing })],
  styleUrls: [],
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
  isLoading = signal(false);
  hasUnreadNotifications = signal(false);
  unreadNotificationCount = signal(0);

  private notificationLoadingTimeoutId: number = -1;

  private readonly helper = new SubscriptionHelper();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store
        .select(NotificationSelectors.unreadNotifications)
        .subscribe((data) => {
          // this.isLoading.set(data.isLoadingNotifications);
          this.hasUnreadNotifications.set(data.hasUnreadNotifications);
          this.unreadNotificationCount.set(data.unreadNotifications.length);
        }),

      this.store.select(UserSelectors.isLoggedIn).subscribe((isLoggedIn) => {
        if (isLoggedIn && this.notificationLoadingTimeoutId == -1) {
          this.notificationLoadingTimeoutId = setInterval(
            () => this.store.dispatch(NotificationActions.loadNotifications()),
            120 * 1000,
          );
        }
      }),
    );

    this.store.dispatch(NotificationActions.loadNotifications());
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
    if (this.notificationLoadingTimeoutId > 0) {
      clearInterval(this.notificationLoadingTimeoutId);
    }
  }
}
