<mat-card appearance="outlined">
  <mat-card-header>
    <mat-icon class="card-icon" mat-card-avatar color="primary"
      >feedback</mat-icon
    >
    <mat-card-title>{{ "user.feedback.title" | translate }}</mat-card-title>
    <mat-card-subtitle>{{
      "user.feedback.subtitle" | translate
    }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>{{ "user.feedback.feedback" | translate }}</mat-label>
          <textarea matInput [formControl]="feedback" rows="10"></textarea>
          <mat-error *ngIf="feedback.hasError('required')">
            {{ "user.feedback.required-error" | translate }}</mat-error
          >
        </mat-form-field>

        <mat-checkbox [formControl]="anonymous">{{
          "user.feedback.anonymous" | translate
        }}</mat-checkbox>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form.invalid"
      (click)="sendFeedback()"
    >
      {{ "user.feedback.send" | translate }}
    </button>
  </mat-card-actions>
</mat-card>
