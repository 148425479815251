import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import {
  StoredArtistProfile,
  StoredArtistProfileEvent,
  StoredArtistProfileLink,
} from "src/app/models/artist/stored-artist-profile";
import { UserData } from "src/app/models/user/user-data";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import {
  ArtistProfileSelectors,
  PurchaseSelectors,
} from "src/app/ngrx/selectors";
import { ArtistProfileSeoService } from "src/app/services/artist/artist-profile-seo.service";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { ArtistPageControlsComponent } from "../artist-page-controls/artist-page-controls.component";
import { ProfilePictureComponent } from "../profile-picture/profile-picture.component";
import { QRCodeComponent } from "../../common/qrcode/qrcode.component";
import { EditableTextFieldComponent } from "../../common/editable-text-field/editable-text-field.component";
import { PartnerLogoComponent } from "../partner-logo/partner-logo.component";
import { TeaserComponent } from "../teaser/teaser.component";
import { ArtistProfileLinksComponent } from "../artist-profile-links/artist-profile-links.component";
import { ArtistProfileEventsComponent } from "../artist-profile-events/artist-profile-events.component";
import { ListedMusicPiecesAndCollectionsComponent } from "../listed-music-pieces-and-collections/listed-music-pieces-and-collections.component";
import { PartnerFooterComponent } from "../partner-footer/partner-footer.component";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseVideoFeedbackComponent } from "../purchase-video-feedback/purchase-video-feedback.component";

@Component({
  selector: "yr-artist-profile",
  templateUrl: "./artist-profile.component.html",
  styleUrls: ["./artist-profile.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    CreateProfileHintComponent,
    ArtistPageControlsComponent,
    ProfilePictureComponent,
    QRCodeComponent,
    EditableTextFieldComponent,
    PartnerLogoComponent,
    TeaserComponent,
    ArtistProfileLinksComponent,
    ArtistProfileEventsComponent,
    ListedMusicPiecesAndCollectionsComponent,
    PartnerFooterComponent,
    PurchaseVideoFeedbackComponent,
  ],
})
export class ArtistProfileComponent implements OnInit, OnDestroy {
  artistProfile?: StoredArtistProfile;
  loggedInUser?: UserData;
  isUploadingProfilePicture: boolean = false;
  isLoading: boolean = true;

  editMode: boolean = false;

  private readonly subscriptions = new SubscriptionService();
  minSellingPrice: number = 0;

  constructor(
    private readonly store: Store<AppState>,
    private readonly seo: ArtistProfileSeoService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(ArtistProfileSelectors.selectArtistProfile)
        .subscribe((data) => {
          this.artistProfile = data.profile;
          this.isLoading = data.isLoading;

          if (data.profile) {
            this.seo.setupArtistProfile(data.profile, this.profileLink());
          }
        }),
    );

    this.subscriptions.add(
      this.store
        .select(UserSelectors.currentUser)
        .subscribe((currentUser) => (this.loggedInUser = currentUser)),
    );
    this.subscriptions.add(
      this.store
        .select(PurchaseSelectors.selectPurchaseSettings)
        .subscribe((data) => {
          this.minSellingPrice = data.purchaseSettings?.minSellingPrice || 1.5;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
    this.seo.reset();
  }

  isOwner() {
    return (
      this.loggedInUser != undefined &&
      this.artistProfile != undefined &&
      this.loggedInUser.username == this.artistProfile.user
    );
  }

  private update(data: StoredArtistProfile) {
    if (!this.artistProfile) return;

    this.store.dispatch(
      ArtistProfileActions.updateProfile({
        artistProfile: data,
      }),
    );
  }

  updateArtistName(artistName: string) {
    this.artistProfile &&
      this.update({ ...this.artistProfile, artistName: artistName });
  }

  updateWebsite(website: string) {
    this.artistProfile &&
      this.update({ ...this.artistProfile, website: website });
  }

  updateInstruments(instruments: string) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        instruments: instruments,
      });
  }

  updateTeachingLocation(teachingLocation: string) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        teachingLocation: teachingLocation,
      });
  }

  updateAboutMe(aboutMe: string) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        aboutMe: aboutMe,
      });
  }

  updateMotto(motto: string) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        motto: motto,
      });
  }

  updateLinks(links: StoredArtistProfileLink[]) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        links: links, // TODO: #63 what happens when we have no id for new entries?
      });
  }

  updateEvents(events: StoredArtistProfileEvent[]) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        events: events,
      });
  }

  updateTeaser(teaser: string | undefined) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        teaser: teaser,
      });
  }

  updateCustomUrl(customUrl?: string) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        customUrl: customUrl,
      });
  }

  updateFeedbackOffering(offer: {
    isOfferingVideoFeedback: boolean;
    videoFeedbackPrice: number;
  }) {
    this.artistProfile &&
      this.update({
        ...this.artistProfile,
        offersVideoFeedback: offer.isOfferingVideoFeedback,
        videoFeedbackPrice: offer.videoFeedbackPrice,
      });
  }

  async share() {
    await navigator.share({ url: this.profileLink() });
  }

  profileLink(): string {
    return window.location.href;
  }

  goToItems(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }
}
