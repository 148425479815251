import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ArtistProfileComponent } from "../artist-profile/artist-profile.component";

@Component({
  selector: "yr-artist-page",
  templateUrl: "./artist-page.component.html",
  styleUrls: ["./artist-page.component.scss"],
  standalone: true,
  imports: [ArtistProfileComponent],
})
export class ArtistPageComponent implements OnInit {
  private artistProfile?: string;
  private artistCustomUrl?: string;
  private focus?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const focus = this.activatedRoute.snapshot.queryParamMap.get("focus");
    this.store.dispatch(
      ArtistProfileActions.focusMusicPiece({
        musicPiece: focus || undefined,
      }),
    );

    this.activatedRoute.paramMap.subscribe((params) => {
      const artistCustomUrl = params.get("artistCustomUrl") || undefined; // see: ArtistProfileUrlMatcher.ts

      if (artistCustomUrl != this.artistCustomUrl && artistCustomUrl) {
        this.artistCustomUrl = artistCustomUrl;
        this.store.dispatch(
          ArtistProfileActions.loadArtistProfileByCustomUrl({
            customUrl: artistCustomUrl,
          }),
        );
      }

      // the above condition and the one below are mutually exclusive: therefore this should be  fine

      const artistProfile = params.get("name") || undefined;
      if (artistProfile != this.artistProfile && artistProfile) {
        this.artistProfile = artistProfile;

        this.store.dispatch(
          ArtistProfileActions.loadArtistProfile({
            name: this.artistProfile,
          }),
        );
      }
    });
  }
}
