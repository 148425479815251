import { CreditStatementDto } from "src/app/dtos/sales/credit-statement.dto";
import { ExtendableConversion } from "../extendable-conversion";
import {
  CreditItem,
  CreditStatement,
  CreditStatementMessage,
} from "src/app/models/sales/credit-statement";
import { UnitService } from "src/app/services/unit/unit.service";

export class CreditStatementConversion extends ExtendableConversion<
  CreditStatementDto,
  CreditStatement
> {
  private readonly us = new UnitService();

  convert(dto: CreditStatementDto): CreditStatement {
    return {
      name: dto.name,
      credit: dto.credit,
      paymentFees: dto.payment_fees,
      platformFees: dto.platform_fees,
      totalPrice: dto.total_price,
      vat: dto.vat,
      status: dto.docstatus == 1 ? "submitted" : "draft",
      artistProfile: dto.artist_profile,
      creation: this.us.parseApiDate(dto.creation),
      modified: this.us.parseApiDate(dto.modified),
      creditCutoffEndDate: this.us.parseApiDate(dto.credit_cutoff_end_date),
      creditCutoffStartDate: this.us.parseApiDate(dto.credit_cutoff_start_date),
      creditItems: (dto.credit_items || []).map(
        (ci) =>
          ({
            name: ci.name,
            title: ci.title,
            price: ci.price,
            purchaseDate: this.us.parseApiDate(ci.purchase_date),
            itemType:
              ci.item_type == "Music Piece Purchase"
                ? "Music Piece Purchase"
                : "Collection Purchase",
            collectionPurchase: ci.collection_purchase,
            musicPiecePurchase: ci.music_piece_purchase,
          }) as CreditItem,
      ),
      messages: (dto.messages || []).map(
        (m) =>
          ({
            creation: this.us.parseApiDate(m.creation),
            message: m.message,
            modified: this.us.parseApiDate(m.modified),
          }) as CreditStatementMessage,
      ),
    };
  }
}
