import { Pipe, PipeTransform } from "@angular/core";
import { MusicPieceToCollectionItemMapping } from "../models/collection/collection-type-data";
import { MusicPieceToDisplay } from "../models/music-piece/music-piece-to-display";

@Pipe({
  name: "musicPieceToCollectionItem",
  standalone: true,
})
export class MusicPieceToCollectionItemPipe implements PipeTransform {
  transform(
    mp: MusicPieceToDisplay,
    mappings: MusicPieceToCollectionItemMapping[],
  ): MusicPieceToCollectionItemMapping[] {
    const result = mappings.filter(
      (m) =>
        m.musicPieceOrLinkedMusicPiece == mp.musicPiece.name ||
        m.musicPieceOrLinkedMusicPiece == mp.linkedMusicPiece?.name,
    );
    return result;
  }
}
