<div mat-dialog-title>
  <mat-icon>upload_file</mat-icon>
  {{ "common.image-upload-dialog.title" | translate }}
</div>
<div mat-dialog-content>
  <yr-image-upload
    [accept]="accept"
    [createOrGetDocument]="data.createOrGetDocument"
    [finishUpload]="data.finishUpload"
    [prepareUpload]="data.prepareUpload"
    (uploadChange)="handleUploadChange($event)"
    (uploadInProgress)="handleUploadInProgress($event)"
    (imageSelected)="handleImageSelected($event)"
    [width]="width"
    [height]="height"
    [hideControls]="true"
  ></yr-image-upload>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button
    mat-button
    (click)="handleCrop()"
    *ngIf="imageSelected && !isUploading"
  >
    {{ "common.image-upload-dialog.crop" | translate }}
  </button>
  <button
    mat-button
    (click)="handleReset()"
    *ngIf="imageSelected && !isUploading"
  >
    {{ "common.reset" | translate }}
  </button>
  <button mat-button (click)="handleClose()" *ngIf="!imageSelected">
    {{ "common.close" | translate }}
  </button>
</div>
