<div fxLayout="column">
  <span class="title">{{ title }}:</span>
  <span
    class="available-resource"
    *ngIf="
      available != 'unlimited' && available !== true && available !== false
    "
  >
    {{ available }} {{ unit }}</span
  >
  <span class="available-resource" *ngIf="available == 'unlimited'">
    ∞ {{ unit }}</span
  >
  <span class="available-resource" *ngIf="available === true">✓</span>
  <span class="available-resource" *ngIf="available === false">✘</span>
</div>
