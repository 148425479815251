<div class="w-full">
  @if (videoFeedbackPurchaseDetails?.videoFeedbackPurchase && requiresPayment) {
    <div class="flex flex-col gap-2">
      <button
        [disabled]="
          !loggedInUser() ||
          loggedInUser()?.email == artistProfile().user ||
          hasPendingPayment
        "
        class="rounded bg-as-600 p-2 text-lg text-white transition-shadow hover:shadow-lg disabled:bg-gray-200"
        (click)="purchaseFeedback()"
      >
        {{
          "artist.purchase-feedback.finish-purchase"
            | translate: purchaseButtonTranslationData
        }}
      </button>
      <p class="text-error">
        {{ "artist.purchase-feedback.pay-pending-purchase" | translate }}
      </p>
      @if (hasPendingPayment) {
        <p>
          @if (
            videoFeedbackPurchaseDetails &&
            videoFeedbackPurchaseDetails.videoFeedbackPurchase
          ) {
            <yr-purchase-status-page-link
              [clientSecret]="
                videoFeedbackPurchaseDetails.videoFeedbackPurchase.clientSecret
              "
              [purchaseType]="'video-feedback'"
            ></yr-purchase-status-page-link>
          }
        </p>
      }
    </div>
  } @else {
    <button
      [disabled]="
        !loggedInUser() ||
        loggedInUser()?.email == artistProfile().user ||
        hasPendingPayment
      "
      class="rounded bg-as-600 p-2 text-lg text-white transition-shadow hover:shadow-lg disabled:bg-gray-200"
      (click)="purchaseFeedback()"
    >
      {{
        "artist.purchase-feedback.purchase"
          | translate: purchaseButtonTranslationData
      }}
    </button>
    @if (hasPendingPayment) {
      <p>
        @if (
          videoFeedbackPurchaseDetails &&
          videoFeedbackPurchaseDetails.videoFeedbackPurchase
        ) {
          <yr-purchase-status-page-link
            [clientSecret]="
              videoFeedbackPurchaseDetails.videoFeedbackPurchase.clientSecret
            "
            [purchaseType]="'video-feedback'"
          ></yr-purchase-status-page-link>
        }
      </p>
    }
  }
</div>
