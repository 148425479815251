import { Injectable } from "@angular/core";
import { LicenseDto } from "src/app/dtos/user/user-license-dto";
import { License } from "src/app/models/user/user-license";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class LicenseDtoToConversion implements Conversion<LicenseDto, License> {
  private minusOneAsUnlimited(input: number): number | "unlimited" {
    return input < 0 ? "unlimited" : input;
  }

  private zeroOrLessAsFree(input: number): number | "free" {
    return input <= 0 ? "free" : input;
  }

  convert(dto: LicenseDto): License {
    // console.log(dto);
    return {
      name: dto.name,
      canShare: dto.can_share > 0,
      title: dto.title,
      markers: this.minusOneAsUnlimited(dto.markers),
      metronomes: this.minusOneAsUnlimited(dto.metronomes),
      musicPieces: this.minusOneAsUnlimited(dto.music_pieces),
      musicScorePages: this.minusOneAsUnlimited(dto.music_score_pages),
      practiceDurationThisMonthMs: this.minusOneAsUnlimited(dto.practice_time),
      recordingPartGroups: this.minusOneAsUnlimited(dto.recording_part_groups),
      storageBytes: this.minusOneAsUnlimited(dto.storage),
      canBecomeArtist: dto.can_become_artist > 0,
      canListMusicPieces: dto.can_list_music_pieces > 0,
      canPitchShift: dto.can_pitch_shift > 0,
      collections: this.minusOneAsUnlimited(dto.collections),
      collectionItems: this.minusOneAsUnlimited(dto.collection_items),
      canShareCollections: dto.can_share_collection > 0,
      canListCollections: dto.can_list_collections > 0,
      pricePerYear: this.zeroOrLessAsFree(dto.price_per_year || 0),
      pricePerMonth: this.zeroOrLessAsFree(dto.price_per_month || 0),
      stripePricePerMonthId: dto.stripe_price_per_month_id,
      stripePricePerYearId: dto.stripe_price_per_year_id,
      canSetCustomUrl: dto.can_set_custom_url > 0,
      canMuteAudioTracks: dto.can_mute_audio_tracks > 0,
      canSetupAudioTracks: dto.can_setup_audio_tracks > 0,
      practiceRoutines: this.minusOneAsUnlimited(dto.practice_routines),
    };
  }
}
