import { Component } from "@angular/core";
import { PurchaseSubscriptionSuccessComponent } from "../purchase-subscription-success/purchase-subscription-success.component";

@Component({
  selector: "yr-purchase-subscription-success-page",
  templateUrl: "./purchase-subscription-success-page.component.html",
  styleUrls: ["./purchase-subscription-success-page.component.scss"],
  standalone: true,
  imports: [PurchaseSubscriptionSuccessComponent],
})
export class PurchaseSubscriptionSuccessPageComponent {}
