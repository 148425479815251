<div class="purchase-details">
  <div class="flex flex-row items-center justify-between truncate">
    <span class="flex-shrink flex-grow truncate text-2xl">
      {{ title() }}
    </span>
    <span class="mx-2 flex-none text-lg">
      {{ purchasedItem().item.price | price }}
    </span>
  </div>
  <p>
    {{ "purchase.purchased-music-piece-item.purchase-date" | translate }}
    {{
      purchasedItem().item.creation
        | date: ("common.dateTimeFormat" | translate)
    }}
  </p>
  <button mat-button color="primary" (click)="downloadReceipt()">
    {{ "purchase.purchased-music-piece-item.download-receipt" | translate }}
  </button>
</div>
