import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  CreateOrGetDocument,
  PrepareUpload,
  FinishUpload,
  BeforeUploadConfirmationHook,
} from "src/app/models/common/upload-types";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { AttachmentService } from "src/app/services/music-piece/attachment.service";
import { UploadDialogComponent } from "../upload-dialog/upload-dialog.component";
import { UploadDialogData } from "src/app/models/music-piece/upload-dialog-data";
import { UploadDialogResult } from "src/app/models/music-piece/upload-dialog-result";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class UploadAttachmentComponent implements OnInit {
  @Input()
  disabled: boolean = false;

  @Input()
  accept: string = "*/*";

  @Output()
  uploadChange: EventEmitter<AttachmentUploadResult> =
    new EventEmitter<AttachmentUploadResult>();

  @Output()
  uploadInProgress = new EventEmitter<boolean>();

  @Input()
  createOrGetDocument: CreateOrGetDocument = () =>
    Promise.resolve({ name: "" });

  @Input()
  prepareUpload: PrepareUpload = (name) => Promise.resolve({ uploadUrl: "" });

  @Input()
  finishUpload: FinishUpload = (name) => Promise.resolve();

  @Output()
  fileSelectedChange = new EventEmitter<File>();

  @Input()
  beforeUploadConfirmation: BeforeUploadConfirmationHook = (_) =>
    Promise.resolve(true);

  _isUploading = false;

  set isUploading(val: boolean) {
    if (val != this._isUploading) {
      this._isUploading = val;
      this.uploadInProgress.emit(val);
    }
  }

  get isUploading() {
    return this._isUploading;
  }
  isUploadFinishedSuccessfully: boolean = false;

  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  private findSelectedFile(files: FileList | null): File | null {
    if (files && files.length == 1) {
      return files?.item(0)!; // yikes!
    } else {
      return null;
    }
  }

  async handleFileInput(event: Event) {
    const input = event.currentTarget as HTMLInputElement;
    const fileToUpload = this.findSelectedFile(input.files);
    this.fileSelectedChange.emit(fileToUpload ?? undefined);

    if (fileToUpload) {
      const shouldUpload = await this.beforeUploadConfirmation(fileToUpload);
      if (shouldUpload) {
        this.upload(fileToUpload);
      } else {
        input.value = input.defaultValue;
      }
    }
  }

  async upload(file: File) {
    const dialog = this.matDialog.open<
      UploadDialogComponent,
      UploadDialogData,
      UploadDialogResult
    >(UploadDialogComponent, {
      data: {
        file: file,
        createOrGetDocument: this.createOrGetDocument,
        prepareUpload: this.prepareUpload,
        finishUpload: this.finishUpload,
        uploadChange: (r) => this.uploadChange.emit(r),
      },
      hasBackdrop: true,
      disableClose: true,
    });

    this.isUploading = true;
    dialog.afterClosed().subscribe((_) => {
      this.isUploading = false;
    });
  }
}
