import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { PurchaseSettings } from "src/app/models/purchase/purchase-settings";
import { AppState } from "src/app/ngrx/reducers";
import { PurchaseSelectors } from "src/app/ngrx/selectors";
import { PricePipe } from "src/app/pipes/price.pipe";

@Component({
  selector: "yr-selling-price-calculator",
  templateUrl: "./selling-price-calculator.component.html",
  styleUrls: ["./selling-price-calculator.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, PricePipe],
})
export class SellingPriceCalculatorComponent implements OnInit {
  private _totalPrice: number = 0;

  @Input()
  set totalPrice(value: number) {
    this._totalPrice = value;
    this.calculatePrices();
  }

  get totalPrice() {
    return this._totalPrice;
  }

  platformFee = 0;

  paymentFee = 0;

  salesTax = 0;

  purchaseSettings?: PurchaseSettings;

  remainingPrice = 0;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(PurchaseSelectors.selectPurchaseSettings)
      .subscribe((data) => {
        this.purchaseSettings = data.purchaseSettings;
        this.calculatePrices();
      });
  }

  calculatePrices() {
    if (this.totalPrice == 0) {
      this.platformFee = 0;
      this.paymentFee = 0;
    } else if (this.purchaseSettings) {
      this.paymentFee =
        this.purchaseSettings.paymentFee * this.totalPrice +
        this.purchaseSettings.fixedPaymentFee;
      this.platformFee =
        this.purchaseSettings.fee * this.totalPrice +
        this.purchaseSettings.fixedFee;

      if (this.purchaseSettings.salesTax > 0) {
        this.salesTax =
          this.totalPrice -
          this.totalPrice / (1 + this.purchaseSettings.salesTax);
      }

      this.remainingPrice =
        this.totalPrice -
        /* this.paymentFee - */ this.platformFee -
        this.salesTax;
    }
  }
}
