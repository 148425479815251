<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ "edit-music-piece.music-piece-uploads.title" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-y-2">
      <div class="text-lg">
        {{ "edit-music-piece.music-piece-uploads.music-score" | translate }}
      </div>

      <div>
        <yr-upload
          *ngIf="musicScore == emptyMusicScore || isUploadingMusicScore"
          accept="application/pdf"
          [createOrGetDocument]="getOrCreateMusicScoreIn()"
          [prepareUpload]="prepareMusicScoreUpload()"
          [finishUpload]="finishMusicScoreUpload()"
          (uploadChange)="uploadChanged($event)"
          (uploadInProgress)="uploadMusicScoreInProgress($event)"
          [disabled]="isLoadingMusicScore"
        ></yr-upload>

        <button
          mat-icon-button
          *ngIf="musicScore != emptyMusicScore && !isUploadingMusicScore"
          (click)="deleteMusicScore()"
          color="warn"
          [disabled]="isLoadingMusicScore || musicPiece?.sharedFrom"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div>
        <div class="text-lg">
          {{ "edit-music-piece.music-piece-uploads.recording" | translate }}
        </div>

        <div
          class="flex flex-row items-center"
          *ngIf="recording.renderFinished"
        >
          <mat-icon color="primary"> done_all </mat-icon>
          <span class="ml-2 text-sm text-gray-600">
            {{ "edit-music-piece.music-piece-uploads.render-done" | translate }}
          </span>
        </div>
        <div
          *ngIf="recording != emptyRecording && !recording.renderFinished"
          class="flex flex-row items-center"
        >
          <mat-icon color="primary">hourglass_empty</mat-icon>
          <span class="ml-2 text-sm text-gray-600">
            {{
              "edit-music-piece.music-piece-uploads.render-in-progress"
                | translate
            }}
          </span>
        </div>

        <button
          mat-stroked-button
          color="primary"
          *ngIf="recording.uploadFinished"
          [disabled]="
            !canSetupAudioTracks ||
            isLoadingRecording ||
            isUpdatingMultipleTracks
          "
          (click)="setMultipleAudioTracks(!recording.useMultipleAudioTracks)"
        >
          <span *ngIf="!recording.useMultipleAudioTracks">
            {{
              "edit-music-piece.music-piece-uploads.enable-multiple-audio-tracks"
                | translate
            }}
          </span>
          <span *ngIf="recording.useMultipleAudioTracks">
            {{
              "edit-music-piece.music-piece-uploads.disable-multiple-audio-tracks"
                | translate
            }}
          </span>
        </button>
      </div>
      <div>
        <yr-recording-upload
          (isUploadRecordingChange)="handleIsUploadingRecordingChange($event)"
          [isLoadingRecording]="isLoadingRecording"
          [recording]="recording"
          [musicPiece]="musicPiece"
        ></yr-recording-upload>
        <div
          *ngIf="
            recording != emptyRecording &&
            !isUploadingRecording &&
            !recording.uploadFinished
          "
          class="text-red-500"
        >
          {{ "edit-music-piece.music-piece-uploads.upload-error" | translate }}
        </div>
        <button
          mat-icon-button
          *ngIf="recording != emptyRecording && !isUploadingRecording"
          [disabled]="isLoadingRecording || musicPiece?.sharedFrom"
          (click)="deleteRecording()"
          color="warn"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div>
        <div class="text-lg">
          {{ "edit-music-piece.music-piece-uploads.thumbnail" | translate }}
        </div>
        <img
          [src]="musicPiece.downloadLink"
          *ngIf="musicPiece && musicPiece.uploadFinished"
          alt="thumbnail"
        />
      </div>
      <div>
        <button
          mat-icon-button
          *ngIf="musicPiece && !musicPiece.uploadFinished"
          (click)="uploadThumbnail()"
        >
          <mat-icon>upload_file</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="musicPiece && musicPiece.uploadFinished"
          [disabled]="musicPiece.sharedFrom"
          (click)="deleteThumbnail()"
          color="warn"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
