import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { VisibilityDirective } from "src/app/directives/visibility.directive";
import { MusicPieceThumbnail } from "src/app/models/music-piece/music-piece-thumbnail";
import {
  Preview,
  WhatToShow,
  WhatToShowFSM,
  initialNoShow,
} from "src/app/models/music-piece/preview";
import { MaterialModule } from "src/app/modules/material.module";
import { PlaybackActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PlaybackSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import {
  registerRotateMirror,
  RotateMirrorOptions,
} from "src/app/videojs-plugin/rotate-mirror-plugin";
import videojs from "video.js";

@Component({
  selector: "yr-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule, VisibilityDirective],
})
export class PreviewComponent implements OnInit, OnDestroy {
  private _preview?: Preview;
  private ranSetup = false;
  isVisible = false;

  private _musicPieceThumbnail?: MusicPieceThumbnail;

  private player?: videojs.Player;

  private readonly subscriptionService = new SubscriptionService();

  @Input()
  set preview(preview: Preview | undefined) {
    this._preview = preview;
    this.setupPreview();
    if (preview) {
      this.whatToShowFSM.preview(preview);
    }
  }

  get preview() {
    return this._preview;
  }

  @Input()
  set musicPieceThumbnail(thumbnail: MusicPieceThumbnail | undefined) {
    this._musicPieceThumbnail = thumbnail;
    if (thumbnail) {
      this.whatToShowFSM.thumbnail(thumbnail);
    }
  }

  get musicPieceThumbnail() {
    return this._musicPieceThumbnail;
  }

  private _videoElement?: ElementRef;

  @ViewChild("video", { static: false })
  set videoElement(data: ElementRef | undefined) {
    this._videoElement = data;
    this.setupPreview();
  }

  get videoElement() {
    return this._videoElement;
  }

  thumbnailPlayClicked = false;

  whatToShow: WhatToShow;
  whatToShowFSM: WhatToShowFSM;

  constructor(
    private readonly store: Store<AppState>,
    private readonly ngZone: NgZone,
  ) {
    this.whatToShow = initialNoShow;
    this.whatToShowFSM = new WhatToShowFSM();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(PlaybackSelectors.selectCurrentPlayback)
        .subscribe((url) => {
          if (this.preview && this.preview.url != url && this.player) {
            this.player.pause();
          }
        }),
    );

    this.subscriptionService.add(
      this.whatToShowFSM.whatToShow().subscribe((wts) => {
        this.whatToShow = wts;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  private setupPreview() {
    if (!this.ranSetup && this.videoElement) {
      this.ranSetup = true;

      const player = videojs(
        this.videoElement.nativeElement,
        {
          autoplay: false,
          loop: true,
          controls: true,
          fluid: false,
          fill: true,
          responsive: true,
          bigPlayButton: true,
          controlBar: {
            pictureInPictureToggle: false,
            playToggle: true,
            fullscreenToggle: false,
            progressControl: {
              seekBar: true,
            },
          },
          userActions: {
            doubleClick: false,
          },
          preload: "metadata", // TODO: #73: when we set the preload to "none", the preview is scaled incorrectly
          playbackRates: [],
          inactivityTimeout: 0, // never hides controlbar
        },
        async () => {
          this.player = player;
          player.rotateMirror();
        },
      );

      player.on("posterchange", () => {
        this.applyMirrorRotate();
      });

      player.on("loadedmetadata", () => {
        this.whatToShowFSM.previewType(
          player.videoHeight() > 0 ? "video" : "audio",
        );

        this.ngZone.run(async () => {
          if (this.whatToShow.showThumbnailAsVideoJsCover) {
            if (this.musicPieceThumbnail?.downloadLink) {
              await player.audioPosterMode(true);
              player.poster(this.musicPieceThumbnail.downloadLink);
            }
          } else if (this.whatToShow.showPreview) {
            if (this.preview?.thumbnailDownloadLink) {
              player.poster(this.preview.thumbnailDownloadLink);
            }
          }
        });

        // at this point we have the dimensions of the video
        // https://docs.videojs.com/player#event:loadedmetadata
        this.applyMirrorRotate();

        if (this.thumbnailPlayClicked) {
          this.player?.play();
        }
      });

      player.on("loaded", (a) => {
        console.log(a);
      });

      player.on("play", () => {
        if (this.preview) {
          this.store.dispatch(
            PlaybackActions.play({
              playingUrl: this.preview.url,
            }),
          );
        }
      });

      this.player = player;
      registerRotateMirror();
    }
  }
  private applyMirrorRotate() {
    /*  console.log(
      `applyMirrorRotate: ${this.preview?.rotation} ${this.preview?.mirrorHorizontal}  ${this.preview?.mirrorVertical}`,
    ); */
    this.player?.trigger("rotateMirror", {
      mirrorHorizontally: this.preview?.mirrorHorizontal || false,
      mirrorVertically: this.preview?.mirrorVertical || false,
      rotation: this.preview?.rotation || 0,
    } as RotateMirrorOptions);
  }

  handleVisibility(visibility: boolean) {
    this.whatToShowFSM.visibilityChanged(visibility);

    if (visibility) {
      this.isVisible = true; // once visible it will stay this way
    }
  }

  play() {
    this.thumbnailPlayClicked = true;
    this.whatToShowFSM.thumbnailPlayClicked();
  }
}
