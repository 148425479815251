import { Component, OnDestroy, OnInit, signal, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import {
  selectEditMusicPieceData,
  selectInitialEditMusicPieceData,
} from "src/app/ngrx/reducers/edit-music-piece.reducer";
import { EditListingComponent } from "../edit-listing/edit-listing.component";
import {
  LicenseSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPieceTypeComponent } from "../../music-piece/music-piece-type/music-piece-type.component";
import { MaterialModule } from "src/app/modules/material.module";
import { SubscriptionHelper } from "@aht/frappe-client";
import { Router } from "@angular/router";

@Component({
  selector: "yr-edit-music-piece",
  templateUrl: "./edit-music-piece.component.html",
  styleUrls: ["./edit-music-piece.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    MusicPieceTypeComponent,
    EditListingComponent,
  ],
})
export class EditMusicPieceComponent implements OnInit, OnDestroy {
  isLoading?: boolean;
  isNew?: boolean;
  isLinkedMusicPiece?: boolean;
  musicPiece?: MusicPiece;
  linkedMusicPiece?: LinkedMusicPiece;
  hasShareLicense: boolean = false;

  hasPracticeDurationLeft = signal(true);

  nameOfPiece = new UntypedFormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(140),
  ]);
  acceptShareTerms = new UntypedFormControl(false);

  instrument = new UntypedFormControl("", Validators.maxLength(140));
  temperament = new UntypedFormControl("", Validators.maxLength(140));
  artist = new UntypedFormControl("", Validators.maxLength(140));
  description = new UntypedFormControl("", Validators.maxLength(200)); // actual db-limit is: 65,535
  collection = new UntypedFormControl("", Validators.maxLength(140));

  form = new UntypedFormGroup({
    name: this.nameOfPiece,
    acceptShareTerms: this.acceptShareTerms,
    instrument: this.instrument,
    temperament: this.temperament,
    artist: this.artist,
    description: this.description,
    collection: this.collection,
  });

  @ViewChild(EditListingComponent)
  listing?: EditListingComponent;

  private readonly helper = new SubscriptionHelper();

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  ngOnInit(): void {
    this.helper.addMany(
      this.store.select(selectInitialEditMusicPieceData).subscribe((data) => {
        this.isNew = data.isNew;
        this.isLinkedMusicPiece = data.isLinkedMusicPiece;
      }),

      this.store.select(selectEditMusicPieceData).subscribe((data) => {
        this.musicPiece = data.musicPiece;
        this.linkedMusicPiece = data.linkedMusicPiece;
        this.isLoading = data.isLoading;

        if (data.musicPiece) {
          this.nameOfPiece.patchValue(data.musicPiece.nameOfPiece);
          this.acceptShareTerms.patchValue(data.musicPiece.acceptShareTerms);
          this.instrument.patchValue(data.musicPiece.instrument);
          this.temperament.patchValue(data.musicPiece.temperament);
          this.artist.patchValue(data.musicPiece.artist);
          this.description.patchValue(data.musicPiece.description);
          this.collection.patchValue(data.musicPiece.collection);
        }
      }),
      this.store
        .select(LicenseSelectors.selectUserLicense)
        .subscribe((data) => {
          this.hasShareLicense = data.license.canShare;
        }),
      this.store
        .select(UsedResourcesSelectors.hasPracticeDurationLeft)
        .subscribe((data) => {
          this.hasPracticeDurationLeft.set(data.hasPracticeDurationLeft);
        }),
    );
  }

  save() {
    this.store.dispatch(
      EditMusicPieceActions.save({
        nameOfPiece: this.nameOfPiece.value,
        name: this.musicPiece?.name,
        acceptShareTerms: this.acceptShareTerms.value == true,
        artist: this.artist.value,
        collection: this.collection.value,
        description: this.description.value,
        instrument: this.instrument.value,
        temperament: this.temperament.value,
      }),
    );

    if (this.listing) {
      this.listing.save();
    }
  }

  navigateToPlayer() {
    if (this.isLinkedMusicPiece && this.linkedMusicPiece) {
      this.router.navigate(["/linked", this.linkedMusicPiece.name]);
    } else {
      this.router.navigate(["/player", this.musicPiece?.name]);
    }
  }
}
