import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { mergeMap, map, filter, withLatestFrom, tap } from "rxjs/operators";
import { RecordingPartGroupService } from "src/app/services/player/recording-part-group.service";
import { RecordingPartService } from "src/app/services/player/recording-part.service";
import { PlayerActions, RecordingPartGroupActions } from "../actions";
import { AppState } from "../reducers";
import { selectRecordingPartGroups } from "../reducers/recording-part-group.reducer";

@Injectable()
export class RecordingPartGroupEffects {
  loadRecordingPartGroupsWhenRecordingIsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlayerActions.recordingLoaded),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) => {
        return this.recordingGroupPartService.getRecordingPartGroups(
          action.recording,
          state.linkedMusicPiece
        );
      }),
      map((groups) =>
        RecordingPartGroupActions.loadedRecordingPartGroups({
          recordingPartGroups: groups,
        })
      )
    )
  );

  loadRecordingPartsForSelectedRecordingPartGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.selectRecordingPartGroup),
      filter((action) => action.recordingPartGroup !== undefined),
      withLatestFrom(this.store.select((s) => s.player)),
      mergeMap(([action, state]) => {
        return this.recordingPartService.loadRecordingParts(
          action.recordingPartGroup!,
          state.linkedMusicPiece
        );
      }),
      map((recordingParts) =>
        RecordingPartGroupActions.loadedRecordingParts({
          recordingParts: recordingParts,
        })
      )
    )
  );

  addRecordingPartGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.addRecordingPartGroup),
      mergeMap((action) =>
        this.recordingGroupPartService.addRecordingGroup(
          action.recordingPartGroup,
          action.totalMs
        )
      ),
      map((rpg) =>
        RecordingPartGroupActions.addedRecordingPartGroup({
          recordingPartGroup: rpg,
        })
      )
    )
  );

  addRecordingPart$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.addRecordingPart),
      mergeMap((action) =>
        this.recordingPartService.addRecordingPart(action.recordingPart)
      ),
      map((recordingPart) =>
        RecordingPartGroupActions.addedRecordingPart({
          recordingPart: recordingPart,
        })
      )
    )
  );

  selectRecordingPartGroupWhenNewRecordingPartGroupIsAdded$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.addedRecordingPartGroup),
      map((action) =>
        RecordingPartGroupActions.selectRecordingPartGroup({
          recordingPartGroup: action.recordingPartGroup,
        })
      )
    )
  );

  selectRecordingPartGroupWhenRecordingPartGroupsAreLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.loadedRecordingPartGroups),
      filter((action) => action.recordingPartGroups.length > 0),
      map((action) =>
        RecordingPartGroupActions.selectRecordingPartGroup({
          recordingPartGroup: action.recordingPartGroups[0],
        })
      )
    )
  );

  deleteRecordingPartGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.deleteRecordingPartGroup),
      mergeMap((action) =>
        this.recordingGroupPartService
          .deleteRecordingGroup(action.recordingPartGroup)
          .pipe(map((_) => action.recordingPartGroup))
      ),
      map((deletedRecordingPartGroup) =>
        RecordingPartGroupActions.deletedRecordingPartGroup({
          recordingPartGroup: deletedRecordingPartGroup,
        })
      )
    )
  );

  selectRecordingPartGroupWhenRecordingPartGroupsAreDeleted$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(RecordingPartGroupActions.deletedRecordingPartGroup),
        withLatestFrom(this.store.select(selectRecordingPartGroups)),
        filter(([_, recordingPartGroups]) => recordingPartGroups.length > 0),
        map(([_, recordingPartGroups]) =>
          RecordingPartGroupActions.selectRecordingPartGroup({
            recordingPartGroup: recordingPartGroups[0],
          })
        )
      )
  );

  deleteRecordingPart$ = createEffect(() =>
    this.actions.pipe(
      ofType(RecordingPartGroupActions.deleteRecordingPart),
      mergeMap((action) =>
        this.recordingPartService
          .deleteRecordingPart(action.recordingPart)
          .pipe(
            map((_) =>
              RecordingPartGroupActions.deletedRecordingPart({
                recordingPart: action.recordingPart,
              })
            )
          )
      )
    )
  );

  updateRecordingPart$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(RecordingPartGroupActions.updateRecordingPart),
        tap((action) => {
          this.recordingPartService
            .updateRecordingPart(action.recordingPart)
            .subscribe(
              (_) => console.log(`updated recording part`),
              (error) =>
                console.error(`failed to update Recording part: ${error}`)
            );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly recordingGroupPartService: RecordingPartGroupService,
    private readonly recordingPartService: RecordingPartService
  ) {}
}
