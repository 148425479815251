import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-editable-text-field",
  templateUrl: "./editable-text-field.component.html",
  styleUrls: ["./editable-text-field.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class EditableTextFieldComponent {
  @Input()
  canEdit: boolean = false;

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  value?: string;

  isInEditMode: boolean = false;

  @Input()
  type: "TextArea" | "Input" = "Input";

  @Input()
  placeholder = "";

  isDirty = false;

  @Input()
  maxLength: number = 140;

  constructor() {}

  edit() {
    this.isInEditMode = true;
  }

  done() {
    this.isInEditMode = false;
    if (this.isDirty) {
      this.valueChange.emit(this.value);
    }
  }

  updateValue(value?: string) {
    this.isDirty = true;
    this.value = value;
  }
}
