import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { UserService } from "../../services/authentication/user.service";
import { RegistrationActions, UserActions } from "../actions";

@Injectable()
export class RegistrationEffects {
  performRegistrationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.registerAction),
      mergeMap((createPotentialUser) => {
        return this.userService.registerPotentialUser(createPotentialUser).pipe(
          map((storedPotentialUser) =>
            RegistrationActions.registrationSuccessAction(storedPotentialUser),
          ),
          catchError(() => of(RegistrationActions.registrationFailedAction())),
        );
      }),
    ),
  );

  finishGoogleSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.finishGoogleSignUp),
      mergeMap((action) => {
        return this.userService.finishGoogleSignUp(action.signUpData).pipe(
          map((_) =>
            UserActions.signInWIthGoogle({
              credential: action.signUpData.credential,
            }),
          ),
          catchError(() => of(RegistrationActions.registrationFailedAction())),
        );
      }),
    ),
  );

  performVerificationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.verifyEmailAction),
      mergeMap((data) => {
        return this.userService
          .verifyEmailAndSetPassword(data.code, data.password)
          .pipe(
            map((result) =>
              RegistrationActions.verificationSuccessAction({
                email: result.email,
                password: data.password,
              }),
            ),
            catchError(() =>
              of(RegistrationActions.verificationFailedAction()),
            ),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
  ) {}
}
