import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AddCollectionItemDialogData } from "src/app/models/collection/add-collection-item-dialog-data";
import { AddCollectionItemDialogResult } from "src/app/models/collection/add-collection-item-dialog-result";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { MaterialModule } from "src/app/modules/material.module";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-add-collection-item-dialog",
  templateUrl: "./add-collection-item-dialog.component.html",
  styleUrls: ["./add-collection-item-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class AddCollectionItemDialogComponent implements OnInit, OnDestroy {
  private readonly subscriptionService = new SubscriptionService();
  collectionItemsToAdd: MusicPieceToDisplay[] = [];
  filteredItemsToAdd: MusicPieceToDisplay[] = [];

  filterText = "";

  isLoadingCollectionItemsToAdd: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: AddCollectionItemDialogData,
    private readonly dialogRef: MatDialogRef<
      AddCollectionItemDialogComponent,
      AddCollectionItemDialogResult
    >,
    private readonly store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.store.dispatch(
      // we could move this someplace else in order to make the dialog super stupid
      EditCollectionActions.loadCollectionItemsToAdd({
        existingCollectionItems: this.data.existingCollectionItems,
        acceptedShareTerms: this.data.acceptedShareTerms,
      }),
    );

    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionItemsToAdd)
        .subscribe((data) => {
          this.collectionItemsToAdd = data.collectionItemsToAdd;
          this.isLoadingCollectionItemsToAdd =
            data.isLoadingCollectionsItemsToAdd;
          this.filteredItemsToAdd = data.filteredCollectionItemsToAdd;
        }),
    );
  }

  add(item: MusicPieceToDisplay) {
    this.dialogRef.close({
      itemToAdd: item,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  applyFilter() {
    this.store.dispatch(
      EditCollectionActions.filterCollectionItemsToAdd({
        filter: this.filterText,
      }),
    );
  }
}
