import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { ArtistCertificationState } from "src/app/models/user/artist-certification";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState, EditMusicPieceSelectors } from "src/app/ngrx/reducers";
import {
  ArtistCertificationSelectors,
  PurchaseSelectors,
} from "src/app/ngrx/selectors";
import { SellingPriceCalculatorComponent } from "../selling-price-calculator/selling-price-calculator.component";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-edit-listing",
  templateUrl: "./edit-listing.component.html",
  styleUrls: ["./edit-listing.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    SellingPriceCalculatorComponent,
    CreateProfileHintComponent,
  ],
})
export class EditListingComponent implements OnInit {
  musicPiece?: MusicPiece;
  hasListingLicense = false;
  isCertified = false;
  isNew = true;
  isLinkedMusicPiece = false;
  listing?: MusicPieceListing;

  isFree = new UntypedFormControl(false);
  isListingMusicPiece = new UntypedFormControl(false);
  price = new UntypedFormControl({ value: 0, disabled: true });
  form = new UntypedFormGroup({
    price: this.price,
    isListingMusicPiece: this.isListingMusicPiece,
  });

  minSellingPrice: number = 1.0;
  totalPrice: number = 0;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(EditMusicPieceSelectors.selectEditMusicPieceData)
      .subscribe((data) => {
        this.musicPiece = data.musicPiece;
      });

    this.store
      .select(ArtistCertificationSelectors.selectArtistCertificationWithLicense)
      .subscribe((data) => {
        this.hasListingLicense = data.license.canListMusicPieces;

        this.isCertified =
          data.artistCertification?.state == ArtistCertificationState.Approved;
      });

    this.store
      .select(EditMusicPieceSelectors.selectInitialEditMusicPieceData)
      .subscribe((data) => {
        this.isLinkedMusicPiece = data.isLinkedMusicPiece;
        this.isNew = data.isNew;
      });

    this.store
      .select(EditMusicPieceSelectors.selectListing)
      .subscribe((listing) => {
        this.listing = listing;
        if (listing) {
          this.isListingMusicPiece.patchValue(true);
          this.price.setValue(listing.price);
          this.isFree.setValue(listing.price <= 0);
          this.price.enable();
          this.calculateTotalPrice();
        } else {
          // TODO: #60 deal with no existing listing
          this.isListingMusicPiece.patchValue(false);
        }
      });

    this.store
      .select(PurchaseSelectors.selectPurchaseSettings)
      .subscribe(
        (data) =>
          (this.minSellingPrice =
            data.purchaseSettings?.minSellingPrice || 1.0),
      );
  }

  handleListingChange() {
    if (this.isListingMusicPiece.value) {
      this.price.setValue(this.minSellingPrice);
      this.calculateTotalPrice();
      this.price.enable();
      this.isFree.enable();
    } else {
      this.price.disable();
      this.isFree.disable();
    }
  }

  calculateTotalPrice() {
    if (this.isFree.value) {
      this.totalPrice = 0;
    } else {
      if (this.price.value < this.minSellingPrice) {
        this.price.setValue(this.minSellingPrice);
      }
      this.totalPrice = this.price.value;
    }
  }

  public save() {
    console.log("SAVE");

    if (this.form.valid && this.musicPiece) {
      console.log("actual save");
      this.store.dispatch(
        EditMusicPieceActions.saveMusicPieceListing({
          isListing: this.isListingMusicPiece.value,
          price: this.totalPrice,
          name: this.listing?.name,
          musicPiece: this.musicPiece.name,
        }),
      );
    }
  }

  canEditExisting(): boolean {
    if (this.isCertified && this.listing) {
      return true;
    }
    if (this.hasListingLicense && this.listing) {
      return true;
    }
    return false;
  }
}
