import { Injectable } from "@angular/core";
import { MusicScoreDto } from "src/app/dtos/music-score/music-score-dto";
import {
  EmptyMusicScore,
  MusicScore,
} from "src/app/models/music-score/music-score";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class MusicScoreDtoToMusicScoreConversion
  implements Conversion<MusicScoreDto, MusicScore>
{
  convert(item: MusicScoreDto): MusicScore {
    if (!item) {
      return EmptyMusicScore;
    }
    return {
      name: item.name,
      nameOfMusicPiece: item.music_piece,
      downloadLink: item.download_link,
      numberOfPages: item.number_of_pages,
    };
  }
}
