import { Conversion } from "../conversion";
import { SubscriptionCheckoutSessionDto } from "src/app/dtos/license/subscription-checkout-session.dto";
import { SubscriptionCheckoutSession } from "src/app/models/license/subscription-checkout-session";

export class SubscriptionCheckoutSessionConversion
  implements
    Conversion<SubscriptionCheckoutSessionDto, SubscriptionCheckoutSession>
{
  convert(dto: SubscriptionCheckoutSessionDto): SubscriptionCheckoutSession {
    return {
      checkoutSessionUrl: dto.checkout_session_url,
    };
  }
}
