import { Component } from "@angular/core";
import { ReadonlyCollectionDetailsComponent } from "../readonly-collection-details/readonly-collection-details.component";
import { ReadonlyCollectionItemsComponent } from "../readonly-collection-items/readonly-collection-items.component";

@Component({
  selector: "yr-readonly-collection",
  templateUrl: "./readonly-collection.component.html",
  styleUrls: ["./readonly-collection.component.scss"],
  standalone: true,
  imports: [
    ReadonlyCollectionDetailsComponent,
    ReadonlyCollectionItemsComponent,
  ],
})
export class ReadonlyCollectionComponent {}
