import { Component, OnInit } from "@angular/core";
import { UserSettingsComponent } from "../user-settings/user-settings.component";
import { UpdatePasswordComponent } from "../../user/update-password/update-password.component";
import { FeedbackComponent } from "../../user/feedback/feedback.component";
import { NewsListComponent } from "../../news/news-list/news-list.component";
import { VersionComponent } from "../../common/version/version.component";
import { NotificationSettingsComponent } from "../../notification/notification-settings/notification-settings.component";

@Component({
  selector: "yr-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.scss"],
  standalone: true,
  imports: [
    UserSettingsComponent,
    UpdatePasswordComponent,
    FeedbackComponent,
    NewsListComponent,
    VersionComponent,
    NotificationSettingsComponent,
  ],
})
export class SettingsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
