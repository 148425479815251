import { SubscriptionHelper } from "@aht/frappe-client";
import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { NotificationTrigger } from "src/app/models/notification/notification-trigger";
import { NotificationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { NotificationSelectors } from "src/app/ngrx/selectors";
import { NotificationItemComponent } from "../notification-item/notification-item.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "yr-notification-list",
  templateUrl: "./notification-list.component.html",
  standalone: true,
  styleUrls: [],
  imports: [NotificationItemComponent, TranslateModule],
})
export class NotificationListComponent implements OnInit, OnDestroy {
  isLoading = signal(false);
  notifications = signal<NotificationTrigger[]>([]);

  private readonly helper = new SubscriptionHelper();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store
        .select(NotificationSelectors.notifications)
        .subscribe((data) => {
          this.isLoading.set(data.isLoadingNotifications);
          this.notifications.set(data.notifications);
        }),
    );

    this.store.dispatch(NotificationActions.loadNotifications());
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }
}
