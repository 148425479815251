import { CommonModule } from "@angular/common";
import { Component, Input, computed, input } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { NgIcon, provideIcons } from "@ng-icons/core";
import { lucideMessagesSquare } from "@ng-icons/lucide";
import { TranslateModule } from "@ngx-translate/core";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";
import { MaterialModule } from "src/app/modules/material.module";
import { PricePipe } from "src/app/pipes/price.pipe";

@Component({
  selector: "yr-explore-artist-profile",
  templateUrl: "./explore-artist-profile.component.html",
  styleUrls: ["./explore-artist-profile.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    NgIcon,
    PricePipe,
    TranslateModule,
  ],
  providers: [provideIcons({ lucideMessagesSquare })],
})
export class ExploreArtistProfileComponent {
  item = input.required<ExploreArtistProfile>();

  artistUrl = computed(() => {
    if (this.item().artist.customUrl) {
      return [`/@${this.item().artist.customUrl}`];
    } else {
      return ["/artists", this.item().artist.name];
    }
  });

  constructor(private readonly router: Router) {}

  navigateToArtist() {
    this.router.navigate(this.artistUrl());
  }
}
