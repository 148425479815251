import { StoredRotationDto } from "src/app/dtos/rotation/stored-rotation-dto";
import { Rotation } from "src/app/models/rotation/rotation";
import { Conversion } from "../conversion";

export class StoredRotationDtoConversion
  implements Conversion<StoredRotationDto, Rotation>
{
  convert(dto: StoredRotationDto): Rotation {
    return {
      mirrorHorizontal: dto.mirror_horizontal == 1,
      mirrorVertical: dto.mirror_vertical == 1,
      recording: dto.recording,
      name: dto.name,
      rotation: dto.rotation as 0 | 90 | 180 | 270, // we check in the backend
    };
  }
}
