<div class="w-full">
  <div *ngIf="artistProfile && artistProfile.teaser">
    <div
      *ngIf="
        teaser &&
        teaser.recording.downloadLink &&
        teaser.recording.renderFinished &&
        hasVideo
      "
      class="aspect-video"
    >
      <yr-teaser-video [teaser]="teaser"></yr-teaser-video>
    </div>
  </div>

  <div *ngIf="isInEditMode" class="my-2">
    <div
      *ngIf="
        !teaser ||
        !teaser.recording.downloadLink ||
        !teaser.recording.renderFinished ||
        !hasVideo
      "
      class="text-as-500"
    >
      {{ "artist.teaser.no-video-hint" | translate }}
    </div>

    <button mat-button (click)="chooseTeaser()" [disabled]="!artistProfile">
      {{ "artist.teaser.choose" | translate }}
    </button>
    <button
      mat-button
      (click)="deleteTeaser()"
      color="warn"
      [disabled]="!artistProfile"
    >
      {{ "common.delete" | translate }}
    </button>
  </div>
</div>
