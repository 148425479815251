import { UnitService } from "src/app/services/unit/unit.service";
import { ExtendableConversion } from "../extendable-conversion";
import { PreviewDtoToPreviewConversion } from "../music-piece/preview-dto-to-preview.conversion";
import { MusicPieceDtoToMusicPieceConversion } from "../music-piece/music-piece-dto-to-music-piece-conversion";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { ExploreCollectionDto } from "src/app/dtos/explore/explore-collection.dto";
import { StoredArtistProfileConversion } from "../artist/stored-artist-profile-conversion";

export class ExploreCollectionConversion extends ExtendableConversion<
  ExploreCollectionDto,
  ExploreCollection
> {
  private readonly us = new UnitService();

  private readonly previewConversion = new PreviewDtoToPreviewConversion();
  private readonly musicPieceConversion =
    new MusicPieceDtoToMusicPieceConversion();
  private readonly artistProfileConversion =
    new StoredArtistProfileConversion();

  convert(dto: ExploreCollectionDto): ExploreCollection {
    return {
      created: this.us.parseApiDate(dto.creation),
      description: dto.description,
      contentType: dto.content_type,
      downloadLink: dto.download_link,
      name: dto.name,
      collection: dto.collection,
      title: dto.title,
      price: dto.price,
      artist: this.artistProfileConversion.convert(dto.artist),
      instrument: dto.instrument,
      temperament: dto.temperament,
      isPracticeRoutine: dto.is_practice_routine > 0,
      items: dto.items
        .map((i) => ({
          order: i.order,
          musicPiece: this.musicPieceConversion.convert(i.music_piece),
          preview:
            i.preview != undefined
              ? this.previewConversion.convert(i.preview)
              : undefined,
        }))
        .sort((a, b) => a.order - b.order),
    };
  }
}
