import { StoredCollectionListingDto } from "src/app/dtos/collection/stored-collection-listing.dto";
import { Conversion } from "../conversion";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";
import { UnitService } from "src/app/services/unit/unit.service";

export class StoredCollectionListingConversion
  implements Conversion<StoredCollectionListingDto, StoredCollectionListing>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredCollectionListingDto): StoredCollectionListing {
    return {
      collection: dto.collection,
      name: dto.name,
      price: dto.price,
      created: this.us.parseApiDate(dto.creation),
    };
  }
}
