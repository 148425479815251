<div matDialogContent>
  <yr-purchase (onSuccess)="close()" (onFailure)="close()"></yr-purchase>
</div>
<div matDialogActions>
  <!-- we need a container element because the matDialogActions breaks the tailwind css classes.-->
  <div class="flex w-full flex-row justify-end">
    <button mat-button [mat-dialog-close]>
      {{ "common.close" | translate }}
    </button>
  </div>
</div>
