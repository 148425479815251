import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
  StoredArtistProfileLink,
  UrlTypeHelper,
} from "src/app/models/artist/stored-artist-profile";
import { UrlTypeValidator } from "src/app/validators/url-type-validator";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-artist-profile-links-form",
  templateUrl: "./artist-profile-links-form.component.html",
  styleUrl: "./artist-profile-links-form.component.scss",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class ArtistProfileLinksFormComponent implements OnInit {
  @Output()
  onSubmit = new EventEmitter<StoredArtistProfileLink>();

  @Output()
  onDelete = new EventEmitter<StoredArtistProfileLink>();

  @Input()
  isInEditMode: boolean = false;

  @Input()
  canDelete: boolean = false;

  private _existingArtistProfileLink?: StoredArtistProfileLink;

  @Input()
  set existingArtistProfileLink(link: StoredArtistProfileLink) {
    this._existingArtistProfileLink = link;
    this.title.setValue(link.title);
    this.url.setValue(link.url || null);
    this.urlType.setValue(link.urlType);

    this.urlTypeChanged(link.urlType);
  }

  get existingArtistProfileLink(): StoredArtistProfileLink | undefined {
    return this._existingArtistProfileLink;
  }

  title = new FormControl(""); // validators are set dynamically based on the urlType below

  url = new FormControl("", {
    validators: [],
  });

  private _supportedUrlTypes: UrlTypeHelper[] = [];

  get supportedUrlTypes() {
    return this._supportedUrlTypes;
  }

  @Input()
  set supportedUrlTypes(urlTypes: UrlTypeHelper[]) {
    this._supportedUrlTypes = urlTypes;
    this.currentlySelectedUrlTypeHelper = undefined;

    this.form.setValidators(
      new UrlTypeValidator(this.supportedUrlTypes, this.url).urlTypeValidator(),
    );

    this.urlTypeChanged(this.urlType.value);
  }

  currentlySelectedUrlTypeHelper?: UrlTypeHelper;

  isLinkUrlType = true;

  urlType = new FormControl("Link");

  form = new FormGroup(
    {
      title: this.title,
      url: this.url,
      urlType: this.urlType,
    },
    {
      validators: [], // will be set dynamically
    },
  );

  private urlTypeChanged(ut: string | null) {
    this.currentlySelectedUrlTypeHelper = this.supportedUrlTypes.find(
      (sut) => sut.urlType == ut,
    );

    this.isLinkUrlType = this.currentlySelectedUrlTypeHelper?.urlType == "Link";

    if (this.currentlySelectedUrlTypeHelper?.urlType == "Link") {
      this.title.setValidators([
        Validators.maxLength(140),
        Validators.required,
      ]);
    } else {
      this.title.setValidators([Validators.maxLength(140)]);
    }
    this.title.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.urlType.valueChanges.subscribe((ut) => this.urlTypeChanged(ut));
  }

  submit() {
    if (this.form.valid) {
      this.onSubmit.emit({
        name: this.existingArtistProfileLink?.name || "",
        title: this.isLinkUrlType ? this.title.value! : this.urlType.value!,
        url: this.url.value || "",
        urlType: UrlTypeHelper.urlType(this.urlType.value || ""),
        created: new Date(),
      });

      if (this.existingArtistProfileLink == undefined) {
        this.form.reset();
      }
    }
  }

  delete() {
    this.onDelete.emit(this.existingArtistProfileLink);
  }
}
