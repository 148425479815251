<form (submit)="submit()" [formGroup]="form">
  <div class="my-2 truncate rounded bg-gray-50 p-2">
    <div class="flex flex-col gap-2">
      <mat-form-field *ngIf="isLinkUrlType">
        <mat-label>{{
          "artist.artist-profile-links.title" | translate
        }}</mat-label>
        <input
          matInput
          [formControl]="title"
          type="text"
          [placeholder]="'artist.artist-profile-links.title' | translate"
        />
        <mat-error *ngIf="title.hasError('required')">
          {{ "errors.required" | translate }}
        </mat-error>
        <mat-error *ngIf="title.hasError('maxlength')">
          {{ "errors.max-length" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{ "artist.artist-profile-links.url-type" | translate }}
        </mat-label>
        <mat-select [formControl]="urlType">
          <mat-option *ngFor="let ut of supportedUrlTypes" [value]="ut.urlType">
            <mat-icon [svgIcon]="ut.icon" *ngIf="ut.icon != ''"></mat-icon>
            {{ ut.urlType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="
          currentlySelectedUrlTypeHelper &&
          currentlySelectedUrlTypeHelper.helpText
        "
        class="whitespace-pre-line text-sm text-gray-700"
      >
        {{ currentlySelectedUrlTypeHelper.helpText | translate }}
      </div>
      <mat-form-field>
        <mat-label>
          {{
            currentlySelectedUrlTypeHelper != undefined
              ? currentlySelectedUrlTypeHelper.urlType
              : ("artist.artist-profile-links.link" | translate)
          }}
        </mat-label>
        <input matInput [formControl]="url" type="text" />
      </mat-form-field>

      <mat-error *ngIf="form.hasError('link')">
        {{ "errors.invalid-url" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('maxlength')">
        {{ "errors.max-length" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('whatsapp')">
        {{ "errors.whatsapp" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('youtube')">
        {{ "errors.youtube" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('facebook')">
        {{ "errors.facebook" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('instagram')">
        {{ "errors.instagram" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('mail')">
        {{ "errors.mail" | translate }}
      </mat-error>
    </div>
    <button mat-icon-button type="submit" [disabled]="form.invalid">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button type="button" (click)="delete()" *ngIf="canDelete">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</form>
