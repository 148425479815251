import { JsonPipe } from "@angular/common";
import { Component, effect, input, output } from "@angular/core";
import {
  FormControl,
  FormGroup,
  MinValidator,
  ReactiveFormsModule,
  ValidatorFn,
} from "@angular/forms";
import { NgIcon, provideIcons } from "@ng-icons/core";
import { lucideCheck } from "@ng-icons/lucide";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "yr-set-video-feedback-offering",
  templateUrl: "./set-video-feedback-offering.component.html",
  standalone: true,
  styleUrls: [],
  imports: [TranslateModule, ReactiveFormsModule, NgIcon, JsonPipe],
  providers: [provideIcons({ lucideCheck })],
})
export class SetVideoFeedbackOfferingComponent {
  errorTextParam: { minPrice: number } = {
    minPrice: 0,
  };

  minSellingPrice = input.required<number>();
  isOfferingVideoFeedback = input.required<boolean>();
  videoFeedbackPrice = input.required<number>();

  isOfferingVideoFeedbackForm = new FormControl<boolean>(false);

  videoFeedbackPriceForm = new FormControl<number>(0);

  form = new FormGroup(
    {
      isOfferingVideoFeedbackForm: this.isOfferingVideoFeedbackForm,
      videoFeedbackPriceForm: this.videoFeedbackPriceForm,
    },
    [this.validateThatPriceIsSetForEnabledOfferings()],
  );

  videoFeedbackOfferingChanged = output<{
    isOfferingVideoFeedback: boolean;
    videoFeedbackPrice: number;
  }>();

  constructor() {
    effect(() => {
      this.videoFeedbackPriceForm.setValue(this.videoFeedbackPrice());
      this.isOfferingVideoFeedbackForm.setValue(this.isOfferingVideoFeedback());
    });

    this.isOfferingVideoFeedbackForm.valueChanges.subscribe(
      (isOfferingFeedback) => {
        if (isOfferingFeedback) {
          this.videoFeedbackPriceForm.enable();
          if (
            (this.videoFeedbackPriceForm.value || 0) < this.minSellingPrice()
          ) {
            this.videoFeedbackPriceForm.setValue(this.minSellingPrice());
          }
        } else {
          this.videoFeedbackPriceForm.disable();
        }
      },
    );

    effect(() => {
      this.errorTextParam = {
        minPrice: this.minSellingPrice(),
      };
    });
  }

  submit() {
    this.videoFeedbackOfferingChanged.emit({
      isOfferingVideoFeedback: this.isOfferingVideoFeedbackForm.value || false,
      videoFeedbackPrice: this.videoFeedbackPriceForm.value || 0,
    });
  }

  private validateThatPriceIsSetForEnabledOfferings(): ValidatorFn {
    return (_) => {
      const isOfferingFeedback =
        this.isOfferingVideoFeedbackForm.value || false;
      const videoFeedbackPrice = this.videoFeedbackPriceForm.value || 0;

      if (isOfferingFeedback && videoFeedbackPrice < this.minSellingPrice()) {
        return {
          priceMustBeSet: true,
        };
      }
      return null;
    };
  }
}
