import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export abstract class WhatsAppNumberValidator {
  private static error() {
    return {
      whatsapp: true,
    };
  }

  private static noError() {
    return null;
  }

  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const numberRegex = /^\+[0-9]+[\s0-9]+$/g;
      if (numberRegex.test(control.value)) {
        return WhatsAppNumberValidator.noError();
      } else {
        return WhatsAppNumberValidator.error();
      }
    };
  }
}
