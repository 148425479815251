import { UnitService } from "src/app/services/unit/unit.service";
import { ExtendableConversion } from "../extendable-conversion";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { StoredLinkedCollectionItemDto } from "src/app/dtos/collection/stored-linked-collection-item.dto";

export class StoredLinkedCollectionItemConversion extends ExtendableConversion<
  StoredLinkedCollectionItemDto,
  StoredLinkedCollectionItem
> {
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredLinkedCollectionItemDto): StoredLinkedCollectionItem {
    const myPracticeInterval =
      dto.my_practice_interval == -1
        ? undefined
        : dto.my_practice_interval || 0;

    return {
      name: dto.name,
      creation: this.us.parseApiDate(dto.creation),
      linkedCollection: dto.linked_collection,
      linkedMusicPiece: dto.linked_music_piece,
      order: dto.order,
      isPreview: dto.is_preview > 0,
      owner: dto.owner,
      practiceInterval:
        dto.practice_interval != undefined ? dto.practice_interval : 0,
      myPracticeInterval: myPracticeInterval,
    };
  }
}
