import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { StoredCollectionConversion } from "src/app/conversions/collection/stored-collection-conversion";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import { asStringConversion, dropConversion } from "../conversion-helper";
import { map, tap } from "rxjs/operators";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredCollectionItemConversion } from "src/app/conversions/collection/stored-collection-item-conversion";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredLinkedCollectionConversion } from "src/app/conversions/collection/stored-linked-collection-conversion";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { StoredLinkedCollectionItemConversion } from "src/app/conversions/collection/stored-linked-collection-item-conversion";
import { MatomoTracker } from "ngx-matomo-client";
import { StoredCollectionListingConversion } from "src/app/conversions/collection/stored-collection-listing-conversion";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";

@Injectable({
  providedIn: "root",
})
export class CollectionService {
  private static readonly COLLECTION_DOCTYPE = "Collection";
  private static readonly COLLECTION_ITEM_DOCTYPE = "Collection Item";
  private static readonly LINKED_COLLECTION_DOCTYPE = "Linked Collection";
  private static readonly LINKED_COLLECTION_ITEM_DOCTYPE =
    "Linked Collection Item";

  private static readonly COLLECTION_LISTING_DOCTYPE = "Collection Listing";

  constructor(
    private readonly http: HttpClient,
    private readonly tracker: MatomoTracker,
  ) {}

  loadCollections(): Observable<StoredCollection[]> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_DOCTYPE,
      this.http,
      new StoredCollectionConversion(),
    ).queryAll(new FrappeRequestHelper().withFields(["*"]));
  }

  loadCollection(name: string): Observable<StoredCollection> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_DOCTYPE,
      this.http,
      new StoredCollectionConversion(),
    ).getSingle(name);
  }

  loadCollectionItemsForCollection(
    name: string,
  ): Observable<StoredCollectionItem[]> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_ITEM_DOCTYPE,
      this.http,
      new StoredCollectionItemConversion(),
    ).queryAll(
      new FrappeRequestHelper()
        .withFields(["*"])
        .withFilter(new FrappeRequestFilter("collection", "=", name)),
    );
  }

  loadCollectionListing(
    collection: string,
  ): Observable<StoredCollectionListing | undefined> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_LISTING_DOCTYPE,
      this.http,
      new StoredCollectionListingConversion(),
    ).queryFirst(
      new FrappeRequestHelper()
        .withFields(["*"])
        .withFilter(new FrappeRequestFilter("collection", "=", collection)),
    );
  }

  createListing(
    collection: string,
    price: number,
  ): Observable<StoredCollectionListing> {
    const crud = new FrappeCrudHelper(
      CollectionService.COLLECTION_LISTING_DOCTYPE,
      this.http,
      new StoredCollectionListingConversion(),
    );

    return crud.create({
      collection: collection,
      price: price,
    });
  }

  updateListing(
    name: string,
    price: number,
  ): Observable<StoredCollectionListing> {
    const crud = new FrappeCrudHelper(
      CollectionService.COLLECTION_LISTING_DOCTYPE,
      this.http,
      new StoredCollectionListingConversion(),
    );

    return crud.update(name, {
      price: price,
    });
  }

  deleteListing(name: string): Observable<void> {
    const crud = new FrappeCrudHelper(
      CollectionService.COLLECTION_LISTING_DOCTYPE,
      this.http,
      dropConversion,
    );

    return crud.delete(name);
  }

  loadLinkedCollectionItemsForCollection(
    linkedCollectionName: string,
  ): Observable<StoredLinkedCollectionItem[]> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_ITEM_DOCTYPE,
      this.http,
      new StoredLinkedCollectionItemConversion(),
    ).queryAll(
      new FrappeRequestHelper()
        .withFields(["*"])
        .withFilter(
          new FrappeRequestFilter(
            "linked_collection",
            "=",
            linkedCollectionName,
          ),
        ),
    );
  }

  createCollection(
    title: string,
    instrument: string,
    temperament: string,
    artist: string,
    description: string,
    isPracticeRoutine: boolean,
  ): Observable<StoredCollection> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_DOCTYPE,
      this.http,
      new StoredCollectionConversion(),
    )
      .create({
        title: title,
        instrument: instrument,
        temperament: temperament,
        artist: artist,
        description: description,
        is_practice_routine: isPracticeRoutine,
      })
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "create", d.name);
        }),
      );
  }

  updateCollection(
    name: string,
    title: string,
    instrument: string,
    temperament: string,
    artist: string,
    description: string,
    acceptShareTerms: boolean,
    isPracticeRoutine: boolean,
  ): Observable<StoredCollection> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_DOCTYPE,
      this.http,
      new StoredCollectionConversion(),
    )
      .update(name, {
        title: title,
        instrument: instrument,
        temperament: temperament,
        artist: artist,
        description: description,
        accept_share_terms: acceptShareTerms,
        is_practice_routine: isPracticeRoutine,
      })
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "update", d.name);
        }),
      );
  }

  updateLinkedCollection(
    name: string,
    isPracticeRoutine: boolean,
  ): Observable<StoredLinkedCollection> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      new StoredLinkedCollectionConversion(),
    )
      .update(name, {
        is_practice_routine: isPracticeRoutine,
      })
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("linked-collection", "update", d.name);
        }),
      );
  }

  prepareUploadUrl(collection: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.collection_controller.create_upload_file_url";

    const helper = new FrappeMethodHelper(this.http, asStringConversion);
    const obs = helper
      .callMethod(
        method,
        new MethodDataWrapper().withDto({
          name: collection,
        }),
      )
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markUploadAsFinished(collection: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.collection_controller.upload_finished";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: collection }),
    );

    return firstValueFrom(obs);
  }

  deleteProfilePicture(collection: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.collection_controller.delete_profile_picture";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: collection }),
    );

    return firstValueFrom(obs);
  }

  addCollectionItem(
    name: string,
    collectionItemToAdd: MusicPieceToDisplay,
    order: number,
  ): Observable<StoredCollectionItem> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_ITEM_DOCTYPE,
      this.http,
      new StoredCollectionItemConversion(),
    ).create({
      collection: name,
      music_piece: collectionItemToAdd.isLinkedMusicPiece
        ? undefined
        : collectionItemToAdd.musicPiece.name,
      linked_music_piece:
        collectionItemToAdd.isLinkedMusicPiece &&
        collectionItemToAdd.linkedMusicPiece
          ? collectionItemToAdd.linkedMusicPiece.name
          : undefined,
      order: order,
    });
  }

  removeCollectionItem(name: string): Observable<void> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_ITEM_DOCTYPE,
      this.http,
      dropConversion,
    ).delete(name);
  }

  updateCollectionItems(
    collectionItemsToUpdate: StoredCollectionItem[],
  ): Observable<StoredCollectionItem[]> {
    const methodHelper = new FrappeMethodHelper(
      this.http,
      new StoredCollectionItemConversion().asMany(),
    );
    return methodHelper.callMethod(
      "yobi_rocks.controllers.collection_controller.update_collection_items",
      new MethodDataWrapper().withDto({
        collection_items: collectionItemsToUpdate.map((item) => {
          return {
            name: item.name,
            order: item.order, // at this point we only update the order and is_preview
            is_preview: item.isPreview, // at this point we only update the order and is_preview
            practice_interval: item.practiceInterval,
          };
        }),
      }),
    );
  }

  updateLinkedCollectionItems(
    collectionItemsToUpdate: StoredLinkedCollectionItem[],
  ): Observable<StoredLinkedCollectionItem[]> {
    const methodHelper = new FrappeMethodHelper(
      this.http,
      new StoredLinkedCollectionItemConversion().asMany(),
    );
    return methodHelper.callMethod(
      "yobi_rocks.controllers.linked_collection_controller.update_linked_collection_items",
      new MethodDataWrapper().withDto({
        linked_collection_items: collectionItemsToUpdate.map((item) => {
          return {
            name: item.name,
            my_practice_interval: item.myPracticeInterval,
          };
        }),
      }),
    );
  }

  deleteCollection(name: string): Observable<void> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_DOCTYPE,
      this.http,
      dropConversion,
    )
      .delete(name)
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "delete", name);
        }),
      );
  }

  deleteLinkedCollection(name: string): Observable<void> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      dropConversion,
    )
      .delete(name)
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "delete-linked", name);
        }),
      );
  }

  share(
    collection: string,
    receiver: string,
  ): Observable<StoredLinkedCollection> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      new StoredLinkedCollectionConversion(),
    )
      .create({
        collection: collection,
        user: receiver,
      })
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "share", d.name);
        }),
      );
  }

  unshare(linkedCollection: string): Observable<void> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      new StoredLinkedCollectionConversion(),
    )
      .delete(linkedCollection)
      .pipe(
        tap((d) => {
          this.tracker.trackEvent("collection", "unshare", linkedCollection);
        }),
      );
  }

  loadLinkedCollectionsFor(
    collection: string,
  ): Observable<StoredLinkedCollection[]> {
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.linked_collection_controller.receivers";

    const helper = new FrappeCrudHelper(
      "",
      this.http,
      new StoredLinkedCollectionConversion(),
    );
    const requestHelper = new FrappeRequestHelper().withParam(
      "collection",
      collection,
    );
    return helper.queryAll(requestHelper, customQueryMethod);
  }

  loadLinkedCollections(): Observable<StoredLinkedCollection[]> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      new StoredLinkedCollectionConversion(),
    ).queryAll(new FrappeRequestHelper().withFields(["*"]));
  }

  loadLinkedCollection(name: string): Observable<StoredLinkedCollection> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_DOCTYPE,
      this.http,
      new StoredLinkedCollectionConversion(),
    ).getSingle(name);
  }

  loadCollectionsForLinkedCollections(): Observable<StoredCollection[]> {
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.linked_collection_controller.collection_for_linked_collections";

    const helper = new FrappeCrudHelper(
      "",
      this.http,
      new StoredCollectionConversion(),
    );
    const requestHelper = new FrappeRequestHelper();
    return helper.queryAll(requestHelper, customQueryMethod);
  }

  loadCollectionItems(): Observable<StoredCollectionItem[]> {
    return new FrappeCrudHelper(
      CollectionService.COLLECTION_ITEM_DOCTYPE,
      this.http,
      new StoredCollectionItemConversion(),
    ).queryAll(new FrappeRequestHelper().withFields(["*"]));
  }

  loadLinkedCollectionItems(): Observable<StoredLinkedCollectionItem[]> {
    return new FrappeCrudHelper(
      CollectionService.LINKED_COLLECTION_ITEM_DOCTYPE,
      this.http,
      new StoredLinkedCollectionItemConversion(),
    ).queryAll(new FrappeRequestHelper().withFields(["*"]));
  }
}
