import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StoredRecordingPartToRecordingConversion } from "src/app/conversions/recording-part/stored-recording-part-dto-to-recording-part-conversion";
import { CreateRecordingPartDto } from "src/app/dtos/recording-part/create-recording-part-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { RecordingPart } from "src/app/models/recording-part/recording-part";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class RecordingPartService {
  constructor(private readonly http: HttpClient) {}

  loadRecordingParts(
    recordingPartGroup: RecordingPartGroup,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<RecordingPart[]> {
    const conversion = new StoredRecordingPartToRecordingConversion();

    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.recording_part_group_controller.get_recording_parts";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording_part_group", recordingPartGroup.name)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  addRecordingPart(recordingPart: RecordingPart): Observable<RecordingPart> {
    const dto: CreateRecordingPartDto = {
      recording_part_group: recordingPart.recordingPartGroup,
      color: recordingPart.color,
      start_marker: recordingPart.startMarker,
      end_marker: recordingPart.endMarker,
      note: recordingPart.note,
      title: recordingPart.title,
    };
    const conversion = new StoredRecordingPartToRecordingConversion();
    const queryHelper = new FrappeCrudHelper(
      "Recording Part",
      this.http,
      conversion
    );
    return queryHelper.create(dto);
  }

  deleteRecordingPart(recordingPart: RecordingPart): Observable<void> {
    const queryHelper = new FrappeCrudHelper("Recording Part", this.http);
    return queryHelper.delete(recordingPart.name);
  }

  updateRecordingPart(recordingPart: RecordingPart): Observable<RecordingPart> {
    const dto = {
      recording_part_group: recordingPart.recordingPartGroup,
      color: recordingPart.color,
      start_marker: recordingPart.startMarker,
      end_marker: recordingPart.endMarker,
      note: recordingPart.note,
      title: recordingPart.title,
    };

    const conversion = new StoredRecordingPartToRecordingConversion();
    const queryHelper = new FrappeCrudHelper(
      "Recording Part",
      this.http,
      conversion
    );
    return queryHelper.update(recordingPart.name, dto);
  }

  // utils
}
