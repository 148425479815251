import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-details",
  templateUrl: "./music-piece-details.component.html",
  styleUrls: ["./music-piece-details.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class MusicPieceDetailsComponent implements OnInit {
  @Input()
  musicPiece?: MusicPiece | MusicPieceListing;

  @Input()
  isListing: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  artistName(): string {
    if (this.musicPiece) {
      if (this.isListing) {
        return (this.musicPiece as MusicPieceListing).musicPieceArtist;
      } else {
        return this.musicPiece.artist;
      }
    }
    return "";
  }
}
