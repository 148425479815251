<!-- Next Event!  -->
<div
  class="flex w-full flex-row justify-center bg-[url('/assets/partner/events-background.jpg')] bg-cover py-32"
  *ngIf="!isInEditMode && nextEvent"
>
  <div
    class="mx-4 grid grid-cols-1 gap-y-4 text-white md:mx-0 md:w-4/5 md:max-w-7xl md:grid-cols-2"
  >
    <div>
      <img
        src="/assets/logo/logo-hands-only.png"
        alt="logo"
        class="aspect-square w-32"
      />
      <div class="mb-8 text-2xl">
        {{ "artist.artist-profile-events.next" | translate }}
        {{ nextEvent.date | date: ("common.dateFormat" | translate) }}
      </div>
      <div class="mb-8 break-normal text-4xl font-bold">
        {{ nextEvent.title }}
      </div>
      <div class="whitespace-pre-line break-normal text-xl">
        {{ nextEvent.description }}
      </div>
      <div class="text-xl">
        <a class="text-as-500" [href]="nextEvent.url" target="_blank">{{
          nextEvent.url
        }}</a>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <div
        *ngIf="nextEventTiming"
        class="flex flex-row items-center justify-center gap-x-4"
      >
        <div class="flex flex-col items-center">
          <div
            class="flex aspect-square w-24 flex-col items-center justify-center rounded-full bg-white text-center text-4xl font-bold text-black lg:w-32"
          >
            <span *ngIf="nextEventTiming.days > 100"> 100+</span>
            <span *ngIf="nextEventTiming.days <= 100">
              {{ nextEventTiming.days }}
            </span>
          </div>

          <div class="mt-4 text-lg">
            {{ "artist.artist-profile-events.days" | translate }}
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="flex aspect-square w-24 flex-col items-center justify-center rounded-full bg-white text-center text-4xl font-bold text-black lg:w-32"
          >
            {{ nextEventTiming.hours }}
          </div>
          <div class="mt-4 text-lg">
            {{ "artist.artist-profile-events.hours" | translate }}
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="flex aspect-square w-24 flex-col items-center justify-center rounded-full bg-white text-center text-4xl font-bold text-black lg:w-32"
          >
            {{ nextEventTiming.minutes }}
          </div>
          <div class="mt-4 text-lg">
            {{ "artist.artist-profile-events.minutes" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Other events -->
<div class="flex w-full flex-row justify-center">
  <div class="md:w-4/5 md:max-w-7xl">
    <div *ngIf="isInEditMode" class="flex flex-col gap-2">
      <div class="text-lg">
        {{ "artist.artist-profile-events.add" | translate }}
      </div>
      <yr-artist-profile-events-form
        (onSubmit)="add($event)"
      ></yr-artist-profile-events-form>
    </div>

    <div
      class="my-8 text-center text-4xl text-gray-700"
      *ngIf="events.length > 0"
    >
      {{ "artist.artist-profile-events.further-events" | translate }}
    </div>

    <button
      (click)="showPastEvents = true"
      mat-button
      *ngIf="!isInEditMode && hasPastEvents && !showPastEvents"
    >
      {{ "artist.artist-profile-events.show-past-events" | translate }}
    </button>
    <button
      (click)="showPastEvents = false"
      mat-button
      *ngIf="!isInEditMode && hasPastEvents && showPastEvents"
    >
      {{ "artist.artist-profile-events.hide-past-events" | translate }}
    </button>
    <div *ngFor="let event of events">
      <ng-container
        *ngIf="isInEditMode; then editMode; else readonlyMode"
      ></ng-container>

      <ng-template #readonlyMode>
        <div
          *ngIf="
            event.name != nextEvent?.name &&
            (event.date.valueOf() >= now || showPastEvents)
          "
          class="my-4 flex flex-col gap-8 truncate rounded p-2 md:flex-row"
        >
          <div class="text-xl text-as-500">
            {{ event.date | date: ("common.dateFormat" | translate) }}
          </div>
          <div class="flex flex-col truncate">
            <div class="whitespace-normal text-xl">
              {{ event.title }}
            </div>
            <div class="whitespace-normal text-lg">
              {{ event.description }}
            </div>
            <div class="mt-2 text-base">
              <a class="text-as-500" [href]="event.url" target="_blank">
                {{ "artist.artist-profile-events.event-link" | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #editMode>
        <yr-artist-profile-events-form
          [existingArtistProfile]="event"
          (onSubmit)="update($event)"
          (onDelete)="delete($event)"
          [canDelete]="true"
        ></yr-artist-profile-events-form>
      </ng-template>
    </div>
  </div>
</div>
