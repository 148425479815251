<div *ngIf="receivers && receivers.length > 0">
  <mat-form-field class="filter-field">
    <mat-label>
      {{ "edit-music-piece.shared-music-piece-receivers.search" | translate }}
    </mat-label>
    <input
      matInput
      [placeholder]="
        'edit-music-piece.shared-music-piece-receivers.search' | translate
      "
      [(ngModel)]="filterText"
      (keyup)="applyFilter()"
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="receiver">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "edit-music-piece.shared-music-piece-receivers.receiver" | translate
        }}
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ "edit-music-piece.shared-music-piece-receivers.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sharedDate">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "edit-music-piece.shared-music-piece-receivers.shared-date"
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.creation | date : ("common.dateTimeFormat" | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "edit-music-piece.shared-music-piece-receivers.actions" | translate
        }}
      </th>
      <td mat-cell *matCellDef="let data">
        <div class="actions">
          <button
            mat-icon-button
            (click)="deleteShare(data)"
            color="warn"
            [disabled]="data.isPartOfLinkedCollection"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
