import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

@Directive({
  selector: "[visibility]",
  standalone: true,
})
export class VisibilityDirective implements OnInit, OnDestroy {
  observer: IntersectionObserver;

  @Output()
  visible = new EventEmitter<boolean>();

  constructor(private el: ElementRef<HTMLElement>) {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          this.visible.emit(e.isIntersecting);
        });
      },
      {
        root: document,
      },
    );
  }

  ngOnInit(): void {
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
