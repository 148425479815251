<div
  class="aspect-video h-auto w-full rounded-lg bg-white shadow"
  *ngIf="collection"
>
  <div class="relative overflow-clip rounded-lg">
    <img
      class="block aspect-video h-auto w-full"
      *ngIf="collection.downloadLink"
      alt="preview"
      [src]="collection.downloadLink"
    />
    <div
      *ngIf="!collection.downloadLink"
      class="flex aspect-video h-auto w-full flex-col items-center justify-center bg-black text-white"
    >
      <mat-icon>hide_image</mat-icon>
    </div>

    <div
      class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
    >
      <button mat-icon-button (click)="showCollectionDetails()">
        <mat-icon>info</mat-icon>
      </button>
    </div>
    <div
      class="border-1 absolute bottom-2 right-2 flex flex-row items-center rounded-lg border-gray-700 bg-black/50 px-2 py-1 text-sm text-white"
    >
      <mat-icon>video_library</mat-icon>
      <span class="ml-2">{{
        "artist.listed-music-pieces-and-collections.collection" | translate
      }}</span>
    </div>
  </div>
  <div class="flex flex-row p-2">
    <div
      class="flex flex-row items-center hover:cursor-pointer"
      (click)="goToArtist()"
    >
      @if (collection.artist && collection.artist.downloadLink &&
      collection.artist.downloadLink.length > 0) {
      <img
        #artistProfile
        [src]="collection.artist.downloadLink"
        [alt]="collection.artist.artistName"
        class="mr-2 h-10 w-10 rounded-full"
      />
      } @else {
      <img
        src="assets/logo/logo.png"
        [alt]="collection.artist.artistName"
        class="mr-2 h-10 w-10 rounded-full"
      />
      }
    </div>

    <div class="flex flex-1 flex-col truncate">
      <button class="truncate text-start text-lg" (click)="goToArtist()">
        {{ collection.title }}
      </button>
      <div class="flex flex-1 flex-row truncate">
        <button
          class="flex-1 truncate text-start text-base font-light"
          (click)="goToArtist()"
        >
          {{ collection.artist.artistName }}
        </button>

        <div class="flex flex-row">
          <button
            class="mx-2 mb-1 rounded bg-as-500 px-1 text-base text-white hover:bg-as-600"
            (click)="goToArtist()"
          >
            {{ collection.price | price }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
