import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { environment } from "src/environments/environment";

@Component({
  selector: "yr-partner-footer",
  templateUrl: "./partner-footer.component.html",
  styleUrl: "./partner-footer.component.scss",
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class PartnerFooterComponent {
  @Input()
  artistProfile?: StoredArtistProfile;

  termsLink = environment.terms;
  gdprLink = environment.gdpr;
}
