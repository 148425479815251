import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StoredPageBreakDtoToPageBreakConversion } from "src/app/conversions/page-break/stored-page-break-dto-to-page-break-conversion";
import { CreatePageBreakDto } from "src/app/dtos/page-break/create-page-break-dto";
import { UpdatePageBreakDto } from "src/app/dtos/page-break/update-page-break-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { PageBreak } from "src/app/models/page-break/page-break";
import { Recording } from "src/app/models/recording/recording";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class PageBreakService {
  constructor(private readonly http: HttpClient) {}

  loadPageBreaksForRecording(
    recording: Recording,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<PageBreak[]> {
    const conversion = new StoredPageBreakDtoToPageBreakConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.page_break_controller.get_page_breaks";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording", recording.name)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  addPageBreak(pageBreak: PageBreak): Observable<PageBreak> {
    const dto: CreatePageBreakDto = {
      recording: pageBreak.recording,
      timestamp: pageBreak.timestampMs,
      music_score: pageBreak.musicScore,
    };

    const conversion = new StoredPageBreakDtoToPageBreakConversion();
    const queryHelper = new FrappeCrudHelper(
      "Page Break",
      this.http,
      conversion
    );
    return queryHelper.create(dto);
  }

  updatePageBreak(pageBreak: PageBreak): Observable<PageBreak> {
    const dto: UpdatePageBreakDto = {
      recording: pageBreak.recording,
      timestamp: pageBreak.timestampMs,
      name: pageBreak.name,
      music_score: pageBreak.musicScore,
    };

    const conversion = new StoredPageBreakDtoToPageBreakConversion();
    const queryHelper = new FrappeCrudHelper(
      "Page Break",
      this.http,
      conversion
    );
    return queryHelper.update(pageBreak.name, dto);
  }

  deletePageBreak(pageBreak: PageBreak): Observable<void> {
    const queryHelper = new FrappeCrudHelper("Page Break", this.http);
    return queryHelper.delete(pageBreak.name);
  }
}
