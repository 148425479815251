import { StoredUserDto } from "src/app/dtos/user/stored-user-dto";
import { StoredUser } from "src/app/models/user/stored-user";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class StoredUserDtoConversion
  implements Conversion<StoredUserDto, StoredUser>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: StoredUserDto): StoredUser {
    return {
      email: dto.email,
      fullName: dto.full_name,
      language: dto.language,
      created: this.us.parseApiDate(dto.creation),
    };
  }
}
