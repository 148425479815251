<div mat-dialog-content>
  <div class="mb-4 flex flex-row items-center">
    <img
      [src]="data.details.artistProfilePictureDownloadLink"
      *ngIf="
        data.details.artistProfilePictureDownloadLink &&
        data.details.artistProfilePictureDownloadLink.length > 0
      "
      [alt]="data.details.artistName"
      class="mr-2 h-10 w-10 rounded-full"
    />
    <img
      src="assets/logo/logo.png"
      [alt]="data.details.artistName"
      *ngIf="!data.details.artistProfilePictureDownloadLink"
      class="mr-2 h-10 w-10 rounded-full"
    />
    <div class="flex flex-grow flex-col items-start truncate">
      <span class="truncate text-lg">{{ data.details.musicPieceName }}</span>
      <span class="flex-1 truncate text-start text-base font-light">
        {{ data.details.artistName }}
      </span>
    </div>
    <div class="flex-grow"></div>
    <span
      *ngIf="data.details.price"
      class="mb-1 ml-3 rounded bg-as-500 px-1 text-base text-white"
    >
      {{ data.details.price | price }}
    </span>
  </div>

  <p
    *ngIf="data.details.description"
    class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
  >
    <mat-icon class="text-as-500">description</mat-icon>
    <span class="ml-2 whitespace-pre-line">
      {{ data.details.description }}
    </span>
  </p>
  <p
    *ngIf="data.details.collection"
    class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
  >
    <mat-icon class="text-as-500">library_books</mat-icon
    ><span class="ml-2">
      {{ data.details.collection }}
    </span>
  </p>
  <p
    *ngIf="data.details.instrument"
    class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
  >
    <mat-icon class="text-as-500">piano</mat-icon>
    <span class="ml-2">
      {{ data.details.instrument }}
    </span>
  </p>
  <p
    *ngIf="data.details.temperament"
    class="mb-2 flex flex-col md:mb-0 md:flex-row md:items-center"
  >
    <mat-icon class="text-as-500">music_note</mat-icon>
    <span class="ml-2">
      {{ data.details.temperament }}
    </span>
  </p>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-button (click)="close()">{{ "common.close" | translate }}</button>
</div>
