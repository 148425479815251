import {
  FrappeApiHelper,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";
import { Injectable } from "@angular/core";
import { MatomoTracker } from "ngx-matomo-client";
import { map, Observable, tap, throwIfEmpty } from "rxjs";
import { VideoFeedback } from "src/app/models/video-feedback/video-feedback";
import { VideoFeedbackItem } from "src/app/models/video-feedback/video-feedback-item";

@Injectable({
  providedIn: "root",
})
export class VideoFeedbackService {
  private readonly videoFeedbackMapping =
    mappingBuilderWithDefaultMappings<VideoFeedback>("Video Feedback", [
      mappingHelper.stringMapper("teacher"),
      mappingHelper.stringMapper("student"),
      mappingHelper.booleanMapper("is_finished", "isFinished"),
      mappingHelper.stringMapper("student_name", "studentFullName"),
      mappingHelper.stringMapper("teacher_name", "teacherFullName"),
      mappingHelper.stringMapper("last_message_hint", "lastMessageHint"),
      mappingHelper.stringMapper(
        "last_message_music_piece_hint",
        "lastMessageMusicPieceHint",
      ),
      mappingHelper.optionalDateMapper("last_message_date", "lastMessageDate"),
      mappingHelper.stringMapper(
        "last_message_sender",
        "lastMessageSenderFullName",
      ),
      mappingHelper.stringMapper("theme"),
    ]);

  private readonly videoFeedbackItemMapping =
    mappingBuilderWithDefaultMappings<VideoFeedbackItem>(
      "Video Feedback Item",
      [
        mappingHelper.stringMapper("sender"),
        mappingHelper.stringMapper("video_feedback", "videoFeedback"),
        mappingHelper.stringMapper("message"),
        mappingHelper.stringMapper("music_piece", "musicPiece"),
        mappingHelper.stringMapper("music_piece_title", "musicPieceTitle"),
        mappingHelper.stringMapper("linked_music_piece", "linkedMusicPiece"),
        mappingHelper.booleanMapper("read"),
        mappingHelper.booleanMapper("deleted_music_piece", "deletedMusicPiece"),
      ],
    );

  sortVideoFeedbacks(videoFeedbacks: VideoFeedback[]): VideoFeedback[] {
    const result = [...videoFeedbacks];
    result.sort((a, b) => this.sortVideoFeedbackByLastMessageOrUpdated(a, b));
    return result;
  }

  sortVideoFeedbackByLastMessageOrUpdated(
    a: VideoFeedback,
    b: VideoFeedback,
  ): number {
    const aDate = Math.max(
      a.lastMessageDate ? a.lastMessageDate.valueOf() : a.modified.valueOf(),
      a.modified.valueOf(),
    );
    const bDate = Math.max(
      b.lastMessageDate ? b.lastMessageDate.valueOf() : b.modified.valueOf(),
      b.modified.valueOf(),
    );
    return bDate - aDate;
  }

  constructor(
    private readonly helper: FrappeApiHelper,
    private readonly tracker: MatomoTracker,
  ) {}

  loadVideoFeedbacks(): Observable<VideoFeedback[]> {
    return this.helper
      .callWithResults(this.videoFeedbackMapping.conversion(), {
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.video_feedbacks",
        type: "GET",
      })
      .pipe(map((result) => this.sortVideoFeedbacks(result)));
  }

  loadVideoFeedback(videoFeedback: string): Observable<VideoFeedback> {
    return this.helper.callWithResult(this.videoFeedbackMapping.conversion(), {
      method:
        "yobi_rocks.controllers.video_feedbacks_controller.video_feedback",
      type: "GET",
      extraParams: new Map([["video_feedback", videoFeedback]]),
    });
  }

  loadVideoFeedbackItems(
    videoFeedback: string,
  ): Observable<VideoFeedbackItem[]> {
    return this.helper.callWithResults(
      this.videoFeedbackItemMapping.conversion(),
      {
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.video_feedback_items",
        type: "GET",
        extraParams: new Map([["video_feedback", videoFeedback]]),
      },
    );
  }

  createVideoFeedbackItem(
    videoFeedback: string,
    message: string | undefined,
    musicPiece: string | undefined,
  ): Observable<VideoFeedbackItem> {
    return this.helper
      .callWithResult(this.videoFeedbackItemMapping.conversion(), {
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.create_video_feedback_item",
        type: "POST",
        body: {
          video_feedback: videoFeedback,
          message: message || "",
          music_piece: musicPiece || "",
        },
      })
      .pipe(
        tap((e) =>
          this.tracker.trackEvent(
            "video-feedback",
            "created-item",
            e.videoFeedback,
          ),
        ),
      );
  }

  markAsRead(videoFeedback: string): Observable<void> {
    return this.helper
      .call({
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.mark_as_read",
        type: "POST",
        body: {
          video_feedback: videoFeedback,
        },
      })
      .pipe(
        tap((_) =>
          this.tracker.trackEvent(
            "video-feedback",
            "mark-as-read",
            videoFeedback,
          ),
        ),
      );
  }

  markAsFinished(videoFeedback: string): Observable<VideoFeedback> {
    return this.helper
      .callWithResult(this.videoFeedbackMapping.conversion(), {
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.mark_as_finished",
        type: "POST",
        body: {
          video_feedback: videoFeedback,
        },
      })
      .pipe(
        tap((_) =>
          this.tracker.trackEvent(
            "video-feedback",
            "mark-as-finished",
            videoFeedback,
          ),
        ),
      );
  }

  updateTheme(videoFeedback: string, theme: string): Observable<VideoFeedback> {
    return this.helper
      .callWithResult(this.videoFeedbackMapping.conversion(), {
        method:
          "yobi_rocks.controllers.video_feedbacks_controller.update_theme",
        type: "POST",
        body: {
          video_feedback: videoFeedback,
          theme: theme,
        },
      })
      .pipe(
        tap((_) =>
          this.tracker.trackEvent(
            "video-feedback",
            "update-theme",
            videoFeedback,
          ),
        ),
      );
  }

  private filterVideoFeedbackLogic(
    filter: string,
    showFinished: boolean,
    videoFeedback: VideoFeedback,
  ): boolean {
    if (videoFeedback.isFinished && !showFinished) {
      return false;
    }

    const textRepresentation =
      (videoFeedback.theme || "").toLocaleLowerCase() +
      (videoFeedback.student || "").toLocaleLowerCase() +
      (videoFeedback.studentFullName || "").toLocaleLowerCase() +
      (videoFeedback.teacher || "").toLocaleLowerCase() +
      (videoFeedback.teacherFullName || "").toLocaleLowerCase() +
      (videoFeedback.lastMessageHint || "").toLocaleLowerCase() +
      (videoFeedback.lastMessageMusicPieceHint || "").toLocaleLowerCase();

    return textRepresentation.includes(filter.toLowerCase() || "");
  }

  filter(
    videoFeedbacks: VideoFeedback[],
    filter: string,
    showFinished: boolean,
  ): VideoFeedback[] {
    const result = videoFeedbacks.filter((vf) =>
      this.filterVideoFeedbackLogic(filter, showFinished, vf),
    );
    return this.sortVideoFeedbacks(result);
  }
}
