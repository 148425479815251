import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { TeaserSelectionDialogData } from "src/app/models/artist/teaser-selection-dialog-data";
import { TeaseSelectionDialogResult } from "src/app/models/artist/teaser-selection-dialog-result";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MaterialModule } from "src/app/modules/material.module";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ArtistProfileSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-teaser-selection-dialog",
  templateUrl: "./teaser-selection-dialog.component.html",
  styleUrl: "./teaser-selection-dialog.component.scss",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class TeaserSelectionDialogComponent implements OnInit, OnDestroy {
  isLoading = true;
  possibleTeasers: MusicPiece[] = [];

  private readonly subscriptions = new SubscriptionService();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: TeaserSelectionDialogData,
    private readonly dialogRef: MatDialogRef<
      TeaserSelectionDialogComponent,
      TeaseSelectionDialogResult
    >,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(ArtistProfileSelectors.selectPossibleTeasers)
        .subscribe((data) => {
          this.isLoading = data.isLoadingPossibleTeasers;
          this.possibleTeasers = [...data.possibleTeasers].sort((a, b) =>
            a.nameOfPiece.localeCompare(b.nameOfPiece),
          );
        }),
    );

    this.store.dispatch(
      ArtistProfileActions.loadPossibleTeasers({
        profile: this.data.artistProfile,
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  chooseMusicPiece(musicPiece: MusicPiece) {
    this.dialogRef.close({
      teaser: musicPiece.name,
    });
  }
}
