import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPiecePurchaseStatus } from "src/app/models/music-piece/music-piece-purchase";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PurchaseSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-purchase-status",
  templateUrl: "./purchase-status.component.html",
  styleUrls: ["./purchase-status.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PurchaseStatusComponent implements OnInit, OnDestroy {
  @Input()
  clientSecret?: string;

  isLoading: boolean = true;
  purchase?: PurchasedItem;

  purchased = MusicPiecePurchaseStatus.PURCHASED;
  pending = MusicPiecePurchaseStatus.PENDING_PAYMENT;
  requiresPayment = MusicPiecePurchaseStatus.REQUIRES_PAYMENT;

  private subscriptions = new SubscriptionService();

  private pollingTimeout = 0;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
    this.stopPollingUpdates();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(PurchaseSelectors.selectPurchaseDetails)
        .subscribe((status) => {
          this.isLoading = status.isLoading;
          this.purchase = status.details;
          if (
            status.details?.item.purchaseStatus ==
            MusicPiecePurchaseStatus.PURCHASED
          ) {
            this.stopPollingUpdates();
          }
        }),
    );

    this.startPollingUpdates();
    this.checkStatus();
  }

  home(): void {
    this.router.navigate(["/"]);
  }

  downloadReceipt() {
    if (this.purchase) {
      this.store.dispatch(
        PurchaseActions.downloadReceipt({ purchase: this.purchase }),
      );
    }
  }

  checkStatus() {
    if (this.purchase) {
      this.store.dispatch(
        PurchaseActions.loadPurchaseDetails({
          purchaseType: this.purchase.type,
          clientSecret: this.clientSecret || "None",
        }),
      );
    }
  }

  startPollingUpdates() {
    if (!this.pollingTimeout) {
      this.pollingTimeout = window.setInterval(() => {
        this.checkStatus();
      }, 10000);
    }
  }

  stopPollingUpdates() {
    if (this.pollingTimeout) {
      window.clearTimeout(this.pollingTimeout);
    }
  }
}
