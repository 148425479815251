import { Injectable } from "@angular/core";
import { UserLicenseDto } from "src/app/dtos/user/user-license-dto";
import { UserLicense } from "src/app/models/user/user-license";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { LicenseDtoToConversion } from "./license-dto-conversion";

@Injectable({
  providedIn: "root",
})
export class UserLicenseDtoToUserLicenseConversion
  implements Conversion<UserLicenseDto, UserLicense>
{
  private readonly unitService = new UnitService();

  private readonly licenseConversion = new LicenseDtoToConversion(); // inject ?

  convert(dto: UserLicenseDto): UserLicense {
    console.log(dto);
    return {
      start: this.unitService.parseApiDate(dto.start_date),
      end: this.unitService.parseApiDate(dto.end_date),
      license: this.licenseConversion.convert(dto.license),
    };
  }
}
