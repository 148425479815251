import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import {
  CollectionItemToDisplay,
  CollectionItemToDisplayHelper,
} from "src/app/models/collection/collection-item-to-display";
import { EditCollectionItemDialogData } from "src/app/models/collection/edit-collection-items-dialog-data";
import { EditCollectionItemsDialogResult } from "src/app/models/collection/edit-collection-items-dialog-result";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-edit-collection-items-dialog",
  templateUrl: "./edit-collection-items-dialog.component.html",
  styleUrls: ["./edit-collection-items-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class EditCollectionItemsDialogComponent {
  isDirty = false;

  existingCollectionItems: CollectionItemToDisplay[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: EditCollectionItemDialogData,
    private readonly dialogRef: MatDialogRef<
      EditCollectionItemsDialogComponent,
      EditCollectionItemsDialogResult
    >,
  ) {
    this.existingCollectionItems = CollectionItemToDisplayHelper.from(
      [...data.existingCollectionItems],
      [...data.musicPieces],
      [...data.linkedMusicPieces],
    );

    this.sort();
  }

  update() {
    this.dialogRef.close({
      updatedCollectionItems: this.existingCollectionItems.map(
        (ci) => ci.collectionItem,
      ),
    });
  }

  up(item: CollectionItemToDisplay) {
    if (this.isFirst(item)) {
      return;
    }

    this.isDirty = true;

    const index = this.existingCollectionItems.indexOf(item);
    const previousItem = this.existingCollectionItems[index - 1];

    previousItem.collectionItem = {
      ...previousItem.collectionItem,
      order: previousItem.collectionItem.order + 1,
    } as StoredCollectionItem;

    item.collectionItem = {
      ...item.collectionItem,
      order: item.collectionItem.order - 1,
    } as StoredCollectionItem;

    this.sort();
  }

  down(item: CollectionItemToDisplay) {
    if (this.isLast(item)) {
      return;
    }

    this.isDirty = true;

    const index = this.existingCollectionItems.indexOf(item);
    const nextItem = this.existingCollectionItems[index + 1];

    nextItem.collectionItem = {
      ...nextItem.collectionItem,
      order: nextItem.collectionItem.order - 1,
    } as StoredCollectionItem;

    item.collectionItem = {
      ...item.collectionItem,
      order: item.collectionItem.order + 1,
    } as StoredCollectionItem;

    this.sort();
  }

  isFirst(item: CollectionItemToDisplay) {
    return item.collectionItem.order <= 0;
  }

  isLast(item: CollectionItemToDisplay) {
    return item.collectionItem.order >= this.existingCollectionItems.length - 1;
  }

  private sort() {
    this.existingCollectionItems = this.existingCollectionItems.sort(
      (a, b) => a.collectionItem.order - b.collectionItem.order,
    );
  }
}
