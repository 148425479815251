import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { StripeCustomer } from "src/app/models/user/stripe-customer";
import { LicenseActions, UserActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { environment } from "src/environments/environment";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-stripe-portal",
  templateUrl: "./stripe-portal.component.html",
  styleUrls: ["./stripe-portal.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class StripePortalComponent implements OnInit, OnDestroy {
  @Input()
  returnUrl: string = `${environment.guiBaseUrl}/settings`;

  hasClickedOpenPortal = false;

  stripeCustomer: StripeCustomer | undefined;

  private subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(UserActions.loadStripeCustomer());

    this.subscriptions.add(
      this.store
        .select(UserSelectors.stripeCustomer)
        .subscribe((data) => (this.stripeCustomer = data.stripeCustomer)),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  openCustomerPortal() {
    this.hasClickedOpenPortal = true;

    this.store.dispatch(
      LicenseActions.openCustomerPortal({
        returnUrl: this.returnUrl,
      }),
    );
  }
}
