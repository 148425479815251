import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { MaterialModule } from "src/app/modules/material.module";
import { FeedbackService } from "src/app/services/user/feedback.service";

@Component({
  selector: "yr-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class FeedbackComponent implements OnInit {
  feedback = new UntypedFormControl("", [Validators.required]);

  anonymous = new UntypedFormControl(false);

  form = new UntypedFormGroup({
    feedback: this.feedback,
    anonymous: this.anonymous,
  });

  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  sendFeedback() {
    this.feedbackService
      .sendFeedback(this.feedback.value, this.anonymous.value)
      .subscribe(
        (_) => {
          this.toastr.success(this.translate.instant("common.success"));
          this.form.reset();
        },
        (error) => {
          console.error(error);
          this.toastr.error(this.translate.instant("common.fail"));
        },
      );
  }
}
