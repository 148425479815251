import { DIALOG_DATA } from "@angular/cdk/dialog";
import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ShowcaseCollectionListingDialogData } from "src/app/models/collection/showcase-collection-listing-info-dialog-data";
import { MusicPieceThumbnailPipe } from "src/app/pipes/music-piece-thumbnail.pipe";
import { PricePipe } from "src/app/pipes/price.pipe";
import { PreviewComponent } from "../../music-piece/preview/preview.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-showcase-collection-info-dialog",
  templateUrl: "./showcase-collection-info-dialog.component.html",
  styleUrls: ["./showcase-collection-info-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PricePipe,
    MusicPieceThumbnailPipe,
    PreviewComponent,
    MaterialModule,
  ],
})
export class ShowcaseCollectionInfoDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) readonly data: ShowcaseCollectionListingDialogData,
    private readonly ref: MatDialogRef<ShowcaseCollectionInfoDialogComponent>,
  ) {}

  close() {
    this.ref.close();
  }
}
