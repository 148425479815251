import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MusicPieceDetailsDialogData } from "src/app/models/music-piece/music-piece-details-dialog-data";
import { MusicPieceTypeComponent } from "../music-piece-type/music-piece-type.component";
import { MusicPieceDetailsComponent } from "../music-piece-details/music-piece-details.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-piece-details-dialog",
  templateUrl: "./music-piece-details-dialog.component.html",
  styleUrls: ["./music-piece-details-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    MusicPieceTypeComponent,
    MusicPieceDetailsComponent,
  ],
})
export class MusicPieceDetailsDialogComponent implements OnInit {
  constructor(
    private readonly dialogRef: MatDialogRef<MusicPieceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: MusicPieceDetailsDialogData,
  ) {}

  ngOnInit(): void {}
}
