import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { FallbackAndUpgradeLicenseDto } from "src/app/dtos/user/fallback-and-upgrade-license.dto";
import { FallbackAndUpgradeLicense } from "src/app/models/user/fallback-and-upgrade-license";
import { LicenseDtoToConversion } from "./license-dto-conversion";

export class FallbackAndUpgradeLicenseConversion
  implements
    Conversion<FallbackAndUpgradeLicenseDto, FallbackAndUpgradeLicense>
{
  convert(dto: FallbackAndUpgradeLicenseDto): FallbackAndUpgradeLicense {
    const licenseConversion = new LicenseDtoToConversion();
    return {
      fallback: dto.fallback
        ? licenseConversion.convert(dto.fallback)
        : undefined,
      upgrade: dto.upgrade ? licenseConversion.convert(dto.upgrade) : undefined,
    };
  }
}
