import { ExtendableConversion } from "../extendable-conversion";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";

export class ExploreMusicPieceToMusicPieceListingConversion extends ExtendableConversion<
  ExploreMusicPiece,
  MusicPieceListing
> {
  convert(emp: ExploreMusicPiece): MusicPieceListing {
    return {
      artist: emp.artist.name,
      artistName: emp.artist.artistName,
      collection: emp.collection,
      creation: emp.creation,
      description: emp.description,
      instrument: emp.instrument,
      musicPiece: emp.musicPiece,
      musicPieceArtist: emp.musicPieceArtist,
      musicPieceName: emp.musicPieceName,
      musicPieceThumbnailUploadFinished: emp.musicPieceThumbnailUploadFinished,
      musicPieceThumbnailDownloadLink: emp.musicPieceThumbnailDownloadLink,
      name: emp.name,
      price: emp.price,
      temperament: emp.temperament,
      artistProfilePictureDownloadLink: emp.artist.downloadLink,
    };
  }
}
