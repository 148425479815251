import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { CreateMusicScoreResponseDtoToMusicScoreConversion } from "src/app/conversions/music-score/create-music-score-response-dto-to-music-score-conversion";
import { MusicScoreDtoToMusicScoreConversion } from "src/app/conversions/music-score/music-score-dto-to-music-score-conversion";

import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicScore } from "src/app/models/music-score/music-score";
import { environment } from "src/environments/environment";
import { asStringConversion, dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import { FrappeRequestHelper } from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class MusicScoreService {
  constructor(private http: HttpClient) {}

  createMusicScore(forMusicPiece: string): Observable<MusicScore> {
    const conversion = new CreateMusicScoreResponseDtoToMusicScoreConversion();
    const crudHelper = new FrappeCrudHelper(
      "Music Score",
      this.http,
      conversion,
    );
    return crudHelper.create({
      music_piece: forMusicPiece,
    });
  }

  deleteMusicScoreFromMusicPiece(score: MusicScore): Observable<void> {
    const params = new HttpParams().append("name", score.name);

    return this.http.delete<void>(
      `${environment.baseUrl}/api/method/yobi_rocks.controllers.music_score_controller.soft_delete`,
      {
        params: params,
      },
    );
  }

  getMusicScoreFromMusicPiece(
    musicPiece: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): Observable<MusicScore> {
    const conversion = new MusicScoreDtoToMusicScoreConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const method =
      "/api/method/yobi_rocks.controllers.music_score_controller.get_music_score";
    const queryHelper = new FrappeRequestHelper()
      .withParam("music_piece", musicPiece.name)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.getSingle("", method, queryHelper);
  }

  prepareMusicScoreUpload(musicScore: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.music_score_controller.create_upload_file_url";

    const helper = new FrappeMethodHelper(this.http, asStringConversion);
    const obs = helper
      .callMethod(
        method,
        new MethodDataWrapper().withDto({
          name: musicScore,
        }),
      )
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markUploadAsFinished(musicScore: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.music_score_controller.upload_finished";

    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: musicScore }),
    );
    return firstValueFrom(obs);
  }

  createMusicScoreDownloadLink(
    musicPiece: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): Observable<MusicScore> {
    const method =
      "yobi_rocks.controllers.music_score_controller.setup_file_url";

    const conversion = new MusicScoreDtoToMusicScoreConversion();
    const helper = new FrappeMethodHelper(this.http, conversion);
    return helper.callMethod(
      method,
      new MethodDataWrapper().withDto({
        music_piece: musicPiece.name,
        linked_music_piece: linkedMusicPiece?.name || "", // otherwise we get 500 error as arguments are missing
      }),
    );
  }
}
