import { Pipe, PipeTransform } from "@angular/core";
import { MusicPiece } from "../models/music-piece/music-piece";
import { MusicPieceThumbnail } from "../models/music-piece/music-piece-thumbnail";
import { MusicPieceListing } from "../models/music-piece/music-piece-listing";

@Pipe({
  name: "thumbnail",
  standalone: true,
})
export class MusicPieceThumbnailPipe implements PipeTransform {
  private isMusicPieceWithThumbnail(
    musicPiece: MusicPiece | MusicPieceListing | undefined,
  ): boolean {
    return musicPiece != undefined && (musicPiece as MusicPiece).uploadFinished;
  }

  private isMusicPieceListingWithThumbnail(
    musicPiece: MusicPiece | MusicPieceListing | undefined,
  ): boolean {
    return (
      musicPiece != undefined &&
      (musicPiece as MusicPieceListing).musicPieceThumbnailUploadFinished
    );
  }

  transform(
    musicPiece: MusicPiece | MusicPieceListing | undefined,
  ): MusicPieceThumbnail | undefined {
    // how can we distinguish between the listing and the music piece?
    // -> The fields have different names (yikes!)

    if (this.isMusicPieceWithThumbnail(musicPiece)) {
      return MusicPieceThumbnail.fromMusicPiece(musicPiece as MusicPiece);
    } else if (this.isMusicPieceListingWithThumbnail(musicPiece)) {
      return MusicPieceThumbnail.fromMusicPieceListing(
        musicPiece as MusicPieceListing,
      );
    } else {
      return;
    }
  }
}
