import { ArtistCertificationEffects } from "./artist-certification.effects";
import { LicenseEffects } from "./license.effects";
import { MarkerEffects } from "./marker.effects";
import { MetronomeEffects } from "./metronome.effects";
import { MusicPieceEffects } from "./music-piece.effects";
import { PlayerEffects } from "./player.effects";
import { RecordingPartGroupEffects } from "./recording-part-group.effects";
import { RegistrationEffects } from "./registration.effects";
import { RotationEffects } from "./rotation.effects";
import { UsedResourcesForUsersEffects } from "./used-resources-for-user.effects";
import { UsedResourcesEffects } from "./used-resources.effects";
import { ArtistProfileEffects } from "./artist-profile.effects";
import { EditMusicPieceEffects } from "./edit-music-piece.effects";
import { UserEffects } from "./user.effects";
import { PurchaseEffects } from "./purchase.effects";
import { SalesEffects } from "./sales.effects";
import { CollectionEffects } from "./collection.effects";
import { EditCollectionEffects } from "./edit-collection.effects";
import { ReadonlyCollectionEffects } from "./readonly-collection.effects";
import { AppEffects } from "./app.effects";
import { ExploreEffects } from "./explore.effects";
import { FaqEffects } from "./faq.effects";
import { NotificationSettingsEffects } from "./notification-settings.effects";
import { NotificationEffects } from "./notification.effects";
import { PlaylistEffects } from "./playlist.effects";
import { PracticeRoutinesEffects } from "./practice-routines.effects";
import { PracticeRoutineEffects } from "./practice-routine.effects";
import { VideoFeedbacksEffects } from "./video-feedbacks.effects";
import { VideoFeedbackEffects } from "./video-feedback.effects";

export const EFFECTS = [
  RegistrationEffects,
  UsedResourcesEffects,
  UsedResourcesForUsersEffects,
  LicenseEffects,
  PlayerEffects,
  MarkerEffects,
  RecordingPartGroupEffects,
  MetronomeEffects,
  RotationEffects,
  MusicPieceEffects,
  ArtistCertificationEffects,
  ArtistProfileEffects,
  EditMusicPieceEffects,
  UserEffects,
  PurchaseEffects,
  SalesEffects,
  CollectionEffects,
  EditCollectionEffects,
  ReadonlyCollectionEffects,
  AppEffects,
  ExploreEffects,
  FaqEffects,
  NotificationSettingsEffects,
  NotificationEffects,
  PlaylistEffects,
  PracticeRoutinesEffects,
  PracticeRoutineEffects,
  VideoFeedbacksEffects,
  VideoFeedbackEffects,
];
