<div *ngIf="collection" class="w-full rounded-lg bg-white shadow">
  <div class="relative overflow-clip rounded-lg hover:cursor-pointer">
    <img
      class="block aspect-video h-auto w-full"
      *ngIf="collection.uploadFinished && collection.downloadLink"
      alt="preview"
      [src]="collection.downloadLink"
      (click)="handlePreviewClick()"
    />
    <div
      *ngIf="!collection.downloadLink || !collection.uploadFinished"
      class="flex aspect-video h-auto w-full flex-col items-center justify-center bg-black text-white"
      fxLayoutAlign="center center"
      (click)="handlePreviewClick()"
    >
      <mat-icon>hide_image</mat-icon>
    </div>
    <div
      class="border-1 absolute left-0 top-2 z-10 rounded-r-full border-gray-700 bg-black/50 text-white"
    >
      <button mat-icon-button (click)="edit()" *ngIf="!linkedCollection">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="open()" *ngIf="linkedCollection">
        <mat-icon>launch</mat-icon>
      </button>
      <button mat-icon-button (click)="info()">
        <mat-icon>info</mat-icon>
      </button>
    </div>
  </div>
  <div class="truncate p-2 text-lg">
    {{ collection.title }}
  </div>
</div>
