import { ExtendableConversion } from "../extendable-conversion";
import { StoredArtistProfileConversion } from "../artist/stored-artist-profile-conversion";
import { ExploreArtistProfileDto } from "src/app/dtos/explore/explore-artist-profile.dto";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";

export class ExploreArtistProfileConversion extends ExtendableConversion<
  ExploreArtistProfileDto,
  ExploreArtistProfile
> {
  private readonly artistProfileConversion =
    new StoredArtistProfileConversion();

  convert(dto: ExploreArtistProfileDto): ExploreArtistProfile {
    return {
      artist: this.artistProfileConversion.convert(dto.artist_profile),
      listedCollectionCount: dto.listed_collection_count || 0,
      listedMusicPieceCount: dto.listed_music_pieces_count || 0,
    };
  }
}
