import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  CollectionItemToDisplay,
  CollectionItemToDisplayHelper,
} from "src/app/models/collection/collection-item-to-display";
import { EditCollectionItemDialogData } from "src/app/models/collection/edit-collection-items-dialog-data";
import { EditCollectionItemsDialogResult } from "src/app/models/collection/edit-collection-items-dialog-result";
import { EditCollectionItemsDialogComponent } from "../edit-collection-items-dialog/edit-collection-items-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-practice-interval-collection-item-dialog",
  templateUrl: "./practice-interval-collection-item-dialog.component.html",
  standalone: true,
  imports: [TranslateModule, MaterialModule],
  styleUrls: [],
})
export class PracticeIntervalCollectionItemDialogComponent {
  isDirty = false;

  existingCollectionItems: CollectionItemToDisplay[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: EditCollectionItemDialogData,
    private readonly dialogRef: MatDialogRef<
      EditCollectionItemsDialogComponent,
      EditCollectionItemsDialogResult
    >,
  ) {
    this.existingCollectionItems = CollectionItemToDisplayHelper.from(
      [...data.existingCollectionItems],
      [...data.musicPieces],
      [...data.linkedMusicPieces],
    );

    this.sort();
  }

  update() {
    this.dialogRef.close({
      updatedCollectionItems: this.existingCollectionItems.map(
        (ci) => ci.collectionItem,
      ),
    });
  }

  updatePracticeInterval(item: CollectionItemToDisplay, interval: number) {
    this.isDirty = true;

    item.collectionItem = {
      ...item.collectionItem,
      practiceInterval: interval,
    };
  }

  private sort() {
    this.existingCollectionItems = this.existingCollectionItems.sort(
      (a, b) => a.collectionItem.order - b.collectionItem.order,
    );
  }
}
