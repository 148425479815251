<p *ngIf="totalPrice == 0"></p>
<div *ngIf="totalPrice > 0">
  <h3>
    {{ "edit-music-piece.selling-price-calculator.title" | translate }}
  </h3>
  <p class="text-xs text-gray-500">
    {{ "edit-music-piece.selling-price-calculator.fees-hint" | translate }}
  </p>

  <div class="grid grid-cols-2 gap-y-2">
    <div>
      {{ "edit-music-piece.selling-price-calculator.price" | translate }}
    </div>
    <div>{{ totalPrice | price }}</div>
    <div>
      {{ "edit-music-piece.selling-price-calculator.sales-tax" | translate }}
    </div>
    <div class="text-red-500">{{ salesTax * -1 | price }}</div>

    <div>
      {{
        "edit-music-piece.selling-price-calculator.platform-fees" | translate
      }}
    </div>
    <div>
      <span class="text-red-500">
        {{ platformFee * -1 | price }}
      </span>
      <br />
      <span class="text-xs text-gray-500">
        {{
          "edit-music-piece.selling-price-calculator.included-fees" | translate
        }}
        {{ paymentFee | price }}.
      </span>
    </div>
    <div class="col-span-2">
      <hr />
    </div>
    <div class="font-bold">
      {{ "edit-music-piece.selling-price-calculator.profit" | translate }}
    </div>
    <div class="font-bold">{{ remainingPrice | price }}</div>
  </div>
</div>
<p *ngIf="!purchaseSettings">...</p>
