<div class="mx-auto mt-2 w-full md:w-4/5">
  <div class="mx-2">
    <yr-edit-music-piece class="mb-2 block"></yr-edit-music-piece>
    <yr-music-piece-uploads
      class="mb-2 block"
      *ngIf="!isNew && !isLinkedMusicPiece"
    ></yr-music-piece-uploads>
    <yr-share-music-piece class="mb-2 block"></yr-share-music-piece>
    <yr-delete-music-piece
      *ngIf="!isNew"
      class="mb-2 block"
    ></yr-delete-music-piece>
  </div>
</div>
