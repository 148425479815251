import { Injectable } from "@angular/core";
import { RecordingDto } from "src/app/dtos/music-score/recording-dto";
import { EmptyRecording, Recording } from "src/app/models/recording/recording";
import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class RecordingDtoToRecordingConversion
  implements Conversion<RecordingDto, Recording>
{
  convert(item: RecordingDto): Recording {
    if (!item) {
      return EmptyRecording;
    }
    return {
      name: item.name,
      musicPieceName: item.music_piece,
      downloadLink: item.download_link,
      contentType: item.content_type,
      mirrorHorizontal: item.mirror_horizontal == 1,
      mirrorVertical: item.mirror_vertical == 1,
      rotation: item.rotation,
      renderFinished: item.render_finished == 1,
      uploadFinished: item.upload_finished == 1,
      contentTypeOriginalFile: item.content_type_original_file,
      useMultipleAudioTracks: item.use_multiple_audio_tracks > 0,
    };
  }
}
