import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { CustomUrlValidator } from "src/app/validators/custom-url-validator";

@Component({
  selector: "yr-custom-artist-url",
  templateUrl: "./custom-artist-url.component.html",
  styleUrl: "./custom-artist-url.component.scss",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class CustomArtistUrlComponent {
  _customUrl?: string;

  @Input()
  set customUrl(customUrl: string | undefined) {
    this._customUrl = customUrl;
    this.customUrlForm.setValue(customUrl || "");
  }

  get customUrl() {
    return this._customUrl;
  }

  @Output()
  customUrlChanged = new EventEmitter<string>();

  customUrlForm = new FormControl<string>("", [
    Validators.maxLength(140),
    CustomUrlValidator.validator(),
  ]);

  form = new FormGroup({
    customUrl: this.customUrlForm,
  });

  submit() {
    if (this.form.valid) {
      this.customUrlChanged.emit(this.customUrlForm.value || "");
    }
  }
}
