import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { RegistrationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { RegistrationSelectors } from "src/app/ngrx/selectors";

import { IdenticalValidator } from "src/app/validators/identical-validator";

@Component({
  selector: "yr-verify-email",
  templateUrl: "./verify-email-page.component.html",
  styleUrls: ["./verify-email-page.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class VerifyEmailPageComponent implements OnInit {
  wasVerifiedSuccessful$ = this.store.select(
    RegistrationSelectors.selectWasVerifiedSuccessful,
  );
  isLoading$ = this.store.select(RegistrationSelectors.selectIsLoading);

  private verificationCode: string = "";

  password = new FormControl("", [
    Validators.required,
    Validators.minLength(7),
  ]);
  passwordConfirmation = new FormControl("", [
    Validators.required,
    Validators.minLength(7),
    new IdenticalValidator(this.password).identicalValidator(),
  ]);

  form = new FormGroup({
    password: this.password,
    passwordConfirmation: this.passwordConfirmation,
  });

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.verificationCode = this.route.snapshot.queryParamMap.get("code") || "";
  }

  finishRegistration() {
    this.store.dispatch(
      RegistrationActions.verifyEmailAction({
        code: this.verificationCode,
        password: this.password.value!,
      }),
    );
  }
}
