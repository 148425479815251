import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { StoredNewsDtoToNewsConversion } from "src/app/conversions/news/stored-news-dto-to-news-conversion";
import { StoredNewsReadDtoToNewsReadConversion } from "src/app/conversions/news/stored-news-read-dto-to-news-read";
import { News } from "src/app/models/news/news";
import { NewsRead } from "src/app/models/news/news-read";
import { UserService } from "../authentication/user.service";
import { UnitService } from "../unit/unit.service";
import { dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  constructor(
    private readonly http: HttpClient,
    private readonly unitService: UnitService,
    private readonly userService: UserService
  ) {}

  loadAllNews(): Observable<News[]> {
    const conversion = new StoredNewsDtoToNewsConversion();

    const requestHelper = new FrappeRequestHelper()
      .withFields(["name", "modified", "english", "german"])
      .withOrderBy("creation", "desc");

    const queryHelper = new FrappeCrudHelper("News", this.http, conversion);
    return queryHelper.queryAll(requestHelper);
  }

  private loadReadUntil(): Observable<NewsRead | undefined> {
    const conversion = new StoredNewsReadDtoToNewsReadConversion();

    const requestHelper = new FrappeRequestHelper().withFields([
      "name",
      "read_until",
    ]);

    const queryHelper = new FrappeCrudHelper(
      "News Read",
      this.http,
      conversion
    );
    return queryHelper.queryFirst(requestHelper);
  }

  loadUnreadNews(): Observable<News[]> {
    return this.loadReadUntil().pipe(
      mergeMap((nr) => {
        return this.loadAllNews().pipe(
          map((news) => {
            if (nr) {
              return news.filter(
                (n) => n.modified.valueOf() > nr.readUntil.valueOf()
              );
            } else {
              return news;
            }
          })
        );
      })
    );
  }

  markNewsAsRead(): Observable<void> {
    const helper = new FrappeCrudHelper("News Read", this.http, dropConversion);
    const dto = {
      read_until: this.unitService.dateToApiDate(new Date()),
    };
    return this.loadReadUntil().pipe(
      mergeMap((nr) => {
        if (nr) {
          return helper.update(nr.name, dto);
        } else {
          return helper.create(dto);
        }
      })
    );
  }
}
