import { Component } from "@angular/core";
import { ToolBarSecondRowComponent } from "../../common/tool-bar-second-row/tool-bar-second-row.component";
import { FaqSearchComponent } from "../faq-search/faq-search.component";
import { FaqComponent } from "../faq/faq.component";

@Component({
  selector: "yr-faq-page",
  templateUrl: "./faq-page.component.html",
  standalone: true,
  imports: [ToolBarSecondRowComponent, FaqSearchComponent, FaqComponent],
})
export class FaqPageComponent {}
