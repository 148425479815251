import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ShowcaseCollectionListingDialogData } from "src/app/models/collection/showcase-collection-listing-info-dialog-data";
import { ShowcaseCollectionInfoDialogComponent } from "../../artist/showcase-collection-info-dialog/showcase-collection-info-dialog.component";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { ExploreCollectionToShowcaseCollectionConversion } from "src/app/conversions/explore/explore-collection-to-showcase-collction.conversion";
import { ArtistProfileLinkService } from "src/app/services/artist/artist-profile-link.service";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { PricePipe } from "src/app/pipes/price.pipe";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-explore-collection",
  templateUrl: "./explore-collection.component.html",
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, PricePipe],
})
export class ExploreCollectionComponent {
  @Input()
  collection?: ExploreCollection;

  private conversion = new ExploreCollectionToShowcaseCollectionConversion();

  constructor(
    private readonly matDialog: MatDialog,
    private readonly artistProfileLinkService: ArtistProfileLinkService,
  ) {}

  showCollectionDetails() {
    if (this.collection) {
      this.matDialog.open<
        ShowcaseCollectionInfoDialogComponent,
        ShowcaseCollectionListingDialogData,
        void
      >(ShowcaseCollectionInfoDialogComponent, {
        data: {
          showcaseCollectionListing: this.conversion.convert(this.collection),
        },
        minWidth: "50vw",
        maxWidth: "80vw",
      });
    }
  }

  goToArtist() {
    if (this.collection) {
      this.artistProfileLinkService.goToArtist(this.collection.artist);
    }
  }
}
