import { MusicPiecePurchaseDto } from "src/app/dtos/music-piece/music-piece-purchase-dto";
import {
  MusicPiecePurchase,
  MusicPiecePurchaseStatus,
} from "src/app/models/music-piece/music-piece-purchase";
import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";

export class MusicPiecePurchaseDtoConversion
  implements Conversion<MusicPiecePurchaseDto, MusicPiecePurchase>
{
  private readonly us: UnitService = new UnitService();

  convert(dto: MusicPiecePurchaseDto): MusicPiecePurchase {
    var purchaseStatus: MusicPiecePurchaseStatus;

    // 0 draft
    // 1 submitted
    // 2 cancelled
    if (dto.docstatus == 1) {
      purchaseStatus = MusicPiecePurchaseStatus.PURCHASED;
    } else if (dto.payment_intent_status == "requires_payment_method") {
      purchaseStatus = MusicPiecePurchaseStatus.REQUIRES_PAYMENT;
    } else {
      purchaseStatus = MusicPiecePurchaseStatus.PENDING_PAYMENT;
    }

    return {
      name: dto.name,
      musicPiece: dto.music_piece,
      price: dto.price,
      purchasedBy: dto.purchased_by,
      creation: this.us.parseApiDate(dto.creation),
      clientSecret: dto.client_secret,
      purchaseStatus: purchaseStatus,
      nameOfMusicPiece: dto.name_of_music_piece,
      platformFee: dto.platform_fee,
      totalSalesTax: dto.total_sales_tax,
      paymentIntentStatus: dto.payment_intent_status,
      paymentDate: this.us.parseOptionalApiDate(dto.payment_date),
    };
  }
}
