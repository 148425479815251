<div class="mx-2">
  <button
    class="btn btn-primary btn-sm my-2 h-10 rounded-md font-normal"
    (click)="markAllAsRead()"
    [disabled]="isMarkingAsRead() || !hasUnreadNotifications()"
  >
    @if (isMarkingAsRead()) {
      <span class="loading loading-spinner"></span>
    }
    <ng-icon name="lucideBellOff" size="1.5rem"></ng-icon>

    {{ "notification.notification-list.mark-all-as-read" | translate }}
  </button>
</div>
