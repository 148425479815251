import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";
import { Preview } from "src/app/models/music-piece/preview";
import { ExploreActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ExploreSelectors } from "src/app/ngrx/selectors";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { CommonModule } from "@angular/common";
import { ExploreArtistProfileComponent } from "../explore-artist-profile/explore-artist-profile.component";
import { CreateProfileHintComponent } from "../../artist-certification/create-profile-hint/create-profile-hint.component";
import { ExploreCollectionComponent } from "../explore-collection/explore-collection.component";
import { ExploreMusicPieceComponent } from "../explore-music-piece/explore-music-piece.component";
import { PreviewForListingPipe } from "src/app/pipes/preview-for-listing.pipe";
import { NoItemsIndicatorComponent } from "../../common/no-items-indicator/no-items-indicator.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-explore",
  templateUrl: "./explore.component.html",
  styleUrls: ["./explore.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    ExploreArtistProfileComponent,
    CreateProfileHintComponent,
    ExploreCollectionComponent,
    ExploreMusicPieceComponent,
    PreviewForListingPipe,
    NoItemsIndicatorComponent,
  ],
})
export class ExploreComponent implements OnInit, OnDestroy {
  isLoadingSearchResults = false;
  isLoadingItems = false;
  items: ExploreArtistProfile[] = [];
  artistProfileSearchResults: ExploreArtistProfile[] = [];
  musicPiecesSearchResults: ExploreMusicPiece[] = [];
  previews: Preview[] = [];
  listedCollectionsSearchResults: ExploreCollection[] = [];
  searchQuery: string = "";

  private readonly subscriptions = new SubscriptionService();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptions.addMany(
      this.store.select(ExploreSelectors.searchResults).subscribe((r) => {
        this.isLoadingSearchResults = r.isLoadingSearchResults;
        this.artistProfileSearchResults = r.partnerSearchResults;
        this.listedCollectionsSearchResults = r.listedCollectionsSearchResults;
        this.musicPiecesSearchResults = r.listedMusicPiecesSearchResults;
        this.previews = r.previewsForListedMusicPiecesSearchResults;
        this.searchQuery = r.query;
      }),
      this.store.select(ExploreSelectors.items).subscribe((r) => {
        this.isLoadingItems = r.isLoadingItems;
        this.items = r.items;
      }),
    );

    this.store.dispatch(ExploreActions.load());
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
