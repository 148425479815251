import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import {
  CollectionItemToDisplayHelper,
  LinkedCollectionItemToDisplay,
} from "src/app/models/collection/collection-item-to-display";
import { PracticeIntervalLinkedCollectionItemDialogData } from "src/app/models/collection/practice-interval-linked-collection-item-dialog-data";
import { PracticeIntervalLinkedCollectionItemDialogResult } from "src/app/models/collection/practice-interval-linked-collection-item-dialog-result";
import { MaterialModule } from "src/app/modules/material.module";
import { PracticeIntervalIndicatorComponent } from "../../practice-routines/practice-interval-indicator/practice-interval-indicator.component";

@Component({
  selector: "yr-practice-interval-readonly-collection-item-dialog",
  templateUrl:
    "./practice-interval-readonly-collection-item-dialog.component.html",
  standalone: true,
  imports: [
    TranslateModule,
    MaterialModule,
    PracticeIntervalIndicatorComponent,
  ],
  styleUrls: [],
})
export class PracticeIntervalReadonlyCollectionItemDialogComponent {
  isDirty = false;
  linkedCollectionItems: LinkedCollectionItemToDisplay[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: PracticeIntervalLinkedCollectionItemDialogData,
    private readonly dialogRef: MatDialogRef<
      PracticeIntervalReadonlyCollectionItemDialogComponent,
      PracticeIntervalLinkedCollectionItemDialogResult
    >,
  ) {
    this.linkedCollectionItems =
      CollectionItemToDisplayHelper.fromLinkedCollectionItems(
        [...data.existingCollectionItems],
        [...data.linkedMusicPieces],
      );

    this.sort();
  }

  update() {
    this.dialogRef.close({
      updatedCollectionItems: this.linkedCollectionItems.map(
        (ci) => ci.collectionItem,
      ),
    });
  }

  updatePracticeInterval(
    item: LinkedCollectionItemToDisplay,
    interval: number,
  ) {
    this.isDirty = true;

    item.collectionItem = {
      ...item.collectionItem,
      myPracticeInterval: interval,
    };
  }

  private sort() {
    this.linkedCollectionItems = this.linkedCollectionItems.sort(
      (a, b) => a.collectionItem.order - b.collectionItem.order,
    );
  }
}
