import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "yr-no-purchases-item",
  templateUrl: "./no-purchases-item.component.html",
  styleUrls: ["./no-purchases-item.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class NoPurchasesItemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
