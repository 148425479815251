import { query } from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { map, mergeMap, mergeMapTo, reduce, tap } from "rxjs/operators";
import { StoredRecordingPartGroupDtoToRecordingPartGroupConversion } from "src/app/conversions/recording-part-group/stored-recording-part-group-dto-to-recording-part-group-conversion";
import { CreateRecordingPartGroupDto } from "src/app/dtos/recording-part-group/create-recording-part-group-dto";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { Marker } from "src/app/models/marker/marker";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { Recording } from "src/app/models/recording/recording";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import {
  FrappeRequestFilter,
  FrappeRequestHelper,
} from "../frappe-request-helper";
import { MarkerService } from "./marker.service";

@Injectable({
  providedIn: "root",
})
export class RecordingPartGroupService {
  constructor(
    private readonly http: HttpClient,
    private readonly markerService: MarkerService
  ) {}

  getRecordingPartGroups(
    recording: Recording,
    linkedMusicPiece?: LinkedMusicPiece
  ): Observable<RecordingPartGroup[]> {
    const conversion =
      new StoredRecordingPartGroupDtoToRecordingPartGroupConversion();
    const crudHelper = new FrappeCrudHelper("", this.http, conversion);
    const customQueryMethod =
      "api/method/yobi_rocks.controllers.recording_part_group_controller.get_recording_part_groups";

    const queryHelper = new FrappeRequestHelper()
      .withParam("recording", recording.name)
      .withParam("linked_music_piece", linkedMusicPiece?.name || "");

    return crudHelper.queryAll(queryHelper, customQueryMethod);
  }

  private createStartMarker(inGroup: RecordingPartGroup): Observable<Marker> {
    return this.markerService.addMarker(inGroup, {
      canDelete: false,
      title: "A",
      name: "",
      timestampMs: 0,
    });
  }

  private createEndMarker(
    inGroup: RecordingPartGroup,
    totalMs: number
  ): Observable<Marker> {
    return this.markerService.addMarker(inGroup, {
      canDelete: false,
      title: "E",
      name: "",
      timestampMs: totalMs,
    });
  }

  addRecordingGroup(
    recordingPartGroup: RecordingPartGroup,
    totalMs: number
  ): Observable<RecordingPartGroup> {
    const dto: CreateRecordingPartGroupDto = {
      recording: recordingPartGroup.recording,
      title: recordingPartGroup.title,
    };
    const conversion =
      new StoredRecordingPartGroupDtoToRecordingPartGroupConversion();
    const queryHelper = new FrappeCrudHelper(
      "Recording Part Group",
      this.http,
      conversion
    );

    // the use of mergeMap and pipe to the old value ensures that the markers are created first, and then the recordingPartGroup is emitted.
    // maybe there is a better way to achieve this? -> reduce/fold with observables would be nice.
    return queryHelper.create(dto).pipe(
      mergeMap((group) => {
        return this.createStartMarker(group).pipe(map((_) => group));
      }),
      mergeMap((group) => {
        return this.createEndMarker(group, totalMs).pipe(map((_) => group));
      })
    );
  }

  deleteRecordingGroup(
    recordingPartGroup: RecordingPartGroup
  ): Observable<void> {
    const queryHelper = new FrappeCrudHelper("Recording Part Group", this.http);

    return queryHelper.delete(recordingPartGroup.name);
  }
}
