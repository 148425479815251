import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";

@Component({
  selector: "yr-upgrade-subscription-hint",
  templateUrl: "./upgrade-subscription-hint.component.html",
  styleUrls: ["./upgrade-subscription-hint.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class UpgradeSubscriptionHintComponent implements OnInit, OnDestroy {
  canUpgrade = false;

  private readonly subscriptions = new SubscriptionService();

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(UserSelectors.canUpgrade).subscribe((data) => {
        this.canUpgrade = data.canUpgrade;
      }),
    );
  }

  upgrade() {
    this.router.navigate(["/subscription"]);
  }
}
