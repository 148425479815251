<div class="card bg-base-100">
  <div class="card-body">
    <div class="card-title">
      {{ "notification.notification-settings.title" | translate }}
    </div>
    <p class="text-gray-500">
      {{ "notification.notification-settings.description" | translate }}
    </p>

    @if (isLoading()) {
      <span class="loading loading-dots"></span>
    } @else {
      <form [formGroup]="form" (submit)="saveUserSettings()">
        <div class="gax-x-4 form-control">
          <label class="label w-48 cursor-pointer">
            <span class="label-text">{{
              "notification.notification-settings.push" | translate
            }}</span>
            <input type="checkbox" class="toggle" [formControl]="push" />
          </label>

          @if (
            !pushNotificationSubscription &&
            userSettings() &&
            userSettings()?.push &&
            !isRegisteringSubscriptionInBrowser() &&
            !isSaving()
          ) {
            <div class="flex flex-col text-error">
              <div class="flex flex-row items-center gap-x-4">
                <ng-icon name="lucideAlertTriangle" size="1.5rem"></ng-icon>
                <button class="btn btn-sm" (click)="registerPush()">
                  {{
                    "notification.notification-settings.re-register" | translate
                  }}
                </button>
              </div>
              <span class="whitespace-pre-line">{{
                "notification.notification-settings.push-error-hint" | translate
              }}</span>
            </div>
          }
          @if (pushNotificationSubscription) {
            <div class="flex flex-row items-center gap-x-4 text-success">
              <ng-icon name="lucideCheckCheck" size="1.5rem"></ng-icon>
              <span class="whitespace-pre-line">
                {{
                  "notification.notification-settings.push-success-hint"
                    | translate
                }}
              </span>
            </div>
          }
        </div>

        <div class="form-control mt-4">
          <label class="label w-48 cursor-pointer">
            <span class="label-text">{{
              "notification.notification-settings.email" | translate
            }}</span>
            <input type="checkbox" class="toggle" [formControl]="email" />
          </label>
        </div>
      </form>
    }
  </div>
</div>
