import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createSelector, Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { VideoFeedbacksActions } from "../actions";
import { debounceTime, map, mergeMap, withLatestFrom } from "rxjs";
import { VideoFeedbackService } from "src/app/services/video-feedback/video-feedbacks.service";
import { VideoFeedbacksSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class VideoFeedbacksEffects {
  loadVideoFeedbacks$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbacksActions.load),
      mergeMap((_) =>
        this.videoFeedbackService.loadVideoFeedbacks().pipe(
          map((videoFeedbacks) =>
            VideoFeedbacksActions.loaded({
              videoFeedbacks,
            }),
          ),
        ),
      ),
    ),
  );

  applyFilter$ = createEffect(() =>
    this.actions.pipe(
      ofType(VideoFeedbacksActions.filter),
      debounceTime(100),
      withLatestFrom(this.store.select(VideoFeedbacksSelectors.videoFeedbacks)),
      map(([action, state]) =>
        VideoFeedbacksActions.filtered({
          filteredVideoFeedbacks: this.videoFeedbackService.filter(
            state.videoFeedbacks,
            action.filter,
            action.showFinished,
          ),
        }),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly videoFeedbackService: VideoFeedbackService,
    private readonly store: Store<AppState>,
  ) {}
}
