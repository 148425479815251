<h1 matDialogTitle>
  {{ "edit-collection.remove-collection-item-dialog.title" | translate }}
</h1>

<div matDialogContent>
  <div *ngFor="let item of existingCollectionItems; let idx = index">
    <mat-divider *ngIf="idx > 0"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="item">
      <div class="title">
        {{ item.musicPiece.musicPiece.nameOfPiece }}
      </div>
      <div class="actions">
        <button
          mat-button
          color="primary"
          (click)="remove(item.collectionItem)"
        >
          {{
            "edit-collection.remove-collection-item-dialog.remove" | translate
          }}
        </button>
      </div>
    </div>
  </div>
</div>

<div matDialogActions fxLayout="row" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]>
    {{ "common.close" | translate }}
  </button>
</div>
