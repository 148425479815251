import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState, UserSelectors } from "../ngrx/reducers";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  isLoggedIn: boolean = false;

  constructor(
    private readonly store: Store<AppState>,
    public router: Router,
  ) {
    this.store
      .select(UserSelectors.isLoggedIn)
      .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn || false));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(UserSelectors.isLoggedIn).pipe(
      tap((isLoggedIn) => {
        // console.log(`auth-guard: ${isLoggedIn}`);
        if (!isLoggedIn) {
          this.router.navigate(["/login"]);
        }
      }),
    );
  }
}
