import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";

@Injectable({
  providedIn: "root",
})
export class ArtistProfileSeoService {
  private addedArtistProfile = false;
  private addedListedMusicPieces = false;
  private addedListedCollections = false;

  private listedMusicPieceTitles: string[] = [];
  private listedCollectionItemTitles: string[] = [];
  private artistProfile?: StoredArtistProfile;
  private artistProfileUrl?: string;

  private oldTitle?: string;

  private oldMeta: HTMLMetaElement[] = [];
  private newMeta: HTMLMetaElement[] = [];

  constructor(
    private readonly title: Title,
    private readonly meta: Meta,
  ) {}

  // can be called multiple times, will only be applied once before calling reset
  setupArtistProfile(artist: StoredArtistProfile, url: string) {
    if (!this.addedArtistProfile) {
      this.artistProfile = artist;
      this.addedArtistProfile = true;
      this.artistProfileUrl = url;

      this.tryApplyTags();
    }
  }

  addListedCollections(collections: ShowcaseCollectionListing[]) {
    if (!this.addedListedCollections) {
      this.listedCollectionItemTitles = collections
        .map((cl) => cl.items.map((i) => i.musicPiece.nameOfPiece))
        .reduce((acc, curr) => [...acc, ...curr], []);

      this.addedListedCollections = true;

      this.tryApplyTags();
    }
  }

  addListedMusicPieces(musicPieces: MusicPieceListing[]) {
    if (!this.addedListedMusicPieces) {
      this.listedMusicPieceTitles = musicPieces.map((mp) => mp.musicPieceName);
      this.addedListedMusicPieces = true;

      this.tryApplyTags();
    }
  }

  reset() {
    this.resetTitle();
    this.resetMeta();

    this.addedArtistProfile = false;
    this.addedListedMusicPieces = false;
    this.addedListedCollections = false;
    this.listedCollectionItemTitles = [];
    this.listedMusicPieceTitles = [];
    this.artistProfile = undefined;
  }

  private tryApplyTags() {
    if (
      this.addedArtistProfile &&
      this.addedListedCollections &&
      this.addedListedMusicPieces &&
      this.artistProfile
    ) {
      const title = `applaus.schule - ${this.artistProfile.artistName}`;
      this.setTitle(title);
      this.setMeta("title", title);
      this.setMeta("og:title", title);
      this.setMeta("twitter:title", title);
      this.setMeta("og:type", "website");
      this.setMeta("og:image", this.artistProfile.downloadLink, "image");
      this.setMeta("twitter:image", this.artistProfile.downloadLink);
      this.setMeta("og:image:type", this.artistProfile.contentType);

      this.setMeta("twitter:card", "summary_large_image");
      if (this.artistProfileUrl) {
        this.setMeta("og:url", this.artistProfileUrl);
        this.setMeta("twitter:url", this.artistProfileUrl);
      }

      const titles = [
        ...new Set([
          ...this.listedCollectionItemTitles,
          ...this.listedMusicPieceTitles,
        ]),
      ];

      const titlesStr = titles.reduce((acc, curr) => {
        // description should be  70-320 characters. Therefore we limit to 250 here to make space for the artist's name and instrument
        // we only take full titles not partial titles
        const spacer = acc.length > 0 ? ", " : "";
        if (acc.length + curr.length + spacer.length < 250) {
          return acc + spacer + curr;
        } else {
          return acc;
        }
      }, "");

      const description = `Lerne ${this.artistProfile.instruments}. Lerne ${titlesStr} von ${this.artistProfile.artistName}`;
      this.setMeta("og:description", description);
      this.setMeta("twitter:description", description);
      this.setMeta("description", description);
    }
  }

  private setTitle(title: string) {
    this.oldTitle = this.title.getTitle();
    this.title.setTitle(title);
  }

  private resetTitle() {
    if (this.oldTitle) {
      this.title.setTitle(this.oldTitle);
      this.oldTitle = undefined;
    }
  }

  private setMeta(name: string, value: string, itemProp?: string) {
    const selector = `name='${name}'`;
    const oldValue = this.meta.getTag(selector);
    if (oldValue) {
      this.oldMeta.push(oldValue);
      this.meta.removeTag(selector);
    }

    const newValue = itemProp
      ? this.meta.addTag({
          name: name,
          content: value,
          itemprop: itemProp,
        })
      : this.meta.addTag({
          name: name,
          content: value,
        });
    this.newMeta.push(newValue!);
  }

  private resetMeta() {
    this.newMeta.forEach((meta) => {
      const selector = `name='${meta.name}'`;
      this.meta.removeTag(selector);
    });

    this.oldMeta.forEach((old) => {
      this.meta.addTag({ name: old.name, content: old.content });
    });

    this.newMeta = [];
    this.oldMeta = [];
  }
}
