import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { FaqActions } from "../actions";
import {
  debounceTime,
  map,
  mergeMap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import { FaqService } from "src/app/services/faq/faq.service";
import { AppState } from "../reducers";
import { Store } from "@ngrx/store";
import { FaqSelectors } from "../selectors";
@Injectable({
  providedIn: "root",
})
export class FaqEffects {
  load$ = createEffect(() =>
    this.actions.pipe(
      ofType(FaqActions.load),
      mergeMap((_) =>
        this.faqService
          .load()
          .pipe(map((r) => FaqActions.loaded({ items: r }))),
      ),
    ),
  );

  triggerSearchOnLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(FaqActions.loaded),
      map((_) => FaqActions.search({ query: "" })),
    ),
  );

  search$ = createEffect(() =>
    this.actions.pipe(
      ofType(FaqActions.search),
      debounceTime(100),
      withLatestFrom(this.store.select(FaqSelectors.items)),
      mergeMap(([action, state]) =>
        this.faqService
          .search(action.query, state.items)
          .pipe(map((r) => FaqActions.searched({ searchResults: r }))),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly faqService: FaqService,
  ) {}
}
