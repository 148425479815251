import { Injectable } from "@angular/core";
import { CreateMusicScoreResponseDto } from "src/app/dtos/music-score/create-music-score-response-dto";
import { MusicScore } from "src/app/models/music-score/music-score";

import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class CreateMusicScoreResponseDtoToMusicScoreConversion
  implements Conversion<CreateMusicScoreResponseDto, MusicScore>
{
  convert(dto: CreateMusicScoreResponseDto): MusicScore {
    return {
      name: dto.name,
      nameOfMusicPiece: dto.music_piece,
      numberOfPages: dto.number_of_pages,
    };
  }
}
