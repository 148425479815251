<mat-card
  appearance="outlined"
  #cardContainer
  yrResize
  (resized)="handleResize($event)"
  class="mx-2"
>
  <mat-card-content>
    <div *ngIf="isLoading" class="wrapper">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="!isLoading">
      <table
        mat-table
        [dataSource]="dataSource"
        style="width: 100%"
        matSort
        *ngIf="!isLoading"
        matSortActive="lastViewed"
        matSortDirection="desc"
        matSortDisableClear
        multiTemplateDataRows
        [fixedLayout]="useFixedLayout"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "musicPiecesTable.name" | translate }}
          </th>

          <td mat-cell *matCellDef="let data">
            <div fxLayout="row" fxLayoutAlign="space-between center truncate">
              <div class="name-of-piece truncate">
                {{ data.musicPiece.nameOfPiece }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "music-piece.music-piece-table.type" | translate }}
          </th>

          <td mat-cell *matCellDef="let data">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <yr-music-piece-type
                [isCopiedMusicPiece]="data.musicPiece && data.musicPiece.isCopy"
                [isLinkedMusicPiece]="data.linkedMusicPiece"
                [collectionInfo]="
                  data
                    | musicPieceToCollectionItem
                      : musicPieceToCollectionItemMapping
                    | collectionInfo: collectionData
                "
              ></yr-music-piece-type>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastViewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "musicPiecesTable.lastViewed" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            {{
              data.musicPiece.lastTimeViewed !== null
                ? (data.musicPiece.lastTimeViewed | date: "d.M.yyyy HH:mm")
                : "-"
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "musicPiecesTable.creation" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            {{ data.musicPiece.creation | date: "d.M.yyyy HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ "musicPiecesTable.actions" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            <div class="actions" fxLayout="row" fxLayoutAlign="start center">
              <button
                mat-button
                (click)="navigateToPlayer(data)"
                color="primary"
                [disabled]="!hasPracticeDurationLeft"
              >
                <mat-icon>music_note</mat-icon>
                <span>Werkbank</span>
              </button>
              <button
                mat-icon-button
                (click)="editMusicPiece(data.musicPiece)"
                *ngIf="!data.linkedMusicPiece && data.musicPiece"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                (click)="editLinkedMusicPiece(data.linkedMusicPiece)"
                *ngIf="data.linkedMusicPiece"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                *ngIf="data.linkedMusicPiece && !disableCreateCopy"
                mat-icon-button
                (click)="createCopyFromLinkedMusicPiece(data)"
                [disabled]="!canCreateNew || !hasFreeStorageLeft"
              >
                <mat-icon>add_to_photos</mat-icon>
              </button>
              <button mat-icon-button (click)="showDetails(data)">
                <mat-icon>info</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="detail"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
