<div class="mr-2 flex flex-row items-center">
  <button
    class="mr-2 !h-10"
    mat-raised-button
    color="primary"
    (click)="addMusicPiece()"
    *ngIf="showNew"
    [disabled]="!canCreateNew"
  >
    <mat-icon>add</mat-icon>
    {{ "music-piece.music-piece-table.new" | translate }}
  </button>
  <input
    *ngIf="showFilter"
    class="h-10 flex-grow rounded px-2 text-base text-zinc-700 caret-as-700 outline-none placeholder:text-base placeholder:text-zinc-500 sm:flex-none"
    type="text"
    id="filter"
    [placeholder]="'music-piece.music-piece-table.search' | translate"
    [(ngModel)]="filterText"
    (keyup)="applyFilter()"
  />

  @if (showFilter && showFilterLinkedMusicPieces) {
    <label class="swap ml-2">
      <input
        type="checkbox"
        [(ngModel)]="includeLinkedMusicPieces"
        (change)="applyFilter()"
      />

      <div class="swap-on flex flex-row items-center text-base text-white">
        <ng-icon name="lucideEye" class="mx-2"></ng-icon>
        <span class="mt-1">
          {{
            "music-piece.music-piece-table.show-linked-music-pieces" | translate
          }}
        </span>
      </div>
      <div class="swap-off flex flex-row items-center text-base text-primary">
        <ng-icon name="lucideEyeOff" class="mx-2"></ng-icon>
        <span class="mt-1">
          {{
            "music-piece.music-piece-table.hide-linked-music-pieces" | translate
          }}
        </span>
      </div>
    </label>
  }

  <!-- <mat-form-field color="primary" *ngIf="showFilter" class="mr-2">
    <mat-label class="text-white">
      {{ "music-piece.music-piece-table.search" | translate }}
    </mat-label>
    <input
      matInput
      [(ngModel)]="filterText"
      (keyup)="applyFilter()"
      class="!text-white placeholder:text-white"
    />
    <mat-icon matSuffix class="text-white">search</mat-icon>
  </mat-form-field> -->

  <div class="hidden flex-grow sm:block"></div>

  <div
    class="my-2 flex h-10 w-fit max-w-full flex-row justify-center rounded bg-white px-2 shadow"
    *ngIf="showDisplayChange"
  >
    <button
      (click)="changeDisplayType('cards')"
      class="mi roundedp-1 mr-2 flex-none shrink transition-colors"
    >
      <mat-icon
        [class.text-as-500]="displayType == 'cards'"
        class="align-middle"
      >
        dashboard
      </mat-icon>
    </button>
    <button
      (click)="changeDisplayType('list')"
      class="flex-none shrink rounded p-1 transition-colors"
    >
      <mat-icon
        [class.text-as-500]="displayType == 'list'"
        class="align-middle"
      >
        list
      </mat-icon>
    </button>
  </div>
</div>
