import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { UrlTypeHelper } from "../models/artist/stored-artist-profile";

export class UrlTypeValidator {
  constructor(
    private readonly supportedUrlTypes: UrlTypeHelper[],
    private readonly urlFormControl: FormControl<string | null>,
  ) {}

  notSupportedError(urlType?: string) {
    return {
      urlType: {
        notSupportedError: `urlType="${urlType}" not supported`,
      },
    };
  }

  noError() {
    return null;
  }

  urlTypeValidator(): ValidatorFn {
    return (control) => {
      const { urlType } = control.value as { url: string; urlType: string }; // not very type safe!

      if (!urlType || urlType == "") return this.notSupportedError();

      const foundUrlTypeHelper = this.supportedUrlTypes.find(
        (sut) => sut.urlType == urlType,
      );

      if (!foundUrlTypeHelper) return this.notSupportedError(urlType);

      return foundUrlTypeHelper.validator()(this.urlFormControl);
    };
  }
}
