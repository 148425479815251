<div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <div *ngIf="purchase && purchase.item.purchaseStatus == this.purchased">
    <h1>
      {{ "purchase.purchase-status.purchased" | translate }}
    </h1>
    <p>
      {{ "purchase.purchase-status.purchased-info" | translate }}
    </p>

    <button mat-button color="primary" (click)="home()">
      {{ "purchase.purchase-status.home" | translate }}
    </button>

    <button mat-button color="primary" (click)="downloadReceipt()">
      {{ "purchase.purchase-status.download-receipt" | translate }}
    </button>
  </div>
  <div *ngIf="purchase && purchase.item.purchaseStatus == this.pending">
    <h1>
      {{ "purchase.purchase-status.pending" | translate }}
    </h1>
    <p>{{ "purchase.purchase-status.pending-info" | translate }}</p>
    <button mat-raised-button color="primary" (click)="checkStatus()">
      {{ "purchase.purchase-status.check-status" | translate }}
    </button>
  </div>
  <div *ngIf="purchase && purchase.item.purchaseStatus == this.requiresPayment">
    <h1>
      {{ "purchase.purchase-status.requires-payment" | translate }}
    </h1>
    <p>{{ "purchase.purchase-status.requires-payment-info" | translate }}</p>

    <button mat-raised-button color="primary" (click)="checkStatus()">
      {{ "purchase.purchase-status.check-status" | translate }}
    </button>
  </div>

  <div *ngIf="!purchase && !isLoading">
    <p class="no-found">
      {{ "purchase.purchase-status.not-found" | translate }}
    </p>
  </div>
</div>
