import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { PurchaseStatusComponent } from "../purchase-status/purchase-status.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-purchase-status-page",
  templateUrl: "./purchase-status-page.component.html",
  styleUrls: ["./purchase-status-page.component.scss"],
  standalone: true,
  imports: [MaterialModule, PurchaseStatusComponent],
})
export class PurchaseStatusPageComponent implements OnInit {
  clientSecret: string = "";
  purchaseType: "music-piece" | "collection" | "video-feedback" = "music-piece";

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.clientSecret =
      this.activatedRoute.snapshot.paramMap.get("client-secret") || "";

    this.purchaseType = "music-piece";

    const paramPurchaseType =
      this.activatedRoute.snapshot.paramMap.get("purchase-type");
    if (paramPurchaseType == "collection") {
      this.purchaseType = "collection";
    }
    if (paramPurchaseType == "video-feedback") {
      this.purchaseType = "video-feedback";
    }

    this.store.dispatch(
      PurchaseActions.loadPurchaseDetails({
        clientSecret: this.clientSecret,
        purchaseType: this.purchaseType,
      }),
    );
  }
}
