import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { interval } from "rxjs";

import { StoredArtistProfileEvent } from "src/app/models/artist/stored-artist-profile";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ArtistProfileEventsFormComponent } from "../artist-profile-events-form/artist-profile-events-form.component";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-artist-profile-events",
  templateUrl: "./artist-profile-events.component.html",
  styleUrl: "./artist-profile-events.component.scss",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ArtistProfileEventsFormComponent,
    MaterialModule,
  ],
})
export class ArtistProfileEventsComponent implements OnInit, OnDestroy {
  now = new Date().setHours(0, 0, 0, 0); // we need to make sure that we show the events of the current day!

  _events: StoredArtistProfileEvent[] = [];

  nextEvent?: StoredArtistProfileEvent;
  nextEventTiming?: { days: number; hours: number; minutes: number };

  @Input()
  set events(events: StoredArtistProfileEvent[]) {
    const sortedEvents = [...events].sort(
      (a, b) => a.date.valueOf() - b.date.valueOf(),
    );
    this._events = sortedEvents;

    this.hasPastEvents = sortedEvents.some((e) => e.date.valueOf() < this.now);
    this.nextEvent = sortedEvents.find((e) => e.date.valueOf() > this.now);
  }

  get events() {
    return this._events;
  }

  @Output()
  eventsChange = new EventEmitter<StoredArtistProfileEvent[]>();

  @Input()
  isInEditMode: boolean = false;

  showPastEvents = false;

  hasPastEvents = false;

  private readonly subscriptions = new SubscriptionService();

  ngOnInit(): void {
    this.calculateNextEventTiming();

    this.subscriptions.add(
      interval(1000).subscribe((_) => this.calculateNextEventTiming()),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  private calculateNextEventTiming() {
    if (this.nextEvent) {
      const diff = DateTime.fromJSDate(this.nextEvent.date).diffNow([
        "days",
        "hours",
        "minutes",
      ]);

      this.nextEventTiming = {
        days: Math.floor(diff.days),
        hours: Math.floor(diff.hours),
        minutes: Math.floor(diff.minutes),
      };
    }
  }

  delete(event: StoredArtistProfileEvent) {
    this.events = this.events.filter((e) => e.name != event.name);
    this.eventsChange.emit(this.events);
  }

  add(event: StoredArtistProfileEvent) {
    this.events = [...this.events, event];
    this.eventsChange.emit(this.events);
  }

  update(event: StoredArtistProfileEvent) {
    this.events = [...this.events.filter((e) => e.name != event.name), event];
    this.eventsChange.emit(this.events);
  }
}
