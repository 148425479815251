<span>
  <button
    mat-button
    color="primary"
    *ngIf="
      isLoggedIn &&
      !hasAlreadyPurchased &&
      !hasPendingPayment &&
      !isSelfListing &&
      !requiresPayment
    "
    [disabled]="purchaseInProgress"
    (click)="buy()"
  >
    <mat-icon>shopping_cart</mat-icon>
    <span *ngIf="!isFreeItem">
      {{ "artist.purchase-button.buy" | translate }}
    </span>
    <span *ngIf="isFreeItem">
      {{ "artist.purchase-button.buy-free" | translate }}
    </span>
  </button>

  <button
    mat-button
    color="primary"
    *ngIf="isLoggedIn && requiresPayment"
    [disabled]="purchaseInProgress || !purchaseDetails"
    (click)="completePayment()"
  >
    <mat-icon>shopping_cart</mat-icon>
    <span>
      {{ "artist.purchase-button.complete-payment" | translate }}
    </span>
  </button>

  <p *ngIf="isLoggedIn && hasPendingPayment" class="pending-payment">
    {{ "artist.purchase-button.pending-payment" | translate }}
  </p>

  <button
    mat-button
    color="primary"
    *ngIf="isLoggedIn && (hasAlreadyPurchased || isSelfListing)"
    (click)="open()"
  >
    <mat-icon>music_note</mat-icon>
    <span *ngIf="musicPieceListing != undefined">
      {{ "artist.purchase-button.practice" | translate }}
    </span>
    <span *ngIf="collectionListing != undefined">
      {{ "artist.purchase-button.open-collection" | translate }}
    </span>
  </button>
  <button mat-button *ngIf="!isLoggedIn" (click)="forceLogin()">
    {{ "artist.purchase-button.login" | translate }}
  </button>
</span>
