import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArtistCertificationConversion } from "src/app/conversions/user/artist-certification-conversion";
import { ArtistCertification } from "src/app/models/user/artist-certification";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeRequestHelper } from "../frappe-request-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";

@Injectable({
  providedIn: "root",
})
export class ArtistCertificationService {
  constructor(private readonly http: HttpClient) {}

  loadArtistCertification(): Observable<ArtistCertification | undefined> {
    const conversion = new ArtistCertificationConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Certification",
      this.http,
      conversion,
    );
    const requestHelper = new FrappeRequestHelper().withFields(["*"]);

    return crudHelper.queryFirst(requestHelper);
  }

  createCertification(username: string): Observable<ArtistCertification> {
    const conversion = new ArtistCertificationConversion();
    const crudHelper = new FrappeCrudHelper(
      "Artist Certification",
      this.http,
      conversion,
    );
    return crudHelper.create({
      user: username,
      state: "",
    });
  }

  requestReview(): Observable<ArtistCertification> {
    const conversion = new ArtistCertificationConversion();
    const methodHelper = new FrappeMethodHelper(this.http, conversion);
    const method =
      "yobi_rocks.controllers.artist_certification_controller.request_review";
    return methodHelper.callMethod(method, new MethodDataWrapper());
  }
}
