import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { MusicPieceThumbnail } from "./music-piece-thumbnail";

export interface Preview {
  url: string;
  musicPiece: string;
  contentType: string;
  mirrorVertical: boolean;
  mirrorHorizontal: boolean;
  rotation: 0 | 90 | 180 | 270;
  thumbnailContentType: string;
  thumbnailDownloadLink: string;
  thumbnailRenderFinished: boolean;
}

export interface WhatToShow {
  showThumbnail: boolean;
  showThumbnailAsVideoJsCover: boolean;
  showPreview: boolean;
  showThumbnailPlay: boolean;
  showNoVideoPlaceholder: boolean;
  showHiddenPlaceholder: boolean;
}

export const initialNoShow = {
  showNoVideoPlaceholder: false,
  showHiddenPlaceholder: true,
  showPreview: false,
  showThumbnail: false,
  showThumbnailPlay: false,
  showThumbnailAsVideoJsCover: false,
} as WhatToShow;

export class WhatToShowFSM {
  private _previewType?: "video" | "audio";
  private _preview?: Preview;
  private _thumbnail?: MusicPieceThumbnail;
  private _isVisible: boolean = false;
  private _thumbnailPlayClicked = false;
  private _previewPlayClicked = false;

  private readonly whatToShowSubject = new BehaviorSubject<WhatToShow>(
    initialNoShow,
  );

  private calculateNext() {
    if (!this._isVisible) {
      this.whatToShowSubject.next(initialNoShow);
      return;
    }

    //   Use Case 1: only thumbnail
    if (
      (!this._preview || !this._preview.url) &&
      this._thumbnail &&
      this._thumbnail.downloadLink
    ) {
      this.whatToShowSubject.next({
        showHiddenPlaceholder: false,
        showNoVideoPlaceholder: false,
        showPreview: false,
        showThumbnail: true,
        showThumbnailAsVideoJsCover: false,
        showThumbnailPlay: false,
      });
      return;
    }

    // Use Case 2: only audio or Use Case 3: only video
    if (
      this._preview &&
      this._preview.url &&
      (!this._thumbnail || !this._thumbnail.downloadLink)
    ) {
      this.whatToShowSubject.next({
        showHiddenPlaceholder: false,
        showNoVideoPlaceholder: false,
        showPreview: true,
        showThumbnail: false,
        showThumbnailAsVideoJsCover: false,
        showThumbnailPlay: false,
      });
      return;
    }

    // Use Case 4: thumbnail + audio -> show thumbnail as Cover
    // or Use Case 5: thumbnail + video
    if (
      this._preview &&
      this._preview.url &&
      this._thumbnail &&
      this._thumbnail.downloadLink
    ) {
      this.whatToShowSubject.next({
        showHiddenPlaceholder: false,
        showNoVideoPlaceholder: false,
        showPreview: this._thumbnailPlayClicked,
        showThumbnail: !this._thumbnailPlayClicked,
        showThumbnailAsVideoJsCover: this._previewType == "audio",
        showThumbnailPlay: !this._thumbnailPlayClicked,
      });
      return;
    }

    // Use Case 6: nothing (no thumbnail, video, audio)
    this.whatToShowSubject.next({
      showHiddenPlaceholder: false,
      showNoVideoPlaceholder: true,
      showPreview: false,
      showThumbnail: false,
      showThumbnailAsVideoJsCover: false,
      showThumbnailPlay: false,
    });
  }

  visibilityChanged(isVisible: boolean) {
    if (!this._isVisible) {
      // we only let it show once and do not care if it becomes invisible again!
      this._isVisible = isVisible;
    }
    this.calculateNext();
  }

  thumbnail(thumbnail: MusicPieceThumbnail) {
    this._thumbnail = thumbnail;
    this.calculateNext();
  }

  preview(preview: Preview) {
    this._preview = preview;
    this.calculateNext();
  }

  previewType(type: "video" | "audio") {
    this._previewType = type;
    this.calculateNext();
  }

  thumbnailPlayClicked() {
    this._thumbnailPlayClicked = true;
    this.calculateNext();
  }

  previewPlayClicked() {
    this._previewPlayClicked = true;
    this.calculateNext();
  }

  whatToShow(): Observable<WhatToShow> {
    return this.whatToShowSubject
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (a, b) =>
            a.showHiddenPlaceholder == b.showHiddenPlaceholder &&
            a.showNoVideoPlaceholder == b.showNoVideoPlaceholder &&
            a.showPreview == b.showPreview &&
            a.showThumbnail == b.showThumbnail &&
            a.showThumbnailAsVideoJsCover == b.showThumbnailAsVideoJsCover &&
            a.showThumbnailPlay == b.showThumbnailPlay,
        ),
      );
  }
}
