import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { BillingInterval } from "src/app/models/license/billing-interval";
import { License } from "src/app/models/user/user-license";
import { MaterialModule } from "src/app/modules/material.module";
import { LicenseActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";

@Component({
  selector: "yr-purchase-subscription",
  templateUrl: "./purchase-subscription.component.html",
  styleUrls: ["./purchase-subscription.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PurchaseSubscriptionComponent {
  @Input()
  license?: License;

  @Input()
  billingInterval: BillingInterval = "monthly";

  hasClickedPurchase = false;

  constructor(private readonly store: Store<AppState>) {}

  purchase() {
    if (this.license) {
      this.hasClickedPurchase = true;
      this.store.dispatch(
        LicenseActions.purchaseSubscription({
          billingInterval: this.billingInterval,
          license: this.license,
        }),
      );
    }
  }
}
