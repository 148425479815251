import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { NewsDialogComponent } from "src/app/components/news/news-dialog/news-dialog.component";
import { News } from "src/app/models/news/news";
import { NewsDialogData } from "src/app/models/news/news-dialog-data";
import { UserService } from "src/app/services/authentication/user.service";
import { NewsService } from "src/app/services/news/news.service";
import {
  LicenseActions,
  NotificationSettingsActions,
  RegistrationActions,
  UsedResourcesForUserActions,
  UserActions,
} from "../actions";
import { of } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserEffects {
  login$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.login),
      mergeMap((action) =>
        this.userService.loginWithUsernameAndPassword(action.auth).pipe(
          map((userData) => UserActions.loggedIn({ userData: userData })),
          catchError((error) => of(UserActions.loginFailed({ error: error }))),
        ),
      ),
    ),
  );

  loginAfterVerification$ = createEffect(() =>
    this.actions.pipe(
      ofType(RegistrationActions.verificationSuccessAction),
      map((action) =>
        UserActions.login({
          auth: { username: action.email, password: action.password },
        }),
      ),
    ),
  );

  handleLoginFailure = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.loginFailed),
        filter(
          (action) => action.error.status == 0 || action.error.status == 401,
        ),
        tap((_) =>
          this.toastr.error(
            this.translate.instant("errors.invalidCredentials"),
          ),
        ),
      ),
    { dispatch: false },
  );

  navigateOnLogin$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.loggedIn),
        tap((user) => this.router.navigate(["/"])),
      ),
    { dispatch: false },
  );

  loadNewsOnLogin$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.loggedIn),
        mergeMap((_) => this.newsService.loadUnreadNews()),
        filter((news) => news && news.length > 0),
        tap((news) => this.showNews(news)),
      ),
    { dispatch: false },
  );

  loadLicenseOnLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => LicenseActions.loadLicenseAction()),
    ),
  );

  loadUpradeAndDowngradeLicenseOnLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => LicenseActions.loadFallbackAndUpgrade()),
    ),
  );

  loadUsedResourcesOnLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => UsedResourcesForUserActions.loadUsedResourcesAction()),
    ),
  );

  loadUserDetailsOnLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => UserActions.loadUserDetails()),
    ),
  );

  loadUserDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loadUserDetails),
      mergeMap((_) => this.userService.getUserDetails()),
      map((storedUser) =>
        UserActions.loadedUserDetails({ userDetails: storedUser }),
      ),
    ),
  );

  loadStripeCustomerOnLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => UserActions.loadStripeCustomer()),
    ),
  );

  triggerLogout$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.triggerLogout),
      map((_) => UserActions.logout()),
    ),
  );

  navigateToLoginPageOnLogout$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.logout),
        tap((_) => this.router.navigate(["/login"])),
      ),
    { dispatch: false },
  );

  loadStripeCustomer$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loadStripeCustomer),
      mergeMap((_) => this.userService.loadStripeCustomer()),
      map((stripeCustomer) =>
        UserActions.loadedStripeCustomer({ customer: stripeCustomer }),
      ),
    ),
  );

  loadPushServerSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.loggedIn),
      map((_) => NotificationSettingsActions.loadServerSettings()),
    ),
  );

  switchLanguage$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.switchLanguage),
      mergeMap((action) =>
        this.userService
          .switchLanguage(action.language)
          .pipe(
            map((_) =>
              UserActions.switchedLanguage({ language: action.language }),
            ),
          ),
      ),
      tap(
        (_) => {
          this.toastr.success(this.translate.instant("common.success"));
        },
        (_) => {
          this.toastr.error(this.translate.instant("common.fail"));
        },
      ),
    ),
  );

  updatePassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.updatePassword),
      mergeMap((action) => this.userService.updatePassword(action.newPassword)),
      map((updatedPassword) =>
        UserActions.updatedPassword({ apiSecret: updatedPassword.apiSecret }),
      ),
    ),
  );

  updatePasswordSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.updatedPassword),
        tap(
          (_) => this.toastr.success(this.translate.instant("common.success")),
          (_) => this.toastr.error(this.translate.instant("common.fail")),
        ),
      ),
    { dispatch: false },
  );

  signInWithGoogle$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserActions.signInWIthGoogle),
      mergeMap((a) =>
        this.userService.loginWithGoogle(a.credential).pipe(
          map((response) => {
            if (!response.requiresSignUp && response.userData) {
              return UserActions.loggedIn({ userData: response.userData });
            } else {
              return UserActions.signInWithGoogleRequiresSignUp({
                credential: response.credential,
              });
            }
          }),
        ),
      ),
    ),
  );

  googleSignInRequiresSignUp$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActions.signInWithGoogleRequiresSignUp),
        tap((a) => {
          this.router.navigate(["/registration"], {
            state: {
              credential: a.credential,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly userService: UserService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly newsService: NewsService,
    private matDialog: MatDialog,
  ) {}

  private showNews(news: News[]) {
    const ref = this.matDialog.open<NewsDialogComponent, NewsDialogData>(
      NewsDialogComponent,
      {
        data: {
          unreadNews: news,
        },
        role: "alertdialog",
        maxHeight: "90vh",
        minWidth: "50vw",
        maxWidth: "80vw",
      },
    );
  }
}
