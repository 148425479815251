import { UnitService } from "src/app/services/unit/unit.service";
import { Conversion } from "../conversion";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { ExploreMusicPieceDto } from "src/app/dtos/explore/explore-music-piece.dto";
import { StoredArtistProfileConversion } from "../artist/stored-artist-profile-conversion";

export class ExploreMusicPieceDtoConversion
  implements Conversion<ExploreMusicPieceDto, ExploreMusicPiece>
{
  private readonly us: UnitService = new UnitService();
  private readonly artistProfileConversion =
    new StoredArtistProfileConversion();

  convert(dto: ExploreMusicPieceDto): ExploreMusicPiece {
    return {
      name: dto.name,
      creation: this.us.parseApiDate(dto.creation),
      musicPiece: dto.music_piece,
      musicPieceName: dto.music_piece_name,
      price: dto.price,
      artist: this.artistProfileConversion.convert(dto.artist),
      collection: dto.collection,
      description: dto.description,
      instrument: dto.instrument,
      musicPieceArtist: dto.music_piece_artist,
      temperament: dto.temperament,
      musicPieceThumbnailDownloadLink: dto.music_piece_thumbnail_download_link,
      musicPieceThumbnailUploadFinished:
        dto.music_piece_thumbnail_upload_finished > 0,
    };
  }
}
