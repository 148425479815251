<div mat-dialog-content>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

  <div class="my-4 text-xl text-black">
    {{ "artist.teaser-selection-dialog.title" | translate }}
  </div>
  <div class="mb-4 whitespace-pre-line text-sm text-gray-700">
    {{ "artist.teaser-selection-dialog.hint" | translate }}
  </div>

  <div
    *ngFor="let musicPiece of possibleTeasers"
    class="flex flex-col gap-2 text-black"
  >
    <div class="flex flex-row">
      <div class="flex-grow truncate text-base">
        {{ musicPiece.nameOfPiece }}
      </div>
      <button
        class="flex flex-grow-0"
        mat-button
        color="primary"
        (click)="chooseMusicPiece(musicPiece)"
      >
        {{ "artist.teaser-selection-dialog.choose" | translate }}
      </button>
    </div>
  </div>

  <div
    *ngIf="possibleTeasers.length == 0 && isLoading == false"
    class="truncate whitespace-pre-line text-as-500"
  >
    {{ "artist.teaser-selection-dialog.no-music-pieces" | translate }}
  </div>
</div>

<div mat-dialog-actions class="flex flex-row !justify-end">
  <button mat-button [mat-dialog-close]>
    {{ "common.cancel" | translate }}
  </button>
</div>
