<div class="video-container !rounded-lg">
  <video
    #video
    *ngIf="
      teaser &&
      teaser.recording.downloadLink &&
      (!hasThumbnail || (hasThumbnail && thumbnailPlayClicked))
    "
    class="video-js vjs-big-play-centered !rounded-lg"
    playsinline
    webkit-playsinline
  >
    <source
      [src]="teaser.recording.downloadLink"
      [type]="teaser.recording.contentType"
    />
  </video>

  <div
    class="thumbnail-container video-js vjs-big-play-centered"
    *ngIf="!thumbnailPlayClicked && teaser && hasThumbnail"
  >
    <img
      class="aspect-video w-full object-cover"
      [src]="teaser.musicPiece.downloadLink"
    />
    <button
      class="vjs-big-play-button"
      type="button"
      title="Play Video"
      aria-disabled="false"
      (click)="play()"
    >
      <span class="vjs-icon-placeholder" aria-hidden="true"></span
      ><span class="vjs-control-text" aria-live="polite">Play Video</span>
    </button>
  </div>
</div>
