@if (isLoading()) {
  <span class="loading loading-dots"></span>
} @else {
  <div class="mb-4 flex flex-col gap-y-4">
    @for (notification of notifications(); track notification.name) {
      <yr-notification-item
        [notification]="notification"
      ></yr-notification-item>
    }
  </div>
}
