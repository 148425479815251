import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, EditCollectionSelectors } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-shared-collection-receivers",
  templateUrl: "./shared-collection-receivers.component.html",
  styleUrls: ["./shared-collection-receivers.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class SharedCollectionReceiversComponent implements OnInit, OnDestroy {
  isLoading = true;
  isSharing = false;

  filterText = "";

  linkedCollections: StoredLinkedCollection[] = [];
  dataSource = new MatTableDataSource<StoredLinkedCollection>();

  private readonly subscriptionService = new SubscriptionService();

  displayedColumns: string[] = ["receiver", "sharedDate", "actions"];

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectLinkedCollections)
        .subscribe((data) => {
          this.isLoading = data.isLoadingLinkedCollections;
          this.isSharing = data.isSharing;
          this.linkedCollections = data.linkedCollections;
          this.dataSource.data = data.linkedCollections;
        }),
    );

    this.dataSource.filterPredicate = (linkedCollection, filter) => {
      return `${linkedCollection.user}.${linkedCollection.name}.${linkedCollection.created}`
        .toLowerCase()
        .includes(filter.toLowerCase());
    };
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  applyFilter() {
    this.dataSource.filter = this.filterText || "";
  }

  unshare(linkedCollection: StoredLinkedCollection) {
    this.store.dispatch(
      EditCollectionActions.unshare({
        linkedCollection: linkedCollection,
      }),
    );
  }
}
