<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingSales"
></mat-progress-bar>
<div *ngIf="!isLoadingSales && sales.length > 0">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="creation"
    matSortDirection="desc"
    matSortDisableClear
    yrResize
    (resized)="handleResize($event)"
  >
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.title" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="w-32 truncate md:w-auto">
          @if (element.type == "collection") {
            {{ element.item.nameOfCollection }}
          } @else if (element.type == "music-piece") {
            {{ element.item.nameOfMusicPiece }}
          } @else if (element.type == "video-feedback") {
            Video Feedback {{ element.item.purchasedBy }}
          }
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.price" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.item.price | number: "1.2-2" }} €
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>{{ totalPrice | number: "1.2-2" }} €</b>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalSalesTax">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.total-sales-tax" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.item.totalSalesTax | number: "1.2-2" }} €
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>{{ totalSalesTax | number: "1.2-2" }} €</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.payment-date" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.item.paymentDate | date: ("common.dateTimeFormat" | translate)
        }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="purchasedBy">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.purchased-by" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.item.purchasedBy }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="platformFee">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.platform-fee" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.item.platformFee | number: "1.2-2" }} €
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>{{ totalPlatformFees | number: "1.2-2" }} €</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="credit">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.credit" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.item.price -
            element.item.totalSalesTax -
            element.item.platformFee | number: "1.2-2"
        }}
        €
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>{{ totalCredits | number: "1.2-2" }} €</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "sales.sales.actions" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="downloadReceipt(element)">
          <mat-icon>download</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 100]" showFirstLastButtons>
  </mat-paginator>

  <mat-divider> </mat-divider>
  <div class="mt-8 flex flex-row justify-between">
    <div>
      <div class="text-3xl">
        {{
          "sales.sales.payout"
            | translate
              : {
                  creditsForNextPayout: creditsForNextPayout | number: "1.2-2"
                }
        }}
      </div>
      <div class="text-lg">
        <span class="text-gray-600">
          ({{
            lastCreditStatementCutoffDate
              | date: ("common.dateFormat" | translate)
          }}
          -
          {{ now | date: ("common.dateFormat" | translate) }})
        </span>
      </div>
      <div class="text-lg text-gray-600">
        {{ "sales.sales.payout-hint" | translate }}
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!isLoadingSales && sales.length == 0"
  fxFill
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <span>{{ "sales.sales.no-sales" | translate }}</span>
  <mat-icon class="no-sales-indicator">remove_shopping_cart</mat-icon>
</div>
