import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";
import {
  PrepareUpload,
  CreateOrGetDocument,
  FinishUpload,
  BeforeUploadConfirmationHook,
} from "src/app/models/common/upload-types";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { EmptyRecording, Recording } from "src/app/models/recording/recording";
import { EditMusicPieceActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { RecordingService } from "src/app/services/recording/recording.service";
import { RecordingUploadPreviewDialogComponent } from "../recording-upload-preview-dialog/recording-upload-preview-dialog.component";
import { RecordingUploadPreviewDialogData } from "src/app/models/common/recording-upload-preview-dialog-data";
import { RecordingUploadPreviewDialogResult } from "src/app/models/common/recording-upload-preview-dialog-result";
import { UploadAttachmentComponent } from "../../music-piece/upload/upload.component";
import { CommonModule } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "yr-recording-upload",
  templateUrl: "./recording-upload.component.html",
  styleUrls: ["./recording-upload.component.scss"],
  standalone: true,
  imports: [CommonModule, UploadAttachmentComponent],
})
export class RecordingUploadComponent {
  @Input()
  recording: Recording = EmptyRecording;
  readonly emptyRecording = EmptyRecording; // required, otherwise we can't reference in html

  @Input()
  musicPiece?: MusicPiece;

  @Input()
  isLoadingRecording: boolean = true;

  isUploadingRecording: boolean = false;

  @Output()
  isUploadRecordingChange = new EventEmitter<boolean>();

  constructor(
    private readonly recordingService: RecordingService,
    private readonly toastrService: ToastrService,
    private readonly store: Store<AppState>,
    private readonly translate: TranslateService,
    private readonly matDialog: MatDialog,
  ) {}

  readonly beforeUploadConfirmation: BeforeUploadConfirmationHook = (file) => {
    return new Promise((resolve, error) => {
      const dialog = this.matDialog.open<
        RecordingUploadPreviewDialogComponent,
        RecordingUploadPreviewDialogData,
        RecordingUploadPreviewDialogResult
      >(RecordingUploadPreviewDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          file: file,
        },
      });

      dialog.afterClosed().subscribe((result) => {
        const shouldUpload = result != undefined && result.shouldUpload;
        resolve(shouldUpload);
      });
    });
  };

  readonly prepareRecordingUpload: PrepareUpload = (musicScore: string) => {
    return this.recordingService.prepareRecordingUpload(musicScore);
  };

  readonly getOrCreateRecordingIn: CreateOrGetDocument = () => {
    if (this.recording == EmptyRecording) {
      const obs = this.recordingService
        .createRecording(this.musicPiece?.name ?? "") // should never be empty
        .pipe(
          tap((recording) => {
            this.store.dispatch(
              EditMusicPieceActions.createdRecording({
                recording: recording,
              }),
            );
          }),
        );
      return firstValueFrom(obs);
    } else {
      return Promise.resolve(this.recording);
    }
  };

  readonly finishUpload: FinishUpload = (name) => {
    this.store.dispatch(EditMusicPieceActions.recordingUploadFinished());
    return this.recordingService.markUploadAsFinished(name);
  };

  uploadRecordingInProgress(isUploading: boolean) {
    this.isUploadingRecording = isUploading;
    this.isUploadRecordingChange.emit(isUploading);
  }

  uploadChanged(result: AttachmentUploadResult) {
    if (result) {
      this.isUploadRecordingChange.emit(false);

      if (result.result == "Success") {
        this.toastrService.success(this.translate.instant("common.success"));
      } else {
        console.log(`upload failed: ${result?.error}`);
        this.toastrService.error(this.translate.instant("common.fail"));
      }
    }
  }
}
