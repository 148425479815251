import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { ReadonlyCollectionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ReadonlyCollectionComponent } from "../readonly-collection/readonly-collection.component";

@Component({
  selector: "yr-readonly-collection-page",
  templateUrl: "./readonly-collection-page.component.html",
  styleUrls: ["./readonly-collection-page.component.scss"],
  standalone: true,
  imports: [ReadonlyCollectionComponent],
})
export class ReadonlyCollectionPageComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.handleRouteData();
  }

  handleRouteData() {
    const linkedCollection =
      this.activatedRoute.snapshot.paramMap.get("name") || "";

    this.store.dispatch(
      ReadonlyCollectionActions.open({ linkedCollection: linkedCollection }),
    );
  }
}
