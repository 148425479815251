import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Conversion } from "../conversions/conversion";
import { ResponseDto } from "../dtos/response-dto";
import { SingleResponseDto } from "../dtos/single-response-dto";

export class ConversionHelper<IN, OUT> {
  constructor(private readonly conversion: Conversion<IN, OUT>) {}

  convertSingleObservable(
    obs: Observable<SingleResponseDto<IN>>
  ): Observable<OUT> {
    return obs.pipe(map((response) => this.convertSingleResponse(response)));
  }

  convertSingleResponse(response: SingleResponseDto<IN>): OUT {
    return this.convertSingle(response.data);
  }

  convertSingle(item: IN): OUT {
    return this.conversion.convert(item);
  }

  convertObservable(obs: Observable<ResponseDto<IN>>): Observable<OUT[]> {
    return obs.pipe(map((response) => this.convertResponse(response)));
  }

  convertResponse(response: ResponseDto<IN>): OUT[] {
    return this.convertMany(response.data);
  }

  convertMany(items: IN[]): OUT[] {
    return (items || []).map((dto) => this.conversion.convert(dto));
  }
}

export class DropConversion implements Conversion<any, void> {
  convert(item: any): void {
    return;
  }
}
export const dropConversion = new DropConversion();

export class NoConversion implements Conversion<any, any> {
  convert(item: any): any {
    return item;
  }
}

export const noConversion = new NoConversion();

export class AsStringConversion implements Conversion<any, string> {
  convert(item: any): string {
    if (item) {
      return "" + item;
    } else {
      return "";
    }
  }
}

export const asStringConversion = new AsStringConversion();
