import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store, createAction } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { EmptyMusicScore } from "src/app/models/music-score/music-score";
import { EmptyRecording } from "src/app/models/recording/recording";
import { LinkedMusicPieceService } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicService } from "src/app/services/music-piece/music-piece.service";
import { MusicScoreService } from "src/app/services/music-score/music-score.service";
import { RecordingService } from "src/app/services/recording/recording.service";
import { EditMusicPieceActions, MusicPieceActions } from "../actions";
import { AppState, EditMusicPieceSelectors } from "../reducers";

@Injectable({
  providedIn: "root",
})
export class EditMusicPieceEffects {
  loadMusicLinkedPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.openMusicPieceForEdit),
      filter((action) => action.isLinkedMusicPiece),
      mergeMap((action) =>
        this.linkedMusicPieceService
          .getLinkedMusicPieceWithMusicPiece(action.name)
          .pipe(
            map((data) =>
              EditMusicPieceActions.loaded({
                linkedMusicPieceWithMusicPiece: data,
              }),
            ),
          ),
      ),
    ),
  );

  loadMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.openMusicPieceForEdit),
      filter((action) => !action.isNew && !action.isLinkedMusicPiece),
      mergeMap((action) =>
        this.musicPieceService.getMusicPiece(action.name).pipe(
          map((data) =>
            EditMusicPieceActions.loaded({
              musicPiece: data,
            }),
          ),
        ),
      ),
    ),
  );

  createMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.save),
      filter((action) => action.name == undefined),
      mergeMap((action) =>
        this.musicPieceService
          .createMusicPiece(
            action.nameOfPiece,
            action.instrument,
            action.temperament,
            action.artist,
            action.description,
            action.collection,
          )
          .pipe(
            map((musicPiece) =>
              EditMusicPieceActions.saved({
                musicPiece: musicPiece,
              }),
            ),
          ),
      ),
      tap((mp) =>
        this.router.navigate(["musicpiece", "edit", mp.musicPiece.name]),
      ),
    ),
  );

  updateMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.save),
      filter((action) => action.name != undefined),
      mergeMap((action) =>
        this.musicPieceService
          .updateMusicPiece(
            action.name!,
            action.nameOfPiece,
            action.acceptShareTerms,
            action.instrument,
            action.temperament,
            action.artist,
            action.description,
            action.collection,
          )
          .pipe(
            map((musicPiece) =>
              EditMusicPieceActions.saved({
                musicPiece: musicPiece,
              }),
            ),
          ),
      ),
    ),
  );

  saveSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditMusicPieceActions.saved),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  deleteMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.deleteMusicPiece),
      mergeMap((action) =>
        this.musicPieceService
          .deleteMusicPiece(action.musicPiece, action.linkedMusicPiece)
          .pipe(
            map((_) =>
              EditMusicPieceActions.deletedMusicPiece({
                linkedMusicPiece: action.linkedMusicPiece,
                musicPiece: action.musicPiece,
              }),
            ),
            tap(
              (_) => {
                this.toastr.success(
                  this.translate.instant(
                    "musicPiecesTable.musicPieceDeletionSuccessful",
                  ),
                );
              },
              (error) => {
                console.error(error);
                this.toastr.error(
                  this.translate.instant(
                    "musicPiecesTable.musicPieceDeletionUnsuccessful",
                  ),
                );
              },
            ),
          ),
      ),
    ),
  );

  navigateToMusicPiecesWhenDelete$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditMusicPieceActions.deletedMusicPiece),
        tap((_) => this.router.navigate(["/"])),
      ),
    { dispatch: false },
  );

  loadRecording$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.loaded),
      filter(
        (action) =>
          action.musicPiece != undefined &&
          action.linkedMusicPieceWithMusicPiece == undefined,
      ),
      mergeMap((action) =>
        this.recordingService
          .getRecordingFromMusicPiece(action.musicPiece!, undefined)
          .pipe(
            map((recording) =>
              EditMusicPieceActions.loadedRecording({ recording }),
            ),
          ),
      ),
    ),
  );

  updateRecordingUsesMultipleTracks$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.updateRecordingUsesMultipleTracks),
      mergeMap((action) =>
        this.recordingService
          .updateRecordingUsesMultipleTracks(
            action.recording,
            action.enableMultipleTracks,
          )
          .pipe(
            map((r) => EditMusicPieceActions.loadedRecording({ recording: r })),
            tap(
              (_) =>
                this.toastr.success(this.translate.instant("common.success")),
              (_) => this.toastr.success(this.translate.instant("common.fail")),
            ),
          ),
      ),
    ),
  );

  loadMusicScore$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.loaded),
      filter(
        (action) =>
          action.musicPiece != undefined &&
          action.linkedMusicPieceWithMusicPiece == undefined,
      ),
      mergeMap((action) =>
        this.musicScoreService
          .getMusicScoreFromMusicPiece(action.musicPiece!, undefined)
          .pipe(
            map((musicScore) =>
              EditMusicPieceActions.loadedMusicScore({ musicScore }),
            ),
          ),
      ),
    ),
  );

  deleteMusicScore$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.deleteMusicScore),
      filter((action) => action.musicScore !== EmptyMusicScore),
      mergeMap((action) =>
        this.musicScoreService
          .deleteMusicScoreFromMusicPiece(action.musicScore)
          .pipe(
            map((_) =>
              EditMusicPieceActions.deletedMusicScore({
                musicScore: action.musicScore,
              }),
            ),
          ),
      ),
    ),
  );

  deleteRecordinfg$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.deleteRecording),
      filter((action) => action.recording !== EmptyRecording),
      mergeMap((action) =>
        this.recordingService
          .deleteRecordingFromMusicPiece(action.recording)
          .pipe(
            map((_) =>
              EditMusicPieceActions.deletedRecording({
                recording: action.recording,
              }),
            ),
          ),
      ),
    ),
  );

  showToastForDeleteActions$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditMusicPieceActions.deletedMusicScore,
          EditMusicPieceActions.deletedRecording,
        ),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  shareMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.shareMusicPiece),
      mergeMap((action) =>
        this.musicPieceService
          .share(action.musicPiece.name, action.receiver)
          .pipe(
            map((_) =>
              EditMusicPieceActions.sharedMusicPiece({
                musicPiece: action.musicPiece,
                receiver: action.receiver,
              }),
            ),
          ),
      ),
    ),
  );

  loadSharedMusicPieceReceivers$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        EditMusicPieceActions.loaded,
        EditMusicPieceActions.sharedMusicPiece,
        EditMusicPieceActions.unsharedMusicPiece,
      ),
      filter((action) => action.musicPiece != undefined),
      mergeMap((action) =>
        this.musicPieceService.getReceivers(action.musicPiece!.name).pipe(
          map((result) =>
            EditMusicPieceActions.loadedSharedMusicPieceReceivers({
              receivers: result,
            }),
          ),
        ),
      ),
    ),
  );

  unshareMusicPiece$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.unshareMusicPiece),
      mergeMap((action) =>
        this.musicPieceService.unshare(action.linkedMusicPiece).pipe(
          map((_) =>
            EditMusicPieceActions.unsharedMusicPiece({
              linkedMusicPiece: action.linkedMusicPiece,
              musicPiece: action.musicPiece,
            }),
          ),
        ),
      ),
    ),
  );

  unshareMusicPieceSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditMusicPieceActions.unsharedMusicPiece),
        tap((_) =>
          this.toastr.success(this.translate.instant("common.success")),
        ),
      ),
    { dispatch: false },
  );

  loadListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.openMusicPieceForEdit),
      mergeMap((action) =>
        this.musicPieceService.getListingForMusicPiece(action.name).pipe(
          map((listing) =>
            EditMusicPieceActions.musicPieceListingLoaded({
              listing: listing,
            }),
          ),
        ),
      ),
    ),
  );

  createListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.saveMusicPieceListing),
      filter((action) => action.name == undefined && action.isListing),
      mergeMap((action) =>
        this.musicPieceService
          .createListing(action.musicPiece, action.price)
          .pipe(
            map((result) =>
              EditMusicPieceActions.createdMusicPieceListing({
                listing: result,
              }),
            ),
          ),
      ),
    ),
  );

  updateListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.saveMusicPieceListing),
      filter((action) => action.name != undefined && action.isListing),
      mergeMap((action) =>
        this.musicPieceService.updateListing(action.name!, action.price).pipe(
          map((result) =>
            EditMusicPieceActions.updatedMusicPieceListing({
              listing: result,
            }),
          ),
        ),
      ),
    ),
  );

  deleteListing$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.saveMusicPieceListing),
      filter((action) => action.name != undefined && !action.isListing),
      mergeMap((action) =>
        this.musicPieceService
          .deleteListing(action.name!)
          .pipe(map((_) => EditMusicPieceActions.deletedMusicPieceListing())),
      ),
    ),
  );

  checkIfRenderIsFinished$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditMusicPieceActions.checkIfRenderIsFinished),
      concatLatestFrom((_) =>
        this.store.select(EditMusicPieceSelectors.selectMusicScoreAndRecording),
      ),
      filter(
        ([_, state]) =>
          state.recording != undefined && !state.recording.renderFinished,
      ),
      mergeMap(([_, state]) => {
        return this.recordingService.getRecordingByName(state.recording!.name);
      }),
      filter((recording) => recording.renderFinished),
      map((recording) =>
        EditMusicPieceActions.recordingRenderingFinished({
          recording: recording,
        }),
      ),
    ),
  );

  reloadMusicPieceWhenThumbnailIsChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        EditMusicPieceActions.deletedThumbnail,
        EditMusicPieceActions.thumbnailUploadFinished,
      ),
      map((action) =>
        EditMusicPieceActions.openMusicPieceForEdit({
          isLinkedMusicPiece: false,
          isNew: false,
          name: action.musicPiece.name,
        }),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly linkedMusicPieceService: LinkedMusicPieceService,
    private readonly musicPieceService: MusicService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly recordingService: RecordingService,
    private readonly musicScoreService: MusicScoreService,
  ) {}
}
