<yr-tool-bar-second-row>
  <div class="mx-auto w-full md:w-4/5">
    <yr-collection-control
      [canCreateNew]="canCreateNew"
      [canFilter]="canFilter"
      (addCollection)="addCollection()"
      (filterChange)="applyFilter($event)"
    ></yr-collection-control>
  </div>
</yr-tool-bar-second-row>

<div class="mx-auto mt-2 w-full md:w-4/5">
  <div class="mx-2">
    <yr-create-profile-hint></yr-create-profile-hint>
  </div>

  <yr-collections
    (editCollection)="editCollection($event)"
    (openLinkedCollection)="
      openLinkedCollection($event.collection, $event.linkedCollection)
    "
  ></yr-collections>
</div>
