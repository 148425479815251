<yr-tool-bar-second-row>
  <div class="mx-auto w-full md:w-4/5">
    <yr-music-pieces-controls
      [canCreateNew]="canCreateNew"
      (filterChange)="applyFilter($event)"
      (displayTypeChange)="changeDisplayType($event)"
      (addMusicPieceChange)="addMusicPiece()"
      [showFilter]="musicPieces.length > 0 || linkedMusicPieces.length > 0"
      [showFilterLinkedMusicPieces]="
        musicPieces.length > 0 || linkedMusicPieces.length > 0
      "
      [showDisplayChange]="
        shouldAllowTablesDisplayType &&
        (musicPieces.length > 0 || linkedMusicPieces.length > 0)
      "
    ></yr-music-pieces-controls>
  </div>
</yr-tool-bar-second-row>

<div class="w-full" yrResize (resized)="handleResize($event)">
  <!-- placeholder element for resize -->
</div>
<div class="mx-auto w-full md:w-4/5">
  <div class="mx-2 mt-2">
    <yr-create-profile-hint></yr-create-profile-hint>
  </div>

  <yr-no-items-indicator
    *ngIf="
      musicPieces.length == 0 && linkedMusicPieces.length == 0 && !isLoading
    "
    icon="home"
    text="{{ 'music-piece.music-piece-page.no-items-hint' | translate }}"
  ></yr-no-items-indicator>

  <yr-music-piece-cards
    *ngIf="displayType === 'cards'"
    [musicPiecesToDisplay]="filteredMusicPieces"
    [isLoading]="isLoading"
    [canCreateNew]="canCreateNew"
    [canShare]="canShare"
    [hasFreeStorageLeft]="hasFreeStorageLeft"
    [hasPracticeDurationLeft]="hasPracticeDurationLeft"
    (addMusicPieceChange)="addMusicPiece()"
    (editMusicPieceChange)="editMusicPiece($event)"
    (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
    (navigateToMusicPieceChange)="navigateToPlayer($event)"
    (createCopyFromLinkedMusicPieceChange)="
      createCopyFromLinkedMusicPiece($event)
    "
    [previews]="previews"
    [linkedMusicPiecePreviews]="linkedMusicPiecePreviews"
    [collectionData]="collectionData"
    [musicPieceToCollectionItemMapping]="musicPieceToCollectionItemMapping"
  ></yr-music-piece-cards>

  <yr-music-pieces-table
    *ngIf="displayType === 'list'"
    [musicPiecesToDisplay]="filteredMusicPieces"
    [isLoading]="isLoading"
    [canCreateNew]="canCreateNew"
    [canShare]="canShare"
    [hasFreeStorageLeft]="hasFreeStorageLeft"
    [hasPracticeDurationLeft]="hasPracticeDurationLeft"
    [collectionData]="collectionData"
    [musicPieceToCollectionItemMapping]="musicPieceToCollectionItemMapping"
    (addMusicPieceChange)="addMusicPiece()"
    (editMusicPieceChange)="editMusicPiece($event)"
    (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
    (navigateToMusicPieceChange)="navigateToPlayer($event)"
    (createCopyFromLinkedMusicPieceChange)="
      createCopyFromLinkedMusicPiece($event)
    "
  >
  </yr-music-pieces-table>
</div>
