import { PurchasedItemDto } from "src/app/dtos/purchase/purchased-item.dto";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { ExtendableConversion } from "../extendable-conversion";
import { CollectionPurchaseDtoConversion } from "../collection/collection-purchase-conversion";
import { MusicPiecePurchaseDtoConversion } from "../music-piece/music-piece-purchase-dto-conversion";
import { VideoFeedbackPurchaseConversionHelper } from "src/app/models/video-feedback/video-feedback-purchase";

export class PurchasedItemDtoConversion extends ExtendableConversion<
  PurchasedItemDto,
  PurchasedItem
> {
  private readonly collectionPurchaseConversion =
    new CollectionPurchaseDtoConversion();
  private readonly musicPiecePurchaseConversion =
    new MusicPiecePurchaseDtoConversion();

  private readonly videoFeedbackPurchaseConversion =
    VideoFeedbackPurchaseConversionHelper.wrapper().conversion();

  convert(dto: PurchasedItemDto): PurchasedItem {
    if (dto.purchase_type == "collection") {
      return {
        item: this.collectionPurchaseConversion.convert(
          dto.collection_purchase!,
        ),
        type: "collection",
      };
    } else if (dto.purchase_type == "music-piece") {
      return {
        item: this.musicPiecePurchaseConversion.convert(
          dto.music_piece_purchase!,
        ),
        type: "music-piece",
      };
    } else if (dto.purchase_type == "video-feedback") {
      return {
        item: this.videoFeedbackPurchaseConversion.convert(
          dto.video_feedback_purchase,
        ),
        type: "video-feedback",
      };
    } else {
      throw `PurchasedItemDtoConversion: invalid type: ${dto.purchase_type}`;
    }
  }
}
