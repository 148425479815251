<mat-progress-bar mode="indeterminate" *ngIf="!license"></mat-progress-bar>
<div *ngIf="license">
  <div fxLayout="row" fxLayout.lt-md="column">
    <div fxLayout="column">
      <yr-licensed-resource
        [available]="license.musicPieces"
        [title]="'user.license.used-music-pieces' | translate"
        [unit]="'user.license.music-pieces' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="millisecondsAsHours(license.practiceDurationThisMonthMs)"
        [title]="'user.license.practice-time' | translate"
        [unit]="'user.license.hours' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="bytesAsMegaBytes(license.storageBytes)"
        [title]="'user.license.storage' | translate"
        [unit]="'user.license.mega-bytes' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.canShare"
        [title]="'user.license.can-share' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.recordingPartGroups"
        [title]="'user.license.recording-part-groups' | translate"
        [unit]="'user.license.recording-part-groups' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.markers"
        [title]="'user.license.markers' | translate"
        [unit]="'user.license.markers' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.musicScorePages"
        [title]="'user.license.music-score-pages' | translate"
        [unit]="'user.license.music-score-pages' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.metronomes"
        [title]="'user.license.metronomes' | translate"
        [unit]="'user.license.metronomes' | translate"
      ></yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.canPitchShift"
        [title]="'user.license.can-pitch-shift' | translate"
      >
      </yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.collections"
        [title]="'user.license.collections' | translate"
        [unit]="'user.license.collections-unit' | translate"
      >
      </yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.collectionItems"
        [title]="'user.license.collection-items' | translate"
        [unit]="'user.license.collection-items-unit' | translate"
      >
      </yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.canShareCollections"
        [title]="'user.license.can-share-collection' | translate"
      >
      </yr-licensed-resource>
      <yr-licensed-resource
        [available]="license.practiceRoutines"
        [title]="'user.license.practice-routines' | translate"
        [unit]="'user.license.practice-routines-unit' | translate"
      >
      </yr-licensed-resource>
    </div>
    <div
      class="used-resources-container"
      fxLayout="column"
      *ngIf="usedResources"
    >
      <yr-licensed-used-resource
        [used]="usedMusicPieces"
        [available]="license.musicPieces"
        [title]="'user.license.used-music-pieces' | translate"
        [unit]="'user.license.music-pieces' | translate"
      ></yr-licensed-used-resource>

      <yr-licensed-used-resource
        [used]="usedStorage"
        [available]="bytesAsMegaBytes(license.storageBytes)"
        [title]="'user.license.used-storage' | translate"
        [unit]="'user.license.mega-bytes' | translate"
      ></yr-licensed-used-resource>

      <yr-licensed-used-resource
        [used]="usedPracticeTime"
        [available]="millisecondsAsHours(license.practiceDurationThisMonthMs)"
        [title]="'user.license.used-practice-time' | translate"
        [unit]="'user.license.hours' | translate"
      ></yr-licensed-used-resource>

      <yr-licensed-used-resource
        [used]="usedCollections"
        [available]="license.collections"
        [title]="'user.license.used-collections' | translate"
        [unit]="'user.license.collections' | translate"
      ></yr-licensed-used-resource>
      <yr-licensed-used-resource
        [used]="usedPracticeRoutines"
        [available]="license.practiceRoutines"
        [title]="'user.license.used-practice-routines' | translate"
        [unit]="'user.license.practice-routines-unit' | translate"
      ></yr-licensed-used-resource>
    </div>
  </div>
</div>
