import { UnitService } from "src/app/services/unit/unit.service";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { MusicPieceDto } from "src/app/dtos/music-piece/music-piece-dto";
import { MusicPiece } from "src/app/models/music-piece/music-piece";

import { Conversion } from "../conversion";

@Injectable({
  providedIn: "root",
})
export class MusicPieceDtoToMusicPieceConversion
  implements Conversion<MusicPieceDto, MusicPiece>
{
  private readonly us: UnitService = new UnitService();

  convert(item: MusicPieceDto): MusicPiece {
    return {
      name: item.name,
      nameOfPiece: item.name_of_piece,
      creation: this.us.parseApiDate(item.creation),
      lastTimeViewed: this.us.parseOptionalApiDate(item.last_time_viewed),
      isShared: item.is_shared > 0,
      sharedFrom: item.shared_from,
      isCopy: item.is_copy > 0,
      acceptShareTerms: item.accept_share_terms > 0,
      artist: item.artist,
      collection: item.collection,
      description: item.description,
      instrument: item.instrument,
      temperament: item.temperament,
      contentType: item.content_type,
      downloadLink: item.download_link,
      uploadFinished: item.upload_finished > 0,
    };
  }
}
