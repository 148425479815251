<div
  *ngIf="canUpgrade"
  class="my-2 flex h-[32px] min-h-[32px] w-[32px] flex-col items-center justify-center rounded-full bg-white p-0 text-base shadow-inner shadow-as-400 transition-all duration-100 hover:cursor-pointer hover:bg-gradient-to-r hover:from-white hover:to-gray-200 md:h-auto md:w-auto md:flex-row md:p-2"
  (click)="upgrade()"
>
  <mat-icon class="items-center text-as-500" svgIcon="crown"></mat-icon>
  <span class="hidden items-center pl-2 text-as-500 md:block">{{
    "user.upgrade-subscription-hint.upgrade" | translate
  }}</span>
</div>
