import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { Preview } from "src/app/models/music-piece/preview";
import { PlayerActions, ReadonlyCollectionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import {
  MusicPieceSelectors,
  ReadonlyCollectionSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { MusicPiecesControlsComponent } from "../../music-piece/music-pieces-controls/music-pieces-controls.component";
import { MusicPieceCardsComponent } from "../../music-piece/music-piece-cards/music-piece-cards.component";
import { MusicPiecesTableComponent } from "../../music-piece/music-pieces-table/music-pieces-table.component";
import { MaterialModule } from "src/app/modules/material.module";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { PracticeIntervalLinkedCollectionItemDialogData } from "src/app/models/collection/practice-interval-linked-collection-item-dialog-data";
import { PracticeIntervalLinkedCollectionItemDialogResult } from "src/app/models/collection/practice-interval-linked-collection-item-dialog-result";
import { PracticeIntervalCollectionItemDialogComponent } from "../../edit-collection/practice-interval-collection-item-dialog/practice-interval-collection-item-dialog.component";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { PracticeIntervalReadonlyCollectionItemDialogComponent } from "../practice-interval-readonly-collection-item-dialog/practice-interval-readonly-collection-item-dialog.component";

@Component({
  selector: "yr-readonly-collection-items",
  templateUrl: "./readonly-collection-items.component.html",
  styleUrls: ["./readonly-collection-items.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MusicPiecesControlsComponent,
    MusicPieceCardsComponent,
    MusicPiecesTableComponent,
    TranslateModule,
  ],
})
export class ReadonlyCollectionItemsComponent implements OnInit, OnDestroy {
  displayType: "cards" | "list" = "cards";

  filteredMusicPieces: MusicPieceToDisplay[] = [];

  hasPracticeDurationLeft = false;

  linkedMusicPiecePreviews: Preview[] = [];
  previews: Preview[] = [];

  linkedCollectionName?: string;

  isLoadingCollectionItems = true;

  linkedCollection?: StoredLinkedCollection;
  linkedCollectionItems: StoredLinkedCollectionItem[] = [];
  linkedMusicPieces: LinkedMusicPieceWithMusicPiece[] = [];

  private readonly subscriptionService = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(ReadonlyCollectionSelectors.selectReadonlyCollectionItems)
        .subscribe((data) => {
          if (data) {
            this.filteredMusicPieces = data.filteredCollectionItems.map(
              (i) => i.musicPiece,
            );
            this.isLoadingCollectionItems = data.isLoadingCollectionItems;
            this.linkedCollectionItems = data.collectionItems;
            this.linkedMusicPieces = data.linkedMusicPieces;
          } else {
            this.filteredMusicPieces = [];
          }
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(MusicPieceSelectors.selectPreviews)
        .subscribe((previews) => {
          this.previews = previews;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(MusicPieceSelectors.selectLinkedMusicPiecePreviews)
        .subscribe((previews) => {
          this.linkedMusicPiecePreviews = previews;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(UsedResourcesSelectors.hasPracticeDurationLeft)
        .subscribe((data) => {
          this.hasPracticeDurationLeft = data.hasPracticeDurationLeft;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(ReadonlyCollectionSelectors.selectReadonlyCollection)
        .subscribe((data) => {
          this.linkedCollection = data.linkedCollection;
          this.linkedCollectionName = data.linkedCollection?.name;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  applyFilter(filter: string) {
    this.store.dispatch(
      ReadonlyCollectionActions.filterCollectionItems({ filter: filter }),
    );
  }

  editLinkedMusicPiece(linkedMusicPiece: LinkedMusicPiece) {
    this.router.navigate(["musicpiece", "edit-share", linkedMusicPiece.name]);
  }

  navigateToPlayer(pair: LinkedMusicPieceWithMusicPiece) {
    if (!this.linkedCollectionName) return;

    this.store.dispatch(
      PlayerActions.musicPieceLoaded({
        musicPiece: pair.musicPiece,
        linkedMusicPiece: pair.linkedMusicPiece,
      }),
    );

    if (pair.linkedMusicPiece) {
      this.router.navigate([
        "/pl",
        "linkedCollection",
        this.linkedCollectionName,
        "linked",
        pair.linkedMusicPiece.name,
      ]);
    } else {
      this.router.navigate([
        "/pl",
        "linkedCollection",
        this.linkedCollectionName,
        "player",
        pair.musicPiece.name,
      ]);
    }
  }

  changeDisplayType(displayType: "cards" | "list") {
    this.displayType = displayType;
  }

  assignPracticeInterval() {
    const dialog = this.matDialog.open<
      PracticeIntervalReadonlyCollectionItemDialogComponent,
      PracticeIntervalLinkedCollectionItemDialogData,
      PracticeIntervalLinkedCollectionItemDialogResult
    >(PracticeIntervalReadonlyCollectionItemDialogComponent, {
      data: {
        existingCollectionItems: this.linkedCollectionItems,
        linkedMusicPieces: this.linkedMusicPieces,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          ReadonlyCollectionActions.editLinkedCollectionItems({
            updateCollectionItems: result.updatedCollectionItems,
          }),
        );
      }
    });
  }
}
