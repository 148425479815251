import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatomoTracker } from "ngx-matomo-client";
import { EMPTY, Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { UsedResourcesForUserDtoConversion } from "src/app/conversions/user/used-resources-for-user-dto-conversion";
import { UsedResourcesForUser } from "src/app/models/user/used-resources-for-user";
import { dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";

@Injectable({
  providedIn: "root",
})
export class UsedResourcesService {
  constructor(
    private readonly http: HttpClient,
    private readonly tracker: MatomoTracker,
  ) {}

  logPracticeTime(ms: number): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method =
      "yobi_rocks.controllers.used_resources_controller.log_practice_time";
    const data = {
      ms: Math.round(ms),
    };
    this.tracker.trackEvent("practice", "elapsed_practice_time", undefined, ms);

    return helper.callMethod(method, new MethodDataWrapper().withDto(data));
  }

  logVideoPlaybackTime(ms: number): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method =
      "yobi_rocks.controllers.used_resources_controller.log_video_playback_time";
    const data = {
      ms: Math.round(ms),
    };
    this.tracker.trackEvent("practice", "elapsed_playback_time", undefined, ms);
    return helper.callMethod(method, new MethodDataWrapper().withDto(data));
  }

  usedResourcesForUser(): Observable<UsedResourcesForUser> {
    const method =
      "api/method/yobi_rocks.controllers.used_resources_controller.used_resources";
    const conversion = new UsedResourcesForUserDtoConversion();
    const helper = new FrappeCrudHelper(
      "Used Resources",
      this.http,
      conversion,
    );

    return helper.getSingle("", method); // name is not required
  }
}
