import { SubscriptionHelper } from "@aht/frappe-client";
import { JsonPipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  computed,
  effect,
  signal,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SwPush } from "@angular/service-worker";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideAlertTriangle, lucideCheckCheck } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { PushNotificationSubscription } from "src/app/models/notification/push-subscription";
import { ServerPushNotificationSettings } from "src/app/models/notification/server-push-notification-settings";
import { UserNotificationSettings } from "src/app/models/notification/user-notification-settings";
import { NotificationSettingsActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { NotificationSettingsSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-notification-settings",
  templateUrl: "./notification-settings.component.html",
  standalone: true,
  imports: [TranslateModule, JsonPipe, ReactiveFormsModule, NgIconComponent],
  providers: [provideIcons({ lucideAlertTriangle, lucideCheckCheck })],
  styleUrls: [],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  serverSettings = signal<ServerPushNotificationSettings | undefined>(
    undefined,
  );
  isLoadingServerSettings = signal(false);
  isLoadingUserSettings = signal(false);
  isCreatingPushNotificationSubscription = signal(false);
  isSavingUserSettings = signal(false);

  isRegisteringSubscriptionInBrowser = signal(false);

  isSaving = computed(() => {
    return (
      this.isSavingUserSettings() ||
      this.isCreatingPushNotificationSubscription()
    );
  });

  isLoading = computed(() => {
    return this.isLoadingServerSettings() || this.isLoadingUserSettings();
  });

  userSettings = signal<UserNotificationSettings | undefined>(undefined);
  pushNotificationSubscription?: PushNotificationSubscription;

  push = new FormControl(false, {
    nonNullable: true,
    validators: [],
  });

  email = new FormControl(false, {
    nonNullable: true,
    validators: [],
  });

  form = new FormGroup({
    push: this.push,
    email: this.email,
  });

  private readonly helper = new SubscriptionHelper();

  constructor(private readonly store: Store<AppState>) {
    effect(() => {
      if (this.isLoading() || this.isSaving()) {
        this.push.disable({
          emitEvent: false,
        });
        this.email.disable({ emitEvent: false });
      } else {
        this.push.enable({ emitEvent: false });
        this.email.enable({ emitEvent: false });
      }
    });

    effect(() => {
      const settings = this.userSettings();

      this.email.setValue(settings?.email ?? false, {
        onlySelf: true, // otherwise we would cause an endless loop: saving -> updating -> saving -> ...
      });

      this.push.setValue(settings?.push ?? false, {
        onlySelf: true, // otherwise we would cause an endless loop: saving -> updating -> saving -> ...
      });
    });
  }

  ngOnInit(): void {
    this.helper.addMany(
      this.store
        .select(NotificationSettingsSelectors.notificationServerSettings)
        .subscribe((data) => {
          this.serverSettings.set(data.serverSettings);
          this.isLoadingServerSettings.set(data.isLoadingServerSettings);
        }),
      this.store
        .select(NotificationSettingsSelectors.notificationUserSettings)
        .subscribe((data) => {
          this.userSettings.set(data.userSettings);
          this.isLoadingUserSettings.set(data.isLoadingUserSettings);
          this.isSavingUserSettings.set(data.isSavingUserSettings);
        }),
      this.store
        .select(NotificationSettingsSelectors.pushNotificationSubscription)
        .subscribe((data) => {
          this.pushNotificationSubscription = data.pushNotificationSubscription;
          this.isCreatingPushNotificationSubscription.set(
            data.isCreatingPushNotificationSubscription,
          );
          this.isRegisteringSubscriptionInBrowser.set(
            data.isRegisteringSubscriptionInBrowser,
          );
        }),
      this.form.valueChanges.subscribe((_) => {
        this.saveUserSettings();
      }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  saveUserSettings() {
    if (this.form.valid) {
      this.store.dispatch(
        NotificationSettingsActions.saveUserSettings({
          settings: {
            email: this.email.value,
            push: this.push.value,
            enabled: this.email.value || this.push.value,
          },
        }),
      );
    }
  }

  registerPush() {
    this.store.dispatch(NotificationSettingsActions.tryFixPushRegistration());
  }
}
