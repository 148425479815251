<div class="card card-compact bg-base-100">
  <div class="card-body flex flex-col items-center md:items-stretch">
    <div class="flex flex-col-reverse items-center justify-between md:flex-row">
      <div class="flex flex-col">
        <div class="card-title text-sm">
          {{ notification().title }}
        </div>
        <div class="">
          {{ notification().message }}
        </div>
      </div>
      <div>
        @if (!notification().read) {
          <button
            class="btn btn-circle btn-ghost"
            (click)="markAsRead()"
            [disabled]="isMarkingAsRead()"
          >
            @if (isMarkingAsRead()) {
              <span class="loading loading-spinner"></span>
            } @else {
              <ng-icon name="lucideBellOff" size="1.5rem"></ng-icon>
            }
          </button>
        }
        <button (click)="openLink()" class="btn btn-circle btn-ghost">
          <ng-icon name="lucideLink" size="1.5rem"></ng-icon>
        </button>
      </div>
    </div>
    <div class="text-sm text-gray-500">
      {{
        notification().creation | date: ("common.dateTimeFormat" | translate)
      }}
    </div>
  </div>
</div>
