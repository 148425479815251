import { SubscriptionHelper } from "@aht/frappe-client";
import { DatePipe } from "@angular/common";
import { Component, input, OnDestroy, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideBellOff, lucideBellRing, lucideLink } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationTrigger } from "src/app/models/notification/notification-trigger";
import { NotificationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { NotificationSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-notification-item",
  templateUrl: "./notification-item.component.html",
  standalone: true,
  styleUrls: [],
  imports: [NgIconComponent, TranslateModule, DatePipe],
  providers: [provideIcons({ lucideBellOff, lucideLink })],
})
export class NotificationItemComponent implements OnInit, OnDestroy {
  notification = input.required<NotificationTrigger>();

  isMarkingAsRead = signal(false);

  private readonly helper = new SubscriptionHelper();

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store.select(NotificationSelectors.markAsRead).subscribe((data) => {
        this.isMarkingAsRead.set(
          data.isMarkingAllAsRead || data.isMarkingAsRead,
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  markAsRead() {
    this.isMarkingAsRead.set(true);

    this.store.dispatch(
      NotificationActions.markAsRead({
        notification: this.notification(),
      }),
    );
  }

  async openLink() {
    const url = this.notification().clickUrl;
    window.location.href = url;
  }
}
