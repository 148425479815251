import { Pipe, PipeTransform } from "@angular/core";
import {
  CollectionTypeData,
  MusicPieceToCollectionItemMapping,
} from "../models/collection/collection-type-data";

@Pipe({
  name: "collectionInfo",
  standalone: true,
})
export class CollectionInfoPipe implements PipeTransform {
  transform(
    mappings: MusicPieceToCollectionItemMapping[],
    collections: CollectionTypeData[],
  ): CollectionTypeData[] {
    const result = collections.filter((c) =>
      mappings.some((mp) => mp.collectionOrLinkedCollectionName == c.name),
    );

    return result;
  }
}
