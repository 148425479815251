<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingCreditStatements"
></mat-progress-bar>

<div
  class="mt-8 text-xl"
  *ngIf="creditStatements && creditStatements.length > 0"
>
  {{ "sales.credit-statements.credit-statements" | translate }}
</div>

<yr-credit-statement
  *ngFor="let cs of creditStatements"
  [creditStatement]="cs.creditStatement"
  [creditPayout]="cs.payout"
  (download)="handleDownload($event)"
></yr-credit-statement>
