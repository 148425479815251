<mat-card appearance="outlined" class="controls-card">
  <mat-card-content>
    <div class="controls" fxLayout="row wrap" fxLayoutAlign="start center">
      <yr-music-pieces-controls
        [canCreateNew]="false"
        [showNew]="false"
        (filterChange)="applyFilter($event.filter)"
        (displayTypeChange)="changeDisplayType($event)"
      ></yr-music-pieces-controls>

      @if (
        linkedCollection?.isPracticeRoutine && linkedCollectionItems.length > 0
      ) {
        <button
          class="control-button"
          color="primary"
          mat-raised-button
          (click)="assignPracticeInterval()"
        >
          {{
            "readonly-collection.readonly-collection-items.practice-interval"
              | translate
          }}
        </button>
      }
    </div>
  </mat-card-content>
</mat-card>

<yr-music-piece-cards
  *ngIf="displayType === 'cards'"
  [musicPiecesToDisplay]="filteredMusicPieces"
  [hasPracticeDurationLeft]="hasPracticeDurationLeft"
  (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
  (navigateToMusicPieceChange)="navigateToPlayer($event)"
  [linkedMusicPiecePreviews]="linkedMusicPiecePreviews"
  [previews]="previews"
  [disableCreateCopy]="true"
  [isLoading]="isLoadingCollectionItems"
></yr-music-piece-cards>

<yr-music-pieces-table
  *ngIf="displayType === 'list'"
  [musicPiecesToDisplay]="filteredMusicPieces"
  [hasPracticeDurationLeft]="hasPracticeDurationLeft"
  [isLoading]="isLoadingCollectionItems"
  (editLinkedMusicPieceChange)="editLinkedMusicPiece($event)"
  (navigateToMusicPieceChange)="navigateToPlayer($event)"
  [disableCreateCopy]="true"
></yr-music-pieces-table>
