import { Component, effect, OnDestroy, OnInit, signal } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import { AttachmentUploadResult } from "src/app/models/music-piece/attachment-upload-result";
import { UserData } from "src/app/models/user/user-data";
import { EditCollectionActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import {
  EditCollectionSelectors,
  UsedResourcesSelectors,
} from "src/app/ngrx/selectors";
import { CollectionService } from "src/app/services/collection/collection.service";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogHelper,
} from "../../common/confirmation-dialog/confirmation-dialog.component";
import {
  CreateOrGetDocument,
  PrepareUpload,
  FinishUpload,
} from "src/app/models/common/upload-types";
import { ImageUploadDialogData } from "src/app/models/common/image-upload-dialog-data";
import { ImageUploadDialogResult } from "src/app/models/common/image-upoload-dialog-result";
import { ImageUploadDialogComponent } from "../../common/image-upload-dialog/image-upload-dialog.component";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";
import { Router } from "@angular/router";
import { hasFreePracticeRoutines } from "src/app/ngrx/selectors/used-resources.selector";

@Component({
  selector: "yr-edit-collection-form",
  templateUrl: "./edit-collection-form.component.html",
  styleUrls: ["./edit-collection-form.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class EditCollectionFormComponent implements OnInit, OnDestroy {
  isLoadingCollection: boolean = false;
  isNew: boolean = false;

  collection?: StoredCollection;

  title = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(140),
  ]);

  instrument = new FormControl("", [Validators.maxLength(140)]);

  temperament = new FormControl("", [Validators.maxLength(140)]);

  artist = new FormControl("", [Validators.maxLength(140)]);

  description = new FormControl("", [Validators.maxLength(200)]); // actual db-limit is 65535

  isPracticeRoutine = new FormControl(false);

  form = new FormGroup({
    title: this.title,
    instrument: this.instrument,
    temperament: this.temperament,
    artist: this.artist,
    description: this.description,
    isPracticeRoutine: this.isPracticeRoutine,
  });

  loggedInUser?: UserData;

  isUploadingPicture: boolean = false;

  hasLinkedCollectionReceivers = false;

  hasFreePracticeRoutines = signal<boolean>(false);

  private readonly subscriptionService = new SubscriptionService();

  constructor(
    private readonly store: Store<AppState>,
    private readonly collectionService: CollectionService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
  ) {
    effect(() => {
      this.setDisabledStateOfPracticeRoutine();
    });
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  ngOnInit(): void {
    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectEditCollectionData)
        .subscribe((data) => {
          this.isLoadingCollection = data.isLoadingCollection;
          this.isNew = data.isNew;

          this.collection = data.collection;
          this.patchFormValue();
        }),
    );
    this.subscriptionService.add(
      this.store.select(UserSelectors.currentUser).subscribe((user) => {
        this.loggedInUser = user;
      }),
    );

    this.subscriptionService.add(
      this.store
        .select(EditCollectionSelectors.selectLinkedCollections)
        .subscribe((data) => {
          this.hasLinkedCollectionReceivers = data.linkedCollections.length > 0;
        }),
    );

    this.subscriptionService.add(
      this.store
        .select(UsedResourcesSelectors.hasFreePracticeRoutines)
        .subscribe((hasFreePracticeRoutines) => {
          this.hasFreePracticeRoutines.set(hasFreePracticeRoutines);
        }),
    );
  }

  private patchFormValue() {
    if (this.collection) {
      this.title.setValue(this.collection.title);
      this.instrument.setValue(this.collection.instrument);
      this.temperament.setValue(this.collection.temperament);
      this.artist.setValue(this.collection.artist);
      this.description.setValue(this.collection.description);
      this.isPracticeRoutine.setValue(this.collection.isPracticeRoutine);

      this.setDisabledStateOfPracticeRoutine();
    }
  }

  private setDisabledStateOfPracticeRoutine() {
    if (!this.hasFreePracticeRoutines() && !this.isPracticeRoutine.value) {
      this.isPracticeRoutine.disable();
    } else {
      this.isPracticeRoutine.enable();
    }
  }

  save() {
    this.store.dispatch(
      EditCollectionActions.saveCollection({
        title: this.title.value!,
        name: this.collection?.name,
        isNew: this.isNew,
        artist: this.artist.value!,
        description: this.description.value!,
        instrument: this.instrument.value!,
        temperament: this.temperament.value!,
        acceptShareTerms: this.collection?.acceptShareTerms ?? false,
        isPracticeRoutine: this.isPracticeRoutine.value || false,
      }),
    );
  }

  isOwner() {
    return (
      this.collection &&
      this.loggedInUser &&
      this.loggedInUser.username == this.collection.owner
    );
  }

  getCollection(): CreateOrGetDocument {
    return () => {
      if (!this.collection) {
        return Promise.reject("collection not set");
      }

      return Promise.resolve(this.collection);
    };
  }

  prepareUploadUrl(): PrepareUpload {
    return (name: string) => this.collectionService.prepareUploadUrl(name);
  }

  finishUpload(): FinishUpload {
    return (name: string) => {
      return this.collectionService.markUploadAsFinished(name);
    };
  }

  uploadChanged(result: AttachmentUploadResult) {
    if (result) {
      if (result.result == "Success") {
        this.toastr.success(this.translate.instant("common.success"));

        if (this.collection) {
          this.store.dispatch(
            EditCollectionActions.openCollectionForEdit({
              isNew: false,
              name: this.collection.name,
            }),
          );
        }
      } else {
        console.log(`upload failed: ${result?.error}`);
        this.toastr.error(this.translate.instant("common.fail"));
      }
    }
  }

  uploadInProgress(isUploading: boolean) {
    this.isUploadingPicture = isUploading;
  }

  openImageUploadDialog() {
    const uploadInProgress = new Subject<boolean>();
    const uploadChanged = new Subject<AttachmentUploadResult>();

    uploadInProgress.subscribe((e) => this.uploadInProgress(e));
    uploadChanged.subscribe((e) => this.uploadChanged(e));

    const ref = this.matDialog.open<
      ImageUploadDialogComponent,
      ImageUploadDialogData,
      ImageUploadDialogResult
    >(ImageUploadDialogComponent, {
      data: {
        height: (500 * 9) / 16,
        width: 500,
        accept: "image/*",
        prepareUpload: this.prepareUploadUrl(),
        finishUpload: this.finishUpload(),
        createOrGetDocument: this.getCollection(),
        uploadChange: uploadChanged,
        uploadInProgress: uploadInProgress,
      },
      maxHeight: "80vh",
      minWidth: "20vw",
      maxWidth: "80vw",
    });

    const subscription = ref.afterClosed().subscribe((result) => {
      uploadChanged.unsubscribe();
      uploadInProgress.unsubscribe();
      subscription.unsubscribe();
    });
  }

  private showDeleteDialog(): Observable<boolean> {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title:
          "edit-collection.edit-collection-form.delete-picture-dialog.title",
        content:
          "edit-collection.edit-collection-form.delete-picture-dialog.content",
      },
    });

    return ref.afterClosed().pipe(map((r) => r == true));
  }

  deleteProfilePicture() {
    this.showDeleteDialog().subscribe(async (result) => {
      if (result && this.collection) {
        await this.collectionService.deleteProfilePicture(this.collection.name);
        this.store.dispatch(
          EditCollectionActions.openCollectionForEdit({
            isNew: false,
            name: this.collection.name,
          }),
        );
      }
    });
  }

  delete() {
    const helper = new ConfirmationDialogHelper(
      this.matDialog,
      "edit-collection.edit-collection-form.delete-dialog.title",
      "edit-collection.edit-collection-form.delete-dialog.content",
    );
    helper.show(() => {
      if (this.collection) {
        this.store.dispatch(
          EditCollectionActions.deleteCollection({
            collection: this.collection,
          }),
        );
      }
    });
  }

  navigateToPracticeRoutine() {
    // practice-routines/:collectionType/:collection
    if (this.collection) {
      this.router.navigate(["/practice-routines", "c", this.collection.name]);
    }
  }
}
