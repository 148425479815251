import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { PurchaseActions } from "src/app/ngrx/actions";
import { AppState, UserSelectors } from "src/app/ngrx/reducers";
import { ConfirmationDialogHelper } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";

import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { PaymentDialogComponent } from "../payment-dialog/payment-dialog.component";
import { PaymentDialogData } from "src/app/models/artist/payment-dialog-data";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/modules/material.module";
import { PurchaseSelectors } from "src/app/ngrx/selectors";
import {
  PurchaseDetails,
  CollectionPurchaseDetails,
} from "src/app/ngrx/selectors/purchase.selector";

@Component({
  selector: "yr-purchase-button",
  templateUrl: "./purchase-button.component.html",
  styleUrls: ["./purchase-button.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class PurchaseButtonComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new SubscriptionService();

  @Input()
  musicPieceListing?: MusicPieceListing;

  @Input()
  collectionListing?: ShowcaseCollectionListing;

  private purchasedLinkedMusicPiece?: LinkedMusicPiece;
  private purchasedLinkedCollection?: StoredLinkedCollection;

  isLoggedIn = false;

  hasPendingPayment = false;

  requiresPayment = false;

  hasAlreadyPurchased = false;

  isFreeItem = false;

  isSelfListing = false;

  purchaseInProgress = false;

  purchaseDetails?: PurchaseDetails | CollectionPurchaseDetails;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(UserSelectors.isLoggedIn)
        .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn)),
    );

    if (this.musicPieceListing) {
      this.subscriptions.add(
        this.store
          .select(
            PurchaseSelectors.selectPurchaseDetailsForListing(
              this.musicPieceListing,
            ),
          )
          .subscribe((purchaseDetails) => {
            this.purchaseDetails = purchaseDetails;

            this.purchasedLinkedMusicPiece = purchaseDetails.linkedMusicPiece;
            const purchaseStatus = purchaseDetails.status;
            this.setFlagsFromPurchaseStatus(purchaseStatus);
          }),
      );

      this.isFreeItem = this.musicPieceListing.price == 0;
    } else if (this.collectionListing) {
      this.subscriptions.add(
        this.store
          .select(
            PurchaseSelectors.selectPurchaseDetailsForCollectionListing(
              this.collectionListing,
            ),
          )
          .subscribe((purchaseDetails) => {
            this.purchaseDetails = purchaseDetails;

            this.purchasedLinkedCollection = purchaseDetails.linkedCollection;
            const purchaseStatus = purchaseDetails.status;
            this.setFlagsFromPurchaseStatus(purchaseStatus);
          }),
      );

      this.isFreeItem = this.collectionListing.price == 0;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  private setFlagsFromPurchaseStatus(
    purchaseStatus: PurchaseSelectors.PurchaseStatus,
  ) {
    switch (purchaseStatus) {
      case PurchaseSelectors.PurchaseStatus.PENDING_PAYMENT:
        this.hasPendingPayment = true;
        this.isSelfListing = false;
        this.requiresPayment = false;
        this.hasAlreadyPurchased = false;
        break;
      case PurchaseSelectors.PurchaseStatus.PURCHASED:
        this.hasAlreadyPurchased = true;
        this.hasPendingPayment = false;
        this.isSelfListing = false;
        this.requiresPayment = false;
        break;
      case PurchaseSelectors.PurchaseStatus.SELF_ITEM:
        this.isSelfListing = true;
        this.hasPendingPayment = false;
        this.requiresPayment = false;
        this.hasAlreadyPurchased = false;
        break;
      case PurchaseSelectors.PurchaseStatus.REQUIRES_PAYMENT:
        this.requiresPayment = true;
        this.isSelfListing = false;
        this.hasPendingPayment = false;
        this.hasAlreadyPurchased = false;
        break;
      default:
        break;
    }
  }

  buy() {
    const showDialog = (data: PaymentDialogData) => {
      new ConfirmationDialogHelper(
        this.matDialog,
        "artist.purchase-button.buy-dialog-title",
        "artist.purchase-button.buy-dialog-content",
      ).show(() => {
        this.purchaseInProgress = true;

        this.matDialog.open<PaymentDialogComponent, PaymentDialogData>(
          PaymentDialogComponent,
          {
            data: data,
            minWidth: "40vw",
            disableClose: true,
            hasBackdrop: false,
          },
        );
      });
    };

    if (this.musicPieceListing) {
      if (this.isFreeItem) {
        this.store.dispatch(
          PurchaseActions.purchase({
            item: {
              item: this.musicPieceListing,
              type: "music-piece",
            },
          }),
        );
      } else if (this.purchaseDetails) {
        showDialog({
          purchaseDetails: this.purchaseDetails,
          purchaseType: "music-piece",
        });
      }
    } else if (this.collectionListing) {
      if (this.isFreeItem) {
        this.store.dispatch(
          PurchaseActions.purchase({
            item: {
              item: this.collectionListing,
              type: "collection",
            },
          }),
        );
      } else if (this.purchaseDetails) {
        showDialog({
          purchaseDetails: this.purchaseDetails,
          purchaseType: "collection",
        });
      }
    }
  }

  completePayment() {
    if (this.purchaseDetails) {
      this.purchaseInProgress = true;
      const dialog = this.matDialog.open<
        PaymentDialogComponent,
        PaymentDialogData
      >(PaymentDialogComponent, {
        data: {
          purchaseDetails: this.purchaseDetails,
          purchaseType:
            this.musicPieceListing != undefined ? "music-piece" : "collection",
        },
        minWidth: "40vw",
        disableClose: true,
        hasBackdrop: false,
      });
      dialog.afterClosed().subscribe((_) => (this.purchaseInProgress = false));
    }
  }

  open() {
    if (this.musicPieceListing) {
      if (this.isSelfListing) {
        this.router.navigate(["/player", this.musicPieceListing.musicPiece]);
      } else if (this.hasAlreadyPurchased && this.purchasedLinkedMusicPiece) {
        this.router.navigate(["/linked", this.purchasedLinkedMusicPiece.name]);
      }
    } else if (this.collectionListing) {
      if (this.isSelfListing) {
        this.router.navigate([
          "/collection",
          "edit",
          this.collectionListing.collection,
        ]);
      } else if (this.hasAlreadyPurchased && this.purchasedLinkedCollection) {
        this.router.navigate([
          "/linked-collection",
          this.purchasedLinkedCollection.name,
        ]);
      }
    }
  }

  forceLogin() {
    this.router.navigate(["/login"]);
  }
}
