import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CreateRecordingResponseDtoToreRecordingConversion } from "src/app/conversions/recording/create-recording-response-dto-to-recording-conversion";
import { RecordingDtoToRecordingConversion } from "src/app/conversions/recording/recording-dto-to-recording-conversion";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { EmptyRecording, Recording } from "src/app/models/recording/recording";
import { asStringConversion, dropConversion } from "../conversion-helper";
import { FrappeCrudHelper } from "../frappe-crud-helper";
import { FrappeMethodHelper, MethodDataWrapper } from "../frappe-method-helper";
import {
  FrappeRequestHelper,
  FrappeRequestFilter,
} from "../frappe-request-helper";

@Injectable({
  providedIn: "root",
})
export class RecordingService {
  constructor(private http: HttpClient) {}

  createRecording(forMusicPiece: string): Observable<Recording> {
    const conversion = new CreateRecordingResponseDtoToreRecordingConversion();
    const helper = new FrappeCrudHelper("Recording", this.http, conversion);

    const dto = { music_piece: forMusicPiece };
    return helper.create(dto);
  }

  createRecordingDownloadLink(
    musicPiece: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): Observable<Recording> {
    const createDownloadUrl: () => Observable<void> = () => {
      const methodHelper = new FrappeMethodHelper(this.http, dropConversion);
      const data = new MethodDataWrapper().withDto({
        music_piece: musicPiece.name,
        linked_music_piece: linkedMusicPiece?.name || "", // otherwise we get 500 error as arguments are missing
      });
      return methodHelper.callMethod(
        "yobi_rocks.controllers.recording_controller.create_download_file_url",
        data,
      );
    };

    // we could make this better by not loading the recording twice.
    // Also we could optimize this by creating a controller method which would take the name of the music piece and find the recording accordingly
    return this.getRecordingFromMusicPiece(musicPiece, linkedMusicPiece).pipe(
      mergeMap((recording) => {
        if (!recording || recording == EmptyRecording) {
          return of(EmptyRecording);
        } else {
          return createDownloadUrl().pipe(
            mergeMap((_) =>
              this.getRecordingFromMusicPiece(musicPiece, linkedMusicPiece),
            ),
          );
        }
      }),
    );
  }

  deleteRecordingFromMusicPiece(recording: Recording): Observable<void> {
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const method = "yobi_rocks.controllers.recording_controller.delete";
    const data = new MethodDataWrapper().withParam("name", recording.name);

    return helper.callMethod(method, data, "DELETE");
  }

  getRecordingFromMusicPiece(
    musicPiece: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): Observable<Recording> {
    const conversion = new RecordingDtoToRecordingConversion();

    if (linkedMusicPiece) {
      const crudHelper = new FrappeCrudHelper("", this.http, conversion);
      const method =
        "/api/method/yobi_rocks.controllers.recording_controller.get_recording_by_linked_music_piece";
      const queryHelper = new FrappeRequestHelper().withParam(
        "music_piece",
        musicPiece.name,
      );

      return crudHelper.getSingle("", method, queryHelper);
    } else {
      const queryHelper = new FrappeRequestHelper()
        .withFields([
          "name",
          "music_piece",
          "download_link",
          "content_type",
          "content_type_original_file",
          "rotation",
          "mirror_vertical",
          "mirror_horizontal",
          "render_finished",
          "upload_finished",
          "use_multiple_audio_tracks",
        ])
        .withFilters([
          new FrappeRequestFilter("music_piece", "=", musicPiece.name),
        ]);

      return new FrappeCrudHelper("Recording", this.http, conversion)
        .queryAll(queryHelper)
        .pipe(map((recordings) => recordings[0]));
    }
  }

  getRecordingByName(name: string): Observable<Recording> {
    const conversion = new RecordingDtoToRecordingConversion();
    return new FrappeCrudHelper("Recording", this.http, conversion).getSingle(
      name,
    );
  }

  prepareRecordingUpload(recording: string): Promise<{ uploadUrl: string }> {
    const method =
      "yobi_rocks.controllers.recording_controller.create_upload_file_url";
    const helper = new FrappeMethodHelper(this.http, asStringConversion);

    const obs = helper
      .callMethod(method, new MethodDataWrapper().withDto({ name: recording }))
      .pipe(
        map((uploadUrl) => {
          return {
            uploadUrl: uploadUrl,
          };
        }),
      );
    return firstValueFrom(obs);
  }

  markUploadAsFinished(recording: string): Promise<void> {
    const method =
      "yobi_rocks.controllers.recording_controller.upload_finished";
    const helper = new FrappeMethodHelper(this.http, dropConversion);
    const obs = helper.callMethod(
      method,
      new MethodDataWrapper().withDto({ name: recording }),
    );
    return firstValueFrom(obs);
  }

  updateRecordingUsesMultipleTracks(
    recording: string,
    useMultipleAudioTracks: boolean,
  ): Observable<Recording> {
    const helper = new FrappeCrudHelper(
      "Recording",
      this.http,
      new RecordingDtoToRecordingConversion(),
    );
    return helper.update(recording, {
      use_multiple_audio_tracks: useMultipleAudioTracks,
    });
  }
}
